import React from 'react';

interface CardFooterProps extends React.PropsWithChildren {
    readonly className?: string;
}

const CardFooter = (props: CardFooterProps): React.JSX.Element => {
    const buildClassName = (): string => {
        let className: string = 'p-4 mt-auto';

        if (props.className !== undefined) {
            className += ' ' + props.className;
        }

        return className;
    };

    return (
        <div className={buildClassName()}>
            {props.children}
        </div>
    );
};

export default CardFooter;
