import Conversation from '../Entity/Messaging/Conversation';
import ProviderSeekerConversation from '../Entity/Messaging/ProviderSeekerConversation';
import ConversationItem from './ConversationHeader/ConversationItem';
import React from 'react';

interface ConversationHeaderProps {
    readonly conversation: Conversation;
    readonly reloadConversation?: () => void;
}

const ConversationHeader = (props: ConversationHeaderProps): React.JSX.Element => {
    // TODO WiP
    const conversation: ProviderSeekerConversation = props.conversation as ProviderSeekerConversation;

    return (
        <div className="row align-items-center">
            <div className="col-md-12 col-lg-5">
                <ConversationItem conversation={conversation} reference={conversation.firstReference} from={conversation.firstReferenceFrom.name} referenceType={conversation.firstReferenceType} reloadConversation={props.reloadConversation} />
            </div>
            <div className="col">
                <div className="mt-3 mb-3 mt-lg-0 mb-lg-0 text-primary text-center">It's a match!</div>
            </div>
            <div className="col-md-12 col-lg-5">
                <ConversationItem conversation={conversation} reference={conversation.secondReference} from={conversation.secondReferenceFrom.name} referenceType={conversation.secondReferenceType} />
            </div>
        </div>
    );
};

export default ConversationHeader;
