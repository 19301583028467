import dividerStyle from './Divider.module.scss';
import React from 'react';

interface DividerProps extends React.PropsWithChildren {
    readonly color: 'primary' | 'grey' | 'lightgrey';
}

const Divider = (props: DividerProps): React.JSX.Element => {
    const determineDividerClassName = (): string => {
        switch (props.color) {
            case 'primary':
                return dividerStyle.dividerPrimary;
            case 'grey':
                return dividerStyle.dividerGrey;
            case 'lightgrey':
                return dividerStyle.dividerLightgrey;
        }
    };

    const determineSeparatorClassName = (): string => {
        switch (props.color) {
            case 'primary':
                return dividerStyle.separatorPrimary;
            case 'grey':
                return dividerStyle.separatorGrey;
            case 'lightgrey':
                return dividerStyle.separatorLightgrey;
        }
    };

    if (props.children === undefined) {
        return (
            <div className={['mt-5', 'mb-5', determineDividerClassName()].join(' ')} />
        );
    }

    return (
        <div className="mw-100">
            <div className={['mt-3', determineSeparatorClassName()].join(' ')}>{props.children}</div>
        </div>
    );
};

export default Divider;
