enum ProductType {
    MatchPointContingent = 0,
    MatchingResultUnlockFlatRate = 1,
    HallOfInspiration = 2
}

const getProductTypeLabel = (productType: ProductType): string => {
    switch (productType) {
        case ProductType.MatchPointContingent:
            return 'Kontingent an MatchPoints';
        case ProductType.MatchingResultUnlockFlatRate:
            return 'Flatrate';
        case ProductType.HallOfInspiration:
            return 'Hall of Inspiration';
        default:
            throw new Error();
    }
};

export default ProductType;
export { getProductTypeLabel };
