enum OrderStep {
    MatchPointSelection = 0,
    OrderOverview = 1,
    OnlinePayment = 2,
    InvoicePayment = 3,
    OnlinePaymentOrderConfirmation = 4,
    InvoicePaymentOrderConfirmation = 5
}

export default OrderStep;
