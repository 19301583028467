enum MarketingStatus {
    Available = 0,
    Reserved = 1,
    Allocated = 2
}

const getMarketingStatusLabel = (marketingStatus: MarketingStatus): string => {
    switch (marketingStatus) {
        case MarketingStatus.Available:
            return 'Verfügbar';
        case MarketingStatus.Reserved:
            return 'Reserviert';
        case MarketingStatus.Allocated:
            return 'Nicht mehr verfügbar';
    }
};

export default MarketingStatus;
export {getMarketingStatusLabel};
