import Property from '../../Entity/Property/Property';
import EnergyCertificateType, {getEnergyCertificateTypeLabel} from '../../Entity/Property/EnergyCertificate/EnergyCertificateType';
import {getEnergyEfficiencyClassLabel} from '../../Entity/Property/EnergyCertificate/EnergyEfficiencyClass';
import EnergyCertificateHeatingType, {getEnergyCertificateHeatingTypeLabel} from '../../Entity/Property/EnergyCertificate/EnergyCertificateHeatingType';
import CollapseCard from '../../Component/CollapseCard/CollapseCard';
import LabelValuePair from '../../../../components/LabelValuePair';
import LabelValuesPair from '../../../../components/LabelValuesPair';
import React from 'react';

interface EnergyCertificateCollapseCardProps {
    readonly property: Property;
}

const EnergyCertificateCollapseCard = (props: EnergyCertificateCollapseCardProps): React.JSX.Element => {
    const energyCertificateHeatingTypeValues: string[] = [];

    if (props.property.energyCertificate !== null) {
        props.property.energyCertificate.energyCertificateHeatingTypes.forEach((energyCertificateHeatingType: EnergyCertificateHeatingType): void => {
            energyCertificateHeatingTypeValues.push(getEnergyCertificateHeatingTypeLabel(energyCertificateHeatingType));
        });
    }

    if (props.property.energyCertificate === null) {
        return <></>;
    }

    return (
        <CollapseCard cardType="shadow" title="Energieausweis" className="mb-3">
            <div className="row">
                <div className="col-12 col-lg-4 mb-3">
                    <LabelValuePair
                        label="Art des Energieausweises"
                        value={getEnergyCertificateTypeLabel(props.property.energyCertificate.energyCertificateType)}
                    />
                </div>
                {props.property.energyCertificate.issueDate !== null &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair
                            label="Ausstellungsdatum"
                            value={props.property.energyCertificate.issueDate.toLocaleDateString('de-DE', {year: 'numeric', month: 'numeric', day: 'numeric'})}
                        />
                    </div>
                }
                {props.property.energyCertificate.expirationDate !== null &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair
                            label="Gültig bis"
                            value={props.property.energyCertificate.expirationDate.toLocaleDateString('de-DE', {year: 'numeric', month: 'numeric', day: 'numeric'})}
                        />
                    </div>
                }
            </div>
            <div className="row">
                {props.property.energyCertificate.buildingConstructionYear !== null &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair
                            label="Baujahr"
                            value={props.property.energyCertificate.buildingConstructionYear.toString()}
                        />
                    </div>
                }
                {props.property.energyCertificate.energyEfficiencyClass !== null &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair
                            label="Energieeffizienzklasse"
                            value={getEnergyEfficiencyClassLabel(props.property.energyCertificate.energyEfficiencyClass)}
                        />
                    </div>
                }
            </div>
            <div className="row">
                {props.property.energyCertificate.energyCertificateHeatingTypes.length > 0 &&
                    <div className="col-12 mb-3">
                        <LabelValuesPair
                            label="Befeuerungsart"
                            values={energyCertificateHeatingTypeValues}
                            delimiter=", "
                        />
                    </div>
                }
            </div>
            {props.property.energyCertificate.energyCertificateType === EnergyCertificateType.EnergyRequirementCertificate &&
                <div className="row">
                    {props.property.energyCertificate.energyDemand !== null &&
                        <div className="col-12 col-md-6 col-lg-4 mb-3">
                            {props.property.energyCertificate.withHotWater === true &&
                                <LabelValuePair
                                    label="Endenergiebedarf (inkl. Warmwasser)"
                                    value={new Intl.NumberFormat('de-DE').format(props.property.energyCertificate.energyDemand) + ' kWh/m²/Jahr'}
                                />
                            }
                            {props.property.energyCertificate.withHotWater === false &&
                                <LabelValuePair
                                    label="Endenergiebedarf (exkl. Warmwasser)"
                                    value={new Intl.NumberFormat('de-DE').format(props.property.energyCertificate.energyDemand) + ' kWh/m²/Jahr'}
                                />
                            }
                        </div>
                    }
                    {props.property.energyCertificate.electricityDemand !== null &&
                        <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <LabelValuePair
                                label="Endenergiebedarf Strom"
                                value={new Intl.NumberFormat('de-DE').format(props.property.energyCertificate.electricityDemand) + ' kWh/m²/Jahr'}
                            />
                        </div>
                    }
                    {props.property.energyCertificate.heatingEnergyDemand !== null &&
                        <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <LabelValuePair
                                label="Endenergiebedarf Wärme"
                                value={new Intl.NumberFormat('de-DE').format(props.property.energyCertificate.heatingEnergyDemand) + ' kWh/m²/Jahr'}
                            />
                        </div>
                    }
                </div>
            }
            {props.property.energyCertificate.energyCertificateType === EnergyCertificateType.EnergyConsumptionCertificate &&
                <div className="row">
                    {props.property.energyCertificate.energyConsumption !== null &&
                        <div className="col-12 col-md-6 col-lg-4 mb-3">
                            {props.property.energyCertificate.withHotWater === true &&
                                <LabelValuePair
                                    label="Energieverbrauchkennwert (inkl. Warmwasser)"
                                    value={new Intl.NumberFormat('de-DE').format(props.property.energyCertificate.energyConsumption) + ' kWh/m²/Jahr'}
                                />
                            }
                            {props.property.energyCertificate.withHotWater === false &&
                                <LabelValuePair
                                    label="Energieverbrauchkennwert (exkl. Warmwasser)"
                                    value={new Intl.NumberFormat('de-DE').format(props.property.energyCertificate.energyConsumption) + ' kWh/m²/Jahr'}
                                />
                            }
                        </div>
                    }
                    {props.property.energyCertificate.electricityConsumption !== null &&
                        <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <LabelValuePair
                                label="Stromverbrauchskennwert"
                                value={new Intl.NumberFormat('de-DE').format(props.property.energyCertificate.electricityConsumption) + ' kWh/m²/Jahr'}
                            />
                        </div>
                    }
                    {props.property.energyCertificate.heatingEnergyConsumption !== null &&
                        <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <LabelValuePair
                                label="Heizenergieverbrauchskennwert"
                                value={new Intl.NumberFormat('de-DE').format(props.property.energyCertificate.heatingEnergyConsumption) + ' kWh/m²/Jahr'}
                            />
                        </div>
                    }
                </div>
            }
            <div className="row">
                {props.property.energyCertificate.otherInformation !== null &&
                    <div className="col-12 mb-3">
                        <LabelValuePair
                            label="Sonstige Angaben zum Energieausweis"
                            value={props.property.energyCertificate.otherInformation}
                        />
                    </div>
                }
            </div>
        </CollapseCard>
    );
};

export default EnergyCertificateCollapseCard;
