import FormData from '../../shared/Entity/Form/FormData';
import UserActivationType from '../../shared/Entity/User/UserActivationType';
import UserActivation from '../../shared/Entity/User/UserActivation';
import FormValidationHandler from '../../shared/FormValidationHandler/FormValidationHandler';
import InputField from '../../shared/Component/Form/Field/InputField';
import React from 'react';
import {Link} from 'react-router-dom';

interface UserActivationFormProps {
    readonly formData: FormData<UserActivation>;
    readonly setFormData: Function;
    readonly formValidationHandler?: FormValidationHandler<UserActivation>;
}

const UserActivationForm = (props: UserActivationFormProps): React.JSX.Element => {
    const userActivation: UserActivation = props.formData.data;

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        if (event.target.name === 'gtcAccepted') {
            userActivation.gtcAccepted = userActivation.gtcAccepted === false;
        } else {
            (userActivation as any)[event.target.name] = event.target.value;
        }

        updateFormData();
        validateField(event.target.name);
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: userActivation});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <>
            <div className="mb-3">
                <InputField
                    name="password"
                    label="Passwort"
                    type="password"
                    required={true}
                    value={userActivation.password}
                    onChange={handleChange}
                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'password')}
                />
            </div>
            <div className="mb-3">
                <InputField
                    name="passwordRepeat"
                    label="Passwort bestätigen"
                    type="password"
                    required={true}
                    value={userActivation.passwordRepeat}
                    onChange={handleChange}
                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'passwordRepeat')}
                />
            </div>
            {userActivation.userActivationType === UserActivationType.AdminCreated && (
                <div className="form-check">
                    <input
                        type="checkbox"
                        checked={userActivation.gtcAccepted ?? undefined}
                        className={`form-check-input pe-2 me-2${FormValidationHandler.hasFieldError(props.formData, 'gtcAccepted') === true ? ' is-invalid' : ''}`}
                        id="gtcAccepted"
                        name="gtcAccepted"
                        required={true}
                        value="true"
                        onChange={handleChange}
                    />
                    <label htmlFor="gtcAccepted" className="form-check-label">Ich habe die <Link to="/agb" target="_blank">Nutzungsbedingungen</Link> gelesen und bin damit einverstanden</label>
                </div>
            )}
        </>
    );
};

export default UserActivationForm;
