import ImageEntity from '../../Entity/Image/Image';
import Slider from '../Slider/Slider';
import Image from '../../Image/Image';
import React from 'react';

interface ImageViewerProps {
    readonly images: ImageEntity[];
    readonly startAt: number;
    readonly buildImageGetPath: (image: ImageEntity) => string;
}

const ImageViewer = (props: ImageViewerProps): React.JSX.Element => {
    return (
        <Slider
            config={{
                type: 'slider',
                showArrows: true,
                arrowsPosition: 'outside',
                rewind: true,
                perView: 1,
                startAt: props.startAt
            }}
        >
            {props.images.map((image: ImageEntity): React.JSX.Element => (
                <div key={'image-viewer-image-' + image.id} className="d-flex align-items-center justify-content-center">
                    <Image image={image} buildImageGetPath={props.buildImageGetPath} style={{objectFit: 'cover', maxWidth: '100%'}} />
                </div>
            ))}
        </Slider>
    );
};

export default ImageViewer;
