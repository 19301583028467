import PropertyService from './PropertyService';
import Property from '../Entity/Property/Property';
import PropertyCard from './PropertyCard';
import Spinner from '../../../components/Spinner';
import {useAppDispatch} from '../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

const PropertyOverviewPage = (): React.JSX.Element => {
    const [properties, setProperties] = useState<Property[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const propertyService: PropertyService = new PropertyService(process.env.REACT_APP_LLASM_API_URL!);

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Meine Flächen'},
            ]
        });

        fetchProperties();
    }, []);

    const fetchProperties = async (): Promise<void> => {
        setIsLoading(true);

        let properties: Property[];

        properties = await propertyService.fetchPropertiesFromApi();

        setProperties(properties);

        setIsLoading(false);
    };

    if (isLoading === true) {
        return <Spinner />;
    }

    return (
        <div className="container-fluid">
            <div className="properties">
                <div className="row align-items-center mb-4">
                    <div className="col-12 col-md-8 d-flex align-items-center">
                        <Link to="/"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                        <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Meine Flächen/Ladenlokale</h1>
                    </div>
                    <div className="col-12 col-md-4 d-flex justify-content-end px-4">
                        <Link to="/flaechen/anbieten" className="btn btn-secondary d-flex align-items-center text">
                            <i className="bi bi-plus-lg fs-5 px-2"></i>
                            Fläche anlegen
                        </Link>
                    </div>
                </div>
                <div className="row px-md-2">
                    {properties.map((property: Property): React.JSX.Element => (
                        <div key={property.id} className="col-12 col-md-6 col-xl-4 col-xxl-3 mb-4">
                            <PropertyCard property={property} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PropertyOverviewPage;
