import FormData from './Entity/Form/FormData';
import SelectOption from './Entity/Form/SelectOption';
import LocationType from './Entity/LocationType';
import LocationCategory, {getLocationCategoryLabel} from './Entity/LocationCategory';
import FormValidationHandler from './FormValidationHandler/FormValidationHandler';
import LocationTypeSelectField from './LocationTypeSelectField';
import LocationCategorySelectField from './LocationCategorySelectField';
import React, {useEffect, useState} from 'react';

interface LocationTypeToLocationCategoriesMapping {
    locationType: LocationType;
    locationCategories: LocationCategory[];
}

interface LocationTypeAndLocationCategorySelectProps {
    readonly formData: FormData<any>;
    readonly setFormData: (formData: FormData<any>) => void;
    readonly locationTypeLabel: string;
    readonly locationCategoryLabel: string;
    readonly locationTypeDescription?: string;
    readonly locationCategoryDescription?: string;
    readonly formValidationHandler?: FormValidationHandler<any>;
}

const locationTypeToLocationCategoriesMappings: LocationTypeToLocationCategoriesMapping[] = [
    {
        locationType: LocationType.DowntownLocation,
        locationCategories: [
            LocationCategory.OneALocation,
            LocationCategory.OneBLocation,
            LocationCategory.OneCLocation,
        ]
    },
    {
        locationType: LocationType.CityDistrictLocation,
        locationCategories: [
            LocationCategory.TwoALocation,
            LocationCategory.TwoBLocation,
            LocationCategory.TwoCLocation,
        ]
    },
    {
        locationType: LocationType.PeripheralLocation,
        locationCategories: [LocationCategory.Other]
    },
    {
        locationType: LocationType.HighFrequencySpecialLocation,
        locationCategories: [
            LocationCategory.TrainStation,
            LocationCategory.ShoppingCenter,
            LocationCategory.RetailPark,
            LocationCategory.Airport,
            LocationCategory.OutletCenter,
            LocationCategory.HighwayRestStop,
            LocationCategory.Other,
        ]
    },
    {
        locationType: LocationType.ScatteredAndSolitaryLayers,
        locationCategories: [LocationCategory.Other]
    },
];

const LocationTypeAndLocationCategorySelect = (props: LocationTypeAndLocationCategorySelectProps): React.JSX.Element => {
    const [locationCategorySelectOptions, setLocationCategorySelectOptions] = useState<SelectOption<LocationCategory>[]>();

    const [locationTypeSelectedValue, setLocationTypeSelectedValue] = useState<LocationType | null>();

    useEffect((): void => {
        setLocationTypeSelectedValue(fetchLocationTypeValueFromFormData());
    }, []);

    useEffect((): void => {
        if (locationTypeSelectedValue === undefined) {
            return;
        }

        setLocationCategorySelectOptions(fetchLocationCategorySelectOptionsBySelectedLocationType());
    }, [locationTypeSelectedValue]);

    useEffect((): void => {
        if (locationCategorySelectOptions === undefined) {
            return;
        }

        updateLocationCategoryValueInFormData();
    }, [locationCategorySelectOptions]);

    const updateLocationCategoryValueInFormData = (): void => {
        if (locationCategorySelectOptions === undefined) {
            return;
        }

        const locationCategoryValue: LocationCategory | null = fetchLocationCategoryValueFromFormData();

        const locationCategoryFromLocationCategorySelectOptions: LocationCategory[] = [];

        locationCategorySelectOptions.map((locationCategorySelectOption: SelectOption<LocationCategory>): void => {
            locationCategoryFromLocationCategorySelectOptions.push(locationCategorySelectOption.value);
        });

        if (locationCategoryFromLocationCategorySelectOptions.includes(locationCategoryValue as LocationCategory) === false) {
            handleLocationCategoryChange(null);
        }
    };

    const fetchLocationCategorySelectOptionsBySelectedLocationType = (): SelectOption<LocationCategory>[] => {
        if (locationTypeSelectedValue === undefined) {
            return [];
        }

        let locationCategories: LocationCategory[] = [];

        const locationTypeToLocationCategoriesMapping: LocationTypeToLocationCategoriesMapping | undefined = locationTypeToLocationCategoriesMappings.find((locationTypeToLocationCategoriesMapping: LocationTypeToLocationCategoriesMapping): boolean => {
            return locationTypeToLocationCategoriesMapping.locationType === locationTypeSelectedValue;
        });

        if (locationTypeToLocationCategoriesMapping !== undefined) {
            locationCategories = locationTypeToLocationCategoriesMapping.locationCategories;
        }

        return locationCategories.map((locationCategory: LocationCategory): SelectOption<LocationCategory> => {
            return {label: getLocationCategoryLabel(locationCategory), value: locationCategory};
        });
    };

    const handleLocationTypeChange = (newValue: LocationType | null): void => {
        setLocationTypeSelectedValue(newValue);

        props.formData.data.locationType = newValue;

        validateField(getLocationTypeFieldName());

        updateFormData();
    };

    const handleLocationCategoryChange = (newValue: LocationCategory | null): void => {
        props.formData.data.locationCategory = newValue;

        validateField(getLocationCategoryFieldName());

        updateFormData();
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: props.formData.data});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    const getLocationTypeFieldName = (): string => {
        return 'locationType';
    };

    const getLocationCategoryFieldName = (): string => {
        return 'locationCategory';
    };

    const fetchLocationTypeValueFromFormData = (): LocationType | null => {
        return props.formData.data.locationType;
    };

    const fetchLocationCategoryValueFromFormData = (): LocationCategory | null => {
        return props.formData.data.locationCategory;
    };

    const locationTypeHasSelectedValue = (): boolean => {
        if (locationTypeSelectedValue === undefined) {
            return false;
        }

        if (locationTypeSelectedValue === null) {
            return false;
        }

        return true;
    };

    return (
        <div className="row">
            <div className="col-lg-6 mb-3">
                <LocationTypeSelectField
                    label={props.locationTypeLabel}
                    title={props.locationTypeDescription!}
                    name={getLocationTypeFieldName()}
                    isClearable={false}
                    required={true}
                    defaultValue={fetchLocationTypeValueFromFormData()}
                    onChange={handleLocationTypeChange}
                    formErrors={FormValidationHandler.getFieldErrors(props.formData, getLocationTypeFieldName())}
                />
            </div>
            {locationTypeHasSelectedValue() === true &&
                <div className="col-lg-6 mb-3">
                    <LocationCategorySelectField
                        selectOptions={locationCategorySelectOptions}
                        label={props.locationCategoryLabel}
                        title={props.locationCategoryDescription!}
                        name={getLocationCategoryFieldName()}
                        isClearable={false}
                        required={true}
                        value={fetchLocationCategoryValueFromFormData()}
                        defaultValue={fetchLocationCategoryValueFromFormData()}
                        onChange={handleLocationCategoryChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, getLocationCategoryFieldName())}
                    />
                </div>
            }
        </div>
    );
};

export default LocationTypeAndLocationCategorySelect;
