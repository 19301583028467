import Tooltip from '../Tooltip/Tooltip';
import collapseStyle from './Collapse.module.scss';
import React, {useEffect, useRef, useState} from 'react';
import { Collapse as BootstrapCollapse } from 'bootstrap';

interface CollapseProps extends React.PropsWithChildren {
    readonly title: string;
    readonly description?: string;
    readonly tooltipText?: string;
    readonly iconClassName?: string;
    readonly headerElement?: React.JSX.Element;
    readonly blurred?: boolean;
    readonly defaultCollapseExpansionState?: boolean;
}

const Collapse = (props: CollapseProps): React.JSX.Element => {
    const collapseRef: React.MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

    const [bootstrapCollapse, setBootstrapCollapse] = useState<BootstrapCollapse>();

    const [collapseTransitioning, setCollapseTransitioning] = useState<boolean>(false);

    const [collapseOpen, setCollapseOpen] = useState<boolean>(true);

    useEffect((): void => {
        if (props.defaultCollapseExpansionState !== undefined) {
            setCollapseOpen(props.defaultCollapseExpansionState);
            setBootstrapCollapse(BootstrapCollapse.getOrCreateInstance(collapseRef.current!, {toggle: props.defaultCollapseExpansionState === false}));
        } else {
            setBootstrapCollapse(BootstrapCollapse.getOrCreateInstance(collapseRef.current!, {toggle: false}));
        }

        collapseRef.current!.addEventListener('hidden.bs.collapse', (): void => {
            setCollapseTransitioning(false);
        });

        collapseRef.current!.addEventListener('shown.bs.collapse', (): void => {
            setCollapseTransitioning(false);
        });
    }, []);

    const toggleCollapse = (): void => {
        if (collapseTransitioning === true) {
            return;
        }

        bootstrapCollapse!.toggle();

        setCollapseTransitioning(true);
        setCollapseOpen((prevState: boolean): boolean => prevState === false);
    };

    const blurred = (): string | undefined => {
        if (props.blurred === true) {
            return collapseStyle.blurred;
        }

        return undefined;
    }

    return (
        <>
            <div className="row">
                <div className="col">
                    <h2 className="fs-5 fw-bold mb-0 d-flex align-items-center">
                        {props.iconClassName !== undefined &&
                            <i className={props.iconClassName + ' fs-3 me-3'} />
                        }
                        {props.title}
                        <button className="btn p-2" type="button" onClick={toggleCollapse}>
                            <i className={`bi ${collapseOpen ? 'bi-chevron-up' : 'bi-chevron-down'} fs-3 mx-3` + collapseStyle.thickIcon}></i>
                        </button>
                    </h2>
                    {props.tooltipText !== undefined &&
                        <div className="ms-auto">
                            <Tooltip
                                title={props.tooltipText}
                                icon={<i className="bi bi-info-circle"></i>}
                                className="text-info"
                            />
                        </div>
                    }
                </div>
                {props.headerElement !== undefined &&
                    <div className="col-4 justify-content-end pt-2">
                        {props.headerElement}
                    </div>
                }
            </div>
            {props.description !== undefined &&
                <div>
                    {props.description}
                </div>
            }
            <div ref={collapseRef} className={['collapse', 'show', blurred()].join(' ')}>
                <div className="pt-3">
                    {props.children}
                </div>
            </div>
        </>
    );
};

export default Collapse;
