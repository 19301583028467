import MessageWrapper from '../../Entity/Messaging/MessageWrapper';
import Card from '../../Component/Card/Card';
import messageStyle from '../Message.module.scss';
import React from 'react';

interface DeletedMessageProps {
    readonly messageWrapper: MessageWrapper;
}

const DeletedMessage = (props: DeletedMessageProps): React.JSX.Element => {
    return (
        <Card cardType="shadow" className={`mb-3 p-3 ${(props.messageWrapper.message.isSender === true) ? messageStyle.senderBackground : messageStyle.recipientBackground} `}>
            <div className="p-2">
                Gelöschte Nachricht.
            </div>
        </Card>
    );
};

export default DeletedMessage;
