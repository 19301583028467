import LoadingIndicator from './LoadingIndicator';
import React from 'react';

const Spinner = (): React.JSX.Element => {
    return (
        <div className="loadingSpinnerContainer">
            <LoadingIndicator />
        </div>
    );
};

export default Spinner;
