import SpecialObjectCategory from '../Property/SpecialObjectCategory';
import Place from '../Location/Place';
import PlaceSearchRadius from '../Location/PlaceSearchRadius';

class MarketplaceAdFilter {
    private _specialObjectCategories: SpecialObjectCategory[] = [];

    private _fundingAvailable: boolean;

    private _countryPlace: Place | null = null;

    private _totalAreaSizeFrom: number | null = null;

    private _totalAreaSizeTo: number | null = null;

    private _city: Place | null = null;

    private _placeSearchRadius: PlaceSearchRadius | null = null;

    private _onlyImmediatelyAvailable: boolean;

    public static createDefault(): MarketplaceAdFilter {
        const marketplaceAdFilter: MarketplaceAdFilter = new this();

        marketplaceAdFilter._fundingAvailable = false;
        marketplaceAdFilter._onlyImmediatelyAvailable = false;

        return marketplaceAdFilter;
    }

    get specialObjectCategories(): SpecialObjectCategory[] {
        return this._specialObjectCategories;
    }

    set specialObjectCategories(value: SpecialObjectCategory[]) {
        this._specialObjectCategories = value;
    }

    get fundingAvailable(): boolean {
        return this._fundingAvailable;
    }

    set fundingAvailable(value: boolean) {
        this._fundingAvailable = value;
    }

    get countryPlace(): Place | null {
        return this._countryPlace;
    }

    set countryPlace(value: Place | null) {
        this._countryPlace = value;
    }

    get totalAreaSizeFrom(): number | null {
        return this._totalAreaSizeFrom;
    }

    set totalAreaSizeFrom(value: number | null) {
        this._totalAreaSizeFrom = value;
    }

    get totalAreaSizeTo(): number | null {
        return this._totalAreaSizeTo;
    }

    set totalAreaSizeTo(value: number | null) {
        this._totalAreaSizeTo = value;
    }

    get city(): Place | null {
        return this._city;
    }

    set city(value: Place | null) {
        this._city = value;
    }

    get placeSearchRadius(): PlaceSearchRadius | null {
        return this._placeSearchRadius;
    }

    set placeSearchRadius(value: PlaceSearchRadius | null) {
        this._placeSearchRadius = value;
    }

    get onlyImmediatelyAvailable(): boolean {
        return this._onlyImmediatelyAvailable;
    }

    set onlyImmediatelyAvailable(value: boolean) {
        this._onlyImmediatelyAvailable = value;
    }
}

export default MarketplaceAdFilter;
