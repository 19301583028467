import UserService from '../../shared/User/UserService';
import MatchPointService from './MatchPointService';
import PaymentService from '../../shared/Payment/PaymentService';
import OrderStep from '../../shared/Entity/MatchPoint/OrderStep';
import UserState from '../../shared/Entity/User/UserState';
import PurchaseMethod from '../../shared/Entity/Payment/PurchaseMethod';
import BillingAddress from '../../shared/Entity/User/BillingAddress';
import Currency from '../../shared/Entity/Currency';
import MatchPointSelection from './MatchPointSelection';
import OrderOverview from './OrderOverview';
import OnlinePayment from './OnlinePayment';
import InvoicePayment from './InvoicePayment';
import OnlinePaymentOrderConfirmation from './OnlinePaymentOrderConfirmation';
import InvoicePaymentOrderConfirmation from './InvoicePaymentOrderConfirmation';
import Spinner from '../../../components/Spinner';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import React, {useEffect, useState} from 'react';

const userService: UserService = new UserService(process.env.REACT_APP_LLASM_API_URL!);
const matchPointService: MatchPointService = new MatchPointService(process.env.REACT_APP_LLASM_API_URL!);
const paymentService: PaymentService = new PaymentService(process.env.REACT_APP_LLASM_API_URL!);

const MatchPointOverviewPage = (): React.JSX.Element => {
    const [numberFormat, setNumberFormat] = useState<Intl.NumberFormat>(new Intl.NumberFormat('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}));

    const [currency, setCurrency] = useState<Currency>(Currency.EURO);

    const {user}: UserState = useAppSelector<UserState>(state => state.user);

    const [taxRate, setTaxRate] = useState<number>();

    const [orderStep, setOrderStep] = useState<OrderStep>(OrderStep.MatchPointSelection);

    const [amount, setAmount] = useState<number>(100);

    const [priceNet, setPriceNet] = useState<number>(0);

    const [priceGross, setPriceGross] = useState<number>(0);

    const [billingAddress, setBillingAddress] = useState<BillingAddress | null>();

    const [purchaseMethod, setPurchaseMethod] = useState<PurchaseMethod | null>(null);

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Meine MatchPoints', link: '/match-points'},
            ]
        });
    }, [dispatch]);

    useEffect((): void => {
        if (user !== undefined) {
            setTaxRate(paymentService.fetchTaxRate(user));
        }
    }, [user]);

    useEffect((): void => {
        setPriceNet(Number(matchPointService.calculatePrice(amount).toFixed(2)));
    }, [amount]);

    useEffect((): void => {
        if (taxRate === undefined) {
            return;
        }

        setPriceGross(Number((priceNet + (priceNet * (taxRate / 100))).toFixed(2)));
    }, [priceNet, taxRate]);

    const resetOrderProcess = (): void => {
        setOrderStep(OrderStep.MatchPointSelection);
        setAmount(100);
        setPurchaseMethod(null);
    };

    if (user === undefined || taxRate === undefined) {
        return <Spinner />;
    }

    switch (orderStep) {
        case OrderStep.OrderOverview:
            return (
                <OrderOverview
                    paymentService={paymentService}
                    setOrderStep={setOrderStep}
                    userService={userService}
                    user={user}
                    numberFormat={numberFormat}
                    currency={currency}
                    taxRate={taxRate}
                    amount={amount}
                    priceNet={priceNet}
                    priceGross={priceGross}
                    billingAddress={billingAddress}
                    setBillingAddress={setBillingAddress}
                    purchaseMethod={purchaseMethod}
                    setPurchaseMethod={setPurchaseMethod}
                />
            );
        case OrderStep.OnlinePayment:
            if (purchaseMethod !== PurchaseMethod.Online) {
                throw new Error('Invalid PurchaseMethod');
            }

            if (billingAddress === undefined || billingAddress === null) {
                throw new Error('BillingAddress is missing');
            }

            if (paymentService.canDoOnlinePayment(user) === false) {
                throw new Error('OnlinePayment is not allowed');
            }

            return (
                <OnlinePayment
                    matchPointService={matchPointService}
                    setOrderStep={setOrderStep}
                    user={user}
                    numberFormat={numberFormat}
                    currency={currency}
                    taxRate={taxRate}
                    amount={amount}
                    priceNet={priceNet}
                    priceGross={priceGross}
                    billingAddress={billingAddress}
                />
            );
        case OrderStep.OnlinePaymentOrderConfirmation:
            if (purchaseMethod !== PurchaseMethod.Online) {
                throw new Error('Invalid PurchaseMethod');
            }

            if (billingAddress === undefined || billingAddress === null) {
                throw new Error('BillingAddress is missing');
            }

            if (paymentService.canDoOnlinePayment(user) === false) {
                throw new Error('OnlinePayment is not allowed');
            }

            return (
                <OnlinePaymentOrderConfirmation
                    user={user}
                    numberFormat={numberFormat}
                    currency={currency}
                    taxRate={taxRate}
                    amount={amount}
                    priceNet={priceNet}
                    priceGross={priceGross}
                    billingAddress={billingAddress}
                    resetOrderProcess={resetOrderProcess}
                />
            );
        case OrderStep.InvoicePayment:
            if (purchaseMethod !== PurchaseMethod.BankTransfer) {
                throw new Error('Invalid PurchaseMethod');
            }

            if (billingAddress === undefined || billingAddress === null) {
                throw new Error('BillingAddress is missing');
            }

            if (paymentService.canDoBankTransferPayment(user) === false) {
                throw new Error('BankTransferPayment is not allowed');
            }

            return (
                <InvoicePayment
                    matchPointService={matchPointService}
                    setOrderStep={setOrderStep}
                    user={user}
                    numberFormat={numberFormat}
                    currency={currency}
                    taxRate={taxRate}
                    amount={amount}
                    priceNet={priceNet}
                    priceGross={priceGross}
                    billingAddress={billingAddress}
                />
            );
        case OrderStep.InvoicePaymentOrderConfirmation:
            if (purchaseMethod !== PurchaseMethod.BankTransfer) {
                throw new Error('Invalid PurchaseMethod');
            }

            if (billingAddress === undefined || billingAddress === null) {
                throw new Error('BillingAddress is missing');
            }

            if (paymentService.canDoBankTransferPayment(user) === false) {
                throw new Error('BankTransferPayment is not allowed');
            }

            return (
                <InvoicePaymentOrderConfirmation
                    user={user}
                    numberFormat={numberFormat}
                    currency={currency}
                    taxRate={taxRate}
                    amount={amount}
                    priceNet={priceNet}
                    priceGross={priceGross}
                    billingAddress={billingAddress}
                    resetOrderProcess={resetOrderProcess}
                />
            );
        case OrderStep.MatchPointSelection:
        default:
            return (
                <MatchPointSelection
                    paymentService={paymentService}
                    setOrderStep={setOrderStep}
                    user={user}
                    numberFormat={numberFormat}
                    currency={currency}
                    amount={amount}
                    setAmount={setAmount}
                    priceNet={priceNet}
                    priceGross={priceGross}
                />
            );
    }
};

export default MatchPointOverviewPage;
