import Property from '../Entity/Property/Property';
import LocationDetail from '../Entity/Property/LocationDetail';
import Detail from '../Entity/Property/Detail';
import OfferDetail from '../Entity/Property/OfferDetail/OfferDetail';
import RentalDetail from '../Entity/Property/OfferDetail/RentalDetail';
import PurchaseDetail from '../Entity/Property/OfferDetail/PurchaseDetail';
import AdditionalDetail from '../Entity/Property/AdditionalDetail';
import EnergyCertificate from '../Entity/Property/EnergyCertificate/EnergyCertificate';
import Contact from '../Entity/Contact/Contact';
import Image from '../Entity/Image/Image';
import Document from '../Entity/Document/Document';
import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import PropertyResponse from '../../../api/Llasm/Property/PropertyResponse';
import PropertyPatchRequest from '../../../api/Llasm/Property/PropertyPatchRequest';
import PropertyPostRequest from '../../../api/Llasm/Property/PropertyPostRequest';
import LocationDetailPostRequest from '../../../api/Llasm/Property/LocationDetailPostRequest';
import LocationDetailPatchRequest from '../../../api/Llasm/Property/LocationDetailPatchRequest';
import DetailPostRequest from '../../../api/Llasm/Property/DetailPostRequest';
import DetailPatchRequest from '../../../api/Llasm/Property/DetailPatchRequest';
import OfferDetailPostRequest from '../../../api/Llasm/Property/OfferDetail/OfferDetailPostRequest';
import OfferDetailPatchRequest from '../../../api/Llasm/Property/OfferDetail/OfferDetailPatchRequest';
import RentalDetailPostRequest from '../../../api/Llasm/Property/OfferDetail/RentalDetailPostRequest';
import RentalDetailPatchRequest from '../../../api/Llasm/Property/OfferDetail/RentalDetailPatchRequest';
import PurchaseDetailPostRequest from '../../../api/Llasm/Property/OfferDetail/PurchaseDetailPostRequest';
import PurchaseDetailPatchRequest from '../../../api/Llasm/Property/OfferDetail/PurchaseDetailPatchRequest';
import AdditionalDetailPostRequest from '../../../api/Llasm/Property/AdditionalDetailPostRequest';
import AdditionalDetailPatchRequest from '../../../api/Llasm/Property/AdditionalDetailPatchRequest';
import EnergyCertificatePostRequest from '../../../api/Llasm/Property/EnergyCertificate/EnergyCertificatePostRequest';
import EnergyCertificatePatchRequest from '../../../api/Llasm/Property/EnergyCertificate/EnergyCertificatePatchRequest';
import ImageResponse from '../../../api/Llasm/Image/ImageResponse';
import DocumentResponse from '../../../api/Llasm/Document/DocumentResponse';
import {AxiosResponse} from 'axios';

class PropertyService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    private readonly resourcePath: string;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
        this.resourcePath = '/properties';
    }

    public async fetchPropertiesFromApi(): Promise<Property[]> {
        const apiResponse: AxiosResponse<PropertyResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath);

        return apiResponse.data.map((propertyResponse: PropertyResponse): Property => {
            return Property.createFromPropertyResponse(propertyResponse);
        });
    }

    public async fetchPropertyFromApiById(id: number): Promise<Property> {
        const apiResponse: AxiosResponse<PropertyResponse> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + id);

        return Property.createFromPropertyResponse(apiResponse.data);
    }

    public async fetchImagesFromProperty(property: Property): Promise<Image[]> {
        const apiResponse: AxiosResponse<ImageResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + property.id + '/images');

        return apiResponse.data.map((imageResponse: ImageResponse): Image => {
            return Image.createFromImageResponse(imageResponse);
        });
    }

    public async fetchDocumentsFromProperty(property: Property): Promise<Document[]> {
        const apiResponse: AxiosResponse<DocumentResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + property.id + '/documents');

        return apiResponse.data.map((documentResponse: DocumentResponse): Document => {
            return Document.createFromDocumentResponse(documentResponse);
        });
    }

    public async deleteImageFromProperty(property: Property, image: Image): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.delete('/properties/' + property.id + '/images/' + image.id);
    }

    public async persistProperty(property: Property): Promise<Property> {
        const response: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, PropertyPostRequest>(
            this.resourcePath,
            PropertyService.mapFromPropertyToPropertyPostRequest(property)
        );

        const apiResponse: AxiosResponse<PropertyResponse> = await this.llasmApiV1Provider.getFromLocation(response.headers.location);

        return Property.createFromPropertyResponse(apiResponse.data);
    }

    public async updateProperty(property: Property): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.patch<null, AxiosResponse<null>, PropertyPatchRequest>(
            this.resourcePath  + '/' + property.id,
            PropertyService.mapFromPropertyToPropertyPatchRequest(property)
        );
    }

    public static buildImageApiPath(property: Property, image: Image): string {
        return '/properties/' + property.id + '/images/' + image.id;
    }

    public static buildThumbnailImageApiPath(property: Property, image: Image): string {
        return '/properties/' + property.id + '/thumbnail-images/' + image.id;
    }

    public static buildDocumentApiPath(property: Property, document: Document): string {
        return '/properties/' + property.id + '/documents/' + document.id;
    }

    private static mapFromPropertyToPropertyPostRequest(property: Property): PropertyPostRequest {
        return PropertyService.mapPropertyToApiRequest(property) as PropertyPostRequest;
    }

    private static mapFromPropertyToPropertyPatchRequest(property: Property): PropertyPatchRequest {
        return PropertyService.mapPropertyToApiRequest(property) as PropertyPatchRequest;
    }

    private static mapPropertyToApiRequest(property: Property): PropertyPostRequest | PropertyPatchRequest {
        return {
            active: property.active,
            marketingStatus: property.marketingStatus,
            title: property.title,
            internalDesignation: property.internalDesignation,
            locationDetail: PropertyService.mapLocationDetailToApiRequest(property.locationDetail),
            detail: PropertyService.mapDetailToApiRequest(property.detail),
            offerDetail: PropertyService.mapOfferDetailToApiRequest(property.offerDetail),
            additionalDetail: PropertyService.mapAdditionalDetailToApiRequest(property.additionalDetail),
            energyCertificate: property.energyCertificate !== null ? PropertyService.mapEnergyCertificateToApiRequest(property.energyCertificate) : null,
            contactIds: property.contacts.map((contact: Contact): number => contact.id!)
        };
    }

    private static mapLocationDetailToApiRequest(locationDetail: LocationDetail): LocationDetailPostRequest | LocationDetailPatchRequest {
        return {
            cityId: locationDetail.city!.id!,
            postalCode: locationDetail.postalCode,
            streetName: locationDetail.streetName,
            houseNumber: locationDetail.houseNumber,
            addressAffix: locationDetail.addressAffix,
            locationDescription: locationDetail.locationDescription,
            locationType: locationDetail.locationType,
            locationCategory: locationDetail.locationCategory,
            locationFactors: locationDetail.locationFactors
        };
    }

    private static mapDetailToApiRequest(detail: Detail): DetailPostRequest | DetailPatchRequest {
        return {
            specialObjectCategories: detail.specialObjectCategories,
            specialObjectSeparations: detail.specialObjectSeparations,
            floors: detail.floors,
            areaSize: detail.areaSize,
            secondaryAreaSize: detail.secondaryAreaSize,
            outdoorSalesAreaSize: detail.outdoorSalesAreaSize,
            storeWidth: detail.storeWidth,
            shopWindowFrontLength: detail.shopWindowFrontLength,
            accessibilityType: detail.accessibilityType,
            groundLevelSalesArea: detail.groundLevelSalesArea,
            parkingLotAvailable: detail.parkingLotAvailable,
            numberOfParkingLots: detail.numberOfParkingLots,
            parkingLotsExplanation: detail.parkingLotsExplanation,
            objectDescription: detail.objectDescription,
            lastUsage: detail.lastUsage
        };
    }

    private static mapOfferDetailToApiRequest(offerDetail: OfferDetail): OfferDetailPostRequest | OfferDetailPatchRequest {
        return {
            propertyAcquisitionTypes: offerDetail.propertyAcquisitionTypes,
            otherDetails: offerDetail.otherDetails,
            fromTimeAvailability: offerDetail.fromTimeAvailability,
            availableFrom: offerDetail.availableFrom !== null ? offerDetail.availableFrom.toISOString().substring(0, 10) : null,
            untilTimeAvailability: offerDetail.untilTimeAvailability,
            availableUntil: offerDetail.availableUntil !== null ? offerDetail.availableUntil.toISOString().substring(0, 10) : null,
            rentalDetail: PropertyService.mapRentalDetailToApiRequest(offerDetail.rentalDetail),
            purchaseDetail: PropertyService.mapPurchaseDetailToApiRequest(offerDetail.purchaseDetail),
            fundingAvailable: offerDetail.fundingAvailable,
            fundedBy: offerDetail.fundedBy,
            fundingCondition: offerDetail.fundingCondition
        };
    }

    private static mapRentalDetailToApiRequest(rentalDetail: RentalDetail): RentalDetailPostRequest | RentalDetailPatchRequest {
        return {
            netRentalPrice: rentalDetail.netRentalPrice,
            runningCosts: rentalDetail.runningCosts,
            commissionAmount: rentalDetail.commissionAmount,
            commissionNote: rentalDetail.commissionNote,
            deposit: rentalDetail.deposit,
            dailyRent: rentalDetail.dailyRent
        };
    }

    private static mapPurchaseDetailToApiRequest(purchaseDetail: PurchaseDetail): PurchaseDetailPostRequest | PurchaseDetailPatchRequest {
        return {
            purchasePrice: purchaseDetail.purchasePrice,
            commissionAmount: purchaseDetail.commissionAmount,
            commissionNote: purchaseDetail.commissionNote
        };
    }

    private static mapAdditionalDetailToApiRequest(additionalDetail: AdditionalDetail): AdditionalDetailPostRequest | AdditionalDetailPatchRequest {
        return {
            buildingCondition: additionalDetail.buildingCondition,
            monumentProtectionType: additionalDetail.monumentProtectionType,
            internetConnectionTypes: additionalDetail.internetConnectionTypes,
            ceilingHeight: additionalDetail.ceilingHeight,
            floorLoadCapacity: additionalDetail.floorLoadCapacity,
            numberOfEntrances: additionalDetail.numberOfEntrances,
            liftTypes: additionalDetail.liftTypes,
            features: additionalDetail.features,
            generalFurnishing: additionalDetail.generalFurnishing,
            storeSpaceInterestPopupConcept: additionalDetail.storeSpaceInterestPopupConcept,
            storeSpaceInterestMixedUseConcept: additionalDetail.storeSpaceInterestMixedUseConcept
        };
    }

    public static mapEnergyCertificateToApiRequest(energyCertificate: EnergyCertificate): EnergyCertificatePostRequest | EnergyCertificatePatchRequest {
        return {
            energyCertificateType: energyCertificate.energyCertificateType,
            issueDate: energyCertificate.issueDate !== null ? energyCertificate.issueDate.toISOString().substring(0, 10) : null,
            expirationDate: energyCertificate.expirationDate !== null ? energyCertificate.expirationDate.toISOString().substring(0, 10) : null,
            buildingConstructionYear: energyCertificate.buildingConstructionYear,
            energyEfficiencyClass: energyCertificate.energyEfficiencyClass,
            energyCertificateHeatingTypes: energyCertificate.energyCertificateHeatingTypes,
            withHotWater: energyCertificate.withHotWater,
            energyDemand: energyCertificate.energyDemand,
            electricityDemand: energyCertificate.electricityDemand,
            heatingEnergyDemand: energyCertificate.heatingEnergyDemand,
            energyConsumption: energyCertificate.energyConsumption,
            electricityConsumption: energyCertificate.electricityConsumption,
            heatingEnergyConsumption: energyCertificate.heatingEnergyConsumption,
            otherInformation: energyCertificate.otherInformation
        };
    }
}

export default PropertyService;
