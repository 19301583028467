import Product from '../Entity/Product/Product';
import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import PurchasePostRequest from '../../../api/Llasm/HallOfInspiration/PurchasePostRequest';
import {AxiosResponse} from 'axios';

class PurchaseService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    private readonly resourcePath: string;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
        this.resourcePath = '/hall-of-inspiration/purchase';
    }

    public async purchaseHallOfInspirationSubscription(product: Product): Promise<void> {
        if (product.id === undefined) {
            throw new Error();
        }

        const apiResponse: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, PurchasePostRequest>(
            this.resourcePath,
            {productId: product.id}
        );
    }
}

export default PurchaseService;
