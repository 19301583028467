import RoundedContainer from '../RoundedContainer/RoundedContainer';
import React, {useEffect, useRef, useState} from 'react';

interface CardMediaProps extends React.PropsWithChildren {
    readonly className?: string;
    readonly height?: number;
    readonly setContainerHeight?: (height: number) => void;
}

const CardMedia = (props: CardMediaProps): React.JSX.Element => {
    const roundedContainerRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement | null>(null);

    const [roundedContainerHeight, setRoundedContainerHeight] = useState<number>();

    useEffect((): void => {
        if (props.height !== undefined) {
            setRoundedContainerHeight(props.height);
        } else {
            resizeRoundedContainerHeight();

            setTimeout((): void => {
                resizeRoundedContainerHeight();
            }, 500);

            window.addEventListener('resize', resizeRoundedContainerHeight);
        }
    }, []);

    useEffect((): void => {
        if (roundedContainerHeight === undefined) {
            return;
        }

        if (props.setContainerHeight !== undefined) {
            props.setContainerHeight(roundedContainerHeight);
        }
    }, [roundedContainerHeight]);

    const resizeRoundedContainerHeight = (): void => {
        if (props.height !== undefined) {
            return;
        }

        if (roundedContainerRef.current === null) {
            return;
        }

        setRoundedContainerHeight(roundedContainerRef.current.offsetWidth / 1.8);
    };

    return (
        <RoundedContainer ref={roundedContainerRef} className={(props.className !== undefined) ? props.className : ''} style={{height: roundedContainerHeight + 'px'}}>
            {props.children}
        </RoundedContainer>
    );
};

export default CardMedia;
