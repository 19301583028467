import Breadcrumb from '../../domain/shared/Entity/Breadcrumb/Breadcrumb';
import BreadcrumbState from '../../domain/shared/Entity/Breadcrumb/BreadcrumbState';
import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';

const initialState: BreadcrumbState = {
    breadcrumbs: []
};

const breadcrumbSlice = createSlice({
    name: 'breadcrumb',
    initialState,
    reducers: {
        setBreadcrumbs: (state: Draft<BreadcrumbState>, action: PayloadAction<Breadcrumb[]>): void => {
            state.breadcrumbs = action.payload;
        },
    }
});

export default breadcrumbSlice.reducer;
export const {setBreadcrumbs} = breadcrumbSlice.actions;
