import PropertyService from '../shared/Property/PropertyService';
import SettlementConceptService from '../shared/SettlementConcept/SettlementConceptService';
import MatchingService from '../shared/Matching/MatchingService';
import Property from '../shared/Entity/Property/Property';
import SettlementConcept from '../shared/Entity/SettlementConcept/SettlementConcept';
import DashboardPage from './Dashboard/DashboardPage';
import InitialEntryPage from './Dashboard/InitialEntryPage';
import Spinner from '../../components/Spinner';
import React, { useEffect, useState } from 'react';

const propertyService: PropertyService = new PropertyService(process.env.REACT_APP_LLASM_API_URL!);

const settlementConceptService: SettlementConceptService = new SettlementConceptService(process.env.REACT_APP_LLASM_API_URL!);

const matchingService: MatchingService = new MatchingService(process.env.REACT_APP_LLASM_API_URL!);

const StartPage = (): React.JSX.Element => {
    const [settlementConcepts, setSettlementConcepts] = useState<SettlementConcept[] | undefined>(undefined);

    const [properties, setProperties] = useState<Property[] | undefined>(undefined);

    useEffect((): void => {
        fetchData();
    }, []);

    const fetchData = async (): Promise<void> => {
        setProperties(await propertyService.fetchPropertiesFromApi());

        setSettlementConcepts(await settlementConceptService.fetchSettlementConceptsFromApi());
    };

    if (settlementConcepts === undefined || properties === undefined) {
        return <Spinner />;
    }

    if (settlementConcepts.length === 0 && properties.length === 0)  {
        return <InitialEntryPage />;
    }

    return (
        <DashboardPage
            matchingService={matchingService}
            settlementConcepts={settlementConcepts}
            properties={properties}
        />
    );
};

export default StartPage;
