import infoButtonStyle from './InfoButton.module.scss';
import React from 'react';

interface InfoButtonProps {
    readonly className?: string;
    readonly onClick: () => void;
}

const InfoButton = (props: InfoButtonProps): React.JSX.Element => {
    const classNames: string[] = [infoButtonStyle.infoButton];

    if (props.className !== undefined) {
        classNames.push(props.className);
    }

    return <i className={'bi bi-info-circle ' + classNames.join(' ')} onClick={(): void => props.onClick()}></i>;
};

export default InfoButton;
