import ConceptService from './ConceptService';
import Concept from '../Entity/HallOfInspiration/Concept';
import Branch from '../Entity/HallOfInspiration/Branch';
import CompanyValue from '../Entity/CompanyValue';
import ImageEntity from '../Entity/Image/Image';
import Card from '../Component/Card/Card';
import CardMedia from '../Component/Card/CardMedia';
import CardContent from '../Component/Card/CardContent';
import CardFooter from '../Component/Card/CardFooter';
import IconBadgeWrapper from '../Component/IconBadge/IconBadgeWrapper';
import CompanyValueIconBadge from './CompanyValueIconBadge';
import TextBadge from '../Component/TextBadge/TextBadge';
import Image from '../Image/Image';
import conceptCardStyle from './ConceptCard.module.scss';
import noImage from '../../../img/no-hall-of-inspiration-concept-image.png';
import React from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';

interface ConceptCardProps {
    readonly concept: Concept;
    readonly className?: string;
}

const ConceptCard = (props: ConceptCardProps): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();

    const buildClassName = (): string => {
        let className: string = conceptCardStyle.conceptCard;

        if (props.className !== undefined) {
            className += ' ' + props.className;
        }

        return className;
    };

    const routeToDetailPage = (): void => {
        navigate('/hall-of-inspiration/konzepte/' + props.concept.id + '?scrollPosition=' + window.scrollY);
    };

    const buildImageGetPath = (image: ImageEntity): string => {
        if (props.concept === undefined) {
            throw new Error();
        }

        return ConceptService.buildImageApiPath(props.concept, image);
    };

    return (
        <Card className={buildClassName()} cardType="shadow">
            <CardMedia>
                {props.concept.mainImage !== null ? (
                    <Image image={props.concept.mainImage} buildImageGetPath={buildImageGetPath} className="img-fluid" />
                ) : (
                    <img src={noImage} style={{width: '100%', height: 'auto'}} alt="Hall of Inspiration" />
                )}
            </CardMedia>
            <CardContent>
                <IconBadgeWrapper positionTop={-40} positionLeft={0}>
                    {props.concept.companyValues.map((companyValue: CompanyValue): React.JSX.Element => (
                        <CompanyValueIconBadge key={'companyValue-' + companyValue} companyValue={companyValue} />
                    ))}
                </IconBadgeWrapper>
                <div className={[conceptCardStyle['title'], 'my-4 fs-5'].join(' ')}>
                    {props.concept.brandName}
                </div>
                <div className="d-flex flex-wrap gap-2">
                    {props.concept.branches.map((branch: Branch): React.JSX.Element => (
                        <div key={'text-badge-container-' + branch.id} className="d-flex flex-wrap gap-2">
                            <TextBadge text={branch.name} />
                        </div>
                    ))}
                </div>
            </CardContent>
            <CardFooter>
                <button className="btn btn-primary py-1 w-100 text-uppercase" onClick={routeToDetailPage}>
                    <i className="bi bi-eye-fill me-4 text-decoration-none"></i>
                    Zur Inspiration
                </button>
            </CardFooter>
        </Card>
    );
};

export default ConceptCard;
