import cardStyle from './Card.module.scss';
import React from 'react';

interface CardProps extends React.PropsWithChildren {
    readonly cardType: 'outline' | 'shadow';
    readonly className?: string;
    readonly style?: React.CSSProperties;
    readonly onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Card = React.forwardRef<HTMLDivElement, CardProps>((props: CardProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const buildClassName = (): string => {
        let className: string = props.cardType === 'outline' ? cardStyle.cardOutline : cardStyle.cardShadow;

        if (props.className !== undefined) {
            className += ' ' + props.className;
        }

        return className;
    };

    return (
        <div ref={ref} className={buildClassName()} style={props.style} onClick={props.onClick}>
            {props.children}
        </div>
    );
});

export default Card;
