import publicPageLayoutStyle from './PublicPageLayout.module.scss';
import React from 'react';

const ImprintPage = (): React.JSX.Element => {
    return (
        <>
            <div className="row g-3 mt-1 mb-5">
                <div className="col-12 pt-1 text-center">
                    <a href="/" className="btn btn-outline-secondary mb-4">
                        <i className="bi bi-chevron-left"></i> ZUR STARTSEITE
                    </a>
                    <h1 className={['text-secondary', publicPageLayoutStyle.letterSpacing, 'mb-5'].join(' ')}>
                        Impressum
                    </h1>
                    <p>
                        <strong>LLASM Technology GmbH</strong><br/>
                        Werkstättenstr. 41<br/>
                        51379 Leverkusen
                    </p>
                    <p>
                        E-Mail: <a className="text-secondary" href="mailto:hallo@llasm.de">hallo@llasm.de</a><br/>
                        Tel.: <a href="tel:+4921714018840" className="text-secondary">+49 2171 40 188 40</a><br/>
                        Webseite: <a className="text-secondary" href="https://www.llasm.de" target="_blank">www.llasm.de</a>
                    </p>
                    <p>
                        Geschäftsführung:<br/>
                        Bo Nintzel, Philipp Ellrich
                    </p>
                    <p>
                        Registereintrag:<br/>
                        Amtsgericht Köln, HRB 111579
                    </p>
                    <p>
                        UStId-Nr::<br/>
                        DE356603147
                    </p>
                    <p>
                        Aufsichtsbehörde:<br/>
                        Mitglied der IHK Köln
                    </p>
                </div>
            </div>
        </>
    );
};

export default ImprintPage;
