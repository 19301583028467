import MatchingResult from '../../Entity/Matching/MatchingResult';
import Tooltip from '../../Component/Tooltip/Tooltip';
import ProgressBar from '../../Component/ProgressBar/ProgressBar';
import React from 'react';

interface MatchingResultScoreProps {
    readonly matchingResult: MatchingResult;
}

const MatchingResultScore = (props: MatchingResultScoreProps): React.JSX.Element => {
    return (
        <>
            <div className="row mb-2">
                <div className="offset-lg-2 col-2 p-lg-0">
                    <Tooltip title="Gesamt-Score">
                        <i className="bi bi-check-circle-fill text-primary"></i>
                    </Tooltip>
                </div>
                <div className="col-8 ps-0 mt-1">
                    <ProgressBar percentage={props.matchingResult.scorePercentage} color="primary" />
                </div>
            </div>
            <div className="row mb-2">
                <div className="offset-lg-3 col-5 col-lg-4 d-flex justify-content-end">
                    <small className="d-lg-none">Fläche</small>
                    <Tooltip title="Passgenauigkeit der Fläche">
                        <i className="material-icons align-middle d-none d-lg-inline text-secondary pb-1">aspect_ratio</i>
                    </Tooltip>
                </div>
                <div className="col-5 p-0 mt-1">
                    <ProgressBar percentage={props.matchingResult.areaPercentage} color="darkGrey" isBackgroundTransparent={true} size="small" />
                </div>
            </div>
            <div className="row mb-2">
                <div className="offset-lg-3 col-5 col-lg-4 d-flex justify-content-end">
                    <small className="d-lg-none">Standort</small>
                    <Tooltip title="Passgenauigkeit des Standorts">
                        <i className="material-icons align-middle d-none d-lg-inline text-secondary pb-1">place</i>
                    </Tooltip>
                </div>
                <div className="col-5 p-0 mt-1">
                    <ProgressBar percentage={props.matchingResult.locationPercentage} color="lightGrey" isBackgroundTransparent={true} size="small" />
                </div>
            </div>
            <div className="row">
                <div className="offset-lg-3 col-5 col-lg-4 d-flex justify-content-end">
                    <small className="d-lg-none">Unternehmen</small>
                    <Tooltip title="Passgenauigkeit der Unternehmensparameter (Präferenzen)">
                        <i className="material-icons align-middle d-none d-lg-inline text-secondary pb-1">adjust</i>
                    </Tooltip>
                </div>
                <div className="col-5 p-0 mt-1">
                    <ProgressBar percentage={props.matchingResult.companyPercentage} color="grey" isBackgroundTransparent={true} size="small" />
                </div>
            </div>
        </>
    );
};

export default MatchingResultScore;
