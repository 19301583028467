import Contact from '../Entity/Contact/Contact';
import Table from '../Component/Table/Table';
import React from 'react';
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

interface ContactListProps {
    readonly contacts: Contact[];
}

interface ContactListRow {
    readonly id: number;
    readonly rowNumber: number;
    readonly name: string;
    readonly email: string;
    readonly phoneNumber: string | null;
}

const columns: GridColDef[] = [
    {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 100,
        cellClassName: 'icon-cell',
        sortable: false,
        getActions: (params: GridRowParams): [React.JSX.Element] => {
            return [
                <GridActionsCellItem
                    icon={
                        <Link to={'/ansprechpartner/' + params.row.id}>
                            <i className="bi bi-pencil-square"></i>
                        </Link>
                    }
                    label="Edit"
                    className="text-primary"
                />,
            ];
        }
    },
    {
        field: 'rowNumber',
        headerName: '#',
        width: 60
    },
    {
        field: 'name',
        headerName: 'Name',
        flex: 1
    },
    {
        field: 'email',
        headerName: 'E-Mail',
        flex: 1
    },
    {
        field: 'phoneNumber',
        headerName: 'Telefon',
        flex: 1
    }
];

const createRows = (contacts: Contact[]): ContactListRow[] => {
    return contacts.map((contact: Contact, index: number): ContactListRow => {
        return {
            id: contact.id!,
            rowNumber: index + 1,
            name: contact.buildDisplayName(),
            email: contact.email,
            phoneNumber: contact.phoneNumber
        };
    });
};

const ContactList = (props: ContactListProps): React.JSX.Element => {
    return (
        <Table rows={createRows(props.contacts)} columns={columns} checkboxSelection={false} />
    );
};

export default ContactList;
