import BrandService from './BrandService';
import Brand from '../Entity/Brand/Brand';
import BrandList from './BrandList';
import Tooltip from '../Component/Tooltip/Tooltip';
import Spinner from '../../../components/Spinner';
import { useAppDispatch } from '../../../app/hooks';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const brandService: BrandService = new BrandService(process.env.REACT_APP_LLASM_API_URL!);

const BrandOverviewPage = (): React.JSX.Element => {
    const [brands, setBrands] = useState<Brand[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Meine Marken'},
            ]
        });

        fetchBrands();
    }, [dispatch]);

    useEffect((): void => {
        fetchBrands();
    }, []);

    const fetchBrands = async (): Promise<void> => {
        setIsLoading(true);

        setBrands(await brandService.fetchBrandsFromApi());

        setIsLoading(false);
    };

    if (isLoading === true) {
        return <Spinner />;
    }

    return (
        <div className="container-fluid">
            <div className="row align-items-center mb-4">
                <div className="col-12 col-md-8 d-flex align-items-center">
                    <Link to="/"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                    <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Meine Marken/Mein Geschäft</h1>
                    <Tooltip
                        title="Deine Marke/Dein Geschäft beschreibt unter anderem Deine Zielgruppe, den Filialisierungsgrad Deines Unternehmens und Preissegmente."
                        icon={<i className="bi bi-info-circle"></i>}
                        className="text-info pb-2"
                    />
                </div>
                <div className="d-none d-md-flex col-md-4 justify-content-end px-4">
                    <Link to="/marken/anlegen" className="btn btn-secondary d-flex align-items-center text-nowrap">
                        <i className="bi bi-plus-lg fs-5 px-2"></i>
                        Marke/Geschäft anlegen
                    </Link>
                </div>
            </div>
            <div className="mb-5">
                <BrandList brands={brands} />
            </div>
            <div className="d-md-none col-12 col-md-4">
                <Link to="/marken/anlegen" className="btn btn-secondary align-items-center mb-3 text-nowrap">
                    <i className="bi bi-plus-lg fs-5 px-2"></i>
                    Marke/Geschäft anlegen
                </Link>
            </div>
        </div>
    );
};

export default BrandOverviewPage;
