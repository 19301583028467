import FormData from '../../Entity/Form/FormData';
import MatchingParameter from '../../Entity/Matching/MatchingParameter';
import IndustryClassification from '../../Entity/IndustryClassification/IndustryClassification';
import AgeStructure from '../../Entity/AgeStructure';
import TargetGroup from '../../Entity/TargetGroup';
import PriceSegment from '../../Entity/PriceSegment';
import BranchingDegree from '../../Entity/BranchingDegree';
import FormValidationHandler from '../../FormValidationHandler/FormValidationHandler';
import Card from '../../Component/Card/Card';
import CollapseCard from '../../Component/CollapseCard/CollapseCard';
import IndustryClassificationSelectForm from '../../IndustryClassification/IndustryClassificationSelectForm';
import AgeStructureSelectField from '../../AgeStructureSelectField';
import TargetGroupSelectField from '../../TargetGroupSelectField';
import PriceSegmentSelectField from '../../PriceSegmentSelectField';
import BranchingDegreeSelectField from '../../BranchingDegreeSelectField';
import React from 'react';

interface MatchingParameterProps {
    readonly formData: FormData<MatchingParameter>;
    readonly setFormData: Function;
    readonly formValidationHandler?: FormValidationHandler<MatchingParameter>;
    readonly industryClassifications: IndustryClassification[];
}

const MatchingParameterForm = (props: MatchingParameterProps): React.JSX.Element => {
    const matchingParameter: MatchingParameter = props.formData.data;

    const handleAgeStructureChange = (ageStructures: AgeStructure[]): void => {
        matchingParameter.ageStructures = ageStructures;

        updateFormData();
    };

    const handleTargetGroupChange = (targetGroups: TargetGroup[]): void => {
        matchingParameter.targetGroups = targetGroups;

        updateFormData();
    };

    const handlePriceSegmentChange = (priceSegments: PriceSegment[]): void => {
        matchingParameter.priceSegments = priceSegments;

        updateFormData();
    };

    const handleBranchingDegreeChange = (branchingDegrees: BranchingDegree[]): void => {
        matchingParameter.branchingDegrees = branchingDegrees;

        updateFormData();
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: matchingParameter});
    };

    const setIndustryClassificationsFormData = (industryClassificationFormData: FormData<IndustryClassification[]>): void => {
        props.formData.data.industryClassifications = industryClassificationFormData.data;

        updateFormData();
    };

    const addIndustryClassification = (): void => {
        props.formData.data.industryClassifications.push(new IndustryClassification());

        updateFormData();
    };

    const removeIndustryClassification = (index: number): void => {
        props.formData.data.industryClassifications = props.formData.data.industryClassifications.filter((industryClassification: IndustryClassification, _index: number): boolean => _index !== index);

        updateFormData();
    };

    return (
        <>
            <CollapseCard
                title="Branchenfilter"
                description="Hier kannst Du die Branchen auswählen, die Du in Deiner Fläche bevorzugst. Du wirst ausschließlich Konzepte aus dieser Branche als Ergebnis erhalten."
                cardType="outline"
                className="p-0 mb-3"
                defaultCollapseExpansionState={false}
                padding="small"
            >
                <div className="mb-3">
                    {matchingParameter.industryClassifications.map((industryClassification: IndustryClassification, index: number): React.JSX.Element => (
                        <div key={index} className={`d-flex flex-column gap-3 ${index === 0 ? 'mb-4 mb-lg-3' : 'mb-3'}`}>
                            <Card cardType="outline" className="p-3 border-light-subtle">
                                <IndustryClassificationSelectForm
                                    industryClassifications={props.industryClassifications}
                                    formData={{data: props.formData.data.industryClassifications}}
                                    setFormData={setIndustryClassificationsFormData}
                                    index={index}
                                />
                                {(index > 0) &&
                                    <div className="text-end align-self-end">
                                        <button type="button" className="btn btn-sm" onClick={(): void => removeIndustryClassification(index)}>
                                            <i className="bi bi-trash text-danger"></i>
                                        </button>
                                    </div>
                                }
                            </Card>
                        </div>
                    ))}
                    <button type="button" className="btn btn-secondary mt-2" onClick={(): void => addIndustryClassification()}>
                        <i className="bi bi-plus-lg fs-6 px-2"></i>Weitere Kategorie
                    </button>
                </div>
            </CollapseCard>
            <CollapseCard
                title="Präferenzen"
                description="Hier hast Du die Möglichkeit, Deine Präferenzen genau anzugeben und näher zu spezifieren."
                cardType="outline"
                className="p-0 mb-3"
                defaultCollapseExpansionState={false}
                padding="small"
            >
                <div className="mb-3">
                    <AgeStructureSelectField
                        label="Angesprochene Altersgruppe"
                        title="Du suchst einen Nachnutzer für Deine Fläche, der eine bestimmte Zielgruppe (Alter) anspricht? Wähle hier Deine favorisierten Matching-Parameter aus."
                        name="ageStructures"
                        classNames="inset-shadow"
                        isMulti={true}
                        isClearable={true}
                        isRequired={true}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'ageStructures')}
                        defaultValue={matchingParameter.ageStructures}
                        onChange={handleAgeStructureChange}
                    />
                </div>
                <div className="mb-3">
                    <TargetGroupSelectField
                        label="Angesprochene Zielgruppe"
                        title="Du suchst einen Nachnutzer für Deine Fläche, der eine bestimmte Zielgruppe (Geschlechter der Kunden) anspricht? Wähle hier Deine favorisierten Matching-Parameter aus."
                        name="targetGroups"
                        classNames="inset-shadow"
                        isMulti={true}
                        isClearable={true}
                        isRequired={true}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'targetGroups')}
                        defaultValue={matchingParameter.targetGroups}
                        onChange={handleTargetGroupChange}
                    />
                </div>
                <div className="mb-3">
                    <PriceSegmentSelectField
                        label="Preissegmente"
                        title="Du suchst einen Nachnutzer für Deine Fläche, der eine bestimmte Zielgruppe (Preissegment) anspricht? Wähle hier Deine favorisierten Matching-Parameter aus."
                        name="priceSegments"
                        classNames="inset-shadow"
                        isMulti={true}
                        isClearable={true}
                        isRequired={true}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'priceSegments')}
                        defaultValue={matchingParameter.priceSegments}
                        onChange={handlePriceSegmentChange}
                    />
                </div>
                <div className="mb-3">
                    <BranchingDegreeSelectField
                        label="Filialisierungsgrad"
                        title="Du suchst einen Nachnutzer für Deine Fläche, der eine bestimmte Unternehmensgröße hat? Wähle hier Deine favorisierten Matching-Parameter aus."
                        name="branchingDegrees"
                        classNames="inset-shadow"
                        isMulti={true}
                        isClearable={true}
                        isRequired={true}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'branchingDegrees')}
                        defaultValue={matchingParameter.branchingDegrees}
                        onChange={handleBranchingDegreeChange}
                    />
                </div>
            </CollapseCard>
        </>
    );
};

export default MatchingParameterForm;
