import UserService from '../../shared/User/UserService';
import AuthenticationState from '../../shared/Entity/Authentication/AuthenticationState';
import Alert from '../../shared/Entity/Alert/Alert';
import AlertType from '../../shared/Entity/Alert/AlertType';
import UserPasswordResetDemand from '../../shared/Entity/User/UserPasswordResetDemand';
import UserPasswordReset from '../../shared/Entity/User/UserPasswordReset';
import FormData from '../../shared/Entity/Form/FormData';
import FieldValidationDefinition from '../../shared/FormValidationHandler/FieldValidationDefinition';
import PasswordValidationDefinition from '../../shared/FormValidationHandler/PasswordValidationDefinition';
import PasswordRepetitionValidationDefinition from '../../shared/FormValidationHandler/PasswordRepetitionValidationDefinition';
import FormValidationHandler from '../../shared/FormValidationHandler/FormValidationHandler';
import UserPasswordResetForm from '../../shared/User//UserPasswordResetForm';
import Spinner from '../../../components/Spinner';
import AlertBox from '../../../components/AlertBox';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {Link, NavigateFunction, useNavigate, useParams} from 'react-router-dom';

const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.');

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Bitte fülle alle rot markierten Pflichtfelder aus.');

const fieldValidationDefinitions: FieldValidationDefinition<UserPasswordReset>[] = [
    new PasswordValidationDefinition<UserPasswordReset>('password', 'Das Passwort muss mindestens 8 Zeichen lang sein, mindestens je einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und auch ein Sonderzeichenenthalten.'),
    new PasswordRepetitionValidationDefinition<UserPasswordReset>('passwordRepeat', 'password', 'Der Wert stimmt nicht mit dem Passwort überein.'),
];

const formValidationHandler: FormValidationHandler<UserPasswordReset> = new FormValidationHandler<UserPasswordReset>(fieldValidationDefinitions);

const userService: UserService = new UserService(process.env.REACT_APP_LLASM_API_URL!);

const userPasswordReset: UserPasswordReset = new UserPasswordReset();

const UserPasswordResetPage = (): React.JSX.Element => {
    const {userPasswordResetUuid} = useParams<string>();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const [userPasswordResetDemand, setUserPasswordResetDemand] = useState<UserPasswordResetDemand>();

    const [formData, setFormData] = useState<FormData<UserPasswordReset>>({data: userPasswordReset});

    const [userPasswordResetSuccessful, setUserPasswordResetSuccessful] = useState<boolean>(false);

    const [alert, setAlert] = useState<Alert>();

    const navigate: NavigateFunction = useNavigate();

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Passwort setzen'},
            ]
        });
    }, [dispatch]);

    useEffect((): void => {
        if (authenticatedUser !== null) {
            navigate('/');
        }

        fetchUserPasswordReset();
    }, []);

    useEffect((): void => {
        if (userPasswordResetSuccessful === true) {
            setAlert(undefined);
        }

    }, [userPasswordResetSuccessful]);

    const fetchUserPasswordReset = async (): Promise<void> => {
        try {
            setIsLoading(true);

            const userPasswordResetDemand: UserPasswordResetDemand = await userService.fetchUserPasswordResetDemandFromApiByUuid(userPasswordResetUuid!);

            setUserPasswordResetDemand(userPasswordResetDemand);

            // TODO - 404 ist hier möglich. handling einbauen
        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    const resetPassword = async (): Promise<void> => {
        formValidationHandler.validate(formData);

        if (formValidationHandler.hasErrors(formData) === true) {
            setAlert(formErrorAlert);

            return;
        }

        setAlert(undefined);

        setIsLoading(true);

        try {
            await userService.resetPassword(userPasswordResetUuid!, formData.data);

            setUserPasswordResetSuccessful(true);
        } catch (error) {
            setAlert(errorAlert);

            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading === true) {
        return <Spinner />;
    }

    return (
        <div className="row g-3 mt-1 mb-3">
            <div className="col-12 pt-1 px-md-3">
                <h1 className="fw-bold text-secondary fs-2 mb-4 mb-lg-5">Ladenfläche trifft Nachmieter:<br />Match mit!</h1>
                <h2 className="fs-3 fw-bold ls-1">Passwort zurücksetzen</h2>
                <p className="text-muted mb-4">Hallo! Vergib ein Passwort und leg los.</p>
            </div>

            <div className="col-12 pt-1 text-start px-md-3">
                {alert !== undefined &&
                    <AlertBox alert={alert} autoDismiss={false} />
                }
            </div>
            <div className="col-12 pt-1 text-start px-md-3">
                {userPasswordResetSuccessful === true ? (
                    <div className="alert alert-primary">
                        Dein Passwort wurde erfolgreich aktualisiert. <Link to="/login">Log Dich ein</Link>.
                    </div>
                ) : (
                    <div>
                        <UserPasswordResetForm formData={formData} setFormData={setFormData} formValidationHandler={formValidationHandler} />
                        <div className="d-flex gap-4 mt-3">
                            <button className="btn btn-secondary w-100" type="submit" onClick={resetPassword}>Speichern</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default UserPasswordResetPage;
