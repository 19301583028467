import Brand from '../Entity/Brand/Brand';
import BrandEdit from './BrandEdit';
import InfoButtonOverlay from '../Component/InfoButtonOverlay/InfoButtonOverlay';
import {useAppDispatch} from '../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';

const BrandPage = (): React.JSX.Element => {
    const {brandId} = useParams<string>();

    const [brand, setBrand] = useState<Brand>();

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Meine Marken', link: '/marken'},
                {name: 'Marke bearbeiten'},
            ]
        });
    }, []);

    return (
        <div className="container-fluid">
            <div className="d-flex align-items-center">
                <Link to="/marken"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                {brand !== undefined &&
                    <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Marke/Geschäft »{brand.brandName}« bearbeiten</h1>
                }
                <InfoButtonOverlay title="Marke bearbeiten" className="fs-5 me-3 ms-auto">
                    <div className="mb-3">
                        Hier kannst Du Deine Marke bearbeiten, die Du mit Deinem Suchprofil verknüpfen kannst. Deine
                        Marke bzw. der Name Deines Geschäfts gibt z. B. Auskunft über die angesprochene
                        Zielgruppe, das Preissegement oder den
                        Filialisierungsgrad Deines Unternehmens.
                    </div>
                </InfoButtonOverlay>
            </div>
            {brandId !== undefined &&
                <div className="py-3 px-0 px-md-3 mb-3">
                    <BrandEdit brandId={Number(brandId)} onBrandFetched={setBrand} />
                </div>
            }
        </div>
    );
};

export default BrandPage;
