import FormData from '../../shared/Entity/Form/FormData';
import BillingAddress from '../../shared/Entity/User/BillingAddress';
import User from '../../shared/Entity/User/User';
import FormValidationHandler from '../../shared/FormValidationHandler/FormValidationHandler';
import InputField from '../../shared/Component/Form/Field/InputField';
import React from 'react';

interface BillingAddressFormProps {
    readonly user: User;
    readonly formData: FormData<BillingAddress>;
    readonly setFormData: (formData: FormData<BillingAddress>) => void;
    readonly formValidationHandler?: FormValidationHandler<BillingAddress>;
}

const BillingAddressForm = (props: BillingAddressFormProps): React.JSX.Element => {
    const billingAddress: BillingAddress = props.formData.data;

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        (billingAddress as any)[event.target.name] = event.target.value;

        updateFormData();
        validateField(event.target.name);
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: billingAddress});
    };

    const validateField = (fieldName: string): void => {
        if (props.formData.formValidationHandler === undefined) {
            return;
        }

        props.formData.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <>
            {props.user.naturalPerson === false &&
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <InputField
                            name="companyName"
                            label="Firmenname"
                            type="text"
                            required={true}
                            value={billingAddress.companyName ?? undefined}
                            onChange={handleChange}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'companyName')}
                        />
                    </div>
                </div>
            }
            <div className="row">
                <div className="col-md-6 mb-3">
                    <InputField
                        name="firstName"
                        label="Vorname"
                        type="text"
                        required={true}
                        value={billingAddress.firstName}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'firstName')}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <InputField
                        name="lastName"
                        label="Nachname"
                        type="text"
                        required={true}
                        value={billingAddress.lastName}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'lastName')}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <InputField
                        name="streetName"
                        label="Straße"
                        type="text"
                        required={true}
                        value={billingAddress.streetName}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'streetName')}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <InputField
                        name="houseNumber"
                        label="Hausnummer"
                        type="text"
                        required={true}
                        value={billingAddress.houseNumber}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'houseNumber')}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <InputField
                        name="postalCode"
                        label="PLZ"
                        type="text"
                        required={true}
                        value={billingAddress.postalCode}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'postalCode')}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <InputField
                        name="placeName"
                        label="Ort"
                        type="text"
                        required={true}
                        value={billingAddress.placeName}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'placeName')}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <InputField
                        name="country"
                        label="Land"
                        type="text"
                        value={props.user.country.countryName}
                        disabled={true}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <InputField
                        name="email"
                        label="E-Mail-Adresse"
                        type="text"
                        required={true}
                        value={billingAddress.email}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'email')}
                    />
                </div>
            </div>
        </>
    );
};

export default BillingAddressForm;
