import React, {useEffect} from 'react';
import overlayStyle from './Overlay.module.scss';

interface OverlayProps extends React.PropsWithChildren {
    readonly show: boolean;
    readonly setShow: (show: boolean) => void;
    readonly title?: React.JSX.Element | string;
    readonly onClose?: () => void;
}

const Overlay = (props: OverlayProps): React.JSX.Element => {
    useEffect((): () => void => {
        if (props.show === true) {
            document.body.style.overflow = 'hidden';
        }

        return (): void => {
            if (props.show === true) {
                document.body.style.overflow = 'unset';
            }
        };
    }, [props.show]);

    if (props.show !== true) {
        return (
            <></>
        );
    }

    const handleCloseClick = (): void => {
        props.setShow(false);

        if (props.onClose !== undefined) {
            props.onClose();
        }
    };

    return (
        <div className={overlayStyle.overlay}>
            <div className="d-flex mb-md-3">
                <div className="flex-grow-1 align-self-center ps-3">
                    {props.title !== undefined &&
                        <div className={['text-primary', overlayStyle.title].join(' ')}>{props.title}</div>
                    }
                </div>
                <div className="p-2">
                    <button className={['btn', overlayStyle.closeButton].join(' ')} onClick={handleCloseClick}>
                        <span className="d-none d-lg-inline-flex me-2">schließen</span>
                        <i className="bi bi-x-lg"></i>
                    </button>
                </div>
            </div>
            <div className={['pt-3', 'pb-5', overlayStyle.overlayContent].join(' ')}>
                {props.children}
            </div>
        </div>
    );
};

export default Overlay;
