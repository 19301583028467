import SettlementConceptService from '../../SettlementConcept/SettlementConceptService';
import MatchingResult from '../../Entity/Matching/MatchingResult';
import Property from '../../Entity/Property/Property';
import ContactIntention from '../../Entity/ContactIntention/ContactIntention';
import Document from '../../Entity/Document/Document';
import Image from '../../Entity/Image/Image';
import ContactIntentionButton from '../../ContactIntention/ContactIntentionButton';
import ConversationInitialization from '../../Messaging/ConversationInitialization';
import Disclaimer from '../../Disclaimer/Disclaimer';
import DocumentCollapseCard from '../../Document/DocumentCollapseCard';
import BaseInformationCollapseCard from '../../SettlementConcept/SettlementConceptDetailInformation/BaseInformationCollapseCard';
import BrandCollapseCard from '../../SettlementConcept/SettlementConceptDetailInformation/BrandCollapseCard';
import PropertyRequirementCollapseCard from '../../SettlementConcept/SettlementConceptDetailInformation/PropertyRequirementCollapseCard';
import LocationRequirementCollapseCard from '../../SettlementConcept/SettlementConceptDetailInformation/LocationRequirementCollapseCard';
import ImageCollapseCard from '../../Image/ImageCollapseCard';
import ContactCollapseCard from '../../Contact/ContactCollapseCard';
import Card from '../../Component/Card/Card';
import CollapseCard from '../../Component/CollapseCard/CollapseCard';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import matchingResultDetailStyle from './MatchingResultDetail.module.scss';
import awesomeLock from '../../../../img/icon-awesome-lock.svg';
import React, {useState} from 'react';

interface MatchingResultsDetailProps {
    readonly matchingResult: MatchingResult;
    readonly property: Property;
}

const MatchingResultDetail = (props: MatchingResultsDetailProps): React.JSX.Element => {
    const [contactIntention, setContactIntention] = useState<ContactIntention | null | undefined>();

    const contactCardHeaderElement = (): React.JSX.Element => {
        return (
            <>
                {contactIntention === null ? (
                    <div className="text-end">
                        <img src={awesomeLock} alt="Gesperrt" className={['img-fluid', 'ms-2', 'mb-2', matchingResultDetailStyle.iconAwesomeLock].join(' ')} />
                    </div>
                ) : (
                    <ContactIntentionButton property={props.property} settlementConcept={props.matchingResult.settlementConcept} />
                )}
            </>
        );
    };

    const buildImageGetPath = (image: Image): string => {
        return SettlementConceptService.buildImageApiPath(props.matchingResult.settlementConcept, image);
    };

    const buildDocumentGetPath = (document: Document): string => {
        return SettlementConceptService.buildDocumentApiPath(props.matchingResult.settlementConcept, document);
    };

    return (
        <>
            {contactIntention === undefined &&
                <div className={matchingResultDetailStyle.loadingIndicatorContainer}>
                    <LoadingIndicator />
                </div>
            }

            <div className={`${contactIntention === undefined ? 'd-none' : ''}`}>
                <BaseInformationCollapseCard settlementConcept={props.matchingResult.settlementConcept} />
                <BrandCollapseCard settlementConcept={props.matchingResult.settlementConcept} />
                <PropertyRequirementCollapseCard settlementConcept={props.matchingResult.settlementConcept} />
                <LocationRequirementCollapseCard settlementConcept={props.matchingResult.settlementConcept} />

                {(props.matchingResult.settlementConcept.images.length > 0) &&
                    <ImageCollapseCard images={props.matchingResult.settlementConcept.images} buildImageGetPath={buildImageGetPath} />
                }

                {(contactIntention === null || contactIntention === undefined) &&
                    <Card cardType="shadow" className="mb-3">
                        <div className="p-5">
                            <ContactIntentionButton property={props.property} settlementConcept={props.matchingResult.settlementConcept} setContactIntention={setContactIntention} />
                            <div className="mt-4 small">
                                Gefällt Dir das Suchprofil? Super! Wenn Du jetzt Dein „Like“ über das „Daumen-hoch“-Icon aktivierst, kannst Du die Kontaktdaten des Ansprechpartners unten freischalten und ihm eine Nachricht senden. Gleichzeitig werden Deine Kontaktdaten und Deine Objektdetails an den Suchprofilanbieter weitergeleitet. Er hat somit auch die Möglichkeit Dich direkt zu kontaktieren.
                                {props.matchingResult.settlementConcept.documents.length > 0 &&
                                    <span className="ms-1">
                                        Außerdem kannst Du Dokumente zum Suchprofil abrufen, die der Anbieter als weiterführende Information hinterlegt hat.
                                    </span>
                                }
                            </div>
                        </div>
                    </Card>
                }

                <CollapseCard cardType="shadow" title="Kontakt aufnehmen" className="mb-3" headerElement={contactCardHeaderElement()} blurred={contactIntention === null}>
                    <ConversationInitialization property={props.property} settlementConcept={props.matchingResult.settlementConcept} />
                </CollapseCard>

                {(contactIntention !== null && props.matchingResult.settlementConcept.documents.length > 0) &&
                    <DocumentCollapseCard documents={props.matchingResult.settlementConcept.documents} buildDocumentGetPath={buildDocumentGetPath} />
                }

                {contactIntention !== null &&
                    <ContactCollapseCard contacts={props.matchingResult.settlementConcept.contacts} />
                }
            </div>
            <Disclaimer title="Rechtliche Hinweise und Haftungsausschluss" className="mt-4 pb-5" />
        </>
    );
};

export default MatchingResultDetail;
