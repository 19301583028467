import Brand from '../Brand/Brand';
import PropertyCriteria from './PropertyCriteria';
import PlaceCriteria from './PlaceCriteria';
import ProviderSeekerConversation from '../Messaging/ProviderSeekerConversation';
import Contact from '../Contact/Contact';
import IndustryClassification from '../IndustryClassification/IndustryClassification';
import Image from '../Image/Image';
import Document from '../Document/Document';
import PropertyInterest from '../PropertyInterest/PropertyInterest';
import InformationScope from '../InformationScope';
import SettlementConceptResponse from '../../../../api/Llasm/SettlementConcept/SettlementConceptResponse';
import DetailedSettlementConceptResponse from '../../../../api/Llasm/SettlementConcept/DetailedSettlementConceptResponse';
import CompleteSettlementConceptResponse from '../../../../api/Llasm/SettlementConcept/CompleteSettlementConceptResponse';
import LockedSettlementConceptResponse from '../../../../api/Llasm/SettlementConcept/LockedSettlementConceptResponse';
import UnlockedSettlementConceptResponse from '../../../../api/Llasm/SettlementConcept/UnlockedSettlementConceptResponse';
import PlaceCriteriaResponse from '../../../../api/Llasm/SettlementConcept/PlaceCriteriaResponse';
import ContactResponse from '../../../../api/Llasm/Contact/ContactResponse';
import IndustryClassificationResponse from '../../../../api/Llasm/IndustryClassification/IndustryClassificationResponse';
import ImageResponse from '../../../../api/Llasm/Image/ImageResponse';
import DocumentResponse from '../../../../api/Llasm/Document/DocumentResponse';
import ResponseType from '../../../../api/Llasm/ResponseType';

class SettlementConcept {
    private _id?: number;

    private _active: boolean;

    private _reference: string;

    private _brand: Brand;

    private _title: string;

    private _description: string;

    private _propertyCriteria: PropertyCriteria;

    private _desiredMinimumLeaseTerm: number | null = null;

    private _interestPopupSpace: boolean;

    private _onlyPopupSpace: boolean;

    private _interestMixedUseSpace: boolean;

    private _onlyMixedUseSpace: boolean;

    private _providerSeekerConversation: ProviderSeekerConversation | null = null;

    private _propertyInterest: PropertyInterest | null = null;

    private _hasContactIntentions: boolean;

    private _hasActivation: boolean;

    private _owner: boolean;

    private _informationScope: InformationScope;

    private _placeCriterias: PlaceCriteria[] = [];

    private _contacts: Contact[] = [];

    private _industryClassifications: IndustryClassification[] = [];

    private _images: Image[] = [];

    private _documents: Document[] = [];

    public static createFromSettlementConceptResponse(settlementConceptResponse: SettlementConceptResponse): SettlementConcept {
        switch (settlementConceptResponse.responseType) {
            case ResponseType.Complete:
                return SettlementConcept.createFromCompleteSettlementConceptResponse(settlementConceptResponse as CompleteSettlementConceptResponse);
            case ResponseType.Locked:
                return SettlementConcept.createFromLockedSettlementConceptResponse(settlementConceptResponse as LockedSettlementConceptResponse);
            case ResponseType.Unlocked:
                return SettlementConcept.createFromUnlockedSettlementConceptResponse(settlementConceptResponse as UnlockedSettlementConceptResponse);
            default:
                throw new Error('Invalid ResponseType');
        }
    }

    public static createFromCompleteSettlementConceptResponse(settlementConceptResponse: CompleteSettlementConceptResponse): SettlementConcept {
        const settlementConcept: SettlementConcept = new this();

        this.mapFromSettlementConceptResponse(settlementConcept, settlementConceptResponse);
        this.mapFromDetailedSettlementConceptResponse(settlementConcept, settlementConceptResponse);

        settlementConcept._brand = Brand.createFromCompleteBrandResponse(settlementConceptResponse.brand);
        settlementConcept._propertyCriteria = PropertyCriteria.createFromCompletePropertyCriteriaResponse(settlementConceptResponse.propertyCriteria);
        settlementConcept._informationScope = InformationScope.Complete;

        settlementConcept._placeCriterias = settlementConceptResponse.placeCriterias.map((placeCriteria: PlaceCriteriaResponse): PlaceCriteria => {
            return PlaceCriteria.createFromPlaceCriteriaResponse(placeCriteria);
        });

        return settlementConcept;
    }

    public static createFromLockedSettlementConceptResponse(settlementConceptResponse: LockedSettlementConceptResponse): SettlementConcept {
        const settlementConcept: SettlementConcept = new this();

        this.mapFromSettlementConceptResponse(settlementConcept, settlementConceptResponse);

        settlementConcept._brand = Brand.createFromLockedBrandResponse(settlementConceptResponse.brand);
        settlementConcept._propertyCriteria = PropertyCriteria.createFromLockedPropertyCriteriaResponse(settlementConceptResponse.propertyCriteria);
        settlementConcept._informationScope = InformationScope.Locked;

        return settlementConcept;
    }

    public static createFromUnlockedSettlementConceptResponse(settlementConceptResponse: UnlockedSettlementConceptResponse): SettlementConcept {
        const settlementConcept: SettlementConcept = new this();

        this.mapFromSettlementConceptResponse(settlementConcept, settlementConceptResponse);
        this.mapFromDetailedSettlementConceptResponse(settlementConcept, settlementConceptResponse);

        settlementConcept._brand = Brand.createFromUnlockedBrandResponse(settlementConceptResponse.brand);
        settlementConcept._propertyCriteria = PropertyCriteria.createFromUnlockedPropertyCriteriaResponse(settlementConceptResponse.propertyCriteria);
        settlementConcept._informationScope = InformationScope.Unlocked;

        return settlementConcept;
    }

    private static mapFromSettlementConceptResponse(settlementConcept: SettlementConcept, settlementConceptResponse: SettlementConceptResponse): void {
        settlementConcept._id = settlementConceptResponse.id;
        settlementConcept._active = settlementConceptResponse.active;
        settlementConcept._reference = settlementConceptResponse.reference;
        settlementConcept._interestPopupSpace = settlementConceptResponse.interestPopupSpace;
        settlementConcept._onlyPopupSpace = settlementConceptResponse.onlyPopupSpace;
        settlementConcept._interestMixedUseSpace = settlementConceptResponse.interestMixedUseSpace;
        settlementConcept._onlyMixedUseSpace = settlementConceptResponse.onlyMixedUseSpace;
        settlementConcept._hasContactIntentions = settlementConceptResponse.hasContactIntentions;
        settlementConcept._hasActivation = settlementConceptResponse.hasActivation;
        settlementConcept._owner = settlementConceptResponse.owner;

        if (settlementConceptResponse.providerSeekerConversation !== null) {
            settlementConcept._providerSeekerConversation = ProviderSeekerConversation.createFromProviderSeekerConversationResponse(settlementConceptResponse.providerSeekerConversation);
        }

        if (settlementConceptResponse.propertyInterest !== null) {
            settlementConcept._propertyInterest = PropertyInterest.createFromPropertyInterestResponse(settlementConceptResponse.propertyInterest);
        }

        settlementConcept._industryClassifications = settlementConceptResponse.industryClassifications.map(
            (industryClassificationResponse: IndustryClassificationResponse): IndustryClassification => {
                return IndustryClassification.createFromIndustryClassificationResponse(industryClassificationResponse);
            }
        );
    }

    private static mapFromDetailedSettlementConceptResponse(settlementConcept: SettlementConcept, settlementConceptResponse: DetailedSettlementConceptResponse): void {
        settlementConcept._title = settlementConceptResponse.title;
        settlementConcept._description = settlementConceptResponse.description;
        settlementConcept._desiredMinimumLeaseTerm = settlementConceptResponse.desiredMinimumLeaseTerm;

        settlementConcept._contacts = settlementConceptResponse.contacts.map(
            (contactResponse: ContactResponse): Contact => {
                return Contact.createFromContactResponse(contactResponse);
            }
        );

        settlementConcept._images = settlementConceptResponse.images.map(
            (imageResponse: ImageResponse): Image => {
                return Image.createFromImageResponse(imageResponse);
            }
        );

        settlementConcept._documents = settlementConceptResponse.documents.map(
            (documentResponse: DocumentResponse): Document => {
                return Document.createFromDocumentResponse(documentResponse);
            }
        );
    }

    get id(): number | undefined{
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get active(): boolean {
        return this._active;
    }

    set active(value: boolean) {
        this._active = value;
    }

    get reference(): string {
        return this._reference;
    }

    set reference(value: string) {
        this._reference = value;
    }

    get brand(): Brand {
        return this._brand;
    }

    set brand(value: Brand) {
        this._brand = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get propertyCriteria(): PropertyCriteria {
        return this._propertyCriteria;
    }

    set propertyCriteria(value: PropertyCriteria) {
        this._propertyCriteria = value;
    }

    get desiredMinimumLeaseTerm(): number | null {
        return this._desiredMinimumLeaseTerm;
    }

    set desiredMinimumLeaseTerm(value: number | null) {
        this._desiredMinimumLeaseTerm = value;
    }

    get interestPopupSpace(): boolean {
        return this._interestPopupSpace;
    }

    set interestPopupSpace(value: boolean) {
        this._interestPopupSpace = value;
    }

    get onlyPopupSpace(): boolean {
        return this._onlyPopupSpace;
    }

    set onlyPopupSpace(value: boolean) {
        this._onlyPopupSpace = value;
    }

    get interestMixedUseSpace(): boolean {
        return this._interestMixedUseSpace;
    }

    set interestMixedUseSpace(value: boolean) {
        this._interestMixedUseSpace = value;
    }

    get onlyMixedUseSpace(): boolean {
        return this._onlyMixedUseSpace;
    }

    set onlyMixedUseSpace(value: boolean) {
        this._onlyMixedUseSpace = value;
    }

    get providerSeekerConversation(): ProviderSeekerConversation | null {
        return this._providerSeekerConversation;
    }

    set providerSeekerConversation(value: ProviderSeekerConversation | null) {
        this._providerSeekerConversation = value;
    }

    get propertyInterest(): PropertyInterest | null {
        return this._propertyInterest;
    }

    set propertyInterest(value: PropertyInterest | null) {
        this._propertyInterest = value;
    }

    get hasContactIntentions(): boolean {
        return this._hasContactIntentions;
    }

    set hasContactIntentions(value: boolean) {
        this._hasContactIntentions = value;
    }

    get hasActivation(): boolean {
        return this._hasActivation;
    }

    set hasActivation(value: boolean) {
        this._hasActivation = value;
    }

    get owner(): boolean {
        return this._owner;
    }

    set owner(value: boolean) {
        this._owner = value;
    }

    get informationScope(): InformationScope {
        return this._informationScope;
    }

    set informationScope(value: InformationScope) {
        this._informationScope = value;
    }

    get placeCriterias(): PlaceCriteria[] {
        return this._placeCriterias;
    }

    set placeCriterias(value: PlaceCriteria[]) {
        this._placeCriterias = value;
    }

    get contacts(): Contact[] {
        return this._contacts;
    }

    set contacts(value: Contact[]) {
        this._contacts = value;
    }

    get industryClassifications(): IndustryClassification[] {
        return this._industryClassifications;
    }

    set industryClassifications(value: IndustryClassification[]) {
        this._industryClassifications = value;
    }

    get images(): Image[] {
        return this._images;
    }

    set images(value: Image[]) {
        this._images = value;
    }

    get documents(): Document[] {
        return this._documents;
    }

    set documents(value: Document[]) {
        this._documents = value;
    }
}

export default SettlementConcept;
