class FormError {

    private readonly _fieldName: string;

    private readonly _message?: string;

    constructor(fieldName: string, message?: string) {
        this._fieldName = fieldName;
        this._message = message;
    }

    get fieldName(): string {
        return this._fieldName;
    }

    get message(): string | undefined {
        return this._message;
    }

}

export default FormError;
