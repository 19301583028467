import FormData from '../Entity/Form/FormData';
import AdditionalDetail from '../Entity/Property/AdditionalDetail';
import SelectOption from '../Entity/Form/SelectOption';
import BuildingCondition, {getBuildingConditionLabel} from '../Entity/Property/BuildingCondition';
import MonumentProtectionType, {getMonumentProtectionTypeLabel} from '../Entity/Property/MonumentProtectionType';
import InternetConnectionType from '../Entity/Property/InternetConnectionType';
import LiftType from '../Entity/Property/LiftType';
import Feature, {getFeatureLabel} from '../Entity/Property/Feature';
import LiftTypeSelectField from './LiftTypeSelectField';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import InternetConnectionTypeSelectField from './InternetConnectionTypeSelectField';
import SelectField from '../Component/Form/Field/SelectField';
import InputField from '../Component/Form/Field/InputField';
import TextAreaField from '../Component/Form/Field/TextAreaField';
import SwitchField from '../Component/Form/Field/SwitchField';
import React, {useEffect} from 'react';

interface AdditionalDetailFormProps {
    readonly formData: FormData<AdditionalDetail>;
    readonly setFormData: (formData: FormData<AdditionalDetail>) => void;
    readonly validationDefinitions: FieldValidationDefinition<AdditionalDetail>[];
}

interface SwitchOption<T> {
    label: string;
    name: string;
    value: T;
    description?: string;
}

const buildingConditionSelectOptions: SelectOption<BuildingCondition | null>[] = [
    {label: 'Bitte wählen', value: null},
    {label: getBuildingConditionLabel(BuildingCondition.NewBuilding), value: BuildingCondition.NewBuilding},
    {label: getBuildingConditionLabel(BuildingCondition.AsNew), value: BuildingCondition.AsNew},
    {label: getBuildingConditionLabel(BuildingCondition.Renovated), value: BuildingCondition.Renovated},
    {label: getBuildingConditionLabel(BuildingCondition.Modernized), value: BuildingCondition.Modernized},
    {label: getBuildingConditionLabel(BuildingCondition.WellLookedAfter), value: BuildingCondition.WellLookedAfter},
    {label: getBuildingConditionLabel(BuildingCondition.Shell), value: BuildingCondition.Shell},
];

const monumentProtectionTypeSelectOptions: SelectOption<MonumentProtectionType | null>[] = [
    {label: 'Bitte wählen', value: null},
    {label: getMonumentProtectionTypeLabel(MonumentProtectionType.singleMonument), value: MonumentProtectionType.singleMonument},
    {label: getMonumentProtectionTypeLabel(MonumentProtectionType.ensembleMonument), value: MonumentProtectionType.ensembleMonument},
];

const featureSwitchOptions: SwitchOption<Feature>[] = [
    {label: getFeatureLabel(Feature.Showroom), name: 'feature' + Feature.Showroom, value: Feature.Showroom},
    {label: getFeatureLabel(Feature.TruckDeliveryOption), name: 'feature' + Feature.TruckDeliveryOption, value: Feature.TruckDeliveryOption},
    {label: getFeatureLabel(Feature.HighVoltageConnection), name: 'feature' + Feature.HighVoltageConnection, value: Feature.HighVoltageConnection},
    {label: getFeatureLabel(Feature.ExhaustAirSystem), name: 'feature' + Feature.ExhaustAirSystem, value: Feature.ExhaustAirSystem},
    {label: getFeatureLabel(Feature.AirConditioning), name: 'feature' + Feature.AirConditioning, value: Feature.AirConditioning},
    {label: getFeatureLabel(Feature.FireProtectionConcept), name: 'feature' + Feature.FireProtectionConcept, value: Feature.FireProtectionConcept, description: 'Liegt ein Brandschutzkonzept für Deine Fläche vor?'},
    {label: getFeatureLabel(Feature.DoorCurtainSystem), name: 'feature' + Feature.DoorCurtainSystem, value: Feature.DoorCurtainSystem, description: 'Technisches System, das an Eingängen von Gebäuden installiert wird, um den Austausch von Luftmassen zwischen Innen- und Außenbereich zu minimieren.'},
    {label: getFeatureLabel(Feature.AutomaticDoorSystem), name: 'feature' + Feature.AutomaticDoorSystem, value: Feature.AutomaticDoorSystem},
    {label: getFeatureLabel(Feature.Alarm), name: 'feature' + Feature.Alarm, value: Feature.Alarm},
    {label: getFeatureLabel(Feature.ChangingRoom), name: 'feature' + Feature.ChangingRoom, value: Feature.ChangingRoom},
    {label: getFeatureLabel(Feature.ColdStorage), name: 'feature' + Feature.ColdStorage, value: Feature.ColdStorage},
    {label: getFeatureLabel(Feature.VentilationSystem), name: 'feature' + Feature.VentilationSystem, value: Feature.VentilationSystem},
    {label: getFeatureLabel(Feature.GreaseSeparator), name: 'feature' + Feature.GreaseSeparator, value: Feature.GreaseSeparator, description: 'Gerät, das in Abwasserleitungen installiert wird, um Fette, Öle und Fettstoffe aus dem Abwasser zu entfernen und so die Kanalisation vor Verstopfungen und Schäden zu schützen.'},
    {label: getFeatureLabel(Feature.WineCellar), name: 'feature' + Feature.WineCellar, value: Feature.WineCellar},
    {label: getFeatureLabel(Feature.GasConnection), name: 'feature' + Feature.GasConnection, value: Feature.GasConnection},
    {label: getFeatureLabel(Feature.BeerConnection), name: 'feature' + Feature.BeerConnection, value: Feature.BeerConnection},
];

const AdditionalDetailForm = (props: AdditionalDetailFormProps): React.JSX.Element => {
    const additionalDetail: AdditionalDetail = props.formData.data;

    useEffect((): void => {
        props.setFormData({...props.formData, formValidationHandler: new FormValidationHandler<AdditionalDetail>(props.validationDefinitions)});
    }, [props.validationDefinitions]);

    const fetchBuildingConditionSelectedOption = (): SelectOption<BuildingCondition | null> | undefined => {
        if (additionalDetail.buildingCondition === undefined) {
            return undefined;
        }

        return buildingConditionSelectOptions.find((selectOption: SelectOption<BuildingCondition | null>): boolean => {
            return additionalDetail.buildingCondition === selectOption.value;
        });
    };

    const fetchMonumentProtectionTypeSelectedOption = (): SelectOption<MonumentProtectionType | null> | undefined => {
        if (additionalDetail.monumentProtectionType === undefined) {
            return undefined;
        }

        return monumentProtectionTypeSelectOptions.find((selectOption: SelectOption<MonumentProtectionType | null>): boolean => {
            return additionalDetail.monumentProtectionType === selectOption.value;
        });
    };

    const toggleFeature = (feature: Feature): void => {
        const featureIndex: number = additionalDetail.features.indexOf(feature);

        if (featureIndex === -1) {
            additionalDetail.features.push(feature);
        } else {
            additionalDetail.features.splice(featureIndex, 1);
        }

        updateFormData();
    };

    const handleBuildingConditionChange = (selectedValue: SelectOption<BuildingCondition | null> | null): void => {
        if (selectedValue === null) {
            return;
        }

        additionalDetail.buildingCondition = selectedValue.value;

        updateFormData();
    };

    const handleMonumentProtectionTypeChange = (selectedValue: SelectOption<MonumentProtectionType | null> | null): void => {
        if (selectedValue === null) {
            return;
        }

        additionalDetail.monumentProtectionType = selectedValue.value;

        updateFormData();
    };

    const handleInternetConnectionTypesChange = (selectedValues: readonly SelectOption<InternetConnectionType>[]): void => {
        additionalDetail.internetConnectionTypes = selectedValues.map((selectOption: SelectOption<InternetConnectionType>): InternetConnectionType => {
            return selectOption.value;
        });

        updateFormData();
    };

    const handleLiftTypesChange = (selectedValues: readonly SelectOption<LiftType>[]): void => {
        additionalDetail.liftTypes = selectedValues.map((selectOption: SelectOption<LiftType>): LiftType => {
            return selectOption.value;
        });

        updateFormData();
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
        switch (event.target.name) {
            case 'ceilingHeight':
                if (event.target.value === '') {
                    additionalDetail.ceilingHeight = null;
                } else {
                    additionalDetail.ceilingHeight = Number(event.target.value);
                }

                break;
            case 'floorLoadCapacity':
                if (event.target.value === '') {
                    additionalDetail.floorLoadCapacity = null;
                } else {
                    additionalDetail.floorLoadCapacity = Number(event.target.value);
                }

                break;
            case 'numberOfEntrances':
                if (event.target.value === '') {
                    additionalDetail.numberOfEntrances = null;
                } else {
                    additionalDetail.numberOfEntrances = Number(event.target.value);
                }

                break;
            case 'generalFurnishing':
                if (event.target.value === '') {
                    additionalDetail.generalFurnishing = null;
                } else {
                    additionalDetail.generalFurnishing = event.target.value;
                }

                break;
            default:
                (additionalDetail as any)[event.target.name] = event.target.value;

                break;
        }

        updateFormData();
        validateField(event.target.name);
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        (additionalDetail as any)[event.target.name] = event.target.checked;

        updateFormData();

        validateField(event.target.name);
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: additionalDetail});
    };

    const validateField = (fieldName: string): void => {
        if (props.formData.formValidationHandler === undefined) {
            return;
        }

        props.formData.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <>
            <div className="row">
                <div className="col-md-4 col-lg-6 mb-3">
                    <SelectField
                        name="buildingCondition"
                        label="Zustand"
                        required={false}
                        options={buildingConditionSelectOptions}
                        value={fetchBuildingConditionSelectedOption()}
                        placeholder="Bitte wählen"
                        onChange={handleBuildingConditionChange}
                        description="Welchen Zustand hat Deine Fläche?"
                    />
                </div>
                <div className="col-md-4 col-lg-3 mb-3">
                    <SelectField
                        name="monumentProtectionType"
                        label="Denkmalschutz"
                        required={false}
                        options={monumentProtectionTypeSelectOptions}
                        value={fetchMonumentProtectionTypeSelectedOption()}
                        placeholder="Bitte wählen"
                        onChange={handleMonumentProtectionTypeChange}
                        description="Ist Deine Fläche denkmalgeschützt oder Teil eines denkmalgeschützten Gebäudekomplexes?"
                    />
                </div>
                <div className="col-md-4 col-lg-3 mb-3">
                    <InternetConnectionTypeSelectField
                        name="internetConnectionTypes"
                        label="Internetanschluss"
                        description="Welchen Internetanschluss hat Deine Fläche? Eine Mehrfachauswahl ist möglich."
                        placeholder="Bitte wählen"
                        required={false}
                        isClearable={true}
                        closeMenuOnSelect={false}
                        value={additionalDetail.internetConnectionTypes}
                        onChange={handleInternetConnectionTypesChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="ceilingHeight"
                        label="Deckenhöhe"
                        type="number"
                        suffix="m"
                        min={0}
                        required={false}
                        value={additionalDetail.ceilingHeight ?? undefined}
                        onChange={handleChange}
                        description="Gib hier optional an, welche Deckenhöhe Deine Fläche hat."
                    />
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="floorLoadCapacity"
                        label="Bodentraglast"
                        type="number"
                        suffix="kg/m²"
                        min={0}
                        required={false}
                        value={additionalDetail.floorLoadCapacity ?? undefined}
                        onChange={handleChange}
                        description="Wenn Du die Bodentraglast Deiner Fläche kennst, dann kannst Du sie hier angeben."
                    />
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="numberOfEntrances"
                        label="Anzahl Eingänge"
                        type="number"
                        min={0}
                        required={false}
                        value={additionalDetail.numberOfEntrances ?? undefined}
                        onChange={handleChange}
                        description="Hat Deine Fläche mehrere Eingänge? Dann kannst Du die Zahl der Eingänge hier erfassen."
                    />
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                    <LiftTypeSelectField
                        name="liftTypes"
                        label="Aufzug/Rolltreppe"
                        placeholder="Bitte wählen"
                        required={false}
                        isClearable={true}
                        closeMenuOnSelect={false}
                        value={additionalDetail.liftTypes}
                        onChange={handleLiftTypesChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                {featureSwitchOptions.map((featureSwitchOption: SwitchOption<Feature>): React.JSX.Element => (
                    <div key={'featureSwitch-' + featureSwitchOption.value} className="col-md-6 col-lg-4 col-xl-3 mb-4">
                        <SwitchField
                            label={featureSwitchOption.label}
                            description={featureSwitchOption.description}
                            selected={additionalDetail.features.includes(featureSwitchOption.value)}
                            name={featureSwitchOption.name}
                            onChange={(): void => toggleFeature(featureSwitchOption.value)}
                        />
                    </div>
                ))}
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    <TextAreaField
                        name="generalFurnishing"
                        label="Ausstattung (allgemein)"
                        rows={6}
                        required={false}
                        value={additionalDetail.generalFurnishing ?? undefined}
                        description="Hat Deine Fläche besondere Ausstattungsmerkmale? Dann kannst Du sie hier eintragen."
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'generalFurnishing')}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    <SwitchField
                        label="Interesse an Pop-Up Konzepten"
                        description="Hast Du Interesse an Pop-Up Konzepten? Damit sind Suchprofile gemeint, die für einen eingeschränkten Zeitraum (meist 2-6 Monate) Flächen besetzen, um sich dort auszuprobieren."
                        selected={additionalDetail.storeSpaceInterestPopupConcept}
                        name="storeSpaceInterestPopupConcept"
                        onChange={handleSwitchChange}
                    />
                </div>
                <div className="col-12 mb-3">
                    <SwitchField
                        label="Interesse an Mixed-Use Konzepten"
                        description="Hast Du Interesse an Mixed-Use Konzepten? Damit sind Suchprofile gemeint, die sich eine gemeinsame Nutzung einer Fläche vorstellen können, indem die Nutzung entweder räumlich oder zeitlich getrennt wird."
                        selected={additionalDetail.storeSpaceInterestMixedUseConcept}
                        name="storeSpaceInterestMixedUseConcept"
                        onChange={handleSwitchChange}
                    />
                </div>
            </div>
        </>
    );
};

export default AdditionalDetailForm;
