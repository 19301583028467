import {getBuildingConditionLabel} from '../../Entity/Property/BuildingCondition';
import {getMonumentProtectionTypeLabel} from '../../Entity/Property/MonumentProtectionType';
import Property from '../../Entity/Property/Property';
import Feature, {getFeatureLabel} from '../../Entity/Property/Feature';
import InternetConnectionType, {getInternetConnectionTypeLabel} from '../../Entity/Property/InternetConnectionType';
import LiftType, {getLiftTypeLabel} from '../../Entity/Property/LiftType';
import BootstrapIcon from '../../Component/Icon/BootstrapIcon';
import CollapseCard from '../../Component/CollapseCard/CollapseCard';
import LabelValuePair from '../../../../components/LabelValuePair';
import LabelValuesPair from '../../../../components/LabelValuesPair';
import React from 'react';

interface AdditionalDetailCollapseCardProps {
    readonly property: Property;
}

const AdditionalDetailCollapseCard = (props: AdditionalDetailCollapseCardProps): React.JSX.Element => {
    const internetConnectionTypeValues: string[] = props.property.additionalDetail.internetConnectionTypes.map((internetConnectionType: InternetConnectionType): string => {
        return getInternetConnectionTypeLabel(internetConnectionType);
    });

    const liftTypeValues: string[] = props.property.additionalDetail.liftTypes.map((liftType: LiftType): string => {
        return getLiftTypeLabel(liftType);
    });

    return (
        <CollapseCard cardType="shadow" title="Zusätzliche Informationen" className="mb-3">
            <div className="row">
                {props.property.additionalDetail.buildingCondition !== null &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair
                            label="Zustand"
                            value={getBuildingConditionLabel(props.property.additionalDetail.buildingCondition)}
                        />
                    </div>
                }
                {props.property.additionalDetail.monumentProtectionType !== null &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair
                            label="Denkmalschutz"
                            value={getMonumentProtectionTypeLabel(props.property.additionalDetail.monumentProtectionType)}
                        />
                    </div>
                }
                {props.property.additionalDetail.internetConnectionTypes.length > 0 &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuesPair
                            label="Internetanschluss"
                            values={internetConnectionTypeValues}
                            delimiter=", "
                        />
                    </div>
                }
                {props.property.additionalDetail.ceilingHeight !== null &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair
                            label="Deckenhöhe"
                            value={props.property.additionalDetail.ceilingHeight + ' m'}
                        />
                    </div>
                }
                {props.property.additionalDetail.floorLoadCapacity !== null &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair
                            label="Bodentraglast"
                            value={props.property.additionalDetail.floorLoadCapacity + ' kg/m²'}
                        />
                    </div>
                }
                {props.property.additionalDetail.numberOfEntrances !== null &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair
                            label="Eingänge"
                            value={props.property.additionalDetail.numberOfEntrances.toString()}
                        />
                    </div>
                }
                {props.property.additionalDetail.liftTypes.length > 0 &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuesPair
                            label="Aufzug/Rolltreppe"
                            values={liftTypeValues}
                            delimiter=", "
                        />
                    </div>
                }
            </div>
            {props.property.additionalDetail.generalFurnishing !== null &&
                <div className="row">
                    <div className="col-12 mb-3">
                        <LabelValuePair
                            label="Ausstattung (allgemein)"
                            value={props.property.additionalDetail.generalFurnishing}
                        />
                    </div>
                </div>
            }
            {props.property.additionalDetail.features.length > 0 &&
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="label-value-pair">
                            <div className="row">
                                {props.property.additionalDetail.features.map((feature: Feature): React.JSX.Element => {
                                    return (
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <BootstrapIcon iconName="check-lg" className="me-1" />
                                            {getFeatureLabel(feature)}
                                        </div>
                                    )
                                })}
                            </div>
                            <label className="text-black-50 pb-1">Weitere Ausstattungsmerkmale</label>
                        </div>
                    </div>
                </div>
            }
            <div className="row">
                {props.property.additionalDetail.storeSpaceInterestPopupConcept === true &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Für PopUp-Konzepte geeignet" value="Ja" />
                    </div>
                }
                {props.property.additionalDetail.storeSpaceInterestMixedUseConcept === true &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Für Mixed-User-Konzepte geeignet" value="Ja" />
                    </div>
                }
            </div>
        </CollapseCard>
    );
};

export default AdditionalDetailCollapseCard;
