import Concept from '../Entity/HallOfInspiration/Concept';
import UserState from '../Entity/User/UserState';
import AuthenticationState from '../Entity/Authentication/AuthenticationState';
import PurchaseHallOfInspirationProductCard from './PurchaseHallOfInspirationProductCard';
import ConceptService from './ConceptService';
import ConceptCard from './ConceptCard';
import InfoButtonOverlay from '../Component/InfoButtonOverlay/InfoButtonOverlay';
import Spinner from '../../../components/Spinner';
import {fetchUser} from '../../../features/user/userSlice';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';

const conceptService: ConceptService = new ConceptService(process.env.REACT_APP_LLASM_API_URL!);

const ConceptOverviewPage = (): React.JSX.Element => {
    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const {user}: UserState = useAppSelector<UserState>(state => state.user);

    const [concepts, setConcepts] = useState<Concept[]>();

    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useAppDispatch();

    const scrollPosition: number | null = searchParams.get('scrollPosition') !== null ? Number(searchParams.get('scrollPosition')) : null;

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Hall of Inspiration'},
            ]
        });
    }, [dispatch]);

    useEffect((): void => {
        fetchConcepts();
    }, []);

    useEffect((): void => {
        if (concepts === undefined) {
            return;
        }

        if (scrollPosition === null) {
            return;
        }

        window.scrollTo({top: scrollPosition});
    }, [concepts]);

    const fetchConcepts = async (): Promise<void> => {
        setConcepts(await conceptService.fetchConceptsFromApi());
    };

    const onPurchaseHallOfInspirationProductOverlayClose = (): void => {
        if (authenticatedUser !== undefined  && authenticatedUser !== null) {
            dispatch(fetchUser(authenticatedUser));
        }

        setConcepts(undefined);

        fetchConcepts();
    };

    if (user === undefined || concepts === undefined) {
        return <Spinner />;
    }

    return (
        <div className="container-fluid">
            <div className="row align-items-center mb-4">
                <div className="col-12 col-md-6 d-flex align-items-center">
                    <Link to="/"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                    <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Hall of Inspiration</h1>
                </div>
                <div className="col-12 col-md-6 d-md-flex align-items-center justify-content-end ">
                    <InfoButtonOverlay title="Hall of Inspiration" className="fs-5 ms-1 me-3">
                        <h5 className="fw-bold mb-3">
                            Was ist die „Hall of Inspiration“?
                        </h5>
                        <div className="mb-5">
                            Die „Hall of Inspiration“ ist ein digitaler Ort, an dem Du Dein Nutzungskonzept einer
                            breiten Zielgruppe vorstellen kannst. Sozusagen ein virtuelles Schaufenster, durch das
                            sich vor allem die Kommunen, Wirtschaftsförderungen und Immobilienbesitzer aus ganz
                            Deutschland von innovativen und besonderen Nutzungskonzepten inspirieren lassen.
                        </div>
                        <h5 className="fw-bold mb-3">
                            Was musst Du dafür tun?
                        </h5>
                        <div className="mb-5">
                            Du kannst Dich mit Deinem Konzept kostenfrei für die „Hall of Inspiration“ bewerben.
                            Anhand von Bildern, Videos und einer Konzeptbeschreibung, die Du bei uns einreichst,
                            entscheidet unser Team über die Veröffentlichung.
                        </div>
                    </InfoButtonOverlay>
                    <a href='https://www.llasm.de/hall-of-inspiration' target="_blank" className="btn btn-secondary">
                        <i className="bi bi-plus-lg"></i> Inspiration einreichen
                    </a>
                </div>
            </div>
            <div className="d-flex flex-wrap gap-4 pb-4">
                {concepts.map((concept: Concept): React.JSX.Element => (
                    <ConceptCard key={'concept-' + concept.id} concept={concept} className="d-flex flex-column justify-content-between" />
                ))}
                {user.hasActiveHallOfInspirationSubscription === false &&
                    <PurchaseHallOfInspirationProductCard
                        className="d-flex flex-column justify-content-between"
                        onPurchaseHallOfInspirationProductOverlayClose={onPurchaseHallOfInspirationProductOverlayClose}
                    />
                }
            </div>
        </div>
    );
};

export default ConceptOverviewPage;
