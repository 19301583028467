abstract class ConversationUser {
    protected _id: number | null = null;

    protected _name: string;

    get id(): number | null  {
        return this._id;
    }

    set id(value: number | null) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }
}

export default ConversationUser;
