import FormData from '../Entity/Form/FormData';
import FormError from '../Entity/Form/FormError';
import FieldValidationDefinition from './FieldValidationDefinition';

class FormValidationHandler<T> {
    private _fieldValidationDefinitions: FieldValidationDefinition<T>[] = [];

    constructor(fieldValidationDefinitions: FieldValidationDefinition<T>[]) {
        this._fieldValidationDefinitions = fieldValidationDefinitions;
    }

    get fieldValidationDefinitions(): FieldValidationDefinition<T>[] {
        return this._fieldValidationDefinitions;
    }

    set fieldValidationDefinitions(value: FieldValidationDefinition<T>[]) {
        this._fieldValidationDefinitions = value;
    }

    public hasFieldValidationDefinition(fieldValidationDefinition: FieldValidationDefinition<T>): boolean {
        return this._fieldValidationDefinitions.some((fieldValidationDefinitionToCheck: FieldValidationDefinition<T>): boolean => {
            return fieldValidationDefinitionToCheck.fieldName === fieldValidationDefinition.fieldName
                && fieldValidationDefinitionToCheck instanceof fieldValidationDefinition.constructor;
        });
    }

    public addFieldValidationDefinition(fieldValidationDefinition: FieldValidationDefinition<T>): void {
        if (this.hasFieldValidationDefinition(fieldValidationDefinition) === false) {
            this._fieldValidationDefinitions.push(fieldValidationDefinition);
        }
    }

    public removeFieldValidationDefinition(fieldValidationDefinition: FieldValidationDefinition<T>): void {
        const index: number = this._fieldValidationDefinitions.findIndex((fieldValidationDefinitionToCheck: FieldValidationDefinition<T>): boolean => {
            return fieldValidationDefinitionToCheck.fieldName === fieldValidationDefinition.fieldName
                && fieldValidationDefinitionToCheck instanceof fieldValidationDefinition.constructor;
        });

        if (index !== -1) {
            this._fieldValidationDefinitions.splice(index, 1);
        }
    }

    public removeFieldValidationDefinitionsByFieldName(fieldName: string): void {
        const indexes: number[] = [];

        this._fieldValidationDefinitions.forEach((fieldValidationDefinition: FieldValidationDefinition<T>, index: number): void => {
            if (fieldValidationDefinition.fieldName === fieldName) {
                indexes.push(index);
            }
        });

        indexes.reverse().forEach((index: number): void => {
            this._fieldValidationDefinitions.splice(index, 1);
        });
    }

    public static hasFieldError<T>(formData: FormData<T>, fieldName: string): boolean {
        if (formData.errors === undefined) {
            return false;
        }

        return formData.errors.some((formError: FormError): boolean => formError.fieldName === fieldName);
    }

    public static getFieldError<T>(formData: FormData<T>, fieldName: string): FormError | undefined {
        if (formData.errors === undefined) {
            return undefined;
        }

        return formData.errors.find((formError: FormError): boolean => {
            return formError.fieldName === fieldName;
        });
    }

    public static getFieldErrors<T>(formData: FormData<T>, fieldName: string): FormError[] {
        if (formData.errors === undefined) {
            return [];
        }

        return formData.errors.filter((formError: FormError): boolean => {
            return formError.fieldName === fieldName;
        });
    }

    public validate(formData: FormData<T>): void {
        const formErrors: FormError[] = [];

        this._fieldValidationDefinitions.forEach((fieldValidationDefinition: FieldValidationDefinition<T>): void => {
            const formError: FormError | null = FormValidationHandler.validateFieldValidationDefinition<T>(formData, fieldValidationDefinition);

            if (formError !== null) {
                formErrors.push(formError);
            }
        });

        formData.errors = formErrors;
    }

    public validateField(fieldName: string, formData: FormData<T>): void {
        if (formData.errors === undefined) {
            formData.errors = [];
        }

        const fieldValidationDefinitions: FieldValidationDefinition<T>[] = this._fieldValidationDefinitions.filter((fieldValidationDefinition: FieldValidationDefinition<T>): boolean => {
           return fieldValidationDefinition.fieldName === fieldName;
        });

        if (fieldValidationDefinitions.length === 0) {
            return;
        }

        formData.errors = formData.errors.filter((formError: FormError): boolean => {
            return formError.fieldName !== fieldName;
        });

        fieldValidationDefinitions.forEach((fieldValidationDefinition: FieldValidationDefinition<T>): void => {
            const formError: FormError | null = FormValidationHandler.validateFieldValidationDefinition<T>(formData, fieldValidationDefinition);

            if (formError !== null) {
                formData.errors!.push(formError);
            }
        });
    }

    public hasErrors(formData: FormData<T>): boolean {
        return (formData.errors !== undefined && formData.errors.length > 0);
    }

    private static validateFieldValidationDefinition<T>(formData: FormData<T>, fieldValidationDefinition: FieldValidationDefinition<T>): FormError | null {
        if (fieldValidationDefinition.check(formData) === false) {
            return new FormError(fieldValidationDefinition.fieldName, fieldValidationDefinition.message ?? '');
        }

        return null;
    }
}

export default FormValidationHandler;
