import User from '../Entity/User/User';
import FormData from '../Entity/Form/FormData';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import InputField from '../Component/Form/Field/InputField';
import React, {ChangeEvent} from 'react';

interface UserFormProps {
    readonly formData: FormData<User>;
    readonly setFormData: Function;
    readonly formValidationHandler?: FormValidationHandler<User>;
}

const UserAccessCredentialsForm = (props: UserFormProps): React.JSX.Element => {
    const user: User = props.formData.data;

    const handleChange = (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        (user as any)[event.target.name] = event.target.value;

        updateFormData();
        validateField(event.target.name);
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: user});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <>
            <div className="row mb-3">
                <div className="col-md-6 col-lg-3">
                    <InputField
                        label="E-Mail"
                        name="email"
                        required={true}
                        type="email"
                        value={user.email}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'email')}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-md-6 col-lg-3">
                    <InputField
                        label="Aktuelles Passwort"
                        name="currentPassword"
                        required={false}
                        type="password"
                        value=""
                        onChange={handleChange}
                    />
                </div>
                <div className="col-md-6 col-lg-3">
                    <InputField
                        label="Neues Passwort"
                        name="password"
                        required={false}
                        type="password"
                        value=""
                        onChange={handleChange}
                    />

                </div>
                <div className="col-md-6 col-lg-3">
                    <InputField
                        label="Neues Passwort erneut eingeben"
                        name="passwordRepeat"
                        required={false}
                        type="password"
                        value=""
                        onChange={handleChange}
                    />
                </div>
            </div>
        </>
    );
};

export default UserAccessCredentialsForm;
