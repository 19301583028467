import textBadgeStyle from './TextBadge.module.scss';
import React from 'react';

interface TextBadgeProps {
    text: string;
}

const TextBadge = (props: TextBadgeProps): React.JSX.Element => {
    return (
        <div className={[textBadgeStyle.badge, 'px-2 py-1'].join(' ')}>
            {props.text}
        </div>
    );
};

export default TextBadge;
