import StoredObject from '../Entity/Storage/StoredObject';
import Product from '../Entity/Product/Product';

class LocalStorageService {
    public saveToStorage<T>(key: string, object: T, hoursTimeToLife: number): void {
        const currentDate: Date = new Date();

        currentDate.setHours(currentDate.getHours() + hoursTimeToLife);

        const storedObject: StoredObject<T> = {
            expiresOn: currentDate,
            object: object
        };

        localStorage.setItem(key, JSON.stringify(storedObject));
    }

    public getFromStorage<T>(key: string): T | null {
        const storageValue: string | null = localStorage.getItem(key);

        if (storageValue === null) {
            return null;
        }

        const parsedStoredObject: {expiresOn: string | null; object: object} = JSON.parse(storageValue);

        const storedObject: StoredObject<T> = {
            expiresOn: (parsedStoredObject.expiresOn !== null) ? new Date(Date.parse(parsedStoredObject.expiresOn!)): null,
            object: parsedStoredObject.object as T
        };

        const currentDate: Date = new Date();

        if (storedObject.expiresOn !== null && storedObject.expiresOn < currentDate) {
            localStorage.removeItem(key);

            return null;
        }

        if (key === 'product') {
            return Object.assign(new Product(), storedObject.object);
        }

        return storedObject.object;
    }

    public removeFromStorage(key: string): void {
        const storageValue: string | null = localStorage.getItem(key);

        if (storageValue !== null) {
            localStorage.removeItem(key);
        }
    }

    public refreshTimeToLife<T>(key: string, hoursTimeToLife: number): void {
        const storedObject: T | null = this.getFromStorage<T>(key);

        if (storedObject === null || storedObject === undefined) {
            return;
        }

        this.saveToStorage<T>(key, storedObject, hoursTimeToLife);
    }
}

export default LocalStorageService;
