import MarketplaceLot from '../../../Entity/Marketplace/MarketplaceLot';
import User from '../../../Entity/User/User';
import Alert from '../../../Entity/Alert/Alert';
import AlertType from '../../../Entity/Alert/AlertType';
import OccupyMarketplaceLot from '../OccupyMarketplaceLot';
import Overlay from '../../../Component/Overlay/Overlay';
import Card from '../../../Component/Card/Card';
import CardMedia from '../../../Component/Card/CardMedia';
import CardContent from '../../../Component/Card/CardContent';
import CardFooter from '../../../Component/Card/CardFooter';
import BootstrapIcon from '../../../Component/Icon/BootstrapIcon';
import freeMarketplaceLotImage from '../../../../../img/free-marketplace-lot-image.jpg';
import marketplaceLotCardStyle from './MarketplaceLotCard.module.scss';
import React, {useState} from 'react';

interface FreeMarketplaceLotCardProps {
    readonly marketplaceLot: MarketplaceLot;
    readonly user: User;
    readonly onAction: (alert: Alert) => void;
}

const successAlert: Alert = new Alert(AlertType.Success, 'Der Stellplatz wurde erfolgreich mit Deiner Fläche belegt.');

const FreeMarketplaceAdCard = (props: FreeMarketplaceLotCardProps): React.JSX.Element => {
    const [showOccupyMarketplaceLotOverlay, setShowOccupyMarketplaceLotOverlay] = useState<boolean>(false);

    const onOccupied = (error: boolean): void => {
        if (error === false) {
            setShowOccupyMarketplaceLotOverlay(false);

            props.onAction(successAlert);
        }
    };

    return (
        <Card cardType="shadow" className="position-relative h-100">
            <CardMedia>
                <div className="cursor-pointer" onClick={(): void => setShowOccupyMarketplaceLotOverlay(true)}>
                    <div className="d-flex align-items-center">
                        <img src={freeMarketplaceLotImage} alt="Kein Bild" className="w-100" />
                    </div>
                    <div className="position-relative">
                        <div className="position-absolute fs-1" style={{top: "-70px", right: "20px"}}>
                            <BootstrapIcon iconName="plus-circle-fill" color="primary" />
                        </div>
                    </div>
                </div>
            </CardMedia>
            <CardContent className="px-4">
                <div className="text-center fs-5 fw-bolder mb-5 text-black-50">
                    Freier Stellplatz
                </div>
                <div className="text-center fs-4 fw-bolder mb-3">
                    Restlaufzeit
                </div>
                <div className="text-center fs-4 fw-bolder mb-3">
                    {props.marketplaceLot.availableNumberOfDays} Tage
                </div>
                <div className={marketplaceLotCardStyle.h100px}></div>
            </CardContent>
            <CardFooter className="position-absolute bottom-0 w-100">
                <button
                    className="btn btn-primary mx-auto text-nowrap text-uppercase w-100 mb-2"
                    onClick={(): void => setShowOccupyMarketplaceLotOverlay(true)}
                >
                    Stellplatz belegen
                </button>
            </CardFooter>
            <Overlay show={showOccupyMarketplaceLotOverlay} setShow={setShowOccupyMarketplaceLotOverlay} title="Stellplatz belegen">
                <div className="container pb-5">
                    <OccupyMarketplaceLot
                        marketplaceLot={props.marketplaceLot}
                        canOccupy={props.user.imprint !== null}
                        onGoBackClick={(): void => setShowOccupyMarketplaceLotOverlay(false)}
                        onOccupied={(error: boolean): void => onOccupied(error)}
                    />
                </div>
            </Overlay>
        </Card>
    );
};

export default FreeMarketplaceAdCard;
