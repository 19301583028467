import Country from '../Location/Country';
import UserResponse from '../../../../api/Llasm/User/UserResponse';

class User {
    private _id?: number;

    // TODO - deprecated
    private _identifier: string;

    private _naturalPerson: boolean;

    private _email: string;

    private _firstName: string;

    private _lastName: string;

    private _companyName: string | null = null;

    private _phoneNumber: string | null = null;

    private _streetName: string;

    private _houseNumber: string;

    private _postalCode: string;

    private _placeName: string;

    private _country: Country;

    private _introComplete: boolean;

    private _imprint: string | null = null;

    private _roles: string[];

    private _numberOfUnreadConversations: number;

    private _activeMatchingResultUnlockFlatRateSubscription: boolean;

    private _hasActiveHallOfInspirationSubscription: boolean;

    private _numberOfMatchPoints: number;

    public static createFromUserResponse(userResponse: UserResponse): User {
        const user: User = new this();

        user._id = userResponse.id;
        user._naturalPerson = userResponse.naturalPerson;
        user._email = userResponse.email;
        user._firstName = userResponse.firstName;
        user._lastName = userResponse.lastName;
        user._companyName = userResponse.companyName;
        user._phoneNumber = userResponse.phoneNumber;
        user._streetName = userResponse.streetName;
        user._houseNumber = userResponse.houseNumber;
        user._postalCode = userResponse.postalCode;
        user._placeName = userResponse.placeName;
        user._country = Country.createFromCountryResponse(userResponse.country);
        user._introComplete = userResponse.introComplete === true;  // TODO - WiP
        user._imprint = userResponse.imprint;
        user._roles = userResponse.roles;
        user._numberOfUnreadConversations = userResponse.numberOfUnreadConversations;
        user._activeMatchingResultUnlockFlatRateSubscription = userResponse.activeMatchingResultUnlockFlatRateSubscription;
        user._hasActiveHallOfInspirationSubscription = userResponse.hasActiveHallOfInspirationSubscription;
        user._numberOfMatchPoints = userResponse.numberOfMatchPoints;

        return user;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    // TODO - deprecated
    get identifier(): string {
        return this._identifier;
    }

    // TODO - deprecated
    set identifier(value: string) {
        this._identifier = value;
    }

    get naturalPerson(): boolean {
        return this._naturalPerson;
    }

    set naturalPerson(value: boolean) {
        this._naturalPerson = value;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get companyName(): string | null {
        return this._companyName;
    }

    set companyName(value: string | null) {
        this._companyName = value;
    }

    get phoneNumber(): string | null {
        return this._phoneNumber;
    }

    set phoneNumber(value: string | null) {
        this._phoneNumber = value;
    }

    get streetName(): string {
        return this._streetName;
    }

    set streetName(value: string) {
        this._streetName = value;
    }

    get houseNumber(): string {
        return this._houseNumber;
    }

    set houseNumber(value: string) {
        this._houseNumber = value;
    }

    get postalCode(): string {
        return this._postalCode;
    }

    set postalCode(value: string) {
        this._postalCode = value;
    }

    get placeName(): string {
        return this._placeName;
    }

    set placeName(value: string) {
        this._placeName = value;
    }

    get country(): Country {
        return this._country;
    }

    set country(value: Country) {
        this._country = value;
    }

    get introComplete(): boolean {
        return this._introComplete;
    }

    set introComplete(value: boolean) {
        this._introComplete = value;
    }

    get imprint(): string | null {
        return this._imprint;
    }

    set imprint(value: string | null) {
        this._imprint = value;
    }

    get roles(): string[] {
        return this._roles;
    }

    set roles(value: string[]) {
        this._roles = value;
    }

    get numberOfUnreadConversations(): number {
        return this._numberOfUnreadConversations;
    }

    set numberOfUnreadConversations(value: number) {
        this._numberOfUnreadConversations = value;
    }

    get activeMatchingResultUnlockFlatRateSubscription(): boolean {
        return this._activeMatchingResultUnlockFlatRateSubscription;
    }

    set activeMatchingResultUnlockFlatRateSubscription(value: boolean) {
        this._activeMatchingResultUnlockFlatRateSubscription = value;
    }

    get hasActiveHallOfInspirationSubscription(): boolean {
        return this._hasActiveHallOfInspirationSubscription;
    }

    set hasActiveHallOfInspirationSubscription(value: boolean) {
        this._hasActiveHallOfInspirationSubscription = value;
    }

    get numberOfMatchPoints(): number {
        return this._numberOfMatchPoints;
    }

    set numberOfMatchPoints(value: number) {
        this._numberOfMatchPoints = value;
    }

    public isFromGermany(): boolean {
        return this._country.isoAlphaTwoCode === 'DE';
    }

    public isFromEuropeanUnionMember(): boolean {
        return this._country.europeanUnionMember === true;
    }

    public isCommercial(): boolean {
        return this._naturalPerson === false;
    }
}

export default User;
