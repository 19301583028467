enum CentralPlaceType {
    SUBCENTER = 0,
    MEDIUM_CENTER = 1,
    ADVANCED_MEDIUM_CENTER = 2,
    MAJOR_CENTER = 3,
    ADVANCED_SUBCENTER = 4
}

const getCentralPlaceTypeLabel = (centralPlaceType: CentralPlaceType): string => {
    switch (centralPlaceType) {
        case CentralPlaceType.SUBCENTER:
            return 'Unterzentrum';
        case CentralPlaceType.MEDIUM_CENTER:
            return 'Mittelzentrum';
        case CentralPlaceType.ADVANCED_MEDIUM_CENTER:
            return 'Mittelzentrum mit Teilfunktion eines Oberzentrums';
        case CentralPlaceType.MAJOR_CENTER:
            return 'Oberzentrum';
        case CentralPlaceType.ADVANCED_SUBCENTER:
            return 'Unterzentrum mit Teilfunktion eines Mittelzentrums';
        default:
            throw new Error();
    }
};

export default CentralPlaceType;
export {getCentralPlaceTypeLabel};
