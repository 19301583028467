import BranchingDegree, { getBranchingDegreeLabel } from './Entity/BranchingDegree';
import SelectOption from './Entity/Form/SelectOption';
import FormError from './Entity/Form/FormError';
import FieldLabel from './Component/Form/Field/FieldLabel';
import React from 'react';
import Select, {OnChangeValue, PropsValue} from 'react-select';

interface BranchingDegreeSelectFieldProps {
    readonly label: string;
    readonly title: string;
    readonly name: string;
    readonly isMulti: boolean;
    readonly isClearable: boolean;
    readonly isRequired: boolean;
    readonly classNames?: string;
    readonly formErrors?: FormError[];
    readonly defaultValue?: BranchingDegree | BranchingDegree[];
    readonly onChange?: Function;
}

const BranchingDegreeSelectField = (props: BranchingDegreeSelectFieldProps): React.JSX.Element => {
    const hasErrors: boolean = props.formErrors !== undefined && props.formErrors.length > 0;

    const selectOptions: SelectOption<BranchingDegree>[] = [
        {label: getBranchingDegreeLabel(BranchingDegree.LOCAL_HERO), value: BranchingDegree.LOCAL_HERO},
        {label: getBranchingDegreeLabel(BranchingDegree.REGIONAL), value: BranchingDegree.REGIONAL},
        {label: getBranchingDegreeLabel(BranchingDegree.NATIONAL), value: BranchingDegree.NATIONAL},
        {label: getBranchingDegreeLabel(BranchingDegree.DACH), value: BranchingDegree.DACH},
        {label: getBranchingDegreeLabel(BranchingDegree.EUROPE), value: BranchingDegree.EUROPE},
        {label: getBranchingDegreeLabel(BranchingDegree.GLOBAL), value: BranchingDegree.GLOBAL},
    ];

    const fetchDefaultValue = (): PropsValue<SelectOption<BranchingDegree>> | undefined => {
        const selectedOptions: SelectOption<BranchingDegree>[] = selectOptions.filter((selectOption: SelectOption<BranchingDegree>): boolean => {
            if (Array.isArray(props.defaultValue) === true) {
                return (props.defaultValue as BranchingDegree[]).includes(selectOption.value);
            } else {
                return selectOption.value === props.defaultValue;
            }
        });

        if (props.isMulti === true) {
            return selectedOptions;
        } else {
            if (selectedOptions.length > 0) {
                return selectedOptions[0];
            }
        }

        return undefined;
    };

    return (
        <div>
            {/* TODO - umbau auf SelectField / MultiSelectField */}
            <FieldLabel label={props.label} htmlFor={props.name} required={props.isRequired} description={props.title} className="mb-2 ps-2" />
            <div>
                <Select
                    name={props.name}
                    options={selectOptions}
                    isClearable={props.isClearable}
                    isMulti={props.isMulti}
                    closeMenuOnSelect={false}
                    className={('inset-shadow ' + props.classNames !== undefined ? props.classNames : '')  + (hasErrors === true ? ' is-invalid' : '')}
                    placeholder="Bitte wählen"
                    defaultValue={fetchDefaultValue()}
                    onChange={(newValue: OnChangeValue<SelectOption<BranchingDegree>, boolean>): void => {
                        if (props.onChange !== undefined) {
                            if (Array.isArray(newValue) === true) {
                                props.onChange((newValue as Array<SelectOption<BranchingDegree>>).map((selectOption: SelectOption<BranchingDegree>): BranchingDegree => selectOption.value));
                            } else {
                                props.onChange((newValue as SelectOption<BranchingDegree>).value);
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default BranchingDegreeSelectField;
