enum InternetConnectionType {
    BroadbandConnection = 0,
    DigitalSubscriberLineConnection = 1,
    CableConnection = 2,
    FiberOpticConnection = 3
}

const getInternetConnectionTypeLabel = (internetConnectionType: InternetConnectionType): string => {
    switch (internetConnectionType) {
        case InternetConnectionType.BroadbandConnection:
            return 'Breitbandanschluss';
        case InternetConnectionType.DigitalSubscriberLineConnection:
            return 'DSL-Anschluss';
        case InternetConnectionType.CableConnection:
            return 'Kabelanschluss';
        case InternetConnectionType.FiberOpticConnection:
            return 'Glasfaseranschluss';
        default:
            throw new Error();
    }
};

export default InternetConnectionType;
export {getInternetConnectionTypeLabel};
