import User from '../../shared/Entity/User/User';
import Currency, {getCurrencySymbol} from '../../shared/Entity/Currency';
import React from 'react';

interface PriceProps {
    readonly numberFormat: Intl.NumberFormat;
    readonly currency: Currency;
    readonly user: User;
    readonly amount: number
    readonly priceNet: number;
    readonly priceGross: number;
    readonly showPricePerMatchPoint: boolean;
}

const Price = (props: PriceProps): React.JSX.Element => {
    const getPrice = (): number => {
        if (props.user.naturalPerson === true) {
            return props.priceGross;
        }

        return props.priceNet;
    };

    return (
        <>
            <div className="text-center fs-2 text-primary">
                {props.numberFormat.format(Number(getPrice().toFixed(2)))} {getCurrencySymbol(props.currency)}
            </div>
            <div className="text-center text-secondary">
                {props.user.naturalPerson === true &&
                    <>(inkl. MwSt.)</>
                }
                {props.user.naturalPerson === false &&
                    <>(zzgl. MwSt.)</>
                }
            </div>
            {props.showPricePerMatchPoint === true && props.amount > 0 &&
                <div className="text-center fs-6 fw-bold mt-3">
                    {(getPrice() / props.amount).toFixed(4).replace('.',',')} {getCurrencySymbol(props.currency)} je MatchPoint
                </div>
            }
        </>
    );
};

export default Price;
