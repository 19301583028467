import React from 'react';

interface CardContentProps extends React.PropsWithChildren {
    readonly className?: string;
}

const CardContent = (props: CardContentProps): React.JSX.Element => {
    const buildClassName = (): string => {
        let className: string = 'p-4';

        if (props.className !== undefined) {
            className += ' ' + props.className;
        }

        return className;
    };

    return (
        <div className={buildClassName()}>
            {props.children}
        </div>
    );
};

export default CardContent;
