import FormError from '../../../Entity/Form/FormError';
import FieldProps from './FieldProps';
import FieldLabel from './FieldLabel';
import React from 'react';
import Select, {ActionMeta, Options, PropsValue, MultiValue} from 'react-select';

interface MultiSelectFieldProps<Option> extends FieldProps {
    readonly options: Options<Option>;
    readonly placeholder: React.ReactNode;
    readonly closeMenuOnSelect: boolean;
    readonly value: MultiValue<Option>;
    readonly onChange: (newValue: MultiValue<Option>, actionMeta: ActionMeta<Option>) => void;
    readonly defaultValue?: PropsValue<Option>;
    readonly isClearable?: boolean;
}

const MultiSelectField = <Option = unknown>(props: MultiSelectFieldProps<Option>): React.JSX.Element => {
    const hasErrors: boolean = props.formErrors !== undefined && props.formErrors.length > 0;

    return (
        <>
            {props.label !== undefined &&
                <FieldLabel label={props.label} htmlFor={props.name} required={props.required} description={props.description} className="mb-2 ps-2" />
            }
            <Select
                isMulti
                name={props.name}
                options={props.options}
                placeholder={props.placeholder}
                closeMenuOnSelect={props.closeMenuOnSelect}
                value={props.value}
                onChange={props.onChange}
                defaultValue={props.defaultValue}
                required={props.required}
                isClearable={props.isClearable}
                className={['inset-shadow', hasErrors === true ?'is-invalid': ''].join(' ')}
            />
            {hasErrors === true &&
                <>
                    {props.formErrors!.map((formError: FormError, index: number): React.JSX.Element => (
                        <div key={'select_error_' + props.name + '_' + index}  className="invalid-feedback d-block">
                            {formError.message}
                        </div>
                    ))}
                </>
            }
        </>
    );
};

export default MultiSelectField;
