import FormError from '../../../Entity/Form/FormError';
import FieldProps from './FieldProps';
import FieldLabel from './FieldLabel';
import fieldStyle from './Field.module.scss';
import inputFieldStyle from './InputField.module.scss';
import React from 'react';

interface InputFieldProps extends FieldProps {
    readonly placeholder?: string;
    readonly value?: string | ReadonlyArray<string> | number;
    readonly type?: React.HTMLInputTypeAttribute;
    readonly max?: number | string;
    readonly maxLength?: number;
    readonly min?: number | string;
    readonly onChange?: React.ChangeEventHandler<HTMLInputElement>;

    readonly     id?: string; // deprecated
}

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>((props: InputFieldProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const hasErrors: boolean = props.formErrors !== undefined && props.formErrors.length > 0;

    return (
        <>
            {props.label !== undefined &&
                <FieldLabel label={props.label} htmlFor={props.name} required={props.required} description={props.description} className="mb-2 ps-2" />
            }
            <div style={props.style} className={(props.suffix) && inputFieldStyle.inputGroup}>
                <input
                    ref={ref}
                    name={props.name}
                    required={props.required}
                    disabled={props.disabled}
                    className={[fieldStyle.container, hasErrors === true ? 'is-invalid form-control' : ''].join(' ')}
                    placeholder={props.placeholder}
                    value={props.value}
                    type={props.type}
                    max={props.max}
                    maxLength={props.maxLength}
                    min={props.min}
                    onChange={props.onChange}
                />
                {props.suffix !== undefined && <div className={inputFieldStyle.suffix}>{props.suffix}</div>}
            </div>
            {hasErrors === true &&
                <>
                    {props.formErrors!.map((formError: FormError, index: number): React.JSX.Element => (
                        <div key={'input_error_' + props.name + '_' + index} className="invalid-feedback d-block">
                            {formError.message}
                        </div>
                    ))}
                </>
            }
        </>
    );
});

export default InputField;
