enum BranchingDegree {
    LOCAL_HERO = 0,
    REGIONAL = 1,
    NATIONAL = 2,
    DACH = 3,
    EUROPE = 4,
    GLOBAL = 5
}

const getBranchingDegreeLabel = (branchingDegree: BranchingDegree): string => {
    switch (branchingDegree) {
        case BranchingDegree.LOCAL_HERO:
            return 'Local Hero';
        case BranchingDegree.REGIONAL:
            return 'Regional';
        case BranchingDegree.NATIONAL:
            return 'National';
        case BranchingDegree.DACH:
            return 'DACH';
        case BranchingDegree.EUROPE:
            return 'Europa';
        case BranchingDegree.GLOBAL:
            return 'Global';
        default:
            throw new Error();
    }
};

export default BranchingDegree;
export {getBranchingDegreeLabel};
