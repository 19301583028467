import welcomeInfoBoxStyle from './WelcomeInfoBox.module.scss';
import React from 'react';
import { Link } from 'react-router-dom';

interface WelcomeInfoBoxProps {
    readonly absolutePosition?: boolean;
}

const WelcomeInfoBox = (props: WelcomeInfoBoxProps): React.JSX.Element => {
    return (
        <div className={props.absolutePosition !== undefined && props.absolutePosition === true ? ['d-none', 'd-lg-block', 'p-3', 'col-lg-4', welcomeInfoBoxStyle.infoBox].join(' ') : 'p-2'}>
            <p>
                <strong>Schön, dass Du hier bist! Das kannst Du hier alles machen:</strong>
            </p>
            <p>
                Du suchst eine neue Ladenfläche? Dann bist Du hier genau richtig.<br />
                Denn hier kannst Du kostenfrei und unverbindlich beliebig viele Flächengesuche anlegen und diese für Eigentümer, Makler und Kommunen sichtbar machen.
            </p>
            <p>
                Du hast eine leerstehende oder bald leerfallende Ladenfläche und suchst einen Mieter oder Käufer? Dann bist Du hier genau richtig. Nutze unser Matching und finde Deinen neuen Nachnutzer!
            </p>
            <p>
                Und so einfach kannst Du loslegen: <Link to="/registrierung">Lege jetzt Dein Profil an!</Link>
            </p>
            <p>
                Du hast Fragen? Unser <Link to="https://www.llasm.de/kontakt" target="_blank">Support</Link> hilft Dir gerne weiter.
            </p>
            <p>
                <strong>Mach mit, match mit!</strong>
            </p>
        </div>
    );
};

export default WelcomeInfoBox;
