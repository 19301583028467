enum EnergyCertificateType {
    EnergyRequirementCertificate = 0,
    EnergyConsumptionCertificate = 1
}

const getEnergyCertificateTypeLabel = (energyCertificateType: EnergyCertificateType): string => {
    switch (energyCertificateType) {
        case EnergyCertificateType.EnergyRequirementCertificate:
            return 'Energiebedarfsausweis';
        case EnergyCertificateType.EnergyConsumptionCertificate:
            return 'Energieverbrauchsausweis';
        default:
            throw new Error();
    }
};

export default EnergyCertificateType;
export {getEnergyCertificateTypeLabel};
