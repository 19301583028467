import fancyNumberInputFieldStyle from './FancyNumberInputField.module.scss';
import React, {useEffect, useRef, useState} from 'react';

interface FancyNumberInputFieldProps {
    readonly amount: number;
    readonly setAmount: (amount: number | ((prevState: number) => number)) => void;
    readonly min: number;
    readonly max: number;
}

let timeout: NodeJS.Timeout | number | undefined = undefined;
let interval: NodeJS.Timeout | number | undefined = undefined;

const FancyNumberInputField = (props: FancyNumberInputFieldProps): React.JSX.Element => {
    const numberInputRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

    const [isFocused, setIsFocused] = useState<boolean>(false);

    useEffect((): void => {
        if (props.amount < props.min) {
            props.setAmount(props.min);
        }

        if (props.amount > props.max) {
            props.setAmount(props.max);
        }
    }, [props.amount]);

    const buildClassName = (): string => {
        let className = fancyNumberInputFieldStyle.fancyNumberInputField;

        if (isFocused === true) {
            className += ' ' + fancyNumberInputFieldStyle.fancyNumberInputFieldFocused;
        }

        return className;
    };

    const countUp = (): void => {
        setIsFocused(true);

        props.setAmount((prevState:  number): number => prevState + 1);

         timeout = setTimeout((): void => {
            stepUpInterval();
        }, 200);
    };

    const countDown = (): void => {
        setIsFocused(true);

        props.setAmount((prevState:  number): number => prevState - 1);

        timeout = setTimeout((): void => {
            stepDownInterval();
        }, 200);
    };

    const cancelCount = (): void => {
        if (timeout !== undefined) {
            clearTimeout(timeout);
        }
        if (interval !== undefined) {
            clearInterval(interval);
        }
    };

    const stepUpInterval = (): void => {
        interval = setInterval((): void => {
            props.setAmount((prevState:  number): number => prevState + 1);
        }, 100);
    };

    const stepDownInterval = (): void => {
        interval = setInterval((): void => {
            props.setAmount((prevState:  number): number => prevState - 1);
        }, 100);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.setAmount(Number(event.target.value));
    };

    return (
        <>
            <span onMouseDown={countDown} onMouseUp={cancelCount} onMouseOut={cancelCount} className="bi bi-dash-circle fs-2" />
            <input ref={numberInputRef} className={buildClassName()} value={props.amount} type="number" onChange={onChange} min={props.min} max={props.max} />
            <span onMouseDown={countUp} onMouseUp={cancelCount} onMouseOut={cancelCount} className="bi bi-plus-circle fs-2" />
        </>
    );
};

export default FancyNumberInputField;
