import UserService from '../User/UserService';
import PurchaseService from './PurchaseService';
import AuthenticationState from '../Entity/Authentication/AuthenticationState';
import User from '../Entity/User/User';
import Product from '../Entity/Product/Product';
import {getDurationLabel} from '../Entity/Product/Duration';
import ProductType from '../Entity/Product/ProductType';
import Overlay from '../Component/Overlay/Overlay';
import LoadingIndicator from '../../../components/LoadingIndicator';
import {useAppSelector} from '../../../app/hooks';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';

interface PurchaseHallOfInspirationProductOverlayProps {
    readonly show: boolean;
    readonly setShow: (show: boolean) => void;
    readonly hallOfInspirationProducts: Product[];
    readonly numberOfConcepts: number;
    readonly onPurchaseHallOfInspirationProductOverlayClose?: () => void;
}

const userService: UserService = new UserService(process.env.REACT_APP_LLASM_API_URL!);
const purchaseService: PurchaseService = new PurchaseService(process.env.REACT_APP_LLASM_API_URL!);

const PurchaseHallOfInspirationProductOverlay = (props: PurchaseHallOfInspirationProductOverlayProps): React.JSX.Element => {
    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const navigate: NavigateFunction = useNavigate();

    const [user, setUser] = useState<User>();

    const [hallOfInspirationProducts, setHallOfInspirationProducts] = useState<Product[]>();

    const [showAgreementError, setShowAgreementError] = useState<boolean>(false);

    const [checkedHallOfInspirationProduct, setCheckedHallOfInspirationProduct] = useState<Product|undefined>();

    const [showPurchaseError, setShowPurchaseError] = useState<boolean>(false);

    const [showPurchaseSuccess, setShowPurchaseSuccess] = useState<boolean>(false);

    const [showActionButton, setShowActionButton] = useState<boolean>(false);

    const [showMatchPointButton, setShowMatchPointButton] = useState<boolean>(false);

    useEffect((): void => {
        if (props.show === true) {
            fetchUser();
        }
    }, [props.show]);

    useEffect((): void => {
        if (user === undefined) {
            return;
        }

        props.hallOfInspirationProducts.forEach((hallOfInspirationProduct: Product): void => {
            if (hallOfInspirationProduct.productType !== ProductType.HallOfInspiration) {
                throw new Error();
            }

            if (hallOfInspirationProduct.priceMatchPoints === null) {
                throw new Error();
            }

            if (hallOfInspirationProduct.duration === null) {
                throw new Error();
            }

            if (user.numberOfMatchPoints >= hallOfInspirationProduct.priceMatchPoints) {
                setShowActionButton(true);
            } else {
                setShowMatchPointButton(true);
            }
        });

        setHallOfInspirationProducts(props.hallOfInspirationProducts);
    }, [props.hallOfInspirationProducts, user]);

    const fetchUser = async (): Promise<void> => {
        if (authenticatedUser === undefined || authenticatedUser === null) {
            return;
        }

        const user: User = await userService.fetchUserFromApiById(authenticatedUser.user.id!);

        setUser(user);
    };

    const purchaseHallOfInspirationConceptProduct = async (): Promise<void> => {
        if (checkedHallOfInspirationProduct === undefined) {
            setShowAgreementError(true);

            return;
        }

        try {
            await purchaseService.purchaseHallOfInspirationSubscription(checkedHallOfInspirationProduct);

            setShowPurchaseSuccess(true);
            setShowPurchaseError(false);
        } catch (error) {
            setShowPurchaseError(true);
            setShowPurchaseSuccess(false);
        }
    };

    const handlePurchaseAgreementCheckboxes = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (hallOfInspirationProducts === undefined) {
            return;
        }

        if (checkedHallOfInspirationProduct === hallOfInspirationProducts[Number(event.target.value)]) {
            setCheckedHallOfInspirationProduct(undefined);
        } else {
            setCheckedHallOfInspirationProduct(hallOfInspirationProducts[Number(event.target.value)]);
        }

        if (showAgreementError === true && event.target.checked === true) {
            setShowAgreementError(false);
        }
    };

    const isPurchaseAgreementCheckboxChecked = (hallOfInspirationProduct: Product): boolean => {
        if (checkedHallOfInspirationProduct === hallOfInspirationProduct) {
            return true;
        } else {
            return false;
        }
    };

    const handleCloseButtonClick = (): void => {
        props.setShow(false);

        if (props.onPurchaseHallOfInspirationProductOverlayClose !== undefined) {
            props.onPurchaseHallOfInspirationProductOverlayClose();
        }
    };

    return (
        <Overlay show={props.show} setShow={props.setShow} title="Hall of Inspiration freischalten" onClose={props.onPurchaseHallOfInspirationProductOverlayClose}>
            <div className="container">
                <div className="fs-5">
                     Wir freuen uns, dass Du Dich von unserer Hall of Inspiration weiter inspirieren lassen möchtest.
                </div>
                <div className="pt-1 mb-3">
                    Du kannst hier die gesamte Hall of Inspiration mit aktuell {props.numberOfConcepts} Konzepten freischalten.
                    Du kannst alle aktuellen Konzepte einsehen und erhältst auch Zugriff auf alle im Buchungszeitraum neu
                    eingestellten Inspirationen.
                </div>
                {(user === undefined || hallOfInspirationProducts === undefined) &&
                    <div className="p-3">
                        <div className="d-flex justify-content-center align-items-center">
                            <LoadingIndicator/>
                        </div>
                    </div>
                }
                {user !== undefined && hallOfInspirationProducts !== undefined &&
                    <>
                        {showPurchaseSuccess === false &&
                            <div className="row">
                                {hallOfInspirationProducts.map((hallOfInspirationProduct: Product, index: number): React.JSX.Element => (
                                    <div key={'hallOfInspirationProduct-' + hallOfInspirationProduct.id} className="col pt-2">
                                        <div className="alert alert-light mb-3">
                                            <div className="fs-3 mb-3">
                                                Hall of Inspiration
                                                für {hallOfInspirationProduct.priceMatchPoints} MatchPoints
                                                für {getDurationLabel(hallOfInspirationProduct.duration!)} freischalten
                                            </div>
                                            {user.numberOfMatchPoints < hallOfInspirationProduct.priceMatchPoints! ? (
                                                <div className="text-danger">
                                                    Für dieses Produkt hast du derzeit nicht genügend MatchPoints.
                                                </div>
                                            ) : (
                                                <div className="d-flex">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={isPurchaseAgreementCheckboxChecked(hallOfInspirationProduct)}
                                                        id={'purchaseAgreementCheckbox' + hallOfInspirationProduct.id}
                                                        name={'purchaseAgreementCheckbox' + hallOfInspirationProduct.id}
                                                        onChange={handlePurchaseAgreementCheckboxes}
                                                        value={index}
                                                    />
                                                    <label className="form-check-label ms-2" htmlFor={'purchaseAgreementCheckbox' + hallOfInspirationProduct.id}>
                                                        Ja, ich
                                                        möchte {hallOfInspirationProduct.priceMatchPoints} MatchPoints
                                                        ausgeben, um die Hall of Inspiration
                                                        für {getDurationLabel(hallOfInspirationProduct.duration!)} freizuschalten
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                        {showAgreementError === true &&
                            <div className="alert alert-danger text-center mb-3 fs-3">
                                Bitte wähle aus, für welches Produkt Du MatchPoints ausgeben möchtest.
                            </div>
                        }
                        {showPurchaseError === true &&
                            <div className="alert alert-danger text-center mb-3 fs-3">
                                Es gab einen Fehler beim Freischalten der Hall of Inspiration, bitte versuche es
                                erneut.
                            </div>
                        }
                        {showPurchaseSuccess === true &&
                            <div className="alert alert-success text-center mb-3 fs-3">
                                Die Freischaltung war erfolgreich.
                            </div>
                        }
                        <div className="d-flex justify-content-end mt-3">
                            <button type="button" className="btn btn-dark" onClick={handleCloseButtonClick}>
                                Schließen
                            </button>
                            {showPurchaseSuccess === false && showMatchPointButton === true &&
                                <button className="btn btn-outline-secondary ms-2" onClick={(): void => navigate('/match-points')}>
                                    MatchPoints kaufen
                                </button>
                            }
                            {(showPurchaseSuccess === false && showActionButton === true) &&
                                <button type="button" className="btn btn-primary ms-2" onClick={purchaseHallOfInspirationConceptProduct}>
                                    Hall of Inspiration freischalten
                                </button>
                            }
                        </div>
                    </>
                }
            </div>
        </Overlay>
    );
};

export default PurchaseHallOfInspirationProductOverlay;
