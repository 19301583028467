import LeanApiConfigurationResponse from '../../../api/Llasm/Messaging/LeanApiConfigurationResponse';

class LeanApiConfiguration {
    private _id?: number;

    private _leanProvider: string;

    public static createFromLeanApiConfigurationResponse(leanApiConfigurationResponse: LeanApiConfigurationResponse): LeanApiConfiguration {
        const leanApiConfiguration: LeanApiConfiguration = new this();

        leanApiConfiguration._id = leanApiConfigurationResponse.id;
        leanApiConfiguration._leanProvider = leanApiConfigurationResponse.leanProvider;

        return leanApiConfiguration;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get leanProvider(): string {
        return this._leanProvider;
    }

    set leanProvider(value: string) {
        this._leanProvider = value;
    }
}

export default LeanApiConfiguration;
