class UserPasswordReset {

    private _password: string;

    private _passwordRepeat: string;

    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }

    get passwordRepeat(): string {
        return this._passwordRepeat;
    }

    set passwordRepeat(value: string) {
        this._passwordRepeat = value;
    }

}

export default UserPasswordReset;
