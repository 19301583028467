import ContactService from '../../shared/Contact/ContactService';
import AuthenticationState from '../../shared/Entity/Authentication/AuthenticationState';
import Contact from '../../shared/Entity/Contact/Contact';
import Spinner from '../../../components/Spinner';
import { useAppSelector } from '../../../app/hooks';
import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

const contactService: ContactService = new ContactService(process.env.REACT_APP_LLASM_API_URL!);

const InitialEntryPage = (): React.JSX.Element => {
    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const [contacts, setContacts] = useState<Contact[] | undefined>(undefined);

    useEffect((): void => {
        fetchData();
    }, []);

    const fetchData = async (): Promise<void> => {
        setContacts(await contactService.fetchContactsFromApi());
    };

    if (contacts === undefined) {
        return <Spinner />;
    }

    return (
        <div className="py-3 mb-4">
            <h1 className="card-title text-secondary text-center mb-4 fs-xl fw-bold">
                Hallo {authenticatedUser?.user.firstName}!<br/>
                <span className="fs-lg">Was möchtest Du tun?</span>
            </h1>
            <div className="d-flex justify-content-center gap-5 flex-wrap mb-5">
                {contacts.length > 0 &&
                    <Link to="/flaechen/anbieten" className="btn p-5 shadow highlight rounded-5 d-flex flex-column align-items-center bg-white text-normal">
                        <span className="text-center fs-1 text-primary mb-3">Nachnutzer finden:</span>
                        <span className="text-center fs-1 text-secondary mb-3">Fläche / Ladenlokal<br/>anlegen</span>
                        <i className="bi bi-shop text-secondary fs-xxl"></i>
                    </Link>
                }
                {(contacts.length === 0) &&
                    <Link to="/flaechen/ersterfassung" className="btn p-5 shadow highlight rounded-5 d-flex flex-column align-items-center bg-white text-normal">
                        <span className="text-center fs-1 text-primary mb-3">Nachnutzer finden:</span>
                        <span className="text-center fs-1 text-secondary mb-3">Fläche / Ladenlokal<br/>anlegen</span>
                        <i className="bi bi-shop text-secondary fs-xxl"></i>
                    </Link>
                }
            </div>
        </div>
    );
};

export default InitialEntryPage;
