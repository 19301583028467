import ContactIntention from '../Entity/ContactIntention/ContactIntention';
import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import ContactIntentionPostRequest from '../../../api/Llasm/ContactIntention/ContactIntentionPostRequest';
import ContactIntentionResponse from '../../../api/Llasm/ContactIntention/ContactIntentionResponse';
import {AxiosResponse} from 'axios';

class ContactIntentionService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    private readonly resourcePath: string;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
        this.resourcePath = '/contact-intentions';
    }

    public async fetchContactIntentionFromApiByIdentifier(identifier: string): Promise<ContactIntention> {
        const apiResponse: AxiosResponse<ContactIntentionResponse> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + identifier);

        return ContactIntention.createFromContactIntentionResponse(apiResponse.data);
    }

    public async persistContactIntention(contactIntention: ContactIntention): Promise<ContactIntention> {
        const response: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, ContactIntentionPostRequest>(
            this.resourcePath,
            ContactIntentionService.mapFromContactIntentionToContactIntentionPostRequest(contactIntention)
        );

        const apiResponse: AxiosResponse<ContactIntentionResponse> = await this.llasmApiV1Provider.getFromLocation(response.headers.location);

        return ContactIntention.createFromContactIntentionResponse(apiResponse.data);
    }

    private static mapFromContactIntentionToContactIntentionPostRequest(contactIntention: ContactIntention): ContactIntentionPostRequest {
        return {
            propertyId: contactIntention.propertyId,
            settlementConceptId: contactIntention.settlementConceptId
        };
    }
}

export default ContactIntentionService;
