import Conversation from '../../Entity/Messaging/Conversation';
import ProviderSeekerConversation from '../../Entity/Messaging/ProviderSeekerConversation';
import LeanConversation from '../../Entity/Messaging/LeanConversation';
import ProviderSeekerConversationListItem from './ProviderSeekerConversationListItem';
import LeanConversationListItem from './LeanConversationListItem';
import conversationListItemStyle from './ConversationListItem.module.scss';
import React from 'react';

interface ConversationListItemProps {
    readonly conversation: Conversation;
    readonly active: boolean;
}

const ConversationListItem = (props: ConversationListItemProps): React.JSX.Element => {
    return (
        <div
            className={props.active === true ? [conversationListItemStyle.conversationListItem, conversationListItemStyle.active].join(' ') : conversationListItemStyle.conversationListItem}
            role="button"
        >
            {props.conversation instanceof ProviderSeekerConversation &&
                <ProviderSeekerConversationListItem providerSeekerConversation={props.conversation} />
            }
            {props.conversation instanceof LeanConversation &&
                <LeanConversationListItem leanConversation={props.conversation} />
            }
        </div>
    );
};

export default ConversationListItem;
