import ContactIntentionResponse from '../../../../api/Llasm/ContactIntention/ContactIntentionResponse';

class ContactIntention {
    private _id?: number;

    private _propertyId: number;

    private _settlementConceptId: number;

    private _createdAt: Date;

    constructor(propertyId: number, settlementConceptId: number) {
        this._propertyId = propertyId;
        this._settlementConceptId = settlementConceptId;
    }

    public static createFromContactIntentionResponse(contactIntentionResponse: ContactIntentionResponse): ContactIntention {
        const contactIntention: ContactIntention = new this(contactIntentionResponse.propertyId, contactIntentionResponse.settlementConceptId);

        contactIntention._id = contactIntentionResponse.id;
        contactIntention._createdAt = new Date(contactIntentionResponse.createdAt);

        return contactIntention;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get propertyId(): number {
        return this._propertyId;
    }

    set propertyId(value: number) {
        this._propertyId = value;
    }

    get settlementConceptId(): number {
        return this._settlementConceptId;
    }

    set settlementConceptId(value: number) {
        this._settlementConceptId = value;
    }

    get createdAt(): Date {
        return this._createdAt;
    }

    set createdAt(value: Date) {
        this._createdAt = value;
    }
}

export default ContactIntention;
