enum LocationType {
    DowntownLocation = 0,
    CityDistrictLocation = 1,
    PeripheralLocation = 2,
    HighFrequencySpecialLocation = 3,
    ScatteredAndSolitaryLayers = 4
}

const getLocationTypeLabel = (locationType: LocationType): string => {
    switch (locationType) {
        case LocationType.DowntownLocation:
            return 'Innenstadtlagen / City / Hauptzentrum (1er-Lage)';
        case LocationType.CityDistrictLocation:
            return 'Stadtteillage / Nebenzentrum / Nahversorgungszentrum (2er-Lage)';
        case LocationType.PeripheralLocation:
            return 'Periphere Lage';
        case LocationType.HighFrequencySpecialLocation:
            return 'Frequenzstarke Sonderlage (z. B. Flughafen, Bahnhof, EKZ)';
        case LocationType.ScatteredAndSolitaryLayers:
            return 'Streu- und Solitärlagen';
        default:
            throw new Error();
    }
};

export default LocationType;
export {getLocationTypeLabel};
