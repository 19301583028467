import UserService from '../../domain/shared/User/UserService';
import UserState from '../../domain/shared/Entity/User/UserState';
import AuthenticatedUser from '../../domain/shared/Entity/Authentication/AuthenticatedUser';
import User from '../../domain/shared/Entity/User/User';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const userService: UserService = new UserService(process.env.REACT_APP_LLASM_API_URL!);

const initialState: UserState = {
    user: undefined,
    isLoading: false,
    error: undefined,
    isError: false,
    message: ''
};

const fetchUser = createAsyncThunk<User | undefined, AuthenticatedUser>('user/fetchUser', async (authenticatedUser: AuthenticatedUser, thunkAPI) => {
    if (authenticatedUser.user === undefined || authenticatedUser.user === null) {
        return undefined;
    }

    if (authenticatedUser.user.id === undefined) {
        return undefined;
    }

    try {
        return await userService.fetchUserFromApiById(authenticatedUser.user.id!);
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder): void => {
        builder
            .addCase(fetchUser.pending, (state): void => {
                state.isLoading = true;
            })
            .addCase(fetchUser.fulfilled, (state, action): void => {
                state.isLoading = false;
                state.user = action.payload;
                state.isError = false;
                state.message = '';
            })
            .addCase(fetchUser.rejected, (state, action): void => {
                state.isLoading = false;
                state.user = undefined;
                state.error = action.payload;
                state.isError = true;
                state.message = String(action.payload);
            });
    }
});

export default userSlice.reducer;
export {fetchUser};
