import FormData from '../Entity/Form/FormData';
import FieldValidationDefinition from './FieldValidationDefinition';

class IsTrueValidationDefinition<T> extends FieldValidationDefinition<T> {
    constructor(fieldName: string, message: string | null) {
        super(fieldName, message);
    }

    public check(formData: FormData<T>): boolean {
        const formDataValue: any = (formData.data as any)[this._fieldName];

        return typeof formDataValue === 'boolean' && formDataValue === true;
    }
}

export default IsTrueValidationDefinition;
