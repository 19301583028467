import FormError from '../Entity/Form/FormError';
import UserIs, {getUserIsLabel} from '../Entity/User/UserIs';
import SelectOption from '../Entity/Form/SelectOption';
import SelectField from '../Component/Form/Field/SelectField';
import React from 'react';
import {OnChangeValue} from 'react-select';

interface UserIsSelectFieldProps {
    readonly label: string;
    readonly name: string;
    readonly isClearable: boolean;
    readonly isRequired: boolean;
    readonly classNames?: string;
    readonly formErrors?: FormError[];
    readonly defaultValue?: UserIs;
    readonly onChange?: (selectedOption: SelectOption<UserIs> | null) => void;
}

const UserIsSelectField = (props: UserIsSelectFieldProps): React.JSX.Element => {
    const selectOptions: SelectOption<UserIs>[] = [
        {label: getUserIsLabel(UserIs.PropertyProvider), value: UserIs.PropertyProvider},
        {label: getUserIsLabel(UserIs.PropertySeeker), value: UserIs.PropertySeeker},
        {label: getUserIsLabel(UserIs.RealEstateAgent), value: UserIs.RealEstateAgent},
        {label: getUserIsLabel(UserIs.MunicipalityCityMarketingEconomicDevelopment), value: UserIs.MunicipalityCityMarketingEconomicDevelopment},
        {label: getUserIsLabel(UserIs.InterestedInHallOfInspiration), value: UserIs.InterestedInHallOfInspiration},
        {label: getUserIsLabel(UserIs.GeneralInterestOther), value: UserIs.GeneralInterestOther},
    ];

    const fetchDefaultValue = (): SelectOption<UserIs> | undefined => {
        return selectOptions.find((selectOption: SelectOption<UserIs>): boolean => {
            return selectOption.value === props.defaultValue;
        });
    };

    return (
        <SelectField
            label={props.label}
            name={props.name}
            required={props.isRequired}
            isClearable={props.isClearable}
            options={selectOptions}
            placeholder="Bitte wählen"
            onChange={(newValue: OnChangeValue<SelectOption<UserIs>, boolean>): void => {
                if (props.onChange !== undefined) {
                    props.onChange(newValue as SelectOption<UserIs>);
                }
            }}
            value={fetchDefaultValue()}
            formErrors={props.formErrors}
        />
    );
};

export default UserIsSelectField;
