import AlertType from './AlertType';
import React from 'react';

class Alert {
    private _alertType: AlertType;

    private _message: string | React.JSX.Element;

    public constructor(alertType: AlertType, message: string | React.JSX.Element) {
        this._alertType = alertType;
        this._message = message;
    }

    get alertType(): AlertType {
        return this._alertType;
    }

    set alertType(value: AlertType) {
        this._alertType = value;
    }

    get message(): string | React.JSX.Element {
        return this._message;
    }

    set message(value: string | React.JSX.Element) {
        this._message = value;
    }

    public buildBootstrapClassName(): string {
        if (this._alertType === AlertType.Success) {
            return 'alert-success';
        }

        if (this._alertType === AlertType.Error) {
            return 'alert-danger';
        }

        if (this._alertType === AlertType.Warning) {
            return 'alert-warning';
        }

        if (this._alertType === AlertType.Info) {
            return 'alert-info';
        }

        return '';
    }
}

export default Alert;
