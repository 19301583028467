import PurchaseDetailResponse from '../../../../../api/Llasm/Property/OfferDetail/PurchaseDetailResponse';

class PurchaseDetail {
    private _purchasePrice: number | null = null;

    private _commissionAmount: string | null = null;

    private _commissionNote: string | null = null;

    public static createFromPurchaseDetailResponse(purchaseDetailResponse: PurchaseDetailResponse): PurchaseDetail {
        const purchaseDetail: PurchaseDetail = new this();

        purchaseDetail._purchasePrice = purchaseDetailResponse.purchasePrice;
        purchaseDetail._commissionAmount = purchaseDetailResponse.commissionAmount;
        purchaseDetail._commissionNote = purchaseDetailResponse.commissionNote;

        return purchaseDetail;
    }

    get purchasePrice(): number | null {
        return this._purchasePrice;
    }

    set purchasePrice(value: number | null) {
        this._purchasePrice = value;
    }

    get commissionAmount(): string | null {
        return this._commissionAmount;
    }

    set commissionAmount(value: string | null) {
        this._commissionAmount = value;
    }

    get commissionNote(): string | null {
        return this._commissionNote;
    }

    set commissionNote(value: string | null) {
        this._commissionNote = value;
    }
}

export default PurchaseDetail;
