enum PropertyAcquisitionType {
    Buy = 0,
    Rent = 1
}

const getPropertyAcquisitionTypeLabel = (propertyAcquisitionType: PropertyAcquisitionType): string => {
    switch (propertyAcquisitionType) {
        case PropertyAcquisitionType.Buy:
            return 'Kauf';
        case PropertyAcquisitionType.Rent:
            return 'Miete';
        default:
            throw new Error();
    }
};

export default PropertyAcquisitionType;
export {getPropertyAcquisitionTypeLabel};
