import FormData from '../../shared/Entity/Form/FormData';
import Country from '../../shared/Entity/Location/Country';
import UserRegistration from '../../shared/Entity/User/UserRegistration';
import SelectOption from '../../shared/Entity/Form/SelectOption';
import UserIs from '../../shared/Entity/User/UserIs';
import OriginChannel from '../../shared/Entity/User/OriginChannel';
import OriginChannelSelectField from '../../shared/User/OriginChannelSelectField';
import UserIsSelectField from '../../shared/User/UserIsSelectField';
import FormValidationHandler from '../../shared/FormValidationHandler/FormValidationHandler';
import SelectField from '../../shared/Component/Form/Field/SelectField';
import InputField from '../../shared/Component/Form/Field/InputField';
import React from 'react';
import {Link} from 'react-router-dom';

interface UserRegistrationFormProps {
    readonly formData: FormData<UserRegistration>;
    readonly setFormData: Function;
    readonly countrySelectOptions: SelectOption<Country>[];
    readonly formValidationHandler?: FormValidationHandler<UserRegistration>;
}

const naturalPersonSelectOptions: SelectOption<boolean>[] = [
    {label: 'ja', value: true},
    {label: 'nein', value: false},
];

const UserRegistrationForm = (props: UserRegistrationFormProps): React.JSX.Element => {
    const userRegistration: UserRegistration = props.formData.data;

    const fetchNaturalPersonSelectedOption = (): SelectOption<boolean> | undefined => {
        if (userRegistration.naturalPerson === undefined) {
            return undefined;
        }

        return naturalPersonSelectOptions.find((selectOption: SelectOption<boolean>): boolean => {
            return userRegistration.naturalPerson === selectOption.value;
        });
    };

    const fetchCountrySelectedOption = (): SelectOption<Country> | undefined => {
        if (userRegistration.country === undefined) {
            return undefined;
        }

        return props.countrySelectOptions.find((selectOption: SelectOption<Country>): boolean => {
            return userRegistration.country === selectOption.value;
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === 'gtcAccepted') {
            userRegistration.gtcAccepted = event.target.checked;
        } else {
            (userRegistration as any)[event.target.name] = event.target.value;
        }

        updateFormData();
        validateField(event.target.name);
    };

    const handleNaturalPersonSelectChange = (selectedValue: SelectOption<boolean> | null): void => {
        if (selectedValue === null) {
            return;
        }

        userRegistration.naturalPerson = selectedValue.value;

        updateFormData();
        validateField('naturalPerson');
    };

    const handleCountrySelectChange = (selectedValue: SelectOption<Country> | null): void => {
        if (selectedValue === null) {
            return;
        }

        userRegistration.country = selectedValue.value;

        updateFormData();
        validateField('country');
    };

    const handleOriginChannelSelectChange = (selectedValue: SelectOption<OriginChannel> | null): void => {
        if (selectedValue === null) {
            return;
        }

        userRegistration.originChannel = selectedValue.value;

        updateFormData();
        validateField('originChannel');
    };

    const handleUserIsSelectChange = (selectedValue: SelectOption<UserIs> | null): void => {
        if (selectedValue === null) {
            return;
        }

        userRegistration.userIs = selectedValue.value;

        updateFormData();
        validateField('userIs');
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: userRegistration});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <>
            <div className="mb-3">
                <SelectField
                    name="naturalPerson"
                    label="Natürliche Person (Privatperson)"
                    required={true}
                    options={naturalPersonSelectOptions}
                    value={fetchNaturalPersonSelectedOption()}
                    defaultValue={{label: 'nein', value: false}}
                    placeholder="Bitte wählen"
                    onChange={handleNaturalPersonSelectChange}
                />
            </div>
            {userRegistration.naturalPerson === false &&
                <div className="mb-3">
                    <InputField
                        name="companyName"
                        label="Firmenname"
                        type="text"
                        required={true}
                        value={userRegistration.companyName ?? undefined}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'companyName')}
                    />
                </div>
            }
            <div className="mb-3">
                <InputField
                    name="firstName"
                    label="Vorname"
                    type="text"
                    required={true}
                    value={userRegistration.firstName}
                    onChange={handleChange}
                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'firstName')}
                />
            </div>
            <div className="mb-3">
                <InputField
                    name="lastName"
                    label="Nachname"
                    type="text"
                    required={true}
                    value={userRegistration.lastName}
                    onChange={handleChange}
                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'lastName')}
                />
            </div>
            <div className="mb-3">
                <InputField
                    name="streetName"
                    label="Straße"
                    type="text"
                    required={true}
                    value={userRegistration.streetName}
                    onChange={handleChange}
                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'streetName')}
                />
            </div>
            <div className="mb-3">
                <InputField
                    name="houseNumber"
                    label="Hausnummer"
                    type="text"
                    required={true}
                    value={userRegistration.houseNumber}
                    onChange={handleChange}
                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'houseNumber')}
                />
            </div>
            <div className="mb-3">
                <InputField
                    name="postalCode"
                    label="PLZ"
                    type="text"
                    required={true}
                    value={userRegistration.postalCode}
                    onChange={handleChange}
                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'postalCode')}
                />
            </div>
            <div className="mb-3">
                <InputField
                    name="placeName"
                    label="Ort"
                    type="text"
                    required={true}
                    value={userRegistration.placeName}
                    onChange={handleChange}
                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'placeName')}
                />
            </div>
            <div className="mb-3">
                <SelectField
                    name="country"
                    label="Land"
                    required={true}
                    options={props.countrySelectOptions}
                    value={fetchCountrySelectedOption()}
                    placeholder="Bitte wählen"
                    onChange={handleCountrySelectChange}
                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'country')}
                />
            </div>
            <div className="mb-3">
                <InputField
                    name="phoneNumber"
                    label="Telefonnummer"
                    type="text"
                    required={false}
                    value={userRegistration.phoneNumber ?? undefined}
                    onChange={handleChange}
                />
            </div>
            <div className="mb-3">
                <InputField
                    name="email"
                    label="E-Mail-Adresse"
                    type="text"
                    required={true}
                    value={userRegistration.email}
                    onChange={handleChange}
                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'email')}
                />
            </div>
            <div className="mb-3">
                <OriginChannelSelectField
                    label="Wie bist du auf uns aufmerksam geworden:"
                    name="originChannel"
                    isClearable={false}
                    isRequired={true}
                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'originChannel')}
                    onChange={handleOriginChannelSelectChange}
                />
            </div>
            <div className="mb-3">
                <UserIsSelectField
                    label="Du bist:"
                    name="userIs"
                    isClearable={false}
                    isRequired={true}
                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'userIs')}
                    onChange={handleUserIsSelectChange}
                />
            </div>
            <div className="mb-3 d-flex flex-row form-check">
                <input
                    type="checkbox"
                    className={`form-check-input pe-2 me-2${FormValidationHandler.hasFieldError(props.formData, 'gtcAccepted') === true ? ' is-invalid' : ''}`}
                    name="gtcAccepted"
                    required={true}
                    id="gtcAccepted"
                    checked={userRegistration.gtcAccepted}
                    value="true"
                    onChange={handleChange}
                />
                <label htmlFor="gtcAccepted" className="form-check-label">Ich habe die <Link to="/agb" target="_blank">Nutzungsbedingungen</Link> gelesen und bin damit einverstanden</label>
            </div>
        </>
    );
};

export default UserRegistrationForm;
