import FormError from '../../../Entity/Form/FormError';
import FieldProps from './FieldProps';
import datePickerFieldStyle from './DatePickerField.module.scss';
import fieldStyle from './Field.module.scss';
import FieldLabel from './FieldLabel';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import {de} from 'date-fns/locale/de';

interface DatePickerFieldProps<T> extends FieldProps {
    readonly value?: Date | null;
    readonly minDate?: Date;
    readonly maxDate?: Date;
    readonly isClearable?: boolean;
    readonly onChange?: (date: Date | null) => void;
}

const DatePickerField = (props: DatePickerFieldProps<HTMLInputElement>): React.JSX.Element => {
    const hasErrors: boolean = props.formErrors !== undefined && props.formErrors.length > 0;

    return (
        <>
            {props.label !== undefined &&
                <FieldLabel label={props.label} htmlFor={props.name} required={props.required} description={props.description} className="mb-2 ps-2" />
            }
            <div className="d-flex flex-column align-items-stretch">
                <DatePicker
                    selected={props.value}
                    name={props.name}
                    id={props.name}
                    disabled={props.disabled}
                    className={[fieldStyle.container, hasErrors === true ? 'is-invalid form-control' : ''].join(' ')}
                    locale={de}
                    dateFormat="dd.MM.YYYY"
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    isClearable={props.isClearable}
                    clearButtonClassName={datePickerFieldStyle.clearButton}
                    onSelect={props.onChange}
                    onChange={props.onChange}
                    onKeyDown={(event) => event.preventDefault()}
                />
            </div>
            {hasErrors === true &&
                <>
                    {props.formErrors!.map((formError: FormError, index: number): React.JSX.Element => (
                        <div key={'input_error_' + props.name + '_' + index} className="invalid-feedback d-block">
                            {formError.message}
                        </div>
                    ))}
                </>
            }
        </>
    );
};

export default DatePickerField;
