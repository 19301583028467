import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import SettlementConceptActivationContingent from '../Entity/SettlementConcept/SettlementConceptActivationContingent';
import SettlementConceptActivationContingentResponse from '../../../api/Llasm/SettlementConcept/SettlementConceptActivationContingentResponse';
import {AxiosResponse} from 'axios';

class SettlementConceptActivationContingentService {

    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    private readonly resourcePath: string;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
        this.resourcePath = '/settlement-concept-activation-contingents';
    }

    public async fetchSettlementConceptActivationContingentsFromApi(): Promise<SettlementConceptActivationContingent[]> {
        const apiResponse: AxiosResponse<SettlementConceptActivationContingentResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath);

        return apiResponse.data.map((settlementConceptActivationContingentResponse: SettlementConceptActivationContingentResponse): SettlementConceptActivationContingent => {
            return SettlementConceptActivationContingent.createFromSettlementConceptActivationContingentResponse(settlementConceptActivationContingentResponse);
        });
    }
}

export default SettlementConceptActivationContingentService;
