import DocumentResponse from '../../../../api/Llasm/Document/DocumentResponse';

class Document {
    private _id?: number;

    private _title: string;

    private _mimeType: string;

    private _fileContentAsBase64: string | null = null;

    public static createFromDocumentResponse(documentResponse: DocumentResponse): Document {
        const document: Document = new this();

        document._id = documentResponse.id;
        document._title = documentResponse.title;
        document._mimeType = documentResponse.mimeType;
        document._fileContentAsBase64 = documentResponse.fileContentAsBase64;

        return document;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get mimeType(): string {
        return this._mimeType;
    }

    set mimeType(value: string) {
        this._mimeType = value;
    }

    get fileContentAsBase64(): string | null {
        return this._fileContentAsBase64;
    }

    set fileContentAsBase64(value: string | null) {
        this._fileContentAsBase64 = value;
    }

}

export default Document;
