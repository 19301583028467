import iconBadgeStyle from './IconBadge.module.scss';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';
import BootstrapIcon from '../Icon/BootstrapIcon';
import MaterialIcon from '../Icon/MaterialIcon';
import Tooltip from '../Tooltip/Tooltip';
import React from 'react';

interface IconBadgeProps extends React.PropsWithChildren {
    readonly iconPublisher: 'font-awesome' | 'bootstrap' | 'material';
    readonly iconName: string;
    readonly fontAwesomeIconType?: 'regular' | 'solid';
    readonly title?: string;
    readonly description?: string;
    readonly backgroundColor?: 'primary' | 'secondary' | 'light-grey';
}

const IconBadge = (props: IconBadgeProps): React.JSX.Element => {
    const buildIcon = (): React.JSX.Element => {
        switch (props.iconPublisher) {
            case 'font-awesome':
                if (props.fontAwesomeIconType === undefined) {
                    return <FontAwesomeIcon iconName={props.iconName} iconType="solid" />;
                } else {
                    return <FontAwesomeIcon iconName={props.iconName} iconType={props.fontAwesomeIconType} />;
                }
            case 'bootstrap':
                return <BootstrapIcon iconName={props.iconName} />;
            case 'material':
                return <MaterialIcon iconName={props.iconName} />;
        }
    };

    const buildBadgeContent = (): React.JSX.Element => {
        return (
            <>
                {buildIcon()}
                {props.title !== undefined &&
                    <span className="ms-2">{props.title}</span>
                }
            </>
        );
    };

    const buildBackgroundColor = (): string => {
        switch (props.backgroundColor) {
            case 'primary':
                return iconBadgeStyle.backgroundColorPrimary;
            case 'secondary':
                return iconBadgeStyle.backgroundColorSecondary;
            case 'light-grey':
                return iconBadgeStyle.backgroundColorLightGrey;
            default:
                return iconBadgeStyle.backgroundColorPrimary;
        }
    };

    return (
        <>
            {props.description !== undefined &&
                <Tooltip title={props.description} className={[iconBadgeStyle.iconBadge, buildBackgroundColor(), 'd-flex justify-content-center align-items-center'].join(' ')}>
                    {buildBadgeContent()}
                </Tooltip>
            }
            {props.description === undefined &&
                <div className={[iconBadgeStyle.iconBadge, buildBackgroundColor(), 'd-flex justify-content-center align-items-center'].join(' ')}>
                    {buildBadgeContent()}
                </div>
            }
        </>
    );
};

export default IconBadge;
