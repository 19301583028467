import Image from '../Entity/Image/Image';
import ImageComponent from './Image';
import CollapseCard from '../Component/CollapseCard/CollapseCard';
import React from 'react';

interface ImageCollapseCardProps {
    readonly images: Image[];
    readonly buildImageGetPath: (image: Image) => string;
}

const ImageCollapseCard = (props: ImageCollapseCardProps): React.JSX.Element => {
    return (
        <CollapseCard cardType="shadow" title="Bilder" className="mb-3">
            <div className="row">
                {props.images.map((image: Image): React.JSX.Element => (
                    <div className="col-12 col-md-6 mb-3">
                        <ImageComponent buildImageGetPath={props.buildImageGetPath} image={image} className="img-fluid" />
                    </div>
                ))}
            </div>
        </CollapseCard>
    );
};

export default ImageCollapseCard;
