import MessageWrapper from '../Entity/Messaging/MessageWrapper';
import Conversation from '../Entity/Messaging/Conversation';
import MessageType from '../Entity/Messaging/MessageType';
import StandardMessage from './Message/StandardMessage';
import NotificationMessage from './Message/NotificationMessage';
import DeletedMessage from './Message/DeletedMessage';
import React from 'react';

interface MessageProps {
    readonly conversation: Conversation;
    readonly messageWrapper: MessageWrapper;
    readonly updateMessageList?: () => void;
    readonly deleteMessage?: (messageWrapper: MessageWrapper) => void;
    readonly updateConversationList?: () => void;
}

const Message = (props: MessageProps): React.JSX.Element => {
    const determineNotificationMessageDividerColor = (): 'primary' | 'grey' | 'lightgrey' => {
        if (props.messageWrapper.message.isSender === true) {
            return 'primary';
        }

        return 'grey';
    };

    return (
        <>
            {props.messageWrapper.message.messageType === MessageType.Standard &&
                <StandardMessage
                    conversation={props.conversation}
                    messageWrapper={props.messageWrapper}
                    updateMessageList={props.updateMessageList}
                    deleteMessage={props.deleteMessage}
                    updateConversationList={props.updateConversationList}
                />
            }
            {props.messageWrapper.message.messageType === MessageType.Notification &&
                <NotificationMessage messageWrapper={props.messageWrapper} dividerColor={determineNotificationMessageDividerColor()} />
            }
            {props.messageWrapper.message.messageType === MessageType.Deleted &&
                <DeletedMessage messageWrapper={props.messageWrapper} />
            }
        </>
    );
};

export default Message;
