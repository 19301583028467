import BrandCreate from './BrandCreate';
import InfoBox from '../Component/InfoBox/InfoBox';
import {useAppDispatch} from '../../../app/hooks';
import React, {useEffect} from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';

const BrandCreatePage = (): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Meine Marken', link: '/marken'},
                {name: 'Marke erfassen'},
            ]
        });
    }, [dispatch]);

    const onBrandCreated = (): void => {
        if (localStorage.getItem('initialEntry') === 'property') {
            navigate('/flaechen/ersterfassung');
        } else if (localStorage.getItem('initialEntry') === 'settlementConcept') {
            navigate('/suchprofile/ersterfassung');
        } else {
            navigate('/marken');
        }
    };

    return (
        <div className="container-fluid">
            <div className="mb-4">
                <div className="d-flex align-items-center w-100">
                    <Link to="/marken"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                    <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Marke/Geschäft anlegen</h1>
                </div>
            </div>
            <InfoBox cardType="outline" className="p-3 mx-0 mx-md-3 mb-3">
                <div className="fs-5 mb-0">
                    Hier kannst Du Deine Marke erfassen, die Du später mit Deinem Suchprofil verknüpfen kannst. Deine
                    Marke gibt z. B. Auskunft über die angesprochene Zielgruppe, das Preissegement oder den
                    Filialisierungsgrad Deines Unternehmens.
                </div>
            </InfoBox>
            <div className="py-3 px-0 px-md-3 mb-3">
                <BrandCreate onBrandCreated={onBrandCreated} />
            </div>
        </div>
    );
};

export default BrandCreatePage;
