import ImageService from './ImageService';
import ImageEntity from '../Entity/Image/Image';
import BootstrapIcon from '../Component/Icon/BootstrapIcon';
import LoadingIndicator from '../../../components/LoadingIndicator';
import React, {useEffect, useState} from 'react';

interface ImageProps {
    readonly image: ImageEntity;
    readonly buildImageGetPath: (image: ImageEntity) => string;
    readonly className?: string;
    readonly style?: React.CSSProperties;
    readonly alt?: string;
    readonly setIsLoading?: (isLoading: boolean) => void;
    readonly buildImageDeletePath?: (image: ImageEntity) => string;
    readonly onDeleted?: () => Promise<void>;
}

const imageService: ImageService = new ImageService(process.env.REACT_APP_LLASM_API_URL!);

const Image = (props: ImageProps): React.JSX.Element => {
    const [image, setImage] = useState<ImageEntity | undefined>(undefined);

    useEffect((): void => {
        fetchImage();
    }, [props.image]);

    const fetchImage = async (): Promise<void> => {
        try {
            setImage(await imageService.fetchImage(props.buildImageGetPath(props.image)));
        } catch (error) {
            setImage(undefined);
        }
    };

    const deleteImage = async (): Promise<void> => {
        if (props.buildImageDeletePath === undefined) {
            return;
        }

        if (props.image.id === undefined) {
            return;
        }

        if (props.setIsLoading !== undefined){
            props.setIsLoading(true);
        }

        const deletePath: string = props.buildImageDeletePath(props.image);

        await imageService.deleteImage(deletePath);

        if (props.onDeleted !== undefined) {
            await props.onDeleted();
        }

        if (props.setIsLoading !== undefined){
            props.setIsLoading(false);
        }
    };

    return (
        <>
            {image === undefined ? (
                <LoadingIndicator />
            ) : (
                <div className="position-relative w-100">
                    <img
                        src={'data:' + image.mimeType + ';base64,' + image.fileContentAsBase64}
                        alt={props.alt ?? (image.title ?? undefined)}
                        className={props.className}
                        style={props.style}
                    />
                    {props.buildImageDeletePath !== undefined &&
                        <button
                            type="button"
                            className="btn btn-lg btn-danger pt-1 pb-1 ps-2 pe-2 mt-3 me-3 position-absolute top-0 end-0"
                            onClick={deleteImage}
                        >
                            <BootstrapIcon iconName="trash-fill" />
                        </button>
                    }
                </div>
            )}
        </>
    );
};

export default Image;
