import ParticipatingUser from '../../Entity/Messaging/ParticipatingUser';
import BootstrapIcon from '../../Component/Icon/BootstrapIcon';
import contactIntentionNotificationMessageTextStyle from './ContactIntentionNotificationMessageText.module.scss';
import React from 'react';

interface ContactIntentionNotificationMessageTextProps {
    readonly participatingUser: ParticipatingUser;
    readonly isSender: boolean;
}

const ContactIntentionNotificationMessageText = (props: ContactIntentionNotificationMessageTextProps): React.JSX.Element => {
    return (
        <>
            <BootstrapIcon iconName="emoji-laughing-fill" className="me-1" color="primary" />
            {props.isSender === false &&
                <span className={contactIntentionNotificationMessageTextStyle.textGrey}>{props.participatingUser.name} hat auf dein Konzept mit <span className="text-primary">"interessiert"</span> reagiert.</span>
            }
            {props.isSender === true &&
                <span className="text-primary">Du hast auf das Konzept von {props.participatingUser.name} mit "interessiert" reagiert.</span>
            }
        </>
    );
};

export default ContactIntentionNotificationMessageText;
