import MessageRead from './MessageRead';
import LeanMessageReadResponse from '../../../../api/Llasm/Messaging/LeanMessageReadResponse';

class LeanMessageRead extends MessageRead {
    public static createFromLeanMessageReadResponse(leanMessageReadResponse: LeanMessageReadResponse): LeanMessageRead {
        const leanMessageRead: LeanMessageRead = new this();

        leanMessageRead._id = leanMessageReadResponse.id;
        leanMessageRead._createdAt = new Date(leanMessageReadResponse.createdAt);

        return leanMessageRead;
    }
}

export default LeanMessageRead;
