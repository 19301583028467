import PaginationEntity from '../Entity/Pagination/Pagination';
import PaginationParameter from '../Entity/Pagination/PaginationParameter';
import SelectOption from '../Entity/Form/SelectOption';
import SelectField from '../Component/Form/Field/SelectField';
import MaterialIcon from '../Component/Icon/MaterialIcon';
import React from 'react';
import {SingleValue} from 'react-select';

interface PaginationProps {
    readonly pagination: PaginationEntity;
    readonly limitOptions: SelectOption<number>[];
    readonly onChange: (paginationParameter: PaginationParameter) => void;
}

const Pagination = (props: PaginationProps): React.JSX.Element => {
    const fetchSelectedLimitOption = (): SelectOption<number> => {
        const selectedLimitOption = props.limitOptions.find((selectOption: SelectOption<number>) => selectOption.value === props.pagination.limit);

        if (selectedLimitOption === undefined) {
            throw new Error('Invalid limit');
        }

        return selectedLimitOption;
    };

    const onLimitChange = (limit: number): void => {
        props.onChange(new PaginationParameter(1, limit));
    };

    const onPageChange = (page: number): void => {
        props.onChange(new PaginationParameter(page, props.pagination.limit));
    };

    return (
        <div className="d-flex justify-content-end align-items-center flex-wrap">
            <div className="mb-3 d-flex justify-content-end align-items-center">
                <label className="me-3">Objekte pro Seite:</label>
                <SelectField
                    options={props.limitOptions}
                    placeholder="Bitte wählen"
                    value={fetchSelectedLimitOption()}
                    onChange={(newValue: SingleValue<SelectOption<number>>): void => onLimitChange(newValue!.value)}
                    name="limit"
                />
            </div>
            <div className="mb-3 d-flex justify-content-end align-items-center">
                <span className="ms-3 me-3">Aktuelle Seite: {props.pagination.page}</span>
                {props.pagination.page === 1 &&
                    <MaterialIcon iconName="first_page" className="text-black-50 fs-3" />
                }
                {props.pagination.page > 1 &&
                    <div className="d-flex align-items-center cursor-pointer" onClick={(): void => onPageChange(1)}>
                        <MaterialIcon iconName="first_page" className="fs-3" />
                    </div>
                }
                {props.pagination.page === 1 &&
                    <MaterialIcon iconName="chevron_left" className="text-black-50 fs-3" />
                }
                {props.pagination.page > 1 &&
                    <div className="d-flex align-items-center cursor-pointer" onClick={(): void => onPageChange(props.pagination.page - 1)}>
                        <MaterialIcon iconName="chevron_left" className="fs-3" />
                    </div>
                }
                {props.pagination.page === props.pagination.pages &&
                    <MaterialIcon iconName="chevron_right" className="text-black-50 fs-3" />
                }
                {props.pagination.page < props.pagination.pages &&
                    <div className="d-flex align-items-center cursor-pointer" onClick={(): void => onPageChange(props.pagination.page + 1)}>
                        <MaterialIcon iconName="chevron_right" className="fs-3" />
                    </div>
                }
                {props.pagination.page === props.pagination.pages &&
                    <MaterialIcon iconName="last_page" className="text-black-50 fs-3" />
                }
                {props.pagination.page < props.pagination.pages &&
                    <div className="d-flex align-items-center cursor-pointer" onClick={(): void => onPageChange(props.pagination.pages)}>
                        <MaterialIcon iconName="last_page" className="fs-3" />
                    </div>
                }
            </div>
        </div>
    );
};

export default Pagination;
