import PurchaseMethod from '../Payment/PurchaseMethod';
import PurchaseResponse from '../../../../api/Llasm/MatchPoint/PurchaseResponse';

class Purchase {
    private _amount: number;

    private _priceNet: number;

    private _purchaseMethod: PurchaseMethod;

    private _paymentProviderClientSecret: string | null;

    public static createFromPurchaseResponse(purchaseResponse: PurchaseResponse): Purchase {
        const purchase: Purchase = new this();

        purchase._amount = purchaseResponse.amount;
        purchase._priceNet = purchaseResponse.priceNet / 100;
        purchase._purchaseMethod = purchaseResponse.purchaseMethod;
        purchase._paymentProviderClientSecret = purchaseResponse.paymentProviderClientSecret;

        return purchase;
    }

    get amount(): number {
        return this._amount;
    }

    set amount(value: number) {
        this._amount = value;
    }

    get priceNet(): number {
        return this._priceNet;
    }

    set priceNet(value: number) {
        this._priceNet = value;
    }

    get purchaseMethod(): PurchaseMethod {
        return this._purchaseMethod;
    }

    set purchaseMethod(value: PurchaseMethod) {
        this._purchaseMethod = value;
    }

    get paymentProviderClientSecret(): string | null {
        return this._paymentProviderClientSecret;
    }

    set paymentProviderClientSecret(value: string | null) {
        this._paymentProviderClientSecret = value;
    }
}

export default Purchase;
