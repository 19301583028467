import FormError from './Entity/Form/FormError';
import PriceSegment, {getPriceSegmentLabel} from './Entity/PriceSegment';
import SelectOption from './Entity/Form/SelectOption';
import FieldLabel from './Component/Form/Field/FieldLabel';
import React from 'react';
import Select, {OnChangeValue, PropsValue} from 'react-select';

interface PriceSegmentSelectFieldProps {
    readonly label: string;
    readonly title: string;
    readonly name: string;
    readonly isMulti: boolean;
    readonly isClearable: boolean;
    readonly isRequired: boolean;
    readonly classNames?: string;
    readonly formErrors?: FormError[];
    readonly defaultValue?: PriceSegment | PriceSegment[];
    readonly onChange?: Function;
}

const PriceSegmentSelectField = (props: PriceSegmentSelectFieldProps): React.JSX.Element => {
    const hasErrors: boolean = props.formErrors !== undefined && props.formErrors.length > 0;

    const selectOptions: SelectOption<PriceSegment>[] = [
        {label: getPriceSegmentLabel(PriceSegment.Low), value: PriceSegment.Low},
        {label: getPriceSegmentLabel(PriceSegment.LowMid), value: PriceSegment.LowMid},
        {label: getPriceSegmentLabel(PriceSegment.Mid), value: PriceSegment.Mid},
        {label: getPriceSegmentLabel(PriceSegment.MidHigh), value: PriceSegment.MidHigh},
        {label: getPriceSegmentLabel(PriceSegment.High), value: PriceSegment.High},
    ];

    const fetchDefaultValue = (): PropsValue<SelectOption<PriceSegment>> | undefined => {
        const selectedOptions: SelectOption<PriceSegment>[] = selectOptions.filter((selectOption: SelectOption<PriceSegment>): boolean => {
            if (Array.isArray(props.defaultValue) === true) {
                return (props.defaultValue as PriceSegment[]).includes(selectOption.value);
            } else {
                return selectOption.value === props.defaultValue;
            }
        });

        if (props.isMulti === true) {
            return selectedOptions;
        } else {
            if (selectedOptions.length > 0) {
                return selectedOptions[0];
            }
        }

        return undefined;
    }

    return (
        <div>
            <FieldLabel label={props.label} htmlFor={props.name} required={props.isRequired} description={props.title} className="mb-2 ps-2" />
            <div>
                {/* TODO - umbau auf SelectField / MultiSelectField */}
                <Select
                    name={props.name}
                    options={selectOptions}
                    isClearable={props.isClearable}
                    isMulti={props.isMulti}
                    closeMenuOnSelect={false}
                    className={(props.classNames !== undefined ? props.classNames : '') + (hasErrors === true ? ' is-invalid' : '')}
                    placeholder="Bitte wählen"
                    defaultValue={fetchDefaultValue()}
                    onChange={(newValue: OnChangeValue<SelectOption<PriceSegment>, boolean>): void => {
                        if (props.onChange !== undefined) {
                            if (Array.isArray(newValue) === true) {
                                props.onChange((newValue as Array<SelectOption<PriceSegment>>).map((selectOption: SelectOption<PriceSegment>): PriceSegment => selectOption.value));
                            } else {
                                props.onChange((newValue as SelectOption<PriceSegment>).value);
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default PriceSegmentSelectField;
