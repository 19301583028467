enum Feature {
    Showroom = 0,
    TruckDeliveryOption = 1,
    HighVoltageConnection = 2,
    ExhaustAirSystem = 3,
    AirConditioning = 4,
    FireProtectionConcept = 5,
    DoorCurtainSystem = 6,
    AutomaticDoorSystem = 7,
    Alarm = 8,
    ChangingRoom = 9,
    ColdStorage = 10,
    VentilationSystem = 11,
    GreaseSeparator = 12,
    WineCellar = 13,
    GasConnection = 14,
    BeerConnection = 15
}

const getFeatureLabel = (feature: Feature): string => {
    switch (feature) {
        case Feature.Showroom:
            return 'Showroom vorhanden';
        case Feature.TruckDeliveryOption:
            return 'LKW-Anlieferungsmöglichkeit';
        case Feature.HighVoltageConnection:
            return 'Starkstromanschluss vorhanden';
        case Feature.ExhaustAirSystem:
            return 'Abluftanlage vorhanden';
        case Feature.AirConditioning:
            return 'Klimaanlage vorhanden';
        case Feature.FireProtectionConcept:
            return 'Brandschutzkonzept vorhanden';
        case Feature.DoorCurtainSystem:
            return 'Türschleieranlage vorhanden';
        case Feature.AutomaticDoorSystem:
            return 'autom. Türanlage vorhanden';
        case Feature.Alarm:
            return 'Alarmanlage vorhanden';
        case Feature.ChangingRoom:
            return 'Umkleidekabine vorhanden';
        case Feature.ColdStorage:
            return 'Kühlhaus vorhanden';
        case Feature.VentilationSystem:
            return 'Lüftungsanlage vorhanden';
        case Feature.GreaseSeparator:
            return 'Fettabscheider vorhanden';
        case Feature.WineCellar:
            return 'Weinkeller vorhanden';
        case Feature.GasConnection:
            return 'Gasanschluss vorhanden';
        case Feature.BeerConnection:
            return 'Bieranschlussleitung vorhanden';
        default:
            throw new Error();
    }
};

export default Feature;
export {getFeatureLabel};
