import Document from '../Entity/Document/Document';
import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import DocumentPatchRequest from '../../../api/Llasm/Document/DocumentPatchRequest';
import DocumentResponse from '../../../api/Llasm/Document/DocumentResponse';
import {AxiosResponse} from 'axios';

class DocumentService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
    }

    public async fetchDocument(getPath: string): Promise<Document> {
        const apiResponse: AxiosResponse<DocumentResponse> = await this.llasmApiV1Provider.get(getPath);

        return Document.createFromDocumentResponse(apiResponse.data);
    }

    public async updateDocument(patchPath: string, document: Document): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.patch<null, AxiosResponse<null>, DocumentPatchRequest>(
            patchPath,
            DocumentService.mapFromDocumentToDocumentPatchRequest(document)
        );
    }

    public async deleteDocument(deletePath: string): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.delete(deletePath);
    }

    public async downloadDocument(getPath: string): Promise<void> {
        const documentEntity: Document = await this.fetchDocument(getPath);

        const pdfLink: string = 'data:' + documentEntity.mimeType + ';base64,' + documentEntity.fileContentAsBase64;
        const anchorElement: HTMLAnchorElement = document.createElement('a');

        anchorElement.href = pdfLink;
        anchorElement.download = documentEntity.title.endsWith('.pdf') ? documentEntity.title : documentEntity.title + '.pdf';
        anchorElement.click();

        anchorElement.remove();
    }

    private static mapFromDocumentToDocumentPatchRequest(document: Document): DocumentPatchRequest {
        return DocumentService.mapDocumentToApiRequest(document);
    }

    private static mapDocumentToApiRequest(document: Document): DocumentPatchRequest {
        return {
            title: document.title
        };
    }
}

export default DocumentService;
