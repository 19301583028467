import Property from '../../Entity/Property/Property';
import PropertyService from '../../Property/PropertyService';
import SettlementConcept from '../../Entity/SettlementConcept/SettlementConcept';
import ImageEntity from '../../Entity/Image/Image';
import MarketingStatusBadge from '../../Property/MarketingStatusBadge';
import ConversationInitializationButton from '../../Messaging/ConversationInitializationButton';
import Card from '../../Component/Card/Card';
import CardMedia from '../../Component/Card/CardMedia';
import CardContent from '../../Component/Card/CardContent';
import IconBadgeWrapper from '../../Component/IconBadge/IconBadgeWrapper';
import Image from '../../Image/Image';
import matchingItemStyle from './MatchingItem.module.scss';
import noImage from '../../../../img/no-brand-logo.jpg';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

interface PropertyMatchingItemProps {
    readonly selectedItem: SettlementConcept;
    readonly matchingItem: Property;
    readonly createLinkToConversation: () => string;
    readonly showDetailInformation: (detailInformationItem: Property) => void;
}

const PropertyMatchingItem = (props: PropertyMatchingItemProps): React.JSX.Element => {
    const [cardMediaContainerHeight, setCardMediaContainerHeight] = useState<number>();

    const buildImageGetPath = (image: ImageEntity): string => {
        return PropertyService.buildImageApiPath(props.matchingItem, image);
    };

    return (
        <div className={matchingItemStyle.container}>
            <Card cardType="shadow">
                {cardMediaContainerHeight !== undefined &&
                    <IconBadgeWrapper positionTop={cardMediaContainerHeight - 80} positionRight={-10}>
                        <MarketingStatusBadge marketingStatus={props.matchingItem.marketingStatus} />
                    </IconBadgeWrapper>
                }
                <CardMedia className="d-flex align-items-center" setContainerHeight={setCardMediaContainerHeight}>
                    {props.matchingItem.images.length === 0 &&
                        <img src={noImage} alt="Kein Bild" className="w-100"/>
                    }

                    {props.matchingItem.images.length > 0 &&
                        <Image image={props.matchingItem.images[0]} buildImageGetPath={buildImageGetPath} className="w-100" />
                    }
                </CardMedia>
                <CardContent>
                    <div className="d-flex justify-content-between align-items-start mb-3 position-relative">
                        <p className="fw-bolder text-overflow-ellipsis">
                            #{props.matchingItem.id} {props.matchingItem.title}
                        </p>
                        <p className={['d-flex', 'align-items-center', matchingItemStyle.locationInformation].join(' ')}>
                            {props.matchingItem.locationDetail.postalCode} {props.matchingItem.locationDetail.city!.placeName}
                        </p>
                    </div>
                    <div className={['d-flex', 'justify-content-between', 'align-items-start', 'mb-3'].join(' ')}>
                        {props.matchingItem.providerSeekerConversation === null &&
                            <ConversationInitializationButton
                                buttonText="Nimm Kontakt auf!"
                                settlementConcept={props.selectedItem}
                                property={props.matchingItem}
                            />
                        }

                        {props.matchingItem.providerSeekerConversation !== null &&
                            <Link to={props.createLinkToConversation()} className="mt-2">
                                <i className="bi bi-chat-fill me-2 text-decoration-none"></i>
                                Konversation öffnen
                            </Link>
                        }

                        <button onClick={(): void => props.showDetailInformation(props.matchingItem)} className="btn btn-outline-secondary">
                            <i className="bi bi-eye-fill me-2"></i>
                            Details
                        </button>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default PropertyMatchingItem;
