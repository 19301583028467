import User from '../../shared/Entity/User/User';
import BillingAddress from '../../shared/Entity/User/BillingAddress';
import Currency from '../../shared/Entity/Currency';
import OrderConfirmationWrapper from './OrderConfirmationWrapper';
import React from 'react';
import {Link} from 'react-router-dom';

interface OnlinePaymentOrderConfirmationProps {
    readonly user: User;
    readonly numberFormat: Intl.NumberFormat;
    readonly currency: Currency;
    readonly taxRate: number;
    readonly amount: number
    readonly priceNet: number;
    readonly priceGross: number;
    readonly billingAddress: BillingAddress;
    readonly resetOrderProcess: () => void;
}

const OnlinePaymentOrderConfirmation = (props: OnlinePaymentOrderConfirmationProps): React.JSX.Element => {
    return (
        <div className="container-fluid pb-4">
            <div className="d-flex align-items-center mb-3">
                <Link to="" onClick={(): void => props.resetOrderProcess()}>
                    <i className="bi bi-arrow-left-short text-secondary fs-lg"></i>
                </Link>
                <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Kauf abschließen</h1>
            </div>
            <OrderConfirmationWrapper
                user={props.user}
                numberFormat={props.numberFormat}
                currency={props.currency}
                taxRate={props.taxRate}
                amount={props.amount}
                priceNet={props.priceNet}
                priceGross={props.priceGross}
                billingAddress={props.billingAddress}
            >
                <>
                    <div className="fs-3 mb-4">
                        Vielen Dank für Deine Buchung!
                    </div>
                    <div className="mb-3">
                        Schön, dass Du MatchPoints bei den Leerstandslotsen erworben hast.
                        Diese werden nach erfolgreicher Zahlungsabwicklung in Kürze Deinem Konto gutgeschrieben,
                        danach kannst du sie entsprechend im Matching oder für die Hall of Inspiration einsetzen.
                    </div>
                    <div>
                        Zu Deiner Buchung werden wir Dir in Kürze eine Bestellübersicht per E-Mail senden.
                    </div>
                </>
            </OrderConfirmationWrapper>
        </div>
    );
};

export default OnlinePaymentOrderConfirmation;
