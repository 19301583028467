import InfoBox from '../../shared/Component/InfoBox/InfoBox';
import reaAd from './rea24-banner.png';
import React from 'react';
import {Link} from 'react-router-dom';

const reaDate: Date = new Date('2024-06-03');

const ReaBanner2024 = (): React.JSX.Element => {
    if (reaDate <= new Date()) {
        return (
            <></>
        );
    }

    return (
        <InfoBox cardType="shadow" className="p-3 mb-5 text-center bg-white text-secondary">
            <h3 className="mb-3">Besuche uns auf der <strong>Real Estate Arena 2024</strong></h3>
            <Link to="https://www.real-estate-arena.com/" target="_blank" className="d-none d-lg-block">
                <img src={reaAd} alt="Real Estate Arena 2024" className="img-fluid mb-3" />
            </Link>
            <div className="mb-2 fs-5">
                Besuche die Leerstandslotsen am 05.-06. Juni 2024 auf der „Real Estate Arena“ in Hannover.
                Du findest uns am Stand G35 in Halle 4.
            </div>
            <div className="mb-2 fs-5">
                <strong>Noch kein Ticket? </strong>
                Melde Dich per E-Mail unter <a href="mailto:hallo@llasm.de">hallo@llasm.de</a>.
                Aber: Schnell sein lohnt sich, wir haben nur noch wenige <strong>Freitickets</strong> übrig!
            </div>
        </InfoBox>
    );
};

export default ReaBanner2024;
