import IconProps from './IconProps';
import React from 'react';

interface FontAwesomeIconProps extends IconProps {
    readonly iconType: 'regular' | 'solid';
}

const FontAwesomeIcon = (props: FontAwesomeIconProps): React.JSX.Element => {
    const buildClassName = (): string => {
        let className: string = 'fa-' + props.iconType + ' fa-' + props.iconName;

        if (props.className !== undefined) {
            className += ' ' + props.className;
        }

        return className;
    };

    return (
        <i className={buildClassName()}></i>
    );
};

export default FontAwesomeIcon;
