import Card from '../../shared//Component/Card/Card';
import Tooltip from '../../shared//Component/Tooltip/Tooltip';
import matchPointCardStyle from './MatchPointCard.module.scss';
import React from 'react';

interface MatchPointCardProps extends React.PropsWithChildren {
    readonly header: string;
    readonly description?: string;
}

const MatchPointCard = (props: MatchPointCardProps): React.JSX.Element => {
    return (
        <Card cardType="shadow" className="p-3 h-100">
            <div className="d-flex justify-content-center fs-4">
                <div className="me-auto"></div>
                <div className="text-center">{props.header}</div>
                <div className="ms-auto">
                    {props.description !== undefined &&
                        <Tooltip title={props.description} icon={<i className="bi bi-info-circle"></i>}/>
                    }
                </div>
            </div>
            <div className={['m-auto', 'mt-3', 'mb-3', matchPointCardStyle.divider].join(' ')}/>
            <div>
                {props.children}
            </div>
        </Card>
    );
};

export default MatchPointCard;
