import ProviderSeekerConversation from '../../Entity/Messaging/ProviderSeekerConversation';
import PropertyInterestFeedback from '../../Entity/PropertyInterest/PropertyInterestFeedback';
import BootstrapIcon from '../../Component/Icon/BootstrapIcon';
import conversationListItemStyle from './ConversationListItem.module.scss';
import React from 'react';

interface ProviderSeekerConversationListItemProps {
    readonly providerSeekerConversation: ProviderSeekerConversation;
}

const ProviderSeekerConversationListItem = (props: ProviderSeekerConversationListItemProps): React.JSX.Element => {
    let lastPropertyInterestFeedback: PropertyInterestFeedback | null = null;

    if (props.providerSeekerConversation.propertyInterests.length > 0) {
        lastPropertyInterestFeedback = props.providerSeekerConversation.propertyInterests.slice(-1)[0].propertyInterestFeedback;
    }

    const showPropertyInterestFeedbackIcon = (): React.JSX.Element => {
        switch (lastPropertyInterestFeedback) {
            case PropertyInterestFeedback.Interested:
                return <BootstrapIcon iconName="emoji-laughing-fill" color="primary" />
            case PropertyInterestFeedback.NotInterested:
                return <BootstrapIcon iconName="emoji-frown-fill" color="grey" />
            case null:
                return <BootstrapIcon iconName="emoji-neutral-fill" color="lightgrey" />
        }
    };

    return (
        <>
            <div className="d-flex mb-0">
                <span className={['me-4', conversationListItemStyle.firstReference].join(' ')}>
                    {props.providerSeekerConversation.firstReference}
                </span>
                <span className={['ms-auto', conversationListItemStyle.date].join(' ')}>{props.providerSeekerConversation.lastMessageAt?.toLocaleDateString('de-DE', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}</span>
            </div>
            {props.providerSeekerConversation.property.participatingUser.id === null ? (
                <div className="d-flex justify-content-end mb-2">
                    {props.providerSeekerConversation.contactIntention !== null &&
                        <BootstrapIcon iconName="emoji-laughing-fill" color="primary" />
                    }
                </div>
            ) : (
                <div className="d-flex justify-content-end mb-2">
                    {showPropertyInterestFeedbackIcon()}
                </div>
            )}
            <div className={['row', 'justify-content-end', 'mb-2', conversationListItemStyle.secondReferenceIcon].join(' ')}>
                <div className={['col-6', conversationListItemStyle.secondReference].join(' ')}>
                    <div>
                        Matcht mit:
                    </div>
                    <div className="text-overflow-ellipsis">
                        {props.providerSeekerConversation.secondReference}
                    </div>
                </div>
            </div>
            <div className={['mb-1', conversationListItemStyle.sender].join(' ')}>
                Von: {props.providerSeekerConversation.firstReferenceFrom.name}
            </div>
            {props.providerSeekerConversation.unreadMessages === true &&
                <div className={conversationListItemStyle.unread}>
                    <i className="bi bi-chat-fill me-2"></i>ungelesene Nachricht(en) an mich
                </div>
            }
            {props.providerSeekerConversation.unreadMessages === false &&
                <div className={conversationListItemStyle.read}>
                    <i className="bi bi-check-circle me-2"></i>alle Nachrichten von mir gelesen
                </div>
            }
        </>
    );
};

export default ProviderSeekerConversationListItem;
