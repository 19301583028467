import MessageWrapper from '../../Entity/Messaging/MessageWrapper';
import Divider from '../../Component/Divider/Divider';
import React from 'react';

interface NotificationMessageProps {
    readonly messageWrapper: MessageWrapper;
    readonly dividerColor: 'primary' | 'grey' | 'lightgrey';
}

const NotificationMessage = (props: NotificationMessageProps): React.JSX.Element => {
    return (
      <Divider color={props.dividerColor}>
          {props.messageWrapper.message.text}
      </Divider>
    );
};

export default NotificationMessage;
