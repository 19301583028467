import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import ImageEntity from '../Entity/Image/Image';
import IndustryClassification from '../Entity/IndustryClassification/IndustryClassification';
import SettlementConceptService from './SettlementConceptService';
import Image from '../Image/Image';
import SettlementConceptDetailInformation from './SettlementConceptDetailInformation/SettlementConceptDetailInformation';
import Overlay from '../Component/Overlay/Overlay';
import Card from '../Component/Card/Card';
import CardMedia from '../Component/Card/CardMedia';
import CardContent from '../Component/Card/CardContent';
import Slider from '../Component/Slider/Slider';
import StatusIndicator from '../Component/StatusIndicator/StatusIndicator';
import noImage from '../../../img/no-settlement-concept-image.jpg';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

interface SettlementConceptCardProps {
    readonly settlementConcept: SettlementConcept;
}

const SettlementConceptCard = (props: SettlementConceptCardProps): React.JSX.Element => {
    const [showDetailInformationOverlay, setShowDetailInformationOverlay] = useState<boolean>(false);

    const [cardMediaContainerHeight, setCardMediaContainerHeight] = useState<number>();

    const buildThumbnailImageGetPath = (image: ImageEntity): string => {
        return SettlementConceptService.buildThumbnailImageApiPath(props.settlementConcept, image);
    };

    return (
        <Card cardType="shadow" className="position-relative">
            <StatusIndicator active={props.settlementConcept.active} right={-10} top={-10} />
            <CardMedia setContainerHeight={setCardMediaContainerHeight}>
                {(props.settlementConcept.images.length === 0) ? (
                    <div className="d-flex align-items-center" style={{height: cardMediaContainerHeight + 'px', overflow: 'hidden'}}>
                        <img src={noImage} alt="Kein Bild" className="w-100" />
                    </div>
                ) : (
                    <Slider
                        config={{
                            type: 'slider',
                            showArrows: props.settlementConcept.images.length > 0,
                            arrowsPosition: 'inside',
                            rewind: true,
                            perView: 1,
                            bound: true
                        }}
                    >
                        {props.settlementConcept.images.map((image: ImageEntity): React.JSX.Element => (
                            <div key={'image-' + image.id} className="d-flex align-items-center" style={{height: cardMediaContainerHeight + 'px', overflow: 'hidden'}}>
                                <Image image={image} buildImageGetPath={buildThumbnailImageGetPath} className="w-100" />
                            </div>
                        ))}
                    </Slider>
                )}
            </CardMedia>
            <CardContent className="px-2 px-md-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="fw-bolder">{props.settlementConcept.title}</div>
                    <div className="text-nowrap">
                        <button className="btn btn-outline-secondary btn-sm me-2" onClick={(): void => setShowDetailInformationOverlay(true)}>
                            <i className="bi bi-eye-fill"></i>
                        </button>
                        <Link to={'/suchprofile/' + props.settlementConcept.id + ''} className="btn btn-sm btn-secondary">
                            <i className="bi bi-pencil-square"></i>
                        </Link>
                    </div>
                </div>
                <div className="mb-4">
                    <div className="row mb-3">
                        <div className="col-4 col-xxl-5">Suchprofil-Nr:</div>
                        <div className="col-8 col-xxl-7">#{props.settlementConcept.reference}</div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4 col-xxl-5">Marke:</div>
                        <div className="col-8 col-xxl-7">
                            {props.settlementConcept.brand.brandName}
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4 col-xxl-5">Branche(n):</div>
                        <div className="col-8 col-xxl-7 overflow-hidden">
                            <ul className="ps-0 list-unstyled">
                            {props.settlementConcept.industryClassifications.map((industryClassification: IndustryClassification, index: number): React.JSX.Element => (
                                <li key={'industryClassification-' + index} className="fs-6 me-1" >
                                    <React.Fragment>{industryClassification.name}</React.Fragment>
                                </li>
                            ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </CardContent>
            <Overlay title={props.settlementConcept.title} show={showDetailInformationOverlay} setShow={setShowDetailInformationOverlay}>
                <div className="container">
                    <SettlementConceptDetailInformation settlementConcept={props.settlementConcept} />
                </div>
            </Overlay>
        </Card>
    );
};

export default SettlementConceptCard;
