import FieldLabel from './FieldLabel';
import React from 'react';

interface SwitchFieldProps {
    readonly label: string;
    readonly description?: string;
    readonly selected: boolean;
    readonly name: string;
    readonly disabled?: boolean;
    readonly onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const SwitchField = (props: SwitchFieldProps): React.JSX.Element => {
    return (
        <div className="form-check form-switch">
            <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                name={props.name}
                id={props.name}
                checked={props.selected}
                onChange={props.onChange}
                disabled={props.disabled}
            />
            <FieldLabel
                label={props.label}
                htmlFor={props.name}
                description={props.description}
                className="form-check-label ms-2 mt-1"
            />
        </div>
    );
};

export default SwitchField;
