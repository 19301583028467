import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import Contact from '../Entity/Contact/Contact';
import ContactResponse from '../../../api/Llasm/Contact/ContactResponse';
import ContactPatchRequest from '../../../api/Llasm/Contact/ContactPatchRequest';
import ContactPostRequest from '../../../api/Llasm/Contact/ContactPostRequest';
import { AxiosResponse } from 'axios';

class ContactService {

    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    private readonly resourcePath: string;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
        this.resourcePath = '/contacts';
    }

    public async fetchContactsFromApi(): Promise<Contact[]> {
        const apiResponse: AxiosResponse<ContactResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath);

        return apiResponse.data.map((contactResponse: ContactResponse): Contact => {
            return Contact.createFromContactResponse(contactResponse);
        });
    }

    public async fetchContactFromApiById(id: number): Promise<Contact> {
        const apiResponse: AxiosResponse<ContactResponse> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + id);

        return Contact.createFromContactResponse(apiResponse.data);
    }

    public async persistContact(contact: Contact): Promise<Contact> {
        const response: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, ContactPostRequest>(
            this.resourcePath,
            ContactService.mapFromContactToContactPostRequest(contact)
        );

        const apiResponse: AxiosResponse<ContactResponse> = await this.llasmApiV1Provider.getFromLocation(response.headers.location);

        return Contact.createFromContactResponse(apiResponse.data);
    }

    public async updateContact(contact: Contact): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.patch<null, AxiosResponse<null>, ContactPatchRequest>(
            this.resourcePath  + '/' + contact.id,
            ContactService.mapFromContactToContactPatchRequest(contact)
        );
    }

    private static mapFromContactToContactPostRequest(contact: Contact): ContactPostRequest {
        return ContactService.mapContactToApiRequest(contact) as ContactPostRequest;
    }

    private static mapFromContactToContactPatchRequest(contact: Contact): ContactPatchRequest {
        return ContactService.mapContactToApiRequest(contact) as ContactPatchRequest;
    }

    private static mapContactToApiRequest(contact: Contact): ContactPostRequest | ContactPatchRequest {
        return {
            email: contact.email,
            naturalPerson: contact.naturalPerson,
            name: contact.name,
            firstName: contact.firstName,
            companyName: contact.companyName,
            position: contact.position,
            streetName: contact.streetName,
            houseNumber: contact.houseNumber,
            postalCode: contact.postalCode,
            placeName: contact.placeName,
            countryName: contact.countryName,
            mobilePhoneNumber: contact.mobilePhoneNumber,
            phoneNumber: contact.phoneNumber,
            faxNumber: contact.faxNumber,
            website: contact.website
        };
    }

}

export default ContactService;
