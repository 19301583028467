import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import popupSpaceInformationStyle from './PopupSpaceInformation.module.scss';
import React from 'react';

interface PopupSpaceInformationProps {
    readonly settlementConcept: SettlementConcept;
    readonly fontSize: 'small' | 'big';
}

const PopupSpaceInformation = (props: PopupSpaceInformationProps): React.JSX.Element => {
    const buildFontSizeStyle = (): string => {
        return (props.fontSize === 'big') ? popupSpaceInformationStyle.fsBig : popupSpaceInformationStyle.fsSmall;
    };

    return (
        <>
            {props.settlementConcept.interestPopupSpace === true &&
                <div className={buildFontSizeStyle()}>
                    <i className="bi bi-clock-history me-3"></i>
                    {(props.settlementConcept.onlyPopupSpace === true) ? (
                        <span>Sucht ausschließlich Popup-Flächen</span>
                    ) : (
                        <span>Hat auch Interesse an Popup-Flächen</span>
                    )}
                </div>
            }
        </>
    );
};

export default PopupSpaceInformation;
