enum ParkingLotRequirementType {
    Optionally = 0,
    Mandatory = 1
}

const getParkingLotRequirementTypeLabel = (parkingLotRequirementType: ParkingLotRequirementType): string => {
    switch (parkingLotRequirementType) {
        case ParkingLotRequirementType.Optionally:
            return 'optional';
        case ParkingLotRequirementType.Mandatory:
            return 'verpflichtend';
        default:
            throw new Error();
    }
};

export default ParkingLotRequirementType;
export {getParkingLotRequirementTypeLabel};
