import FormError from '../Entity/Form/FormError';
import SelectOption from '../Entity/Form/SelectOption';
import OriginChannel, {getOriginChannelLabel} from '../Entity/User/OriginChannel';
import SelectField from '../Component/Form/Field/SelectField';
import React from 'react';
import {OnChangeValue} from 'react-select';

interface OriginChannelSelectFieldProps {
    readonly label: string;
    readonly name: string;
    readonly isClearable: boolean;
    readonly isRequired: boolean;
    readonly classNames?: string;
    readonly formErrors?: FormError[];
    readonly defaultValue?: OriginChannel;
    readonly onChange?: (selectedOption: SelectOption<OriginChannel> | null) => void;
}

const OriginChannelSelectField = (props: OriginChannelSelectFieldProps): React.JSX.Element => {
    const selectOptions: SelectOption<OriginChannel>[] = [
        {label: getOriginChannelLabel(OriginChannel.LinkedIn), value: OriginChannel.LinkedIn},
        {label: getOriginChannelLabel(OriginChannel.FacebookInstagram), value: OriginChannel.FacebookInstagram},
        {label: getOriginChannelLabel(OriginChannel.GoogleSearch), value: OriginChannel.GoogleSearch},
        {label: getOriginChannelLabel(OriginChannel.PressReleasePublication), value: OriginChannel.PressReleasePublication},
        {label: getOriginChannelLabel(OriginChannel.FlyerBrochure), value: OriginChannel.FlyerBrochure},
        {label: getOriginChannelLabel(OriginChannel.FairExhibitionIndustryEvent), value: OriginChannel.FairExhibitionIndustryEvent},
        {label: getOriginChannelLabel(OriginChannel.Recommendation), value: OriginChannel.Recommendation},
        {label: getOriginChannelLabel(OriginChannel.Other), value: OriginChannel.Other},
    ];

    const fetchDefaultValue = (): SelectOption<OriginChannel> | undefined => {
        return selectOptions.find((selectOption: SelectOption<OriginChannel>): boolean => {
            return selectOption.value === props.defaultValue;
        });
    };

    return (
        <SelectField
            label={props.label}
            name={props.name}
            required={props.isRequired}
            isClearable={props.isClearable}
            options={selectOptions}
            placeholder="Bitte wählen"
            onChange={(newValue: OnChangeValue<SelectOption<OriginChannel>, boolean>): void => {
                if (props.onChange !== undefined) {
                    props.onChange(newValue as SelectOption<OriginChannel>);
                }
            }}
            value={fetchDefaultValue()}
            formErrors={props.formErrors}
        />
    );
};

export default OriginChannelSelectField;
