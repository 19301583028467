enum OriginChannel {
    LinkedIn = 0,
    FacebookInstagram = 1,
    GoogleSearch = 2,
    PressReleasePublication = 3,
    FlyerBrochure = 4,
    FairExhibitionIndustryEvent = 5,
    Recommendation = 6,
    Other = 7
}

const getOriginChannelLabel = (originChannel: OriginChannel): string => {
    switch (originChannel) {
        case OriginChannel.LinkedIn:
            return 'LinkedIn';
        case OriginChannel.FacebookInstagram:
            return 'Facebook/Instagram';
        case OriginChannel.GoogleSearch:
            return 'Google-Suche';
        case OriginChannel.PressReleasePublication:
            return 'Pressemeldung/Publikation/Veröffentlichung';
        case OriginChannel.FlyerBrochure:
            return 'Flyer oder Broschüre';
        case OriginChannel.FairExhibitionIndustryEvent:
            return 'Messe/Ausstellung oder Branchenveranstaltung';
        case OriginChannel.Recommendation:
            return 'Empfehlung';
        case OriginChannel.Other:
            return 'Sonstiges';
    }
}

export default OriginChannel;
export {getOriginChannelLabel};
