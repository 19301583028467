import MarketplaceAd from '../../Entity/Marketplace/MarketplaceAd';
import PropertyDetailInformationOverlay from '../../Property/PropertyDetailInformationOverlay';
import PropertyCard from '../PropertyCard';
import Message from './Message';
import BootstrapIcon from '../../Component/Icon/BootstrapIcon';
import CollapseCard from '../../Component/CollapseCard/CollapseCard';
import React, {useState} from 'react';

interface MarketplaceAdCardProps {
    readonly marketplaceAd: MarketplaceAd;
}

const MarketplaceAdCard = (props: MarketplaceAdCardProps): React.JSX.Element => {
    const [showDetailInformationOverlay, setShowDetailInformationOverlay] = useState<boolean>(false);

    const buildThumbnailImageGetPath = (): string => {
        return '/marketplace-ads/' + props.marketplaceAd.id + '/thumbnail-images';
    };

    const buildImageGetPath = (): string => {
        return '/marketplace-ads/' + props.marketplaceAd.id + '/images';
    };

    const buildDocumentGetPath = (): string => {
        return '/marketplace-ads/' + props.marketplaceAd.id + '/documents';
    };

    return (
        <>
            <PropertyCard property={props.marketplaceAd.property} thumbnailImageGetPath={buildThumbnailImageGetPath()}>
                <button
                    className="btn btn-primary mx-auto text-nowrap text-uppercase w-100 mb-3"
                    onClick={(): void => setShowDetailInformationOverlay(true)}
                >
                    <BootstrapIcon iconName="eye-fill" className="me-2" />
                    Details
                </button>
            </PropertyCard>
            <PropertyDetailInformationOverlay
                show={showDetailInformationOverlay}
                setShow={setShowDetailInformationOverlay}
                property={props.marketplaceAd.property}
                contactCollapseCardChildren={<Message marketplaceAd={props.marketplaceAd} />}
                imageGetPath={buildImageGetPath()}
                documentGetPath={buildDocumentGetPath()}
            >
                {props.marketplaceAd.imprint !== null &&
                    <CollapseCard cardType="shadow" title="Anbieter-Impressum" className="mb-3">
                        {props.marketplaceAd.imprint.split('\n').map((item: string, index: number): React.JSX.Element => {
                            return <React.Fragment key={index}>{item}<br /></React.Fragment>
                        })}
                    </CollapseCard>
                }
            </PropertyDetailInformationOverlay>
        </>
    );
};

export default MarketplaceAdCard;
