import SettlementConceptService from '../SettlementConceptService';
import SettlementConcept from '../../Entity/SettlementConcept/SettlementConcept';
import Document from '../../Entity/Document/Document';
import Image from '../../Entity/Image/Image';
import ImageCollapseCard from '../../Image/ImageCollapseCard';
import ContactCollapseCard from '../../Contact/ContactCollapseCard';
import Disclaimer from '../../Disclaimer/Disclaimer';
import BaseInformationCollapseCard from './BaseInformationCollapseCard';
import BrandCollapseCard from './BrandCollapseCard';
import PropertyRequirementCollapseCard from './PropertyRequirementCollapseCard';
import LocationRequirementCollapseCard from './LocationRequirementCollapseCard';
import DocumentCollapseCard from '../../Document/DocumentCollapseCard';
import React from 'react';

interface SettlementConceptDetailInformationProps {
    readonly settlementConcept: SettlementConcept;
}

const SettlementConceptDetailInformation = (props: SettlementConceptDetailInformationProps): React.JSX.Element => {
    const buildImageGetPath = (image: Image): string => {
        return SettlementConceptService.buildImageApiPath(props.settlementConcept, image);
    };

    const buildDocumentGetPath = (document: Document): string => {
        if (props.settlementConcept === undefined) {
            throw new Error();
        }

        return SettlementConceptService.buildDocumentApiPath(props.settlementConcept, document);
    };

    return (
        <>
            <BaseInformationCollapseCard settlementConcept={props.settlementConcept} />
            <BrandCollapseCard settlementConcept={props.settlementConcept} />
            <PropertyRequirementCollapseCard settlementConcept={props.settlementConcept} />
            <LocationRequirementCollapseCard settlementConcept={props.settlementConcept} />
            <ContactCollapseCard contacts={props.settlementConcept.contacts} />

            {props.settlementConcept.images.length > 0 &&
                <ImageCollapseCard images={props.settlementConcept.images} buildImageGetPath={buildImageGetPath} />
            }
            {props.settlementConcept.documents.length > 0 &&
                <DocumentCollapseCard documents={props.settlementConcept.documents} buildDocumentGetPath={buildDocumentGetPath} />
            }

            <Disclaimer title="Rechtliche Hinweise und Haftungsausschluss" className="mt-4 pb-5" />
        </>
    );
};

export default SettlementConceptDetailInformation;
