import MarketingStatus, {getMarketingStatusLabel} from '../Entity/MarketingStatus';
import IconBadge from '../Component/IconBadge/IconBadge';
import React from 'react';

interface MarketingStatusBadgeProps {
    readonly marketingStatus: MarketingStatus;
}

const MarketingStatusBadge = (props: MarketingStatusBadgeProps): React.JSX.Element => {
    return (
        <>
            {props.marketingStatus === MarketingStatus.Available &&
                <IconBadge iconPublisher="bootstrap" iconName="house-check-fill" title={getMarketingStatusLabel(MarketingStatus.Available)} backgroundColor="primary" />
            }
            {props.marketingStatus === MarketingStatus.Reserved &&
                <IconBadge iconPublisher="bootstrap" iconName="house-lock-fill" title={getMarketingStatusLabel(MarketingStatus.Reserved)} backgroundColor="light-grey" />
            }
            {props.marketingStatus === MarketingStatus.Allocated &&
                <IconBadge iconPublisher="bootstrap" iconName="house-x-fill" title={getMarketingStatusLabel(MarketingStatus.Allocated)} backgroundColor="secondary" />
            }
        </>
    );
};

export default MarketingStatusBadge;
