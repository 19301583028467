import Messenger from './Messenger';
import {useAppDispatch} from '../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';

const MessagingPage = (): React.JSX.Element => {
    const dispatch = useAppDispatch();

    const [conversationIdentifier, setConversationIdentifier] = useState<string | undefined>();

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Meine Konversationen'},
            ]
        });
    }, [dispatch]);

    useEffect((): void => {
        const conversationIdentifier: string | null = searchParams.get('conversation');

        if (conversationIdentifier === null) {
            setConversationIdentifier(undefined);
        } else {
            setConversationIdentifier(conversationIdentifier);
        }
    }, [searchParams]);

    return (
        <>
            <div className="container-fluid mb-4">
                <div className="align-items-center mb-4">
                    <div className="d-flex align-items-center">
                        <Link to="/"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                        <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Meine Konversationen</h1>
                    </div>
                </div>
            </div>
            <div className="container-fluid ps-0 pe-0 pe-md-3 overflow-hidden mb-4">
                <Messenger conversationIdentifier={conversationIdentifier} />
            </div>
        </>
    );
};

export default MessagingPage;
