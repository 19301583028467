enum AdPartnerType {
    Standard = 0,
    Premium = 1,
    Platinum = 2
}

const getAdPartnerTypeLabel = (adPartnerType: AdPartnerType): string => {
    switch (adPartnerType) {
        case AdPartnerType.Standard:
            return 'Standard-Partner';
        case AdPartnerType.Premium:
            return 'Premium-Partner';
        case AdPartnerType.Platinum:
            return 'Platin-Partner';
        default:
            throw new Error();
    }
};

export default AdPartnerType;
export {getAdPartnerTypeLabel};
