import ContactCreate from './ContactCreate';
import InfoBox from '../Component/InfoBox/InfoBox';
import { useAppDispatch } from '../../../app/hooks';
import React, {useEffect} from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';

const ContactCreatePage = (): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Meine Ansprechpartner', link: '/ansprechpartner'},
                {name: 'Ansprechpartner anlegen'},
            ]
        });
    }, [dispatch]);

    const onContactCreated = (): void => {
        if (localStorage.getItem('initialEntry') === 'property') {
            navigate('/flaechen/ersterfassung');
        } else if (localStorage.getItem('initialEntry') === 'settlementConcept') {
            navigate('/suchprofile/ersterfassung');
        } else {
            navigate('/ansprechpartner');
        }
    };

    return (
        <div className="container-fluid">
            <div className="mb-4">
                <div className="d-flex align-items-center w-100">
                    <Link to="/ansprechpartner"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                    <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Ansprechpartner anlegen</h1>
                </div>
            </div>
            <InfoBox cardType="outline" className="p-3 mx-0 mx-md-3 mb-3">
                <div className="fs-5 mb-0">
                    Hier erfasst Du Ansprechpartner zu Deinen Suchprofilen oder Ladenlokalen.
                    Die angegebenen Kontaktdaten der Ansprechpartner werden im Fall eines erfolgreichen Matchings dem jeweils anderen Matching-Partner freigeschaltet.
                </div>
            </InfoBox>
            <div className="py-3 px-0 px-md-3 mb-3">
                <ContactCreate onContactCreated={onContactCreated} />
            </div>
        </div>
    );
};

export default ContactCreatePage;
