import FormData from '../Entity/Form/FormData';
import FieldValidationDefinition from './FieldValidationDefinition';

class EmailValidationDefinition<T> extends FieldValidationDefinition<T> {
    constructor(fieldName: string, message: string | null) {
        super(fieldName, message);
    }

    public check(formData: FormData<T>): boolean {
        const formDataValue: any = (formData.data as any)[this._fieldName];

        if (formDataValue === undefined) {
            return false;
        }

        const regExp: RegExp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        const match: RegExpMatchArray = formDataValue.match(regExp);

        return match !== null && match.length > 0;
    }
}

export default EmailValidationDefinition;
