import welcome1 from './introduction-visual-01.svg';
import welcome2 from './introduction-visual-02.svg';
import welcome4 from './introduction-visual-04.svg';
import introductionSliderStyle from './IntroductionSlider.module.scss';
import Glide from '@glidejs/glide';
import React, {MutableRefObject, useEffect, useRef, useState} from 'react';

interface IntroductionSliderProps {
    readonly onClose?: () => void;
}

const IntroductionSlider = (props: IntroductionSliderProps): React.JSX.Element => {
    const glideRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

    const buttonNextRef: MutableRefObject<HTMLButtonElement | null> = useRef<HTMLButtonElement | null>(null);

    const buttonPreviousRef: MutableRefObject<HTMLButtonElement | null> = useRef<HTMLButtonElement | null>(null);

    const buttonCloseRef: MutableRefObject<HTMLButtonElement | null> = useRef<HTMLButtonElement | null>(null);

    const [glide, setGlide] = useState<Glide>();

    const [title, setTitle] = useState<string>('Willkommen bei LeAn Match!');

    useEffect((): () => void => {
        const glide: Glide = new Glide(glideRef.current!, {
            type: 'slider',
            perView: 1
        });

        glide.mount();

        setGlide(glide);

        return (): void => {
            glide.destroy();
        };
    }, []);

    useEffect((): void => {
        if (glide === undefined) {
            return;
        }

        glide.on('run', (): void => {
            setGliderButtonState();
        });
    }, [glide]);

    const setGliderButtonState = (): void => {
        if (buttonCloseRef.current === null || buttonPreviousRef.current === null || buttonNextRef.current === null) {
            return;
        }

        switch (glide!.index) {
            case 1:
                setTitle('Stelle Dein Ladenlokal ein!');
                buttonCloseRef.current.classList.add('d-none');
                buttonPreviousRef.current.classList.remove('d-none');
                buttonNextRef.current.classList.remove('d-none');

                break;
            case 2:
                setTitle('Finde passende Konzepte!');
                buttonCloseRef.current.classList.remove('d-none');
                buttonPreviousRef.current.classList.remove('d-none');
                buttonNextRef.current.classList.add('d-none');

                break;
            default:
                setTitle('Willkommen bei LeAn Match!');
                buttonCloseRef.current.classList.add('d-none');
                buttonPreviousRef.current.classList.add('d-none');
                buttonNextRef.current.classList.remove('d-none');

                break;
        }
    };

    const onPreviousButtonClick = (): void => {
        if (glide === undefined) {
            return;
        }

        glide.go('<');

        setGliderButtonState();
    };

    const onButtonNextClick = (): void => {
        if (glide === undefined) {
            return;
        }

        glide.go('>');

        setGliderButtonState();
    };

    const onCloseButtonClick = (): void => {
        if (glide === undefined) {
            return;
        }

        localStorage.setItem('welcomeOverlayHidden', 'true');

        glide.go('=0');

        if (props.onClose !== undefined) {
            props.onClose();
        }

        //TODO: set user.introComplete = true;
    };

    return (
        <>
            <div className="container">
                <div ref={glideRef} className={'glide ' + introductionSliderStyle.introductionSlider}>
                    <div className="glide__track" data-glide-el="track">
                        <ul className="glide__slides">
                            <li className="glide__slide px-5" style={{width: '100vh'}}>
                                <div className="row pb-5">
                                    <div className="col-12 d-flex justify-content-center">
                                        <h1 className="fw-bold">{title}</h1>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <img src={welcome1} alt="Willkommen" className="img-fluid" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <p>
                                            Leerstandslotsen und LeAn GmbH sind angetreten, um die nachhaltige Vermittlung von Ladenflächen zu revolutionieren und Leerstand in den Kommunen nachhaltig zu füllen.
                                            Freie oder freiwerdende Ladenflächen treffen in unserem geschützten Datenraum auf vielfältige und passgenaue Suchprofile.
                                        </p>
                                    </div>
                                </div>
                                <p className="mt-3">
                                    Schön, dass Du dabei bist! So einfach kannst Du loslegen:
                                </p>
                                <p>Vervollständige Dein Profil:</p>
                                <ul className="mb-0">
                                    <li>
                                        Erfasse Ansprechpartner:innen
                                    </li>
                                    <li>
                                        Erfasse freie Ladenlokale oder leerfallende Immobilien in Deiner Stadt
                                    </li>
                                    <li>
                                        Starte ein Matching und nimm Kontakt zu Konzeptbetreibern auf
                                    </li>
                                </ul>
                            </li>
                            <li className="glide__slide px-5 pb-5">
                                <div className="row pb-5">
                                    <div className="col-12 d-flex justify-content-center">
                                        <h1 className="fw-bold">{title}</h1>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <img src={welcome2} alt="Anbieten" className="img-fluid" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <p>
                                            Du hast ein leerstehendes oder bald leerfallendes Ladenlokal in Deiner Stadt und suchst einen Mieter oder Käufer? Dann bist Du hier genau richtig.
                                        </p>
                                        <p>
                                            Ähnlich wie bei einem Exposé, kannst Du Informationen zu Deiner Fläche eingeben. Diese Flächen- und Standortangaben werden dann für das Matching genutzt.
                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-3 mb-5">
                                    <div className="col-12">
                                        <p>
                                            Gib alle relevanten Daten zu Deinem Objekt ein.
                                        </p>
                                        <p>
                                            Danach kannst Du ein Matching starten.
                                        </p>
                                        <p>
                                            Du brauchst Hilfe? Wende Dich an David unter <a href="mailto:help@llasm.de">help@llasm.de</a> / <a href="tel:+4921714018846">+49 2171 40188-46</a>.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="glide__slide px-5 pb-5">
                                <div className="row pb-5">
                                    <div className="col-12 d-flex justify-content-center">
                                        <h1 className="fw-bold">{title}</h1>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <img src={welcome4} alt="Let's Match" className="img-fluid" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <p>
                                            Nachdem Du Dein Objekt eingestellt und aktiviert hast, kannst Du ein Matching starten. Klicke dazu einfach auf den Button "Let's Match".
                                        </p>
                                        <p>
                                            Du erhälst daraufhin eine Liste mit passenden Untenehmen, die Du direkt über die Plattform kontaktieren kannst.
                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <p>
                                            Über den Branchenfilter kannst Du Deine Suche auf bestimmte Branchenklassifizierungen einschränken.
                                        </p>
                                        <p>
                                            Außerdem kannst Du Präferenzen festlegen, die das Scoring der Ergebnisse beeinflussen.
                                        </p>
                                        <p>
                                            Du brauchst Hilfe? Wende Dich an David unter <a href="mailto:help@llasm.de">help@llasm.de</a> / <a href="tel:+4921714018846">+49 2171 40188-46</a>.
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="glide__bullets" data-glide-el="controls[nav]">
                        <button className="glide__bullet" data-glide-dir="=0"></button>
                        <button className="glide__bullet" data-glide-dir="=1"></button>
                        <button className="glide__bullet" data-glide-dir="=2"></button>
                    </div>
                </div>
            </div>
            <div className="fixed-bottom bg-white">
                <div className="container">
                    <div className="row pt-2 mb-4">
                        <div className="col-6">
                            <button ref={buttonPreviousRef} className="btn btn-primary d-none" onClick={onPreviousButtonClick}>Zurück</button>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                            <button ref={buttonNextRef} className="btn btn-primary ms-2" onClick={onButtonNextClick}>Weiter</button>
                            <button ref={buttonCloseRef} className="btn btn-secondary d-none ms-2" onClick={onCloseButtonClick}>Fertig</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IntroductionSlider;
