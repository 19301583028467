import FormError from '../../../Entity/Form/FormError';
import FieldProps from './FieldProps';
import FieldLabel from './FieldLabel';
import React from 'react';
import AsyncSelect from 'react-select/async';
import { MultiValue, ActionMeta, GroupBase, OptionsOrGroups } from 'react-select';

interface AsyncMultiSelectFieldProps<Option, Group extends GroupBase<Option>> extends FieldProps {
    readonly placeholder: React.ReactNode;
    readonly value?: MultiValue<Option>;
    readonly closeMenuOnSelect: boolean;
    readonly onChange: (newValue: MultiValue<Option>, actionMeta: ActionMeta<Option>) => void;
    readonly loadOptions?: (inputValue: string, callback: (options: OptionsOrGroups<Option, Group>) => void) => Promise<OptionsOrGroups<Option, Group>> | void;
    readonly defaultOptions?: OptionsOrGroups<Option, Group> | boolean;
    readonly loadingMessage?: (obj: { inputValue: string; }) => React.ReactNode;
    readonly options?: OptionsOrGroups<Option, Group>;
}

const AsyncMultiSelectField = <Option = unknown, Group extends GroupBase<Option> = GroupBase<Option>>(props: AsyncMultiSelectFieldProps<Option, Group> & React.RefAttributes<MultiValue<Option>>): React.JSX.Element => {
    const hasErrors: boolean = props.formErrors !== undefined && props.formErrors.length > 0;

    return (
        <>
            {props.label !== undefined &&
                <FieldLabel label={props.label} htmlFor={props.name} required={props.required} description={props.description} className="mb-2 ps-2" />
            }
            <AsyncSelect
                isMulti
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                closeMenuOnSelect={props.closeMenuOnSelect}
                onChange={props.onChange}
                loadOptions={props.loadOptions}
                defaultOptions={props.defaultOptions}
                loadingMessage={props.loadingMessage}
                className={(hasErrors === true ? 'is-invalid form-control' : '') + ' inset-shadow'}
            />
            {hasErrors === true &&
                <>
                    {props.formErrors?.map((formError: FormError, index: number): React.JSX.Element => (
                        <div key={'select_error_' + props.name + '_' + index}  className="invalid-feedback d-block">
                            {formError.message}
                        </div>
                    ))}
                </>
            }
        </>
    );
};

export default AsyncMultiSelectField;
