import Card from '../domain/shared/Component/Card/Card';
import React from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';

interface SidebarLink {
    readonly title: string;
    readonly icon: React.JSX.Element;
    readonly to: string;
    readonly header?: string;
    readonly ellipsis?: boolean;
}

interface SidebarProps {
    readonly sidebarLinks: SidebarLink[];
}

const Sidebar = (props: SidebarProps): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();

    const goTo = (url: string): void => {
        if (url.startsWith('http') === true || url.startsWith('https') === true) {
            window.open(url);
        } else {
            navigate(url);
        }
    };

    return (
        <aside className="gap-3">
            {props.sidebarLinks.map((sidebarLink: SidebarLink, index: number): React.JSX.Element => (
                <div key={index} className="d-flex justify-content-end mb-3">
                    <Card
                        cardType="shadow"
                        className="btn d-flex flex-column justify-content-center rounded-default text-muted highlight w-100"
                        onClick={(): void => goTo(sidebarLink.to)}
                    >
                        <div className="d-flex flex-column align-items-center w-100">
                            {sidebarLink.ellipsis === true &&
                                <>
                                    {sidebarLink.header !== undefined &&
                                        <span className="text-overflow-ellipsis text-primary w-100">{sidebarLink.header}</span>
                                    }
                                    <span className="text-overflow-ellipsis text-secondary w-100">{sidebarLink.title}</span>
                                </>
                            }
                            {sidebarLink.ellipsis !== true &&
                                <>
                                    {sidebarLink.header !== undefined &&
                                        <span className="text-primary">{sidebarLink.header}</span>
                                    }
                                    <span>{sidebarLink.title}</span>
                                </>
                            }
                            {sidebarLink.icon}
                        </div>
                    </Card>
                </div>
            ))}
        </aside>
    );
};

export default Sidebar;
