import IconProps from './IconProps';
import React from 'react';

interface MaterialIconProps extends IconProps {
}

const MaterialIcon = (props: MaterialIconProps): React.JSX.Element => {
    const buildClassName = (): string => {
        let className: string = 'material-icons';

        if (props.className !== undefined) {
            className += ' ' + props.className;
        }

        return className;
    };

    return (
        <span className={buildClassName()}>{props.iconName}</span>
    );
};

export default MaterialIcon;
