import UserRegistration from '../../shared/Entity/User/UserRegistration';
import UserActivation from '../../shared/Entity/User/UserActivation';
import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import UserRegistrationPostRequest from '../../../api/Llasm/User/UserRegistrationPostRequest';
import UserActivationRequest from '../../../api/Llasm/User/UserActivationRequest';
import UserActivationResponse from '../../../api/Llasm/User/UserActivationResponse';
import { AxiosResponse } from 'axios';

class UserRegistrationService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
    }

    public async persistUserRegistration(userRegistration: UserRegistration): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.post<null, AxiosResponse<null>, UserRegistrationPostRequest>(
            '/user-registrations',
            UserRegistrationService.mapFromUserRegistrationToUserRegistrationPostRequest(userRegistration)
        );
    }

    public async fetchUserActivationFromApiByActivationUuid(activationUuid: string): Promise<UserActivation> {
        const apiResponse: AxiosResponse<UserActivationResponse> = await this.llasmApiV1Provider.get('/user-activations' + '/' + activationUuid);

        return UserActivation.createFromUserActivationResponse(apiResponse.data);
    }

    public async activateUser(userActivation: UserActivation): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.post<null, AxiosResponse<null>, UserActivationRequest>(
            '/user-activations' + '/' + userActivation.activationUuid + '/activation',
            UserRegistrationService.mapFromUserActivationToUserActivationRequest(userActivation)
        );
    }

    private static mapFromUserRegistrationToUserRegistrationPostRequest(userRegistration: UserRegistration): UserRegistrationPostRequest {
        return UserRegistrationService.mapUserRegistrationToApiRequest(userRegistration);
    }

    private static mapUserRegistrationToApiRequest(userRegistration: UserRegistration): UserRegistrationPostRequest {
        return {
            email: userRegistration.email,
            naturalPerson: userRegistration.naturalPerson,
            firstName: userRegistration.firstName,
            lastName: userRegistration.lastName,
            companyName: userRegistration.companyName,
            streetName: userRegistration.streetName,
            houseNumber: userRegistration.houseNumber,
            postalCode: userRegistration.postalCode,
            placeName: userRegistration.placeName,
            countryId: userRegistration.country.id!,
            phoneNumber: userRegistration.phoneNumber,
            originChannel: userRegistration.originChannel,
            userIs: userRegistration.userIs,
            gtcAccepted: userRegistration.gtcAccepted
        };
    }

    private static mapFromUserActivationToUserActivationRequest(userActivation: UserActivation): UserActivationRequest {
        return UserRegistrationService.mapUserActivationToApiRequest(userActivation);
    }

    private static mapUserActivationToApiRequest(userActivation: UserActivation): UserActivationRequest {
        return {
            gtcAccepted: userActivation.gtcAccepted,
            password: userActivation.password,
            passwordRepeat: userActivation.passwordRepeat
        };
    }
}

export default UserRegistrationService;
