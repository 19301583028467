import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import {getBranchingDegreeLabel} from '../Entity/BranchingDegree';
import LabelValuesPair from '../../../components/LabelValuesPair';
import LabelValuePair from '../../../components/LabelValuePair';
import Card from '../Component/Card/Card';
import InfoBox from '../Component/InfoBox/InfoBox';
import React from 'react';

interface SettlementConceptLockedInformationProps {
    readonly settlementConcept: SettlementConcept;
}

const SettlementConceptLockedInformation = (props: SettlementConceptLockedInformationProps): React.JSX.Element => {
    const industryClassificationValues: string[] = props.settlementConcept.industryClassifications.map(
        industryClassification => industryClassification.name
    );

    return (
        <>
            <Card cardType="shadow">
                <div className="p-5">
                    {industryClassificationValues.length > 0 &&
                        <div className="col-12 mb-3">
                            <LabelValuesPair label="Branche(n)" values={industryClassificationValues} delimiter=" · " />
                        </div>
                    }
                    <div className="col-12 mb-3">
                        <LabelValuePair label="Filialisierungsgrad" value={getBranchingDegreeLabel(props.settlementConcept.brand.branchingDegree)} />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <LabelValuePair label="Gesamtfläche von" value={new Intl.NumberFormat('de-DE').format(props.settlementConcept.propertyCriteria.areaSizeMinimum) + ' m²'} />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <LabelValuePair label="Gesamtfläche bis" value={new Intl.NumberFormat('de-DE').format(props.settlementConcept.propertyCriteria.areaSizeMaximum) + ' m²'} />
                    </div>
                </div>
            </Card>
            <InfoBox cardType="outline" className="p-3 mt-5">
                <div className="mb-0 fs-6">
                    Die Daten zu diesem Suchprofil stehen Dir leider nicht mehr zur Verfügung.
                </div>
            </InfoBox>
        </>
    );
};

export default SettlementConceptLockedInformation;
