enum TimeAvailability {
    Immediately = 0,
    ByArrangement = 1,
    CertainDate = 2
}

const getTimeAvailabilityLabel = (timeAvailability: TimeAvailability): string => {
    switch (timeAvailability) {
        case TimeAvailability.Immediately:
            return 'sofort';
        case TimeAvailability.ByArrangement:
            return 'nach Vereinbarung';
        case TimeAvailability.CertainDate:
            return 'einem bestimmten Datum';
    }
};

export default TimeAvailability;
export {getTimeAvailabilityLabel};
