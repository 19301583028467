import ParticipatingUser from '../../Entity/Messaging/ParticipatingUser';
import PropertyInterestFeedback, {getPropertyInterestFeedbackLabel} from '../../Entity/PropertyInterest/PropertyInterestFeedback';
import BootstrapIcon from '../../Component/Icon/BootstrapIcon';
import propertyInterestNotificationMessageTextStyle from './PropertyInterestNotificationMessageText.module.scss';
import React from 'react';

interface PropertyInterestNotificationMessageTextProps {
    readonly participatingUser: ParticipatingUser;
    readonly isSender: boolean;
    readonly propertyInterestFeedback: PropertyInterestFeedback;
}

const PropertyInterestNotificationMessageText = (props: PropertyInterestNotificationMessageTextProps): React.JSX.Element => {
    const buildPropertyInterestFeedbackClassName = (): string => {
        if (props.propertyInterestFeedback === PropertyInterestFeedback.Interested) {
            return 'text-primary';
        } else {
            return propertyInterestNotificationMessageTextStyle.textGrey;
        }
    };

    return (
        <>
            {props.propertyInterestFeedback === PropertyInterestFeedback.Interested &&
                <BootstrapIcon iconName="emoji-laughing-fill" className="me-1" color="primary" />
            }
            {props.propertyInterestFeedback === PropertyInterestFeedback.NotInterested &&
                <BootstrapIcon iconName="emoji-frown-fill" className="me-1" color="grey" />
            }
            {props.isSender === false &&
                <span className={propertyInterestNotificationMessageTextStyle.textGrey}>{props.participatingUser.name} hat auf dein Flächenangebot mit <span className={buildPropertyInterestFeedbackClassName()}>"{getPropertyInterestFeedbackLabel(props.propertyInterestFeedback).toLowerCase()}"</span> reagiert.</span>
            }
            {props.isSender === true &&
                <span className="text-primary">Du hast auf das Flächenangebot von {props.participatingUser.name} mit <span className={buildPropertyInterestFeedbackClassName()}>"{getPropertyInterestFeedbackLabel(props.propertyInterestFeedback).toLowerCase()}"</span> reagiert.</span>
            }
        </>
    );
};

export default PropertyInterestNotificationMessageText;
