import BrandService from './BrandService';
import FormData from '../Entity/Form/FormData';
import SelectOption from '../Entity/Form/SelectOption';
import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import Brand from '../Entity/Brand/Brand';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import BrandCreate from './BrandCreate';
import Overlay from '../Component/Overlay/Overlay';
import SelectField from '../Component/Form/Field/SelectField';
import LoadingIndicator from '../../../components/LoadingIndicator';
import React, {useEffect, useState} from 'react';

interface SelectOrCreateBrandProps {
    readonly formData: FormData<SettlementConcept>;
    readonly setFormData: (formData: FormData<SettlementConcept>) => void;
    readonly validateField?: (fieldName: string) => void;
}

const brandService: BrandService = new BrandService(process.env.REACT_APP_LLASM_API_URL!);

const SelectOrCreateBrand = (props: SelectOrCreateBrandProps): React.JSX.Element => {
    const [brandSelectOptions, setBrandSelectOptions] = useState<SelectOption<Brand>[] | undefined>(undefined);

    const [showCreateBrandOverlay, setShowCreateBrandOverlay] = useState<boolean>(false);

    useEffect((): void => {
        fetchBrandSelectOptions();
    }, []);

    const fetchBrandSelectedOption = (): SelectOption<Brand> | undefined => {
        if (brandSelectOptions === undefined) {
            return undefined;
        }

        if (props.formData.data.brand === undefined) {
            return undefined;
        }

        return brandSelectOptions.find((selectOption: SelectOption<Brand>): boolean => {
            return selectOption.value.id === props.formData.data.brand.id;
        });
    };

    const fetchBrandSelectOptions = async (): Promise<void> => {
        const selectOptions: SelectOption<Brand>[] = (await (brandService.fetchBrandsFromApi())).map((brand: Brand): SelectOption<Brand> => {
            return {
                label: brand.brandName,
                value: brand
            };
        });

        setBrandSelectOptions(selectOptions);
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: props.formData.data});
    };

    const handleBrandSelectChange = (selectedOption: SelectOption<Brand> | null): void => {
        if (selectedOption === null) {
            return;
        }

        props.formData.data.brand = selectedOption.value;

        updateFormData();

        if (props.validateField !== undefined) {
            props.validateField('brand');
        }
    };

    const onBrandCreated = (brand: Brand): void => {
        setShowCreateBrandOverlay(false);

        brandSelectOptions?.push({
            label: brand.brandName,
            value: brand
        });

        props.formData.data.brand = brand;

        if (props.validateField !== undefined) {
            props.validateField('brand');
        }
    };

    const onCloseButtonClick = (): void => {
        setShowCreateBrandOverlay(false);
    };

    if (brandSelectOptions === undefined) {
        return <LoadingIndicator />;
    }

    return (
        <>
            <div className="row align-items-end">
                <div className="col-md-8">
                    <SelectField
                        label="Marke/Name des Geschäfts"
                        name="brand"
                        required={true}
                        options={brandSelectOptions}
                        placeholder="Bitte wählen"
                        onChange={handleBrandSelectChange}
                        value={fetchBrandSelectedOption()}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'brand')}
                    />
                </div>
                <div className="col-md-4">
                    <button type="button" className="btn btn-secondary" onClick={(): void => setShowCreateBrandOverlay(true)}>
                        <i className="bi bi-plus-lg fs-6 px-2"></i>Marke/Geschäft anlegen
                    </button>
                </div>
            </div>
            <Overlay show={showCreateBrandOverlay} setShow={setShowCreateBrandOverlay} title="Marke/Geschäft anlegen">
                <div className="container">
                    <BrandCreate onBrandCreated={onBrandCreated} onCloseButtonClick={onCloseButtonClick} />
                </div>
            </Overlay>
        </>
    );
};

export default SelectOrCreateBrand;
