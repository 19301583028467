import ConversationService from '../../Messaging/ConversationService';
import SettlementConcept from '../../Entity/SettlementConcept/SettlementConcept';
import Property from '../../Entity/Property/Property';
import SettlementConceptMatchingItem from './SettlementConceptMatchingItem';
import PropertyMatchingItem from './PropertyMatchingItem';
import React from 'react';

interface MatchingItemProps {
    readonly selectedItem: SettlementConcept | Property;
    readonly matchingItem: SettlementConcept | Property;
    readonly setShowDetailInformationOverlay: (showDetailInformationOverlay: boolean) => void;
    readonly setDetailInformationItem: (detailInformationItem: SettlementConcept | Property) => void;
}

const MatchingItem = (props: MatchingItemProps): React.JSX.Element => {
    const createLinkToConversation = (): string => {
        if (props.matchingItem.providerSeekerConversation === null) {
            throw new Error('no providerSeekerConversation');
        }

        return ConversationService.createLinkToConversation(props.matchingItem.providerSeekerConversation);
    };

    const showDetailInformation = (detailInformationItem: SettlementConcept | Property): void => {
        props.setDetailInformationItem(detailInformationItem);
        props.setShowDetailInformationOverlay(true);
    };

    return (
        <>
            {(props.matchingItem instanceof SettlementConcept && props.selectedItem instanceof Property) &&
                <SettlementConceptMatchingItem
                    selectedItem={props.selectedItem}
                    matchingItem={props.matchingItem}
                    createLinkToConversation={createLinkToConversation}
                    showDetailInformation={showDetailInformation}
                />
            }

            {(props.matchingItem instanceof Property && props.selectedItem instanceof SettlementConcept) &&
                <PropertyMatchingItem
                    selectedItem={props.selectedItem}
                    matchingItem={props.matchingItem}
                    createLinkToConversation={createLinkToConversation}
                    showDetailInformation={showDetailInformation}
                />
            }
        </>
    );
};

export default MatchingItem;
