import FormData from '../Entity/Form/FormData';
import SelectOption from '../Entity/Form/SelectOption';
import EnergyCertificate from '../Entity/Property/EnergyCertificate/EnergyCertificate';
import EnergyCertificateType, {getEnergyCertificateTypeLabel} from '../Entity/Property/EnergyCertificate/EnergyCertificateType';
import EnergyEfficiencyClass, {getEnergyEfficiencyClassLabel} from '../Entity/Property/EnergyCertificate/EnergyEfficiencyClass';
import EnergyCertificateHeatingType, {getEnergyCertificateHeatingTypeLabel} from '../Entity/Property/EnergyCertificate/EnergyCertificateHeatingType';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import SelectField from '../Component/Form/Field/SelectField'
import TextAreaField from '../Component/Form/Field/TextAreaField';
import InputField from '../Component/Form/Field/InputField';
import CheckboxField from '../Component/Form/Field/CheckboxField';
import DatePickerField from '../Component/Form/Field/DatePickerField';
import Tooltip from '../Component/Tooltip/Tooltip';
import React, {useEffect} from 'react';

const energyCertificateTypeSelectOptions: SelectOption<EnergyCertificateType>[] = [
    {label: getEnergyCertificateTypeLabel(EnergyCertificateType.EnergyConsumptionCertificate), value: EnergyCertificateType.EnergyConsumptionCertificate},
    {label: getEnergyCertificateTypeLabel(EnergyCertificateType.EnergyRequirementCertificate), value: EnergyCertificateType.EnergyRequirementCertificate},
];

const energyEfficiencyClassSelectOptions: SelectOption<EnergyEfficiencyClass | null>[] = [
    {label: 'Bitte wählen', value: null},
    {label: getEnergyEfficiencyClassLabel(EnergyEfficiencyClass.APlus), value: EnergyEfficiencyClass.APlus},
    {label: getEnergyEfficiencyClassLabel(EnergyEfficiencyClass.A), value: EnergyEfficiencyClass.A},
    {label: getEnergyEfficiencyClassLabel(EnergyEfficiencyClass.B), value: EnergyEfficiencyClass.B},
    {label: getEnergyEfficiencyClassLabel(EnergyEfficiencyClass.C), value: EnergyEfficiencyClass.C},
    {label: getEnergyEfficiencyClassLabel(EnergyEfficiencyClass.D), value: EnergyEfficiencyClass.D},
    {label: getEnergyEfficiencyClassLabel(EnergyEfficiencyClass.E), value: EnergyEfficiencyClass.E},
    {label: getEnergyEfficiencyClassLabel(EnergyEfficiencyClass.F), value: EnergyEfficiencyClass.F},
    {label: getEnergyEfficiencyClassLabel(EnergyEfficiencyClass.G), value: EnergyEfficiencyClass.G},
    {label: getEnergyEfficiencyClassLabel(EnergyEfficiencyClass.H), value: EnergyEfficiencyClass.H},
];

const withHotWaterOptions: SelectOption<boolean>[] = [
    {label: 'Nein', value: false},
    {label: 'Ja', value: true},
];

const energyCertificateHeatingTypes: EnergyCertificateHeatingType[] = [
    EnergyCertificateHeatingType.Oil,
    EnergyCertificateHeatingType.Gas,
    EnergyCertificateHeatingType.Electric,
    EnergyCertificateHeatingType.AlternativeEnergySources,
    EnergyCertificateHeatingType.SolarSystem,
    EnergyCertificateHeatingType.HeatPumpBrineWater,
    EnergyCertificateHeatingType.HeatPumpAirWater,
    EnergyCertificateHeatingType.DistrictHeating,
    EnergyCertificateHeatingType.CombinedHeatAndPowerPlant,
    EnergyCertificateHeatingType.SupplementaryDecentralizedHotWater,
    EnergyCertificateHeatingType.PelletHeating,
];

interface AdditionalDetailFormProps {
    readonly formData: FormData<EnergyCertificate>;
    readonly setFormData: (formData: FormData<EnergyCertificate>) => void;
    readonly validationDefinitions: FieldValidationDefinition<EnergyCertificate>[];
}

const EnergyCertificateForm = (props: AdditionalDetailFormProps): React.JSX.Element => {
    const energyCertificate: EnergyCertificate = props.formData.data;

    useEffect((): void => {
        props.setFormData({...props.formData, formValidationHandler: new FormValidationHandler<EnergyCertificate>(props.validationDefinitions)});
    }, [props.validationDefinitions]);

    useEffect((): void => {
        if (props.formData.formValidationHandler === undefined) {
            return;
        }

        if (energyCertificate.energyCertificateType === EnergyCertificateType.EnergyRequirementCertificate) {
            props.formData.formValidationHandler.addFieldValidationDefinition(
                new RequiredValidationDefinition<EnergyCertificate>('energyDemand', 'Es muss ein Endenergiebedarf eingegeben werden.')
            );
        } else {
            props.formData.formValidationHandler.removeFieldValidationDefinitionsByFieldName('energyDemand');
        }

        if (energyCertificate.energyCertificateType === EnergyCertificateType.EnergyConsumptionCertificate) {
            props.formData.formValidationHandler.addFieldValidationDefinition(
                new RequiredValidationDefinition<EnergyCertificate>('energyConsumption', 'Es muss ein Energieverbrauchkennwert eingegeben werden.')
            );
        } else {
            props.formData.formValidationHandler.removeFieldValidationDefinitionsByFieldName('energyConsumption');
        }
    }, [energyCertificate.energyCertificateType, props.formData.formValidationHandler]);

    const fetchEnergyCertificateTypeSelectedOption = (): SelectOption<EnergyCertificateType> | undefined  => {
        if (energyCertificate.energyCertificateType === undefined) {
            return undefined;
        }

        return energyCertificateTypeSelectOptions.find((selectOption: SelectOption<EnergyCertificateType>): boolean => {
            return energyCertificate.energyCertificateType === selectOption.value;
        });
    };

    const fetchEnergyEfficiencyClassSelectedOption = (): SelectOption<EnergyEfficiencyClass | null> | undefined => {
        return energyEfficiencyClassSelectOptions.find((selectOption: SelectOption<EnergyEfficiencyClass | null>): boolean => {
            return energyCertificate.energyEfficiencyClass === selectOption.value;
        });
    };

    const fetchWithHotWaterSelectedOption = (): SelectOption<boolean> | undefined => {
        if (energyCertificate.withHotWater === undefined) {
            return undefined;
        }

        return withHotWaterOptions.find((selectOption: SelectOption<boolean>): boolean => {
            return energyCertificate.withHotWater === selectOption.value;
        });
    };

    const toggleEnergyCertificateHeatingType = (energyCertificateHeatingType: EnergyCertificateHeatingType): void => {
        const energyCertificateHeatingTypeIndex: number = energyCertificate.energyCertificateHeatingTypes.indexOf(energyCertificateHeatingType);

        if (energyCertificateHeatingTypeIndex === -1) {
            energyCertificate.energyCertificateHeatingTypes.push(energyCertificateHeatingType);
        } else {
            energyCertificate.energyCertificateHeatingTypes.splice(energyCertificateHeatingTypeIndex, 1);
        }

        updateFormData();
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
        switch (event.target.name) {
            case 'buildingConstructionYear':
                if (event.target.value === '') {
                    energyCertificate.buildingConstructionYear = null;
                } else {
                    energyCertificate.buildingConstructionYear = Number(event.target.value);
                }

                break;
            case 'energyConsumption':
                if (event.target.value === '') {
                    energyCertificate.energyConsumption = null;
                } else {
                    if (Number(event.target.value) < 0) {
                        energyCertificate.energyConsumption = 0;
                    } else {
                        energyCertificate.energyConsumption = Number(event.target.value);
                    }
                }

                break;
            case 'energyDemand':
                if (event.target.value === '') {
                    energyCertificate.energyDemand = null;
                } else {
                    if (Number(event.target.value) < 0) {
                        energyCertificate.energyDemand = 0;
                    } else {
                        energyCertificate.energyDemand = Number(event.target.value);
                    }
                }

                break;
            case 'electricityConsumption':
                if (event.target.value === '') {
                    energyCertificate.electricityConsumption = null;
                } else {
                    if (Number(event.target.value) < 0) {
                        energyCertificate.electricityConsumption = 0;
                    } else {
                        energyCertificate.electricityConsumption = Number(event.target.value);
                    }
                }

                break;
            case 'electricityDemand':
                if (event.target.value === '') {
                    energyCertificate.electricityDemand = null;
                } else {
                    if (Number(event.target.value) < 0) {
                        energyCertificate.electricityDemand = 0;
                    } else {
                        energyCertificate.electricityDemand = Number(event.target.value);
                    }
                }

                break;
            case 'heatingEnergyConsumption':
                if (event.target.value === '') {
                    energyCertificate.heatingEnergyConsumption = null;
                } else {
                    if (Number(event.target.value) < 0) {
                        energyCertificate.heatingEnergyConsumption = 0;
                    } else {
                        energyCertificate.heatingEnergyConsumption = Number(event.target.value);
                    }
                }

                break;
            case 'heatingEnergyDemand':
                if (event.target.value === '') {
                    energyCertificate.heatingEnergyDemand = null;
                } else {
                    if (Number(event.target.value) < 0) {
                        energyCertificate.heatingEnergyDemand = 0;
                    } else {
                        energyCertificate.heatingEnergyDemand = Number(event.target.value);
                    }
                }

                break;
            case 'otherInformation':
                if (event.target.value === '') {
                    energyCertificate.otherInformation = null;
                } else {
                    energyCertificate.otherInformation = event.target.value;
                }
                break;
            default:
                (energyCertificate as any)[event.target.name] = event.target.value;

                break;
        }

        updateFormData();

        validateField(event.target.name);
    };

    const handleEnergyCertificateTypeChange = (selectedValue: SelectOption<EnergyCertificateType> | null): void => {
        if (selectedValue === null) {
            return;
        }

        energyCertificate.energyCertificateType = selectedValue.value;

        if (selectedValue.value !== EnergyCertificateType.EnergyRequirementCertificate) {
            energyCertificate.energyDemand = null;
            energyCertificate.electricityDemand = null;
            energyCertificate.heatingEnergyDemand = null;
        }

        if (selectedValue.value !== EnergyCertificateType.EnergyConsumptionCertificate) {
            energyCertificate.energyConsumption = null;
            energyCertificate.electricityConsumption = null;
            energyCertificate.heatingEnergyConsumption = null;
        }

        updateFormData();
        validateField('energyCertificateType');
    };

    const handleEnergyEfficiencyClassChange = (selectedValue: SelectOption<EnergyEfficiencyClass | null> | null): void => {
        if (selectedValue === null) {
            return;
        }

        energyCertificate.energyEfficiencyClass = selectedValue.value;

        updateFormData();
        validateField('energyEfficiencyClass');
    };

    const handleIssueDateChange = (date: Date | null): void => {
        energyCertificate.issueDate = date;

        updateFormData();
    };

    const handleExpirationDateChange = (date: Date | null): void => {
        energyCertificate.expirationDate = date;

        updateFormData();
    };

    const handleWithHotWaterChange = (selectedValue: SelectOption<boolean> | null): void => {
        if (selectedValue === null) {
            return;
        }

        energyCertificate.withHotWater = selectedValue.value;

        updateFormData();
        validateField('energyEfficiencyClass');
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: energyCertificate});
    };

    const validateField = (fieldName: string): void => {
        if (props.formData.formValidationHandler === undefined) {
            return;
        }

        props.formData.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <>
            <div className="row">
                <div className="col-12 col-lg-4 mb-3">
                    <SelectField
                        name="energyCertificateType"
                        label="Art des Energieausweises"
                        required={true}
                        options={energyCertificateTypeSelectOptions}
                        value={fetchEnergyCertificateTypeSelectedOption()}
                        placeholder="Bitte wählen"
                        onChange={handleEnergyCertificateTypeChange}
                        description="Bitte wähle hier, welche Art von Energieausweis für Deine Fläche vorliegt."
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'energyCertificateType')}
                    />
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <DatePickerField
                        name="issueDate"
                        label="Ausstelldatum"
                        description="Wann wurde der Energieausweis augestellt?"
                        value={energyCertificate.issueDate}
                        isClearable={true}
                        onChange={handleIssueDateChange}
                    />
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <DatePickerField
                        name="expirationDate"
                        label="Gültig bis"
                        description="Bis wann genau ist der Energieausweis gültig?"
                        value={energyCertificate.expirationDate}
                        isClearable={true}
                        onChange={handleExpirationDateChange}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 mb-3">
                    <InputField
                        name="buildingConstructionYear"
                        label="Baujahr"
                        description="Welches Baujahr ist auf dem Energieausweis angegeben?"
                        type="number"
                        required={false}
                        value={energyCertificate.buildingConstructionYear ?? undefined}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'buildingConstructionYear')}
                    />
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <SelectField
                        name="energyEfficiencyClass"
                        label="Energieeffizienzklasse"
                        required={false}
                        options={energyEfficiencyClassSelectOptions}
                        value={fetchEnergyEfficiencyClassSelectedOption()}
                        placeholder="Bitte wählen"
                        onChange={handleEnergyEfficiencyClassChange}
                        description="Bitte wähle aus, welche Energieeffizienzklasse Dein Objekt laut Energieausweis aufweist."
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    Befeuerungsart
                    <Tooltip
                        title="Wähle die im Energieausweis eingetragenen wesentlichen Energieträger (Befeuerungsart) der Heizung."
                        icon={<i className="bi bi-info-circle"></i>}
                        className="text-info ms-1"
                    />
                </div>
            </div>
            <div className="row mb-3">
                {energyCertificateHeatingTypes.map((energyCertificateHeatingType: EnergyCertificateHeatingType): React.JSX.Element => (
                    <div key={'energyCertificateHeatingTypeCheckbox-' + energyCertificateHeatingType} className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
                        <CheckboxField
                            checked={energyCertificate.energyCertificateHeatingTypes.includes(energyCertificateHeatingType)}
                            name={'energyCertificateHeatingTypes' + energyCertificateHeatingType}
                            label={getEnergyCertificateHeatingTypeLabel(energyCertificateHeatingType)}
                            onChange={(): void => toggleEnergyCertificateHeatingType(energyCertificateHeatingType)}
                        />
                    </div>
                ))}
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
                    {energyCertificate.energyCertificateType === EnergyCertificateType.EnergyConsumptionCertificate &&
                        <InputField
                            name="energyConsumption"
                            label="Energieverbrauchkennwert"
                            description="Bitte trage hier den Energieverbrauchkennwert laut Energieausweis ein."
                            type="number"
                            suffix="kWh/m²/Jahr"
                            required={true}
                            min={0}
                            value={energyCertificate.energyConsumption ?? undefined}
                            onChange={handleChange}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'energyConsumption')}
                        />
                    }
                    {energyCertificate.energyCertificateType === EnergyCertificateType.EnergyRequirementCertificate &&
                        <InputField
                            name="energyDemand"
                            label="Endenergiebedarf"
                            description="Bitte trage hier den Endenergiebedarf laut Energieausweis ein."
                            type="number"
                            suffix="kWh/m²/Jahr"
                            min={0}
                            required={true}
                            value={energyCertificate.energyDemand ?? undefined}
                            onChange={handleChange}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'energyDemand')}
                        />
                    }
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
                    <SelectField
                        name="withHotWater"
                        label="inkl. Warmwasser"
                        required={false}
                        options={withHotWaterOptions}
                        value={fetchWithHotWaterSelectedOption()}
                        placeholder="Bitte wählen"
                        onChange={handleWithHotWaterChange}
                        description={((): string | undefined => {
                            if (energyCertificate.energyCertificateType === EnergyCertificateType.EnergyConsumptionCertificate) {
                                return 'Beinhaltet der Energieverbrauchkennwert Warmwasser?';
                            } else if (energyCertificate.energyCertificateType === EnergyCertificateType.EnergyRequirementCertificate) {
                                return 'Beinhaltet der Endenergiebedarf Warmwasser?';
                            } else {
                                return undefined;
                            }
                        })()}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'withHotWater')}
                    />
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
                    {energyCertificate.energyCertificateType === EnergyCertificateType.EnergyConsumptionCertificate &&
                        <InputField
                            name="electricityConsumption"
                            label="Stromverbrauchskennwert"
                            description="Bitte trage hier den Stromverbrauchskennwert laut Energieausweis ein."
                            type="number"
                            suffix="kWh/m²/Jahr"
                            min={0}
                            required={false}
                            value={energyCertificate.electricityConsumption ?? undefined}
                            onChange={handleChange}
                        />
                    }
                    {energyCertificate.energyCertificateType === EnergyCertificateType.EnergyRequirementCertificate &&
                        <InputField
                            name="electricityDemand"
                            label="Endenergiebedarf Strom"
                            description="Bitte trage hier den Endenergiebedarf Strom laut Energieausweis ein."
                            type="number"
                            suffix="kWh/m²/Jahr"
                            min={0}
                            required={false}
                            value={energyCertificate.electricityDemand ?? undefined}
                            onChange={handleChange}
                        />
                    }
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
                    {energyCertificate.energyCertificateType === EnergyCertificateType.EnergyConsumptionCertificate &&
                        <InputField
                            name="heatingEnergyConsumption"
                            label="Heizenergieverbrauchskennwert"
                            description="Bitte trage hier den Heizenergieverbrauchskennwert laut Energieausweis ein."
                            type="number"
                            suffix="kWh/m²/Jahr"
                            min={0}
                            required={false}
                            value={energyCertificate.heatingEnergyConsumption ?? undefined}
                            onChange={handleChange}
                        />
                    }
                    {energyCertificate.energyCertificateType === EnergyCertificateType.EnergyRequirementCertificate &&
                        <InputField
                            name="heatingEnergyDemand"
                            label="Endenergiebedarf Wärme"
                            description="Bitte trage hier den Endenergiebedarf Wärme laut Energieausweis ein."
                            type="number"
                            suffix="kWh/m²/Jahr"
                            min={0}
                            required={false}
                            value={energyCertificate.heatingEnergyDemand ?? undefined}
                            onChange={handleChange}
                        />
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    <TextAreaField
                        name="otherInformation"
                        label="Zusätzliche Information zum Energieausweis"
                        description="Gibt es zusätzliche Hinweise zum Energieausweis? Liegt dieser ggf. erst zur Besichtigung vor?"
                        required={false}
                        value={energyCertificate.otherInformation ?? undefined}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </>
    );
};

export default EnergyCertificateForm;
