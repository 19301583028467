import PropertyService from '../../Property/PropertyService';
import SettlementConceptService from '../../SettlementConcept/SettlementConceptService';
import ConversationService from '../ConversationService';
import ReferenceType from '../../Entity/Messaging/ReferenceType';
import Property from '../../Entity/Property/Property';
import SettlementConcept from '../../Entity/SettlementConcept/SettlementConcept';
import Conversation from '../../Entity/Messaging/Conversation';
import ProviderSeekerConversation from '../../Entity/Messaging/ProviderSeekerConversation';
import ConversationType from '../../Entity/Messaging/ConversationType';
import LeanConversation from '../../Entity/Messaging/LeanConversation';
import LeanProperty from '../../Entity/Messaging/LeanProperty';
import UserState from '../../Entity/User/UserState';
import MarketingStatus, {getMarketingStatusLabel} from '../../Entity/MarketingStatus';
import InformationScope from '../../Entity/InformationScope';
import PropertyDetailInformation from '../../Property/PropertyDetailInformation/PropertyDetailInformation';
import SettlementConceptDetailInformation from '../../SettlementConcept/SettlementConceptDetailInformation/SettlementConceptDetailInformation';
import SettlementConceptLockedInformation from '../../SettlementConcept/SettlementConceptLockedInformation';
import Card from '../../Component/Card/Card';
import Overlay from '../../Component/Overlay/Overlay';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import messagingStyle from '../Messaging.module.scss';
import conversationItemStyle from './ConversationItem.module.scss';
import {useAppSelector} from '../../../../app/hooks';
import React, {useEffect, useState} from 'react';

interface ConversationItemProps {
    readonly conversation: Conversation;
    readonly reference: string;
    readonly from: string;
    readonly referenceType: ReferenceType;
    readonly reloadConversation?: () => void;
}

const propertyService: PropertyService = new PropertyService(process.env.REACT_APP_LLASM_API_URL!);

const settlementConceptService: SettlementConceptService = new SettlementConceptService(process.env.REACT_APP_LLASM_API_URL!);

const conversationService: ConversationService = new ConversationService(process.env.REACT_APP_LLASM_API_URL!);

const ConversationItem = (props: ConversationItemProps): React.JSX.Element => {
    const {user}: UserState = useAppSelector<UserState>(state => state.user);

    const [showOverlay, setShowOverlay] = useState<boolean>(false);

    const [property, setProperty] = useState<Property>();

    const [settlementConcept, setSettlementConcept] = useState<SettlementConcept>();

    const [leanProperty, setLeanProperty] = useState<LeanProperty>();

    useEffect((): void => {
        setProperty(undefined);
        setSettlementConcept(undefined);

        if (props.referenceType === ReferenceType.Property && (props.conversation instanceof ProviderSeekerConversation)) {
            fetchProviderSeekerProperty();
        }

        fetchSettlementConcept();

        setLeanProperty(undefined);
    }, [props.conversation]);

    useEffect((): void => {
        if (showOverlay === false) {
            return;
        }

        if (props.referenceType === ReferenceType.Property && leanProperty === undefined && (props.conversation instanceof LeanConversation)) {
            fetchLeanProperty();
        }
    }, [showOverlay]);

    const fetchProviderSeekerProperty = async (): Promise<void> => {
        setProperty(await propertyService.fetchPropertyFromApiById((props.conversation as ProviderSeekerConversation).property.id!));
    };

    const fetchSettlementConcept = async (): Promise<void> => {
        setSettlementConcept(await settlementConceptService.fetchSettlementConceptFromApiById((props.conversation as ProviderSeekerConversation).settlementConcept.id));
    };

    const fetchLeanProperty = async (): Promise<void> => {
        const leanProperty: LeanProperty = (props.conversation as LeanConversation).leanProperty;

        leanProperty.leanPropertyExpose = await conversationService.fetchLeanPropertyExposeFromApi(props.conversation as LeanConversation);

        setLeanProperty(leanProperty);
    };

    const isSafari = (): boolean => {
        // @ts-ignore
        if (window.safari !== undefined) {
            return true;
        }

        // @ts-ignore
        if (window.ApplePaySetupFeature !== undefined) {
            return true;
        }

        return false;
    };

    const buildPropertyMarketingStatusClassName = (): string => {
        if (property === undefined) {
            return '';
        }

        let className: string = conversationItemStyle.marketingStatus;

        switch (property.marketingStatus) {
            case MarketingStatus.Available:
                return className + ' text-primary';
            case MarketingStatus.Reserved:
                return className + ' text-secondary';
            case MarketingStatus.Allocated:
                return className + ' text-danger';
        }
    };

    const reloadConversation = (): void => {
        if (props.reloadConversation === undefined) {
            return;
        }

        if (props.conversation instanceof ProviderSeekerConversation === false) {
            return;
        }

        if (props.referenceType !== ReferenceType.Property) {
            return;
        }

        if (settlementConcept === undefined || settlementConcept.owner === false) {
            return;
        }

        props.reloadConversation();
    };

    const buildOverlayTitle = (): React.JSX.Element | string => {
        if (props.referenceType === ReferenceType.Property && property !== undefined) {
            return (
                <>
                    <div>
                        {property.title}
                    </div>
                    {property.informationScope === InformationScope.Complete && property.internalDesignation !== null &&
                        <div className="text-black fs-5">
                            ({property.internalDesignation})
                        </div>
                    }
                </>
            );
        }

        return props.reference;
    };

    return (
        <>
            <Card cardType="shadow" className={['d-flex', 'align-items-center', (props.conversation.firstReferenceType === props.referenceType) ? conversationItemStyle.recipientBackground : conversationItemStyle.senderBackground].join(' ')}>
                <div className="px-3 py-2 flex-grow-1">
                    {/* TODO: Shorten settlementConcept title if longer then 35 chars */}
                    <div className="mb-1">{props.reference}</div>
                    <div className="row">
                        <div className="col">
                            <div className={['mb-0 ', conversationItemStyle.sender].join(' ')}>
                                Von: {props.from}
                                {(props.referenceType === ReferenceType.Property && leanProperty === undefined && props.conversation instanceof LeanConversation) &&
                                    <>
                                        <a className="ms-1 cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="bi bi-question-circle"></i>
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-start maxw-400px">
                                            <li className="p-3">
                                                <small>
                                                    Die Kommunikation läuft über unseren Partner <a href="https://www.le-an.de" target="_blank">LeAn®</a>.
                                                    Die Übermittlung erfolgt datenschutzkonform gemäß unserer <a href="/datenschutz" target="_blank">Datenschutzerklärung</a>.
                                                </small>
                                            </li>
                                        </ul>
                                    </>
                                }
                            </div>
                        </div>
                        {property !== undefined &&
                            <div className={['col', 'd-flex', 'justify-content-end', buildPropertyMarketingStatusClassName()].join(' ')}>
                                {getMarketingStatusLabel(property.marketingStatus)}
                            </div>
                        }
                    </div>
                </div>
                <div className="px-2 py-2">
                    <button className="btn btn-link fs-4 float-end" onClick={(): void => setShowOverlay(true)}>
                        <i className="bi bi-eye-fill"></i>
                    </button>
                </div>
            </Card>
            <Overlay title={buildOverlayTitle()} show={showOverlay} setShow={setShowOverlay} onClose={reloadConversation}>
                <div className="container">
                    {props.referenceType === ReferenceType.Property &&
                        <>
                            {props.conversation.conversationType === ConversationType.Lean &&
                                <>
                                    {leanProperty === undefined &&
                                        <div className={messagingStyle.loadingIndicatorContainer}>
                                            <LoadingIndicator />
                                        </div>
                                    }
                                    {(leanProperty !== undefined && leanProperty.leanPropertyExpose !== null) &&
                                        <div className="vh-100">
                                            <p>
                                                Das untenstehende Objekt-Exposé wird über unseren Partner LeAn® – die Leerstands- & Ansiedlungsmanagementplattform für Kommunen – bereitgestellt.
                                                <span> <a href="https://www.le-an.de" target="_blank">Mehr erfahren »</a></span>
                                            </p>
                                            {isSafari() === true ? (
                                                <iframe
                                                    className="w-100 h-75"
                                                    src={'data:application/pdf;base64,' + leanProperty.leanPropertyExpose!.fileContentAsBase64}>
                                                </iframe>
                                            ) : (
                                                <object
                                                    type="application/pdf"
                                                    data={'data:application/pdf;base64,' + leanProperty.leanPropertyExpose!.fileContentAsBase64}
                                                    className="w-100 h-75"
                                                >
                                                </object>
                                            )}
                                        </div>
                                    }
                                </>
                            }

                            {props.conversation.conversationType === ConversationType.ProviderSeeker &&
                                <>
                                    {(property === undefined || settlementConcept === undefined) &&
                                        <div className={messagingStyle.loadingIndicatorContainer}>
                                            <LoadingIndicator />
                                        </div>
                                    }
                                    {property !== undefined && settlementConcept !== undefined &&
                                        <PropertyDetailInformation property={property} settlementConcept={settlementConcept} />
                                    }
                                </>
                            }
                        </>
                    }

                    {props.referenceType === ReferenceType.SettlementConcept &&
                        <>
                            {(settlementConcept === undefined || user === undefined) &&
                                <div className={messagingStyle.loadingIndicatorContainer}>
                                    <LoadingIndicator />
                                </div>
                            }
                            {settlementConcept !== undefined && user !== undefined &&
                                <>
                                    {(settlementConcept.informationScope === InformationScope.Complete || settlementConcept.informationScope === InformationScope.Unlocked) ? (
                                        <SettlementConceptDetailInformation settlementConcept={settlementConcept} />
                                    ) : (
                                        <SettlementConceptLockedInformation settlementConcept={settlementConcept} />
                                    )}
                                </>
                            }
                        </>
                    }
                </div>
            </Overlay>
        </>
    );
};

export default ConversationItem;
