import MessageRead from './MessageRead';
import ProviderSeekerMessageReadResponse from '../../../../api/Llasm/Messaging/ProviderSeekerMessageReadResponse';

class ProviderSeekerMessageRead extends MessageRead {
    public static createFromProviderSeekerMessageReadResponse(providerSeekerMessageReadResponse: ProviderSeekerMessageReadResponse): ProviderSeekerMessageRead {
        const providerSeekerMessageRead: ProviderSeekerMessageRead = new this();

        providerSeekerMessageRead._id = providerSeekerMessageReadResponse.id;
        providerSeekerMessageRead._createdAt = new Date(providerSeekerMessageReadResponse.createdAt);

        return providerSeekerMessageRead;
    }
}

export default ProviderSeekerMessageRead;
