import ImageEntity from '../Entity/Image/Image';
import Image from './Image';
import LoadingIndicator from '../../../components/LoadingIndicator';
import uploaderStyle from '../Component/Uploader/Uploader.module.scss';
import singleImageUploaderStyle from './SingleImageUploader.module.scss';
import BootstrapIcon from '../Component/Icon/BootstrapIcon';
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import Dropzone, {DropzoneFile, DropzoneOptions} from 'dropzone';

interface SingleImageUploaderProps {
    readonly token: string;
    readonly autoProcessQueue: boolean,
    readonly maxFileSize: number;
    readonly image: ImageEntity | null;
    readonly isProcessingImage: boolean;
    readonly setIsProcessingImages?: (isProcessingImages: boolean) => void;
    readonly onAddedFile?: (dropzoneFile: DropzoneFile) => void;
    readonly onRemovedFile?: (dropzoneFile: DropzoneFile) => void;
    readonly onQueueComplete?: () => void;
    readonly uploadUrl?: string;
    readonly uploadText?: string;
    readonly buildImageGetPath?: (image: ImageEntity) => string;
    readonly buildImageDeletePath?: (image: ImageEntity) => string;
    readonly onDeleted?: () => Promise<void>;
}

interface UploadError {
    readonly file: boolean;
    readonly upload: boolean;
}

interface ImperativeHandle {
    readonly uploadFiles: (uploadUrl: string) => void;
}

const SingleImageUploader = forwardRef((props: SingleImageUploaderProps, ref: React.ForwardedRef<any>): React.JSX.Element => {
    if (props.autoProcessQueue === true && props.uploadUrl === undefined) {
        throw new Error('If autoProcessQueue is true, please provide an uploadUrl.');
    }

    const dropzoneRef: React.MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

    const dropzoneConfig: DropzoneOptions = {
        url: props.uploadUrl ?? 'https://',
        method: 'post',
        paramName: 'image',
        headers: {
            'Authorization': 'Bearer ' + props.token
        },
        autoProcessQueue: props.autoProcessQueue,
        maxFilesize: props.maxFileSize,
        maxFiles: 1,
        acceptedFiles: 'image/jpeg, image/png',
        clickable: ['.dz-clickarea']
    };

    const [dropzone, setDropzone] = useState<Dropzone>();

    const [dropzoneFile, setDropzoneFile] = useState<DropzoneFile>();

    const [uploadError, setUploadError] = useState<UploadError>({
        file: false,
        upload: false
    });

    useImperativeHandle(ref, (): ImperativeHandle => ({
        uploadFiles(uploadUrl: string): void {
            if (dropzone === undefined) {
                return;
            }

            dropzone.on('processing', (dropzoneFile: DropzoneFile): void => {
                dropzone.options.url = uploadUrl;
            });

            dropzone.processQueue();
        }
    }));

    useEffect((): () => void => {
        const dropzone: Dropzone = new Dropzone(dropzoneRef.current!, dropzoneConfig);

        dropzone.on('addedfile', (addedFile: DropzoneFile): void => {
            setTimeout(
                (): void => {
                    setDropzoneFile(addedFile);
                },
                500
            );

            if (props.onAddedFile !== undefined) {
                props.onAddedFile(addedFile);
            }
        });

        dropzone.on('removedfile', (dropzoneFile: DropzoneFile): void => {
            if (props.onRemovedFile !== undefined) {
                props.onRemovedFile(dropzoneFile);
            }
        });

        dropzone.on('error', (dropzoneFile: DropzoneFile): void => {
            dropzone.removeFile(dropzoneFile);

            if (dropzoneFile.accepted === false) {
                setUploadError((prevState) => ({
                    ...prevState,
                    file: true,
                    upload: false
                }));
            } else {
                setUploadError((prevState) => ({
                    ...prevState,
                    file: false,
                    upload: true
                }));
            }
        });

        dropzone.on('sending', (dropzoneFiles: DropzoneFile[]): void => {
            if (props.setIsProcessingImages !== undefined) {
                props.setIsProcessingImages(true);
            }
        });

        dropzone.on('queuecomplete', (): void => {
            if (props.onQueueComplete !== undefined) {
                props.onQueueComplete();
            }

            dropzone.removeAllFiles();
        });

        setDropzone(dropzone);

        return (): void => {
            dropzone.destroy();
        };
    }, []);

    const removeDropzoneFile = (dropzoneFile: DropzoneFile): void => {
        if (dropzone === undefined) {
            return;
        }

        dropzone.removeFile(dropzoneFile);

        setDropzoneFile(undefined);
    };

    if (props.image !== null && props.buildImageGetPath === undefined) {
        throw new Error('buildImageGetPath is not defined.');
    }

    return (
        <div className={uploaderStyle.uploader}>
            {props.isProcessingImage === true &&
                <div className="uploaderLoadingIndicatorContainer">
                    <LoadingIndicator />
                </div>
            }

            <div className="row">
                <div className="col-12">
                    <div className={['d-flex', 'flex-column', 'justify-content-center', singleImageUploaderStyle.singleImageUploader].join(' ')}>
                        <div ref={dropzoneRef} className="dropzone mb-4 mb-xl-0">
                            <div className="dz-clickarea w-100 h-100">
                                <p className="text-center fs-3">
                                    {props.uploadText === undefined &&
                                        <span>Zieh hier schöne Bilder rein</span>
                                    }
                                    {props.uploadText !== undefined &&
                                        <span>{props.uploadText}</span>
                                    }
                                </p>
                                <p className="text-center"><small>(PNG oder JPEG, max. {props.maxFileSize}MB)</small></p>
                                {uploadError.file === true && <p className="text-center text-danger fs-6">Bitte nur ein Bild mit .jpeg oder .png Format hochladen, das maximal {props.maxFileSize}MB groß sind!</p>}
                                {uploadError.upload === true && <p className="text-center text-danger fs-6">Das Bild konnte nicht hochgeladen werden!</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {props.image !== null &&
                <div className="row mt-3">
                    <div className="col-12 col-md-6 position-relative">
                        <Image
                            image={props.image}
                            buildImageGetPath={props.buildImageGetPath!}
                            className="w-100"
                            style={{objectFit: 'cover'}}
                            setIsLoading={props.setIsProcessingImages}
                            buildImageDeletePath={props.buildImageDeletePath}
                            onDeleted={props.onDeleted}
                        />
                    </div>
                </div>
            }
            {(props.autoProcessQueue === false && dropzoneFile !== undefined) &&
                <div className="row mt-3">
                    <div className="col-12 col-md-6 position-relative">
                        <div className="position-relative">
                            <img
                                className="w-100"
                                src={dropzoneFile.dataURL}
                                alt={dropzoneFile.name}
                                style={{objectFit: 'cover', height: '250px'}}
                            />
                            <button
                                type="button"
                                className="btn btn-lg btn-danger pt-1 pb-1 ps-2 pe-2 mt-3 me-3 position-absolute top-0 end-0"
                                onClick={(): void => removeDropzoneFile(dropzoneFile as DropzoneFile)}
                            >
                                <BootstrapIcon iconName="trash-fill" />
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
});

export default SingleImageUploader;
