import User from '../Entity/User/User';

class PaymentService {
    public constructor(apiUrl: string) {
    }

    public fetchTaxRate(user: User): number {
        return 19;
    }

    public canDoPayment(user: User): boolean {
        return this.canDoOnlinePayment(user) === true || this.canDoBankTransferPayment(user) === true;
    }

    public canDoOnlinePayment(user: User): boolean {
        return user.isFromGermany() === true;
    }

    public canDoBankTransferPayment(user: User): boolean {
        return user.isFromGermany() === true || (user.isFromEuropeanUnionMember() === true && user.isCommercial() === true);
    }
}

export default PaymentService;
