import Contact from '../Entity/Contact/Contact';
import LabelValuePair from '../../../components/LabelValuePair';
import React from 'react';

interface ContactDetailInformationProps {
    readonly contact: Contact;
}

const ContactDetailInformation = (props: ContactDetailInformationProps): React.JSX.Element => {
    return (
        <>
            <div className="row">
                {(props.contact.companyName !== null && props.contact.companyName !== '') &&
                    <div className="col-12 mb-3">
                        <LabelValuePair label="Unternehmen" value={props.contact.companyName} />
                    </div>
                }
                {props.contact.firstName !== '' &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Vorname" value={props.contact.firstName} />
                    </div>
                }
                {props.contact.name !== '' &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Name" value={props.contact.name} />
                    </div>
                }
                {(props.contact.position !== null && props.contact.position !== '') &&
                    <div className="col-12  col-md-6  col-lg-4 mb-3">
                        <LabelValuePair label="Position" value={props.contact.position} />
                    </div>
                }
            </div>
            <div className="row">
                {(props.contact.phoneNumber !== null && props.contact.phoneNumber !== '') &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Telefonnr." value={props.contact.phoneNumber} />
                    </div>
                }
                {(props.contact.mobilePhoneNumber !== null && props.contact.mobilePhoneNumber !== '') &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Mobilnr." value={props.contact.mobilePhoneNumber} />
                    </div>
                }
                {props.contact.email !== '' &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair
                            label="E-Mail"
                            value={
                                <a href={'mailto:' + props.contact.email} className="text-secondary">
                                    {props.contact.email}
                                </a>
                            }
                        />
                    </div>
                }
            </div>
            <div className="row">
                {(props.contact.streetName !== null && props.contact.streetName !== '') &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Straße, Hausnr." value={props.contact.streetName + (props.contact.houseNumber !== null ? ' ' + props.contact.houseNumber : '')} />
                    </div>
                }
                {(props.contact.postalCode !== null && props.contact.postalCode !== '' && props.contact.placeName !== null && props.contact.placeName !== '') &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="PLZ, Ort" value={props.contact.postalCode + ' ' + props.contact.placeName} />
                    </div>
                }
                {(props.contact.website !== null && props.contact.website !== '') &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair
                            label="Homepage"
                            value={
                                <a href={props.contact.website} target="_blank" rel="noreferrer" className="text-secondary">
                                    Zur Webseite
                                </a>
                            }
                        />
                    </div>
                }
            </div>
        </>
    );
};

export default ContactDetailInformation;
