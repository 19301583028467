enum Currency {
    EURO = 0,
    DOLLAR = 1
}

const getCurrencyIsoCode = (currency: Currency): string => {
    switch (currency) {
      case Currency.EURO:
          return 'EUR';
      case Currency.DOLLAR:
          return 'USD'
      default:
          throw new Error();
    }
};

const getCurrencySymbol = (currency: Currency): string => {
    switch (currency) {
        case Currency.EURO:
            return '€';
        case Currency.DOLLAR:
            return '$'
        default:
            throw new Error();
    }
};

export default Currency;
export {getCurrencyIsoCode, getCurrencySymbol};
