import MarketplaceLot from '../../../Entity/Marketplace/MarketplaceLot';
import User from '../../../Entity/User/User';
import Alert from '../../../Entity/Alert/Alert';
import OccupiedMarketplaceLotCard from './OccupiedMarketplaceLotCard';
import FreeMarketplaceLotCard from './FreeMarketplaceLotCard';
import React from 'react';

interface MarketplaceLotCardProps {
    readonly marketplaceLot: MarketplaceLot;
    readonly user: User;
    readonly onAction: (alert: Alert) => void;
}

const MarketplaceLotCard = (props: MarketplaceLotCardProps): React.JSX.Element => {
    if (props.marketplaceLot.marketplaceAd !== null) {
        return <OccupiedMarketplaceLotCard marketplaceLot={props.marketplaceLot} onAction={props.onAction} />;
    } else {
        return <FreeMarketplaceLotCard marketplaceLot={props.marketplaceLot} user={props.user} onAction={props.onAction} />;
    }
};

export default MarketplaceLotCard;
