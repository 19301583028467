import FormData from '../Entity/Form/FormData';
import FieldValidationDefinition from './FieldValidationDefinition';

class NumericValidationDefinition<T> extends FieldValidationDefinition<T> {
    constructor(fieldName: string, message: string | null = null) {
        super(fieldName, message);
    }

    public check(formData: FormData<T>): boolean {
        const formDataValue: any = (formData.data as any)[this._fieldName];

        if (formDataValue === undefined) {
            return false;
        }

        return isNaN(Number(formDataValue)) === false;
    }
}

export default NumericValidationDefinition;
