import RentalDetail from '../Entity/Property/OfferDetail/RentalDetail';
import FormData from '../Entity/Form/FormData';
import SpecialObjectCategory from '../Entity/Property/SpecialObjectCategory';
import PropertyAcquisitionType from '../Entity/PropertyAcquisitionType';
import InputField from '../Component/Form/Field/InputField';
import TextAreaField from '../Component/Form/Field/TextAreaField';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import React, {useEffect} from 'react';

interface RentalDetailFormProps {
    readonly formData: FormData<RentalDetail>;
    readonly setFormData: (formData: FormData<RentalDetail> | ((prevState: FormData<RentalDetail>) => FormData<RentalDetail>)) => void;
    readonly validationDefinitions: FieldValidationDefinition<RentalDetail>[];
    readonly specialObjectCategories: SpecialObjectCategory[];
    readonly propertyAcquisitionTypes: PropertyAcquisitionType[];
}

const RentalDetailForm = (props: RentalDetailFormProps): React.JSX.Element => {
    const rentalDetail: RentalDetail = props.formData.data;

    useEffect((): void => {
        props.setFormData((prevState: FormData<RentalDetail>): FormData<RentalDetail> => ({...prevState, formValidationHandler: new FormValidationHandler<RentalDetail>(props.validationDefinitions)}));
    }, [props.validationDefinitions]);

    useEffect((): void => {
        if (
            props.specialObjectCategories.includes(SpecialObjectCategory.PopUpArea) === false
            && props.specialObjectCategories.includes(SpecialObjectCategory.CoWorkingSpace) === false
        ) {
            rentalDetail.dailyRent = null;
        }

        updateFormData();
    }, [props.specialObjectCategories.length]);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
        switch (event.target.name) {
            case 'netRentalPrice':
                if (event.target.value === '') {
                    rentalDetail.netRentalPrice = null;
                } else {
                    rentalDetail.netRentalPrice = Number(event.target.value);
                }

                break;
            case 'commissionAmount':
                if (event.target.value === '') {
                    rentalDetail.commissionAmount = null;
                } else {
                    rentalDetail.commissionAmount = event.target.value;
                }

                break;
            case 'deposit':
                if (event.target.value === '') {
                    rentalDetail.deposit = null;
                } else {
                    rentalDetail.deposit = event.target.value;
                }

                break;
            case 'dailyRent':
                if (event.target.value === '') {
                    rentalDetail.dailyRent = null;
                } else {
                    rentalDetail.dailyRent = Number(event.target.value);
                }

                break;
            case 'runningCosts':
                if (event.target.value === '') {
                    rentalDetail.runningCosts = null;
                } else {
                    rentalDetail.runningCosts = Number(event.target.value);
                }

                break;
            case 'commissionNote':
                if (event.target.value === '') {
                    rentalDetail.commissionNote = null;
                } else {
                    rentalDetail.commissionNote = event.target.value;
                }

                break;
            default:
                (rentalDetail as any)[event.target.name] = event.target.value;

                break;
        }

        updateFormData();
        validateField(event.target.name);
    };

    const updateFormData = (): void => {
        props.setFormData((prevState: FormData<RentalDetail>): FormData<RentalDetail> => ({...prevState, data: rentalDetail}));
    };

    const validateField = (fieldName: string): void => {
        if (props.formData.formValidationHandler === undefined) {
            return;
        }

        props.formData.formValidationHandler.validateField(fieldName, props.formData);
        props.setFormData((prevState: FormData<RentalDetail>): FormData<RentalDetail> => ({...prevState, errors: props.formData.errors}));
    };

    return (
        <>
            <div className="fw-bold mb-3">Mietinformationen (bzw. Pachtinformationen)</div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="mb-3">
                        <InputField
                            name="netRentalPrice"
                            label="Miete/Pacht (netto) / Monat"
                            type="number"
                            suffix="€"
                            min={0}
                            required={false}
                            value={rentalDetail.netRentalPrice ?? undefined}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <InputField
                            name="commissionAmount"
                            label="Provision bei Mietvertragsabschluss"
                            description="Sofern eine Provision für den Mieter anfällt, sollte diese hier angegeben werden. Bei provisionsfreien Angeboten kann das Feld leer bleiben."
                            type="text"
                            required={false}
                            value={rentalDetail.commissionAmount ?? undefined}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <InputField
                            name="deposit"
                            label="Kaution"
                            description="Muss der Mieter eine Kaution hinterlegen? Dann gib hier bitte die Höhe an."
                            type="text"
                            required={false}
                            value={rentalDetail.deposit ?? undefined}
                            onChange={handleChange}
                        />
                    </div>
                    {(props.specialObjectCategories.includes(SpecialObjectCategory.PopUpArea) === true || props.specialObjectCategories.includes(SpecialObjectCategory.CoWorkingSpace) === true) &&
                        <div className="mb-3">
                            <InputField
                                name="dailyRent"
                                label="Tagesmiete (netto)"
                                description="Sofern Deine Fläche auch Tagesweise gemietet werden kann, hast Du die Möglichkeit, hier die Tagesmiete anzugeben."
                                type="number"
                                suffix="€"
                                min={0}
                                required={false}
                                value={rentalDetail.dailyRent ?? undefined}
                                onChange={handleChange}
                            />
                        </div>
                    }
                </div>
                <div className="col-12 col-md-6">
                    <div className="mb-3">
                        <InputField
                            name="runningCosts"
                            label="Nebenkosten inkl. Heizkosten / Monat"
                            description="Hier kannst Du optional die Höhe der Nebenkosten pro Monat eingeben."
                            type="number"
                            suffix="€"
                            min={0}
                            required={false}
                            value={rentalDetail.runningCosts ?? undefined}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <TextAreaField
                            style={{gridRowEnd: 'span 5', alignSelf: 'baseline'}}
                            name="commissionNote"
                            label="Provision (Erläuterung)"
                            rows={4}
                            required={false}
                            value={rentalDetail.commissionNote ?? undefined}
                            description="Hier kannst Du weitere Informationen (z.B. Art der Provision, Fälligkeit der Provision, Verweis auf die AGB, rechtliche Hinweise...) zur geltenden Provisionsvereinbarung geben."
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default RentalDetailForm;
