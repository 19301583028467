import FormData from '../Entity/Form/FormData';
import Brand from '../Entity/Brand/Brand';
import Image from '../Entity/Image/Image';
import AgeStructure from '../Entity/AgeStructure';
import BranchingDegree from '../Entity/BranchingDegree';
import CompanyValue from '../Entity/CompanyValue';
import PriceSegment from '../Entity/PriceSegment';
import TargetGroup from '../Entity/TargetGroup';
import ImageEntity from '../Entity/Image/Image';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import InputField from '../Component/Form/Field/InputField';
import AgeStructureSelectField from '../AgeStructureSelectField';
import BranchingDegreeSelectField from '../BranchingDegreeSelectField';
import PriceSegmentSelectField from '../PriceSegmentSelectField';
import TargetGroupSelectField from '../TargetGroupSelectField';
import SingleImageUploader from '../Image/SingleImageUploader';
import React from 'react';
import {DropzoneFile} from 'dropzone';

interface BrandFormProps {
    readonly formData: FormData<Brand>;
    readonly setFormData: (formData: FormData<Brand>) => void;
    readonly logoUploaderRef: React.Ref<any>;
    readonly token: string;
    readonly autoProcessQueue: boolean;
    readonly maxFileSize: number;
    readonly logo: Image | null;
    readonly onAddedFile: (dropzoneFile: DropzoneFile) => void;
    readonly onRemovedFile: (dropzoneFile: DropzoneFile) => void;
    readonly onQueueComplete?: () => void;
    readonly isProcessingLogo: boolean;
    readonly setIsProcessingLogo?: (isProcessingImages: boolean) => void;
    readonly uploadUrl?: string;
    readonly buildLogoGetPath?: (image: ImageEntity) => string;
    readonly buildLogoDeletePath?: (image: ImageEntity) => string;
    readonly onLogoDeleted?: () => Promise<void>;
    readonly formValidationHandler?: FormValidationHandler<Brand>;
}

const BrandForm = (props: BrandFormProps): React.JSX.Element => {
    const brand: Brand = props.formData.data;

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        (brand as any)[event.target.name] = event.target.value;

        updateFormData();
        validateField(event.target.name);
    };

    const handleAgeStructureChange = (ageStructures: AgeStructure[]): void => {
        brand.ageStructures = ageStructures;

        updateFormData();
        validateField('ageStructures');
    };

    const handleTargetGroupChange = (targetGroups: TargetGroup[]): void => {
        brand.targetGroups = targetGroups;

        updateFormData();
        validateField('targetGroups');
    };

    const handlePriceSegmentChange = (priceSegments: PriceSegment[]): void => {
        brand.priceSegments = priceSegments;

        updateFormData();
        validateField('priceSegments');
    };

    const handleCompanyValuesChange = (companyValues: CompanyValue[]): void => {
        brand.companyValues = companyValues;

        updateFormData();
        validateField('companyValues');
    };

    const handleBranchingDegreeChange = (branchingDegree: BranchingDegree): void => {
        brand.branchingDegree = branchingDegree;

        updateFormData();
        validateField('branchingDegree');
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: brand});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <div className="row mb-3">
            <div className="mb-3 col-md-6">
                <InputField
                    name="brandName"
                    label="Markenname"
                    type="text"
                    required={true}
                    description="Wie lautet Dein Markenname oder Dein Geschäftsname?"
                    value={brand.brandName}
                    onChange={handleChange}
                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'brandName')}
                />
                <div className="mt-4">
                    <SingleImageUploader
                        ref={props.logoUploaderRef}
                        token={props.token}
                        autoProcessQueue={props.autoProcessQueue}
                        maxFileSize={props.maxFileSize}
                        image={props.logo}
                        isProcessingImage={props.isProcessingLogo}
                        setIsProcessingImages={props.setIsProcessingLogo}
                        onAddedFile={props.onAddedFile}
                        onRemovedFile={props.onRemovedFile}
                        onQueueComplete={props.onQueueComplete}
                        uploadUrl={props.uploadUrl}
                        buildImageGetPath={props.buildLogoGetPath}
                        buildImageDeletePath={props.buildLogoDeletePath}
                        onDeleted={props.onLogoDeleted}
                        uploadText="Zieh hier Dein Markenlogo rein"
                    />
                </div>
            </div>
            <div className="mb-3 col-md-6">
                <div className="mb-3">
                    <AgeStructureSelectField
                        label="Angesprochene Altersgruppe"
                        title="Dein Konzept ist auf eine bestimmte Zielgruppe ausgelegt? Hier kannst Du das vorrangig angesprochene Alterssegment angeben."
                        name="ageStructures"
                        classNames={(FormValidationHandler.hasFieldError(props.formData, 'ageStructures') === true ? 'is-invalid form-control' : '') + ' inset-shadow'}
                        isMulti={true}
                        isClearable={true}
                        isRequired={true}
                        defaultValue={props.formData.data.ageStructures}
                        onChange={handleAgeStructureChange}
                    />
                </div>
                <div className="mb-3">
                    <TargetGroupSelectField
                        label="Angesprochene Zielgruppe"
                        title="Welche Zielgruppe spricht Dein Konzept vorwiegend an? (Bezugsrahmen: Geschlechter der Kunden)."
                        name="targetGroups"
                        classNames={(FormValidationHandler.hasFieldError(props.formData, 'targetGroups') === true ? 'is-invalid form-control' : '') + ' inset-shadow'}
                        isMulti={true}
                        isClearable={true}
                        isRequired={true}
                        defaultValue={props.formData.data.targetGroups}
                        onChange={handleTargetGroupChange}
                    />
                </div>
                <div className="mb-3">
                    <PriceSegmentSelectField
                        label="Preissegmente"
                        title="In welche Preissegmente würdest Du Deine Produkten und/oder Dienstleistungen einordnen?"
                        name="priceSegments"
                        classNames={(FormValidationHandler.hasFieldError(props.formData, 'priceSegments') === true ? 'is-invalid form-control' : '') + ' inset-shadow'}
                        isMulti={true}
                        isClearable={true}
                        isRequired={true}
                        defaultValue={props.formData.data.priceSegments}
                        onChange={handlePriceSegmentChange}
                    />
                </div>
                {/* TODO: To be added later, requires a process for approval by an administrator
                <div className="mb-3">
                    <CompanyValueSelectField
                        label="Unternehmenswerte"
                        title="Was macht Dein Suchprofil aus? Bist Du innovativ? Bist Du nachhaltig? Oder bist Du doch eher serviceorientiert?"
                        name="companyValues"
                        classNames={(FormValidationHandler.hasFieldError(props.formData, 'companyValues') === true ? 'is-invalid form-control' : '') + ' inset-shadow'}
                        isMulti={true}
                        isClearable={true}
                        isRequired={true}
                        defaultValue={props.formData.data.companyValues}
                        onChange={handleCompanyValuesChange}
                    />
                </div>
                */}
                <div className="mb-3">
                    <BranchingDegreeSelectField
                        label="Filialisierungsgrad"
                        title="Wo und wie viele Standorte hast Du/Dein Geschäft bereits?"
                        name="branchingDegree"
                        classNames={(FormValidationHandler.hasFieldError(props.formData, 'branchingDegree') === true ? 'is-invalid form-control' : '') + ' inset-shadow'}
                        isMulti={false}
                        isClearable={false}
                        isRequired={true}
                        defaultValue={props.formData.data.branchingDegree}
                        onChange={handleBranchingDegreeChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default BrandForm;
