import LocationService from '../Location/LocationService';
import PlaceCriteria from '../Entity/SettlementConcept/PlaceCriteria';
import AutocompletePlace from '../Entity/Location/AutocompletePlace';
import FormData from '../Entity/Form/FormData';
import Place from '../Entity/Location/Place';
import SelectOption from '../Entity/Form/SelectOption';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import InputField from '../Component/Form/Field/InputField';
import SwitchField from '../Component/Form/Field/SwitchField';
import AsyncMultiSelectField from '../Component/Form/Field/AsyncMultiSelectField';
import React, {useEffect, useState} from 'react';

interface PlaceCriteriaFormProps {
    readonly formData: FormData<PlaceCriteria>;
    readonly setFormData: (formData: FormData<PlaceCriteria>) => void;
}

const locationService: LocationService = new LocationService(process.env.REACT_APP_LLASM_API_URL!);

const PlaceCriteriaForm = (props: PlaceCriteriaFormProps): React.JSX.Element => {
    const placeCriteria: PlaceCriteria = props.formData.data;

    const [stateSelectOptions, setStateSelectOptions] = useState<SelectOption<Place>[]>();

    useEffect((): void => {
        fetchStateSelectOptions();
    }, []);

    useEffect((): void => {
        if (props.formData.formValidationHandler === undefined) {
            return;
        }

        if (props.formData.data.targetedCitySearch === false) {
            props.formData.formValidationHandler.addFieldValidationDefinition(new RequiredValidationDefinition<PlaceCriteria>('cityPopulationMinimum', 'Es muss eine Mindesteinwohnerzahl angegeben werden.'));
        } else {
            props.formData.formValidationHandler.removeFieldValidationDefinitionsByFieldName('cityPopulationMinimum');
        }
    }, [props.formData]);


    const fetchStateSelectOptions = async (): Promise<SelectOption<Place>[]> => {
        // TODO - only for old logic
        if (placeCriteria.countryPlace === undefined) {
            return [];
        }

        const selectOptions: SelectOption<Place>[] = (await (locationService.fetchStatesByCountryPlace(placeCriteria.countryPlace))).map((place: Place): SelectOption<Place> => {
            return {
                label: place.placeName,
                value: place
            };
        });

        setStateSelectOptions(selectOptions);

        return selectOptions;
    };

    const fetchCitySelectOptions = async (searchTerm: string): Promise<SelectOption<AutocompletePlace>[]> => {
        if (placeCriteria.expansionStates.length === 0) {
            return [];
        }

        if (searchTerm === '' || searchTerm.length < 3) {
            return [];
        }

        const expansionStateQueryParameter: string = placeCriteria.expansionStates.map((place: Place): string => {
            return place.uuid;
        }).join(',');

        return (await (locationService.fetchPlacesBySearchTerm(searchTerm, expansionStateQueryParameter))).map((autocompletePlace: AutocompletePlace): SelectOption<AutocompletePlace> => {
            return {
                label: autocompletePlace.autoCompleteDisplay,
                value: autocompletePlace
            };
        });
    };

    const fetchExpansionStateSelectedOptions = (): SelectOption<Place>[] => {
        if (stateSelectOptions === undefined) {
            return [];
        }

        return stateSelectOptions.filter((stateSelectOption: SelectOption<Place>): boolean => {
            const place: Place | undefined = placeCriteria.expansionStates.find((place: Place): boolean => {
                return place.id === stateSelectOption.value.id;
            });

            return place !== undefined;
        });
    };

    const fetchExpansionCitySelectedOptions = (): SelectOption<AutocompletePlace>[] => {
        return placeCriteria.expansionCities.map((place: Place): SelectOption<AutocompletePlace> => {
            const autocompletePlace: AutocompletePlace = new AutocompletePlace();

            autocompletePlace.autoCompleteDisplay = place.placeName;
            autocompletePlace.place = place;

            return {
                label: autocompletePlace.autoCompleteDisplay,
                value: autocompletePlace
            };
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
        switch (event.target.name) {
            case 'cityPopulationMinimum':
                if (event.target.value === '') {
                    placeCriteria.cityPopulationMinimum = null;
                } else {
                    placeCriteria.cityPopulationMinimum = Number(event.target.value);
                }

                break;
            case 'cityPopulationMaximum':
                if (event.target.value === '') {
                    placeCriteria.cityPopulationMaximum = null;
                } else {
                    placeCriteria.cityPopulationMaximum = Number(event.target.value);
                }

                break;
            default:
                (placeCriteria as any)[event.target.name] = event.target.value;

                break;
        }

        validateField(event.target.name);
        updateFormData();
    };

    const handleTargetedCitySearchSwitchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        placeCriteria.targetedCitySearch = event.target.checked;

        if (event.target.checked === true) {
            placeCriteria.cityPopulationMinimum = null;
            placeCriteria.cityPopulationMaximum = null;
        }

        updateFormData();
    };

    const handleExpansionStateSelectChange = (selectedExpansionStates: readonly SelectOption<Place>[]): void => {
        placeCriteria.expansionStates = selectedExpansionStates.map((stateSelectOption: SelectOption<Place>): Place => {
            return stateSelectOption.value;
        });

        validateField('expansionStates');
        updateFormData();
    };

    const handleExpansionCitySelectChange = (selectedExpansionCities: readonly SelectOption<AutocompletePlace>[]): void => {
        placeCriteria.expansionCities = selectedExpansionCities.map((citySelectOption: SelectOption<AutocompletePlace>): Place => {
            return citySelectOption.value.place;
        });

        if (placeCriteria.expansionCities.length === 0) {
            placeCriteria.targetedCitySearch = false;
        } else if (placeCriteria.expansionCities.length === 1) {
            placeCriteria.targetedCitySearch = true;
            placeCriteria.cityPopulationMinimum = null;
            placeCriteria.cityPopulationMaximum = null;
        }

        updateFormData();
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: placeCriteria});
    };

    const validateField = (fieldName: string): void => {
        if (props.formData.formValidationHandler === undefined) {
            return;
        }

        props.formData.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    const isTargetedCitySearchDisabled = (): boolean => {
        return placeCriteria.expansionCities.length === 0;
    };

    return (
        <>
            <div className="row mb-3">
                <div className="col-lg-4 mb-3">
                    <AsyncMultiSelectField
                        label="Expansionsgebiet"
                        name="expansionStates"
                        required={true}
                        description="Suchst Du bundesweit (Auswahl leer lassen), oder gezielt in einem oder mehreren Bundesländern nach neuen Standorten?"
                        loadOptions={fetchStateSelectOptions}
                        defaultOptions
                        closeMenuOnSelect={false}
                        placeholder="Bitte wählen"
                        options={stateSelectOptions}
                        onChange={handleExpansionStateSelectChange}
                        value={fetchExpansionStateSelectedOptions()}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'expansionStates')}
                    />
                </div>
                <div className="col-lg-4 mb-3">
                    <AsyncMultiSelectField
                        label="favorisierte Standorte"
                        name="expansionCities"
                        required={false}
                        description="Gibt es konkrete Städte, in die Du mit Deinem Unternehmen expandieren oder umziehen möchtest?"
                        loadOptions={fetchCitySelectOptions}
                        defaultOptions
                        closeMenuOnSelect={true}
                        placeholder="Orte suchen"
                        loadingMessage={(): string => 'Passende Orte werden gesucht...'}
                        onChange={handleExpansionCitySelectChange}
                        value={fetchExpansionCitySelectedOptions()}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'expansionCities')}
                    />
                </div>
                <div className="col-lg-4 mb-3 pt-lg-4">
                    <SwitchField
                        label="Suche nur in favorisierten Standorten"
                        description="Sofern Du eine oder mehrere Städte als favorisierte Standorte angegeben hast, hast Du hier die Möglichkeit auszuwählen. Möchtest Du nur in den favorisierten Standorten suchen? Oder möchtest Du darüber hinaus auch in dem von Dir ausgewählten Bundesland suchen?"
                        selected={placeCriteria.targetedCitySearch}
                        name="targetedCitySearch"
                        disabled={isTargetedCitySearchDisabled()}
                        onChange={handleTargetedCitySearchSwitchChange}
                    />
                </div>
            </div>
            {placeCriteria.targetedCitySearch === false &&
                <div className="row mb-3">
                    <div className="col-lg-6 mb-3">
                        <InputField
                            label="Einwohnerzahl von"
                            name="cityPopulationMinimum"
                            required={true}
                            type="number"
                            min={1}
                            value={placeCriteria.cityPopulationMinimum ?? undefined}
                            description="Wie viele Einwohner muss eine Stadt mindestens haben, in der Du ansiedeln willst?"
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'cityPopulationMinimum')}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-lg-6 mb-3">
                        <InputField
                            label="Einwohnerzahl bis"
                            name="cityPopulationMaximum"
                            required={false}
                            type="number"
                            min={0}
                            value={placeCriteria.cityPopulationMaximum ?? undefined}
                            description="Wie viele Einwohner darf eine Stadt maximal haben, in der Du ansiedeln willst?"
                            onChange={handleChange}
                        />
                    </div>
                </div>
            }
        </>
    );
};

export default PlaceCriteriaForm;
