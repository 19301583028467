import ContactResponse from '../../../../api/Llasm/Contact/ContactResponse';

class Contact {
    private _id?: number;

    private _email: string;

    private _naturalPerson: boolean;

    private _name: string;

    private _firstName: string;

    private _companyName: string | null = null;

    private _position: string | null = null;

    private _streetName: string | null = null;

    private _houseNumber: string | null = null;

    private _postalCode: string | null = null;

    private _placeName: string | null = null;

    private _countryName: string | null = null;

    private _mobilePhoneNumber: string | null = null;

    private _phoneNumber: string | null = null;

    private _faxNumber: string | null = null;

    private _website: string | null = null;

    private _areaOfResponsibility: string | null = null;

    public static createFromContactResponse(contactResponse: ContactResponse): Contact {
        const contact: Contact = new this();

        contact._id = contactResponse.id;
        contact._email = contactResponse.email;
        contact._naturalPerson = contactResponse.naturalPerson;
        contact._name = contactResponse.name;
        contact._firstName = contactResponse.firstName;
        contact._streetName = contactResponse.streetName;
        contact._houseNumber = contactResponse.houseNumber;
        contact._postalCode = contactResponse.postalCode;
        contact._placeName = contactResponse.placeName;
        contact._countryName = contactResponse.countryName;
        contact._mobilePhoneNumber = contactResponse.mobilePhoneNumber;
        contact._phoneNumber = contactResponse.phoneNumber;
        contact._faxNumber = contactResponse.faxNumber;
        contact._website = contactResponse.website;
        contact._companyName = contactResponse.companyName;
        contact._position = contactResponse.position;
        contact._areaOfResponsibility = contactResponse.areaOfResponsibility;

        return contact;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get naturalPerson(): boolean {
        return this._naturalPerson;
    }

    set naturalPerson(value: boolean) {
        this._naturalPerson = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get companyName(): string | null {
        return this._companyName;
    }

    set companyName(value: string | null) {
        this._companyName = value;
    }

    get position(): string | null {
        return this._position;
    }

    set position(value: string | null) {
        this._position = value;
    }

    get streetName(): string | null {
        return this._streetName;
    }

    set streetName(value: string | null) {
        this._streetName = value;
    }

    get houseNumber(): string | null {
        return this._houseNumber;
    }

    set houseNumber(value: string | null) {
        this._houseNumber = value;
    }

    get postalCode(): string | null {
        return this._postalCode;
    }

    set postalCode(value: string | null) {
        this._postalCode = value;
    }

    get placeName(): string | null {
        return this._placeName;
    }

    set placeName(value: string | null) {
        this._placeName = value;
    }

    get countryName(): string | null {
        return this._countryName;
    }

    set countryName(value: string | null) {
        this._countryName = value;
    }

    get mobilePhoneNumber(): string | null {
        return this._mobilePhoneNumber;
    }

    set mobilePhoneNumber(value: string | null) {
        this._mobilePhoneNumber = value;
    }

    get phoneNumber(): string | null {
        return this._phoneNumber;
    }

    set phoneNumber(value: string | null) {
        this._phoneNumber = value;
    }

    get faxNumber(): string | null {
        return this._faxNumber;
    }

    set faxNumber(value: string | null) {
        this._faxNumber = value;
    }

    get website(): string | null {
        return this._website;
    }

    set website(value: string | null) {
        this._website = value;
    }

    get areaOfResponsibility(): string | null {
        return this._areaOfResponsibility;
    }

    set areaOfResponsibility(value: string | null) {
        this._areaOfResponsibility = value;
    }

    public buildDisplayName(): string
    {
        let displayName: String = this._name;

        if (this._firstName !== null) {
            displayName = this._firstName + ' ' + displayName;
        }

        if (this._naturalPerson !== true && this._companyName !== null && this._companyName !== '') {
            return <string>displayName.concat(' (' + this._companyName + ')');
        }

        return <string>displayName;
    }
}

export default Contact;
