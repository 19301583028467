import ConversationService from './ConversationService';
import Conversation from '../Entity/Messaging/Conversation';
import ConversationListItem from './ConversationList/ConversationListItem';
import LoadingIndicator from '../../../components/LoadingIndicator';
import messagingStyle from './Messaging.module.scss';
import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

const conversationService: ConversationService = new ConversationService(process.env.REACT_APP_LLASM_API_URL!);

interface ConversationListProps {
    readonly conversations: Conversation[];
    readonly setConversations: (conversations: Conversation[]) => void;
    readonly activeConversation: Conversation | undefined;
    readonly setActiveConversation: (conversation: Conversation) => void;
}

const ConversationList = (props: ConversationListProps): React.JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect((): void => {
        fetchConversations();
    }, []);

    useEffect((): void => {
        if (props.activeConversation === undefined && props.conversations.length > 0) {
            props.setActiveConversation(props.conversations[0]);
        }
    }, [props.conversations]);

    const fetchConversations = async (): Promise<void> => {
        setIsLoading(true);

        try {
            const conversations: Conversation[] = await conversationService.fetchConversationsFromApi();

            conversationService.sortConversations(conversations);

            props.setConversations(conversations);
        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    const setActiveConversation = (conversation: Conversation): void => {
        searchParams.delete('conversation');

        setSearchParams(searchParams);

        props.setActiveConversation(conversation);
    };

    const isActive = (conversation: Conversation): boolean => {
        if (props.activeConversation === undefined) {
            return false;
        }

        return props.activeConversation.buildIdentifier() === conversation.buildIdentifier();
    };

    if (isLoading === true) {
        return (
            <div className={messagingStyle.loadingIndicatorContainer}>
                <LoadingIndicator />
            </div>
        );
    }

    return (
        <>
            {props.conversations.map((conversation: Conversation): React.JSX.Element => (
                <div key={'conversation-item-wrapper-' + conversation.buildIdentifier()} onClick={(): void => setActiveConversation(conversation)}>
                    <ConversationListItem conversation={conversation} active={isActive(conversation)} />
                </div>
            ))}
        </>
    );
};

export default ConversationList;
