import SortingDirection from './SortingDirection';

class SortingOption {
    private _sortingBy: string;

    private _sortingDirection: SortingDirection;

    private _label: string;

    private _bootstrapIconName: string | null = null;

    constructor(sortingBy: string, sortingDirection: SortingDirection, label: string, bootstrapIconName?: string) {
        this._sortingBy = sortingBy;
        this._sortingDirection = sortingDirection;
        this._label = label;

        if (bootstrapIconName !== undefined) {
            this._bootstrapIconName = bootstrapIconName;
        }
    }

    get sortingBy(): string {
        return this._sortingBy;
    }

    set sortingBy(value: string) {
        this._sortingBy = value;
    }

    get sortingDirection(): SortingDirection {
        return this._sortingDirection;
    }

    set sortingDirection(value: SortingDirection) {
        this._sortingDirection = value;
    }

    get label(): string {
        return this._label;
    }

    set label(value: string) {
        this._label = value;
    }

    get bootstrapIconName(): string | null {
        return this._bootstrapIconName;
    }

    set bootstrapIconName(value: string | null) {
        this._bootstrapIconName = value;
    }
}

export default SortingOption;
