import publicPageLayoutStyle from './PublicPageLayout.module.scss';
import React from 'react';

const GtcPage = (): React.JSX.Element => {
    return (
        <>
            <div className="row g-3 mt-1 mb-5">
                <div className="col-12 pt-1 text-center">
                    <a href="/" className="btn btn-outline-secondary mb-4">
                        <i className="bi bi-chevron-left"></i> ZUR STARTSEITE
                    </a>
                    <h1 className={['text-secondary', publicPageLayoutStyle.letterSpacing, 'mb-2'].join(' ')}>
                        Allgemeine Geschäftsbedingungen (AGB)
                    </h1>
                    <p className="text-end">
                        Stand: 18.09.2024
                    </p>
                    <div className="text-start">
                        <h2>1. Vorbemerkung</h2>
                        <p>
                            1.1 Für Zwecke dieser Nutzungsbedingungen gelten die nachfolgenden Begriffsdefinitionen:
                        </p>
                        <p>
                            (Aus Gründen der besseren Lesbarkeit wird im Folgenden weitgehend auf die gleichzeitige Verwendung weiblicher und männlicher oder ausschließlich
                            neutraler Sprachformen verzichtet und das generische Maskulinum verwendet. Sämtliche Personenbezeichnungen gelten gleichermaßen für alle geschlechtlichen Identitäten,
                            die mitgedacht werden.)
                        </p>
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <td>Plattform/Portal</td>
                                    <td>
                                        Die über das Internet unter https://lean-match.de erreichbare Plattform, auf der Kommunen, kommunale Wirtschaftsförderungen, kommunale Stadtmarketing- oder Entwicklungsgesellschaften Objekte einstellen können.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Portalbetreiber</td>
                                    <td>
                                        Die LLASM Technology GmbH als Betreiber der Plattform.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Immobilien</td>
                                    <td>
                                        Immobilien im Sinne dieser Vereinbarung sind Gebäude bzw. darin befindliche Räumlichkeiten zur gewerblichen Nutzung sowie Grundstücke, die für eine gewerbliche Nutzung vorgesehen sind.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Objekt</td>
                                    <td>
                                        Konkretes insgesamt angebotenes Gebäude, Grundstück oder konkreter Geschäftsraum in einer Immobilie.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Expansionsprofil / Suchprofil</td>
                                    <td>
                                        Die Beschreibung von Anforderungen an ein Objekt, die ein Konzeptbetreiber hat, um sein Gewerbe betreiben zu können.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Konzeptbetreiber</td>
                                    <td>
                                        Juristische oder natürliche Personen, die Expansions- bzw. Suchprofile für ihr Gewerbe in der Datenbank des Portalbetreibers eingestellt haben.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Matching / Matching-Algorithmus</td>
                                    <td>
                                        Ein Algorithmus der LLASM, der Immobilien und Expansionsprofile auf Basis von Gemeinsamkeiten und eines Punkte-Systems bewertet und Vorschläge zu Passungen macht.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Nutzende Person</td>
                                    <td>
                                        Mitarbeiter aus einer Kommune, kommunalen Wirtschaftsförderung, kommunalen Stadtmarketing- oder Entwicklungsgesellschaft, der die Plattform nutzt.
                                    </td>
                                </tr>
                                <tr>
                                    <td>CreditPoints</td>
                                    <td>
                                        Käuflich zu erwerbende virtuelle Währung, mit deren Hilfe optional unterschiedliche Premium-Funktionen genutzt werden können.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            1.2 Der Portalbetreiber bietet kommunalen Immobilienanbietenden die Möglichkeit, ein oder mehrere Objekte zu inserieren.
                        </p>
                        <p>
                            1.3 Das Einstellen von Immobilienangeboten erfolgt, nachdem die hier vorliegenden Nutzungsbedingungen akzeptiert wurden und nach einer Bereitstellung eines Zugangs in einem geschützten Bereich.
                        </p>
                        <h2 className="mt-3">
                            2. Geltung
                        </h2>
                        <p>
                            2.1 Diese Nutzungsbedingungen gelten nur gegenüber Körperschaften des öffentlichen Rechts, Anstalten und öffentlich-rechtlichen Stiftungen.
                        </p>
                        <p>
                            2.2 Eine Bereitstellung eines Accounts ist nur bei Akzeptanz dieser Nutzungsbedingungen möglich. Spätestens mit Vertragsschluss über die Nutzung von LeAn Match gelten diese Bedingungen als angenommen. Gegenbestätigungen der nutzenden Person unter Hinweis auf ihre Geschäfts- bzw. Einkaufsbedingungen wird hiermit widersprochen.
                        </p>
                        <h2 className="mt-3">
                            3. Teilnahmeberechtigung
                        </h2>
                        <p>
                            3.1 Zur Einstellung von Immobilienangeboten in den internen Bereich der Plattform ist jeder berechtigt, der eine eigene Immobilie anbieten möchte. Ebenfalls berechtigt sind Personen, die im Auftrag eines Eigentümers eine Immobilie vermitteln bzw. verwalten oder in seinem Auftrag potenzielle Nachnutzer suchen.
                        </p>
                        <p>
                            3.2 Der Portalbetreiber kann sowohl bei der Registrierung als auch zu einem späteren Zeitpunkt während des Nutzungsverhältnisses Nachweise für die Einhaltung der Bedingungen zur Teilnahmeberechtigung zur Prüfung der Berechtigung der Nutzung fordern.
                        </p>
                        <p>
                            3.3 Eine nutzende Person wird informiert, wenn ihr Zugang gesperrt bzw. abgelehnt wird oder wenn ihr Immobilienangebot nicht in das Portal aufgenommen werden kann.
                        </p>
                        <h2 className="mt-3">
                            4. Nutzung der Plattform, Richtigkeit und Aktualität von Daten
                        </h2>
                        <p>
                            4.1 Für die Nutzung der Plattform ist zunächst ein gültiger Vertrag erforderlich.
                        </p>
                        <p>
                            4.2 Die nutzende Person steht dafür ein, dass die von ihr getätigten Angaben wahr und vollständig sind. Er verpflichtet sich, alle künftigen Änderungen der Angaben zur Person, der Teilnahmeberechtigung unverzüglich dem Portalbetreiber mitzuteilen bzw. im Portal zu aktualisieren.
                        </p>
                        <h2 className="mt-3">
                            5. Eingabe von Angeboten
                        </h2>
                        <p>
                            5.1 Die Platzierung der Immobilienangebote erfolgt im internen Bereich, der durch eine beschränkte Zugriffsmöglichkeit gekennzeichnet ist. Auf diesen internen Bereich und damit auf die Informationen zu Immobilienangeboten haben zunächst nur der Portalbetreiber und ggf. von ihm zur Leistungserbringung eingesetzte Dritte (siehe Ziffer 8) Zugriff. Zudem hat ein Immobilienanbietender jeweils Zugriff auf die unter seiner Registrierung eingestellten Immobilienangebote. Nach einem erfolgreichen Matching kann das Immobilienangebot auch Konzeptbetreibern, deren Suchprofil zur Immobilie passt, zur Verfügung gestellt werden (siehe Ziffer 6).
                        </p>
                        <p>
                            5.2 Die Eingabe der Immobilienangebote erfolgt über eine entsprechende Eingabemaske, die über den internen Bereich im Portal nach einem Login mit den Registrierungsdaten erreichbar ist.
                        </p>
                        <p>
                            5.3 Zudem kann der Portalbetreiber vorsehen, über kompatible Schnittstellen Eingaben mittels branchenspezifischer Software zuzulassen.
                        </p>
                        <p>
                            5.4 Der Portalbetreiber ist frei in seiner Entscheidung zur Aufnahme, Aufrechterhaltung, Weiterentwicklung oder Erweiterung von entsprechenden Eingabeanbindungen. Der Immobilienanbietende hat keinen Anspruch darauf, dass die Nutzung über eine zugelassene Software dauerhaft ermöglicht wird oder dass bestimmte Standards zugelassen oder bestimmte Softwareprodukte angebunden werden.
                        </p>
                        <p>
                            5.5 Für jedes einzelne Immobilienangebot ist die Eingabe von wesentlichen Daten zu der Immobilie erforderlich, aus der sich insbesondere Lage, Nutzungsart, Flächen, Kontaktdaten, und Art der Nutzungsüberlassung ergeben. Zudem sind Angaben zum Status des Immobilienanbieters zu tätigen (z.B. Eigentümerverhältnis, Alleinvermarktungsauftrag/Leadmandat). Ein Immobilienanbietender kann nach unterschiedlichen gesetzlichen Vorgaben zur Erteilung bestimmter Informationen im Rahmen seiner Angebote verpflichtet sein (vgl. z.B. § 2 Dienstleistungsinformationspflichtenverordnung / DL-InfoV oder wenn die Angebote Bestandteile enthalten, die als Allgemeine Geschäftsbedingungen zu werten sind, Angaben nach § 36 des Gesetzes über die alternative Streitbeilegung in Verbrauchersachen / VSBG). Es obliegt dem Immobilienanbietenden die notwendigen Informationen zur Verfügung zu stellen und zu prüfen, ob diese in den hinterlegten Daten oder verknüpften Dokumenten entsprechend wiedergegeben werden. Details zu den notwendigen und möglichen Angaben ergeben sich jeweils aus dem entsprechenden Eingabeformular.
                        </p>
                        <p>
                            5.6 Der Portalbetreiber kann die Bereitstellung von Abbildungen zum Immobilienangebot vorsehen. In diesem Zusammenhang wird auf die Garantie und Haftung des Immobilienanbieters zu den eingestellten Informationen und Inhalten verwiesen (vergleiche Ziffer 9).
                        </p>
                        <p>
                            5.7 Der Portalbetreiber kann Beschränkungen für die Eingabe von Immobilienangeboten vorsehen. Diese Beschränkungen können die Anzahl von einstellbaren Immobilienangeboten betreffen und/oder bestimmte Anforderungen an die inhaltliche Ausgestaltung stellen (z.B. bestimmte Nutzungsarten).
                        </p>
                        <p>
                            5.8 Der Portalbetreiber gewährleistet keine Einhaltung bestimmter Fristen. Insbesondere ist er an Veröffentlichungsfristen nicht gebunden.
                        </p>
                        <h2 className="mt-3">
                            6. Matching und Kontaktanbahnung zwischen Immobilienanbietenden und Konzeptbetreibern
                        </h2>
                        <p>
                            6.1 Basierend auf einem Matching-Algorithmus und einem Punktesystem ermittelt der Portalbetreiber nach vollständiger Eingabe eines Objekts und Start des Matching-Prozesses durch den Immobilienanbietenden passende Suchprofile für die Immobilie. Der Immobilienanbietende kann hierzu über Filterkriterien auswählen, welche Arten von Suchprofilen für ihn in Frage kommen.
                        </p>
                        <p>
                            6.2 Der Immobilienanbietende erhält als Ergebnis eine Liste von passenden Suchprofilen mit den zugehörigen Informationen.
                        </p>
                        <p>
                            6.3 Nach der Einsicht der Matching-Ergebnisse besteht die Möglichkeit, alle vom Konzeptbetreiber eingetragenen Detailinformationen einzusehen. Suchprofile können dann durch den Immobilienanbietenden als interessant eingestuft werden. Für diese Suchprofile erhalten im Anschluss sowohl der Konzeptbetreiber als auch der Immobilienanbietende Zugang zu allen eingetragenen Informationen der Immobilie bzw. des Suchprofils. Außerdem können beide Parteien über die Plattform chatten bzw. sich Nachrichten schreiben.
                        </p>
                        <p>
                            6.4 Die Matching-Ergebnisse bleiben für die Dauer des bestehenden Vertragsverhältnisses sichtbar.
                        </p>
                        <h2 className="mt-3">
                            7. CreditPoints
                        </h2>
                        <p>
                            7.1 Der Portalbetreiber kann vorsehen, über eine virtuelle Währung Premium-Funktionen oder Bereiche auf der Plattform freischaltbar zu machen.
                        </p>
                        <p>
                            7.2 Die CreditPoints können je nach Einsatzmöglichkeit auch einen anderen Namen tragen.
                        </p>
                        <p>
                            7.3 CreditPoints werden durch die nutzende Person kostenpflichtig auf der Plattform erworben und können zu einem beliebigen Zeitpunkt auf der Plattform ausgegeben werden.
                        </p>
                        <h2 className="mt-3">
                            8. Einschaltung Dritter
                        </h2>
                        <p>
                            8.1 Der Portalbetreiber ist berechtigt, zur Erbringung von Leistungen nach diesem Nutzungsvertrag Dritte einzusetzen. Er wird dabei die rechtlichen Bedingungen beachten, insbesondere solche datenschutzrechtlicher Art.
                        </p>
                        <p>
                            8.2 Informationen zu den Personen, die für wesentliche Dienstleistungen im Rahmen dieses Vertrages eingesetzt werden, sind im Impressum auf dem Portal abrufbar.
                        </p>
                        <h2 className="mt-3">
                            9. Pflichten, Garantien und Haftung der nutzenden Person
                        </h2>
                        <p>
                            9.1 Die nutzende Person ist für die von ihr eingestellten Inhalte selbst verantwortlich. Dies umfasst die Pflicht der nutzenden Person sicherzustellen, dass alle gesetzlich vorgesehenen Inhalte zutreffend enthalten sind und die eingestellten Immobilien keine sachfremden Inhalte aufweisen.
                        </p>
                        <p>
                            9.2 Sollte ein gesetzlich zwingend vorgesehener Inhalt nicht in der gebotenen Form eingestellt oder nicht durch ein beizufügendes Dokument aufgenommen werden können, wird die nutzende Person unverzüglich den Portalbetreiber in Textform hierzu unterrichten und eine Nutzung des Portals mit den bei ihm betroffenen Angeboten bis zu einer Klärung unterlassen.
                        </p>
                        <p>
                            9.3 Die nutzende Person stellt sicher, dass die von ihm eingegebenen und bereitgestellten Inhalte nicht gegen die gesetzlichen Bestimmungen insbesondere die Bestimmungen zum Jugendschutz oder Persönlichkeitsrechte oder sonstige Rechte zugunsten Dritter verstoßen. Dies schließt die Persönlichkeitsrechte von identifizierbaren oder benannten und/oder abgebildeten natürlichen Personen ebenso ein, wie die Rechte von juristischen Personen, Personenvereinigungen und Unternehmen sowie die Beachtung von Marken- Design- und Urheberrechten.
                        </p>
                        <p>
                            9.4 Die nutzende Person garantiert, dass die von ihm eingestellten Inhalte, insbesondere Abbildungen, Grafiken, Logos und verwendete Firmierungen und Markennamen frei von Rechten Dritter (insbes. Urheber- oder Markenrechte, Persönlichkeitsrechte, Rechte am eigenen Bild) sind oder er über entsprechende Berechtigungen zur Verwendung auf dem Portal für die entsprechende Dauer verfügt.
                        </p>
                        <p>
                            9.5 Immobilienangebote dürfen nur von den dazu berechtigten Personen in das Portal eingestellt werden. Sie müssen ernsthaft gemeint sein und wahrheitsgemäße und vollständige Angaben enthalten, also nicht irreführend sind.
                        </p>
                        <p>
                            9.6 Die zur Verfügung gestellten Abbildungen (Fotos außen oder innen, Planzeichnungen, Karten) müssen einen konkreten Bezug zum Objekt aufweisen und dürfen ebenfalls nicht irreführend sein. Abbildungen dürfen somit keine bloßen Platzhalter bzw. Beispielabbildungen sein. Das Einstellen von angebotsfremden oder rechtswidrigen Inhalten ist nicht gestattet.
                        </p>
                        <p>
                            9.7 Wenn feststeht, dass ein angebotenes Objekt nicht mehr verfügbar ist, hat der Immobilienanbietende das Immobilienangebot unverzüglich für den aktiven Auswahlprozess auf dem Portal inaktiv zu stellen oder zu löschen.
                        </p>
                        <p>
                            9.8 Die nutzende Person darf den Service auf dem Portal nur gemäß dieser Nutzungsbedingungen im Rahmen des bestimmungsgemäßen Zwecks des Portals nutzen.
                        </p>
                        <p>
                            9.9 Von dem Portalbetreiber vorgesehene Zugangsbeschränkungen und Sicherheitsmaßnahmen sind zu beachten und dürfen nicht umgangen, entfernt oder unbrauchbar gemacht werden.
                        </p>
                        <p>
                            9.10 Die nutzende Person ist verpflichtet, dafür zu sorgen, dass seine technische Infrastruktur nach dem Stand der Technik gegen unbefugte Nutzung und Virenbefall angemessen gesichert ist, damit von ihr keine Gefahren für die technischen Einrichtungen des Portalbetreibers ausgehen.
                        </p>
                        <p>
                            9.11 Der Immobilienanbietende ist nicht berechtigt, die gewonnenen Daten der Konzeptbetreiber zu Zwecken zu nutzen, die nicht in unmittelbarem Zusammenhang mit dem Kauf-, Miet- oder sonstigem Interesse am konkreten Objekt stehen. Insbesondere ist ihm untersagt, die Daten von Konzeptbetreibern an Dritte weiterzugeben oder sie anderweitig gewerblich zu nutzen. Der Immobilienanbietende hat das Datenschutzrecht zu beachten.
                        </p>
                        <p>
                            9.12 Werbung und kommerzielle Angebote, die über die mit der Werbung zum Objekt verbundenen Werbewirkung hinausgehen, insbesondere „SPAM“, sind zu unterlassen.
                        </p>
                        <p>
                            9.13 Die nutzende Person ist verpflichtet, Zugangsdaten und Passwort geheim zu halten und alle notwendigen Schutzvorkehrungen zu treffen, um die Kenntnisnahme Unbefugter von dem Passwort zu verhindern. Hat die nutzende Person den Verdacht oder Anhaltspunkte dafür, dass sein Passwort Unbefugten zugänglich geworden ist oder von Unbefugten verwendet wird, ist er verpflichtet, den Portalbetreiber hierüber unverzüglich zu informieren, damit dieser die erforderlichen Schritte zur Verhinderung einer missbräuchlichen Nutzung vornehmen kann.
                        </p>
                        <p>
                            9.14 Die nutzende Person ist für die unbefugte Nutzung des Portals unter seinem Passwort verantwortlich, sofern er nicht nachweisen kann, dass ihm der Missbrauch seines Passworts nicht zuzurechnen ist. Die nutzende Person kann ihr Passwort selbständig ändern.
                        </p>
                        <h2 className="mt-3">
                            10. Nutzungsrechte
                        </h2>
                        <p>
                            Mit der Einstellung von Inhalten in die Plattform (räumt die nutzende Person dem Portalbetreiber das Recht ein, diese Inhalte (insbesondere Medien wie Texte, Bilder, Grundrisse o. ä.) unentgeltlich und unbefristet mit den für den Betrieb der Plattform erforderlichen Nutzungsarten zu nutzen, insbesondere die Inhalte zu speichern, zu vervielfältigen, zu bearbeiten, abzuändern, bereitzuhalten, zu übermitteln, zu übertragen, zu veröffentlichen und anderen Nutzenden im internen Bereich zugänglich zu machen. Die Rechtseinräumung umfasst auch die Unterlizenzierung an Dritte, wenn diese für den Betrieb und Services des Portals eingeschaltet werden. Des Weiteren ist der Portalbetreiber berechtigt, die im Portal eingestellten Inhalte unter Wahrung datenschutzrechtlicher Vorschriften zum Zwecke der Analyse, Weiterentwicklung der Plattform sowie zur Produktentwicklung und der darauf basierenden Erbringungen von künftigen Services (insbesondere im Rahmen statistischer Auswertungen und Übersichten) im vorgenannten Umfang zu nutzen.
                        </p>
                        <h2 className="mt-3">
                            11. Verbotene Nutzungen
                        </h2>
                        <p>
                            Die nutzende Person verpflichtet sich, die Nutzung von softwaretechnischen Maßnahmen, die insbesondere automatisierte Eingriffe und Datenübermittlungen bewirken, welche Abläufe auf dem Portal erschweren, die Infrastruktur des Portals unangemessen belasten, den Betrieb des Portals beeinträchtigen oder Rechte des Portalbetreibers, seiner Lizenzgeber oder von anderen nutzenden Personen verletzen, zu unterlassen.
                        </p>
                        <h2 className="mt-3">
                            12. Verfügbarkeit, Unterrichtung
                        </h2>
                        <p>
                            12.1 Der Portalbetreiber gewährleistet keine ununterbrochene Verfügbarkeit des Portals und/oder seiner Services. Insbesondere kann der Portalbetreiber Leistungen zeitweilig beschränken, wenn dies im Hinblick auf Sicherheitsanforderungen, Kapazitätsgrenzen, der Integrität des IT-Systems oder zur Durchführung technischer Maßnahmen erforderlich ist oder dies der ordnungsgemäßen oder verbesserten Leistungserbringung im Rahmen von Wartungsarbeiten dient. Der Portalbetreiber versucht die Dauer und Anzahl von Unterbrechungen oder Beschränkungen zu begrenzen.
                        </p>
                        <p>
                            12.2 Im Übrigen bemüht sich der Portalbetreiber darum, die Leistungen ohne Unterbrechungen verfügbar zu halten und Übermittlungen fehlerfrei zu erbringen. Nicht im Einflussbereich des Portalbetreibers liegen jedoch insbesondere Störungen der Internetinfrastruktur der entsprechenden Provider. Wenn ein Systemausfall die Nutzung des Portals absehbar nicht nur unerheblich beeinträchtigt oder unmöglich macht, werden die nutzenden Personen in geeigneter Form unterrichtet.
                        </p>
                        <h2 className="mt-3">
                            13. Leistungseinstellung
                        </h2>
                        <p>
                            13.1 Die Aufrechterhaltung der Erreichbarkeit der Plattform sowie der Umfang des Angebots und sonstiger Leistungen, die kostenlos erbracht werden, stehen im Ermessen des Portalbetreibers. Der Portalbetreiber behält sich vor, die Pflege und Bereithaltung des Portals ohne Angabe eines Grundes oder einer Vorankündigung einzustellen.
                        </p>
                        <p>
                            13.2 Im Falle der Einstellung des Portals wird der Portalbetreiber die nutzenden Personen über die mitgeteilten Kontaktdaten (via E-Mail) informieren.
                        </p>
                        <p>
                            13.3 Der Portalbetreiber ist darüber hinaus auch berechtigt, die Nutzung des Portals vollständig oder lediglich für einzelne nutzende Personen vorübergehend oder endgültig einzustellen, wenn der Portalbetreiber verpflichtet ist, eine die Leistungserbringung unzulässig oder unmöglich machende Anordnung einer Behörde oder eines Gerichts zu befolgen.
                        </p>
                        <p>
                            13.4 Der Portalbetreiber wird die nutzenden Personen nach Möglichkeit vorher über die entsprechenden Leistungseinschränkungen und deren voraussichtliche Dauer per E-Mail an die bei der Registrierung angegebene E-Mail-Adresse informieren.
                        </p>
                        <h2 className="mt-3">
                            14. Ausschluss von nutzenden Personen, Sicherheitsmaßnahmen
                        </h2>
                        <p>
                            14.1 Der Portalbetreiber ist berechtigt, nutzende Personen bei Vorliegen von nicht nur unerheblichen Rechtsverletzungen oder Verstößen gegen diese Nutzungsbedingungen von der weiteren Teilnahme zeitweilig oder auf Dauer für die Zukunft auszuschließen. Der Portalbetreiber kann diese Maßnahmen auch dann treffen, wenn die nutzende Person nach einer Beanstandung ein gerügtes vertragswidriges Verhalten fortsetzt oder eine gebotene Handlung unterlässt.
                        </p>
                        <p>
                            14.2 Besteht der durch Vorliegen tatsächlicher Anhaltspunkte begründete Verdacht, dass die nutzende Person das Portal und damit im Zusammenhang stehende Leistungen vertragswidrig nutzt oder von den Einrichtungen der nutzenden Person oder durch ihre Nutzung die Gefahr von Schäden für den Portalbetreiber oder Dritte ausgeht oder droht, kann der Portalbetreiber Sicherheitsmaßnahmen ergreifen, die auch eine vorläufige Leistungseinschränkung oder vorläufige Suspendierung der Teilnahme für die nutzende Person beinhalten können. Hierzu gehört insbesondere die Gefahr der Verbreitung von Schadcode (Computerviren).
                        </p>
                        <p>
                            14.3 Im Fall der vorübergehenden oder endgültigen Leistungseinschränkung für einzelne nutzende Personen werden diese durch den Portalbetreiber unverzüglich per E-Mail benachrichtigt und es wird Gelegenheit zur Stellungnahme gegeben.
                        </p>
                        <h2 className="mt-3">
                            15. Verfahren bei Verstößen gegen diese Nutzungsbedingungen
                        </h2>
                        <p>
                            15.1 Verstöße gegen die Nutzungsbedingungen durch die nutzende Person und/oder seine Mitarbeiter und/oder sonstige Personen, die für ihn tätig werden, werden vom Portalbetreiber in der Regel in einem dreistufigen Verfahren geahndet:
                        </p>
                            <ul>
                                <li>
                                    Stufe 1: Abmahnung
                                </li>
                                <li>
                                    Stufe 2: Zeitlicher Ausschluss von der Nutzung der Dienste, i.d.R. 2 Monate.
                                </li>
                                <li>
                                    Dauerhafter Ausschluss von der Nutzung der Dienste.
                                </li>
                            </ul>
                        <p>
                            15.2 Sollte die nutzende Person ein Unternehmen sein, so betrifft der zeitliche Ausschluss nicht nur die handelnde natürliche Person, sondern das Unternehmen insgesamt.
                        </p>
                        <p>
                            15.3 Der Portalbetreiber behält sich vor, auf besonders schwerwiegende Verstöße gegen die Nutzungsbedingungen abweichend zu reagieren und/oder einzelne Stufen des dreistufigen Verfahrens auszulassen und unmittelbar die gravierendere Ahndung vorzunehmen.
                        </p>
                        <p>
                            15.4 Weitergehende Rechte, etwa Kündigungsrechte und Schadensersatzansprüche, bleiben unberührt.

                        </p>
                        <h2 className="mt-3">
                            16. Hinweise zu den Inhalten, Rechtsverletzungsmeldungen
                        </h2>
                        <p>
                            16.1 Die im Portal eingestellten Inhalte bzw. Angebote sind für den Portalbetreiber fremde Inhalte i.S.v. § 8 Absatz 1 Telemediengesetz (TMG). Die rechtliche Verantwortung für diese Inhalte liegt nicht beim Portalbetreiber, sondern bei derjenigen Person, welche die Inhalte im Portal eingestellt hat.
                        </p>
                        <p>
                            16.2 Die über das Portal zur Verfügung gestellten Inhalte werden mit Sorgfalt erstellt und gepflegt. Der Portalbetreiber kann schon wegen der Einstellung von Inhalten durch nutzende Personen dennoch Fehler nicht ausschließen. Wenn Fehler auffallen, wird darum gebeten, diese an den Portalbetreiber, z.B. unter hallo@llasm.de zu melden.
                        </p>
                        <p>
                            16.3 Gleiches gilt, wenn Rechtsverletzungen in Inhalten erfolgen, die auf dem Portal zugänglich sind. Rechteinhaber können bei der Verletzung von Urheber-, Marken-, Design-, Patentrechten und anderer immaterieller Schutzrechte zudem das Rechtsverletzungsmeldesystem nutzen.
                        </p>
                        <h2 className="mt-3">
                            17. Haftung
                        </h2>
                        <p>
                            17.1 Der Portalbetreiber haftet auf Aufwendungs- und Schadensersatz – gleich aus welchem Rechtsgrund – insbesondere aus Gewährleistung, unerlaubter Handlung, Organisationsverschulden, Verschulden bei Vertragsabschluss oder etwaigen anderen verschuldensabhängigen Ansprüchen – nur, soweit die Haftung auf dem Verschuldensmaßstab Vorsatz oder grobe Fahrlässigkeit beruht oder der Schaden auf einer Verletzung vertragswesentlicher Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglichen und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf oder Ansprüchen nach §§ 1, 4 des Produkthaftungsgesetzes beruht.
                        </p>
                        <p>
                            17.2 Der vorstehende Haftungsausschluss für Fälle einfacher Fahrlässigkeit gilt nicht bei Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, sowie im Fall der Übernahme einer Beschaffenheitsgarantie oder des arglistigen Verschweigens eines Mangels i.S.v. § 444 BGB.
                        </p>
                        <p>
                            17.3 In diesen Fällen haftet der Portalbetreiber auch bei einfacher Fahrlässigkeit.
                        </p>
                        <p>
                            17.4 Bei Übernahme einer Beschaffenheitsgarantie haftet der Portalbetreiber nur für solche Schäden, deren Ausbleiben Gegenstand der Garantie war.
                        </p>
                        <p>
                            17.5 Soweit die Haftung des Portalbetreibers vorstehend geregelt ist, gilt dies auch für seine Beschäftigten, Vertreter und Erfüllungsgehilfen.
                        </p>
                        <h2 className="mt-3">
                            18. Haftung für Datenverluste, Wiederherstellung, wirtschaftliche Erwartungen
                        </h2>
                        <p>
                            Die in Ziffer 20 geregelte Haftung gilt für den Verlust von Daten und Programmen sowie deren Wiederherstellung nur, soweit diese Schäden und Aufwendungen nicht durch angemessene Vorsorgemaßnahmen, insbesondere die Anfertigung von Sicherungskopien der Daten vermeidbar gewesen wären.
                        </p>
                        <h2 className="mt-3">
                            19. Rechtsbeziehung zu nutzenden Personen und deren Ansprüchen untereinander
                        </h2>
                        <p>
                            19.1 Der Portalbetreiber ist keine Partei der Rechtsbeziehungen zwischen Immobilienanbietenden und Konzeptbetreibern. Ansprüche aus dem Verhältnis Immobilienanbietenden und Konzeptbetreibern betreffen allein diese beiden Parteien und entstehen nicht gegenüber dem Portalbetreiber.
                        </p>
                        <p>
                            19.2 Der Portalbetreiber haftet damit weder für Provisionsforderungen – auch nicht für den Fall, dass ein Konzeptbetreiber aufgrund vorliegender Daten einen Makler umgehen sollte – noch für sonstige wirtschaftliche Folgen des Leistungsaustauschs dieser Parteien und entsprechender Vorbereitungshandlungen oder für den Eintritt wirtschaftlicher Erwartungen, die eine nutzende Person im Zusammenhang mit seiner Nutzung des Portals hegt. Insbesondere übernimmt der Portalbetreiber weder eine Gewähr oder Garantie für die zwischen den Konzeptbetreibern und den Immobilienanbietenden ggf. zustande kommenden Vertragsverhältnisse noch eine Haftung für Sach- und Rechtsmängel der Objekte. Ihn trifft auch keine Verpflichtung, für eine Erfüllung eventueller Verpflichtungen der nutzenden Personen zu sorgen oder darauf hinzuwirken.
                        </p>
                        <p>
                            19.3 Die Kontaktaufnahme des Immobilienanbietenden mit einem oder mehreren Konzeptbetreibern erfolgt ohne Empfehlung und/oder Zutun des Portalbetreibers. Auch in Fällen, in denen der Portalbetreiber oder einer seiner Mitarbeiter und Erfüllungsgehilfen von einer nutzenden Person gebeten werden, einen Kontakt zu einer der Konzeptbetreiber oder Immobilienanbietenden aufzunehmen oder herzustellen sind solche Handlungen nicht auf eine Teilnahme an einem Vertragsverhältnis ausgerichtet und nicht dazu bestimmt, einen Vertrag abzuschließen, insbesondere nicht konkludent auf den Abschluss eines Maklervertrags gerichtet.
                        </p>
                        <p>
                            19.4 Ein etwaiger Vertragsschluss ist nicht beabsichtigt und steht zudem vorsorglich unter dem Vorbehalt der Schriftform.
                        </p>
                        <p>
                            19.5 Für die Richtigkeit, Vollständigkeit und Aktualität der von nutzenden Personen eingegebenen Daten übernimmt der Portalbetreiber keine Verantwortung. Gleiches gilt für die wahre Identität und die Verfügungsbefugnis der nutzenden Personen. Bei Zweifeln sind die nutzenden Personen gehalten, sich in geeigneter Weise über die Identität und Verfügungsbefugnis zu informieren. Auch die Leistungsfähigkeit und Bonität der nutzenden Personen wird durch den Portalbetreiber zu keinem Zeitpunkt überprüft.

                        </p>
                        <h2 className="mt-3">
                            20. Höhere Gewalt
                        </h2>
                        <p>
                            20.1 Der Portalbetreiber haftet nicht für Schäden, die als Folge der Beeinträchtigung des Portals entstehen, die durch die Störung seines Betriebs und/oder der für den Betrieb des Portals eingesetzten Infrastruktur infolge von höherer Gewalt, Aufruhr, Kriegs- und Naturereignissen oder infolge von sonstigen vom Portalbetreiber nicht zu vertretenden Vorkommnissen (z.B. Streik, Aussperrung, Verkehrsstörungen, Epidemien, Pandemien, Verfügungen von öffentlicher Hand des In- und Auslands) veranlasst oder auf nicht schuldhaft verursachte technische Probleme zurückzuführen sind.
                        </p>
                        <p>
                            20.2 Dies gilt auch, soweit diese Störungen bei vom Portalbetreiber beauftragten Dritten eintreten.
                        </p>
                        <h2 className="mt-3">
                            21. Änderungsvorbehalt
                        </h2>
                        <p>
                            21.1 Der Portalbetreiber behält sich das Recht vor, diese Nutzungsbedingungen zu ändern und sie insbesondere an künftig veränderte Umstände anzupassen. Bei einer Änderung werden die Interessen der nutzenden Personen unter Berücksichtigung der Interessen des Portalbetreibers angemessen berücksichtigt.
                        </p>
                        <p>
                            21.2 Die registrierte nutzende Person kann das Angebot des Portalbetreibers zu einer Änderung der Nutzungsbedingungen (etwa bei einem Login) annehmen und die geänderten Bedingungen ausdrücklich akzeptieren. In diesem Fall erlangen die geänderten Nutzungsbedingungen zum angegebenen Zeitpunkt Geltung.
                        </p>
                        <p>
                            21.3 Die registrierte nutzende Person wird auf die Änderung der Nutzungsbedingungen in Textform per Mitteilung an die angegebene E-Mail hingewiesen und es werden die geänderten Bedingungen spätestens sechs Wochen vor ihrem Inkrafttreten an die registrierte nutzende Person übermittelt. Widerspricht eine registrierte nutzende Person der Geltung der neuen Nutzungsbedingungen nicht innerhalb von vier Wochen nach Empfang der Mitteilung, gilt die geänderte Vereinbarung als angenommen. Der Portalbetreiber wird der registrierten nutzenden Person in der Mitteilung, welche die geänderten Bedingungen enthält, auf die Bedeutung dieser vierwöchigen Frist, das Widerspruchsrecht der registrierten nutzenden Person und die Rechtsfolgen des Schweigens gesondert hinweisen.
                        </p>
                        <p>
                            21.4 Widerspricht die registrierte nutzende Person den Änderungen, sind beide Parteien berechtigt, das Vertragsverhältnis zum Wirksamwerden der Änderungen zu kündigen. Sonstige Kündigungsmöglichkeiten bleiben unberührt.
                        </p>
                        <h2 className="mt-3">
                            22. Laufzeit, Werktage
                        </h2>
                        <p>
                            22.1 Das Nutzungsverhältnis der nutzenden Person und dem Portalbetreiber ist von unbefristeter Dauer. Es kann von jeder Partei ohne Angabe von Gründen mit einer Frist von zehn Werktagen gekündigt werden.
                        </p>
                        <p>
                            22.2 Unter Werktagen nach dieser Vereinbarung verstehen sich alle Wochentage mit Ausnahme von Samstagen, Sonntagen und gesetzlichen Feiertagen am Sitz der nutzenden Person. Heiligabend und Silvester werden wie Feiertage behandelt.
                        </p>
                        <h2 className="mt-3">
                            23. Schlussbestimmungen
                        </h2>
                        <p>
                            23.1 Die Vertragssprache ist Deutsch. Vom Portalbetreiber bereitgestellte Übersetzungen dienen lediglich der Information. Maßgeblich ist die Fassung der Bestimmungen in der deutschen Originalsprache. Das gilt für die Vertragsunterlagen, den Schriftverkehr, die Abwicklung und sämtliche Verhandlungen.
                        </p>
                        <p>
                            23.2 Auf die Verträge der nutzenden Personen mit dem Portalbetreiber und die in ihrer Ausführung erfolgenden Einzelverträge zwischen diesen Parteien findet das Recht der Bundesrepublik Deutschland Anwendung. Die Anwendung internationaler Übereinkommen ist ausgeschlossen.
                        </p>
                        <p>
                            23.3 Sofern es sich der nutzenden Person um einen Kaufmann, eine juristische Person des öffentlichen Rechts oder um ein öffentlich-rechtliches Sondervermögen handelt, ist Gerichtsstand für alle Streitigkeiten aus Vertragsverhältnissen zwischen dem Portalbetreiber und der nutzenden Person Leverkusen. Der Portalbetreiber ist zudem berechtigt, die nutzende Person an ihrem allgemeinen Gerichtsstand zu verklagen.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GtcPage;
