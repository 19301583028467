enum PropertyInterestFeedback {
    Interested = 0,
    NotInterested = 1
}

const getPropertyInterestFeedbackLabel = (propertyInterestFeedback: PropertyInterestFeedback): string => {
    switch (propertyInterestFeedback) {
        case PropertyInterestFeedback.Interested:
            return 'Interessiert';
        case PropertyInterestFeedback.NotInterested:
            return 'Nicht interessiert';
    }
};

export default PropertyInterestFeedback;
export {getPropertyInterestFeedbackLabel};
