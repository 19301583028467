import LocationService from '../../Location/LocationService';
import FormData from '../../Entity/Form/FormData';
import MarketplaceAdFilter from '../../Entity/Marketplace/MarketplaceAdFilter';
import Place from '../../Entity/Location/Place';
import SelectOption from '../../Entity/Form/SelectOption';
import AutocompletePlace from '../../Entity/Location/AutocompletePlace';
import PlaceSearchRadius, {getPlaceSearchRadiusLabel} from '../../Entity/Location/PlaceSearchRadius';
import SelectField from '../../Component/Form/Field/SelectField';
import InputField from '../../Component/Form/Field/InputField';
import AsyncSelectField from '../../Component/Form/Field/AsyncSelectField';
import SwitchField from '../../Component/Form/Field/SwitchField';
import React, {useEffect, useRef, useState} from 'react';
import {SelectInstance} from 'react-select';

interface FilterProps {
    readonly countryPlaces: Place[];
    readonly marketplaceAdFilter: MarketplaceAdFilter;
    readonly onSubmit: (marketplaceAdFilter: MarketplaceAdFilter) => void;
}

const locationService: LocationService = new LocationService(process.env.REACT_APP_LLASM_API_URL!);

const placeSearchRadiusSelectOptions: SelectOption<PlaceSearchRadius | null>[] = [
    {label: 'Bitte wählen', value: null},
    {label: getPlaceSearchRadiusLabel(PlaceSearchRadius.Ten), value: PlaceSearchRadius.Ten},
    {label: getPlaceSearchRadiusLabel(PlaceSearchRadius.Twenty), value: PlaceSearchRadius.Twenty},
    {label: getPlaceSearchRadiusLabel(PlaceSearchRadius.Forty), value: PlaceSearchRadius.Forty},
];

const Filter = (props: FilterProps): React.JSX.Element => {
    const citySelectRef = useRef<SelectInstance<SelectOption<AutocompletePlace>> | null>(null);

    const [formData, setFormData] = useState<FormData<MarketplaceAdFilter>>({data: props.marketplaceAdFilter});

    const [countryPlaceSelectOptions, setCountryPlaceSelectOptions] = useState<SelectOption<Place>[]>([]);

    useEffect((): void => {
        setCountryPlaceSelectOptions(props.countryPlaces.map((place: Place): SelectOption<Place> => {
            return {
                label: place.placeName,
                value: place
            };
        }));
    }, [props.countryPlaces]);

    useEffect((): void => {
        if (props.marketplaceAdFilter.city === null && props.marketplaceAdFilter.placeSearchRadius !== null) {
            props.marketplaceAdFilter.placeSearchRadius = null

            updateFormData();
        }
    }, [formData.data.city]);

    const fetchCountryPlaceSelectedOption = (): SelectOption<Place> | undefined => {
        if (props.marketplaceAdFilter.countryPlace === null) {
            return undefined;
        }

        const countryPlace: Place = props.marketplaceAdFilter.countryPlace;

        return countryPlaceSelectOptions.find((selectOption: SelectOption<Place>): boolean => {
            return countryPlace.id === selectOption.value.id;
        });
    };

    const fetchCitySelectOptions = async (searchTerm: string): Promise<SelectOption<AutocompletePlace>[]> => {
        if (props.marketplaceAdFilter.countryPlace === null) {
            return [];
        }

        if (searchTerm === '' || searchTerm.length < 3) {
            return [];
        }

        return (await (locationService.fetchPlacesBySearchTerm(searchTerm, undefined, props.marketplaceAdFilter.countryPlace.uuid))).map((autocompletePlace: AutocompletePlace): SelectOption<AutocompletePlace> => {
            return {
                label: autocompletePlace.autoCompleteDisplay,
                value: autocompletePlace
            };
        });
    };

    const fetchPlaceSearchRadiusSelectedOption = (): SelectOption<PlaceSearchRadius | null> | undefined => {
        return placeSearchRadiusSelectOptions.find((selectOption: SelectOption<PlaceSearchRadius | null>): boolean => {
            return props.marketplaceAdFilter.placeSearchRadius === selectOption.value;
        });
    };

    const handleCountryPlaceChange = (selectOption: SelectOption<Place> | null): void => {
        if (selectOption !== null && selectOption.value !== props.marketplaceAdFilter.countryPlace) {
            props.marketplaceAdFilter.city = null;

            clearCitySelect();
        }

        if (selectOption === null) {
            props.marketplaceAdFilter.countryPlace = null;
        } else {
            props.marketplaceAdFilter.countryPlace = selectOption.value;
        }

        updateFormData();
    };

    const handleCityChange = (selectOption: SelectOption<AutocompletePlace> | null): void => {
        if (selectOption === null) {
            props.marketplaceAdFilter.city = null;
        } else {
            props.marketplaceAdFilter.city = selectOption.value.place;
        }

        updateFormData();
    };

    const handlePlaceSearchRadiusChange = (selectOption: SelectOption<PlaceSearchRadius | null> | null): void => {
        if (selectOption === null) {
            props.marketplaceAdFilter.placeSearchRadius = null;
        } else {
            props.marketplaceAdFilter.placeSearchRadius = selectOption.value;
        }

        updateFormData();
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        (props.marketplaceAdFilter as any)[event.target.name] = event.target.checked;

        updateFormData();
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
        switch (event.target.name) {
            case 'totalAreaSizeFrom':
                if (event.target.value === '') {
                    props.marketplaceAdFilter.totalAreaSizeFrom = null;
                } else {
                    props.marketplaceAdFilter.totalAreaSizeFrom = Number(event.target.value);
                }

                break;
            case 'totalAreaSizeTo':
                if (event.target.value === '') {
                    props.marketplaceAdFilter.totalAreaSizeTo = null;
                } else {
                    props.marketplaceAdFilter.totalAreaSizeTo = Number(event.target.value);
                }

                break;
            default:
                (props.marketplaceAdFilter as any)[event.target.name] = event.target.value;

                break;
        }

        updateFormData();
    };

    const clearCitySelect = (): void => {
        if (citySelectRef.current === null) {
            return;
        }

        citySelectRef.current.clearValue();
    };

    const createAutocompleteSelectOptionFromPlace = (place: Place): SelectOption<AutocompletePlace> => {
        const autocompletePlace: AutocompletePlace = new AutocompletePlace();

        autocompletePlace.autoCompleteDisplay = place.placeName;
        autocompletePlace.place = place;

        return {
            label: autocompletePlace.autoCompleteDisplay,
            value: autocompletePlace
        };
    };

    const updateFormData = (): void => {
        setFormData({...formData, data: props.marketplaceAdFilter});
    };

    const onSubmit = (): void => {
        props.onSubmit(props.marketplaceAdFilter);
    };

    return (
        <div className="row">
            <div className="col-12 col-md-6 mb-3">
                <SelectField
                    label="Land"
                    name="countryPlaces"
                    options={countryPlaceSelectOptions}
                    required={false}
                    value={fetchCountryPlaceSelectedOption()}
                    placeholder="Bitte wählen"
                    onChange={handleCountryPlaceChange}
                />
            </div>
            <div className="col-12 col-md-3 mb-3">
                <InputField
                    name="totalAreaSizeFrom"
                    label="Gesamtfläche von"
                    description="Gib hier die Minimalgröße der Fläche ein, die Du suchst."
                    type="number"
                    suffix="m²"
                    min={0}
                    required={false}
                    value={props.marketplaceAdFilter.totalAreaSizeFrom ?? undefined}
                    onChange={handleChange}
                />
            </div>
            <div className="col-12 col-md-3 mb-3">
                <InputField
                    name="totalAreaSizeTo"
                    label="Gesamtfläche bis"
                    description="Gib hier an, wie groß die Gesamtfläche des Objekts, das Du suchst maximal sein darf."
                    type="number"
                    suffix="m²"
                    min={0}
                    required={false}
                    value={props.marketplaceAdFilter.totalAreaSizeTo ?? undefined}
                    onChange={handleChange}
                />
            </div>
            <div className="col-12 col-md-3 mb-3">
                <AsyncSelectField
                    ref={citySelectRef}
                    label="Ort"
                    name="city"
                    placeholder="Ort suchen"
                    value={props.marketplaceAdFilter.city !== null ? createAutocompleteSelectOptionFromPlace(props.marketplaceAdFilter.city) : undefined}
                    required={false}
                    loadOptions={fetchCitySelectOptions}
                    closeMenuOnSelect={false}
                    loadingMessage={(): string => 'Passende Orte werden gesucht...'}
                    onChange={handleCityChange}
                    isClearable={true}
                />
            </div>
            <div className="col-12 col-md-3 mb-3">
                <SelectField
                    label="Umkreis"
                    name="placeSearchRadius"
                    options={placeSearchRadiusSelectOptions}
                    required={false}
                    disabled={props.marketplaceAdFilter.city === null}
                    value={fetchPlaceSearchRadiusSelectedOption()}
                    placeholder="Bitte wählen"
                    onChange={handlePlaceSearchRadiusChange}
                />
            </div>
            <div className="col-12 col-md-3 mb-3 pt-3">
                <SwitchField
                    label="nur sofort verfügbare Flächen"
                    description="Wähle diese Option, wenn Du nur Angebote angezeigt bekommen möchtest, die sofort zur Verfügung stehen."
                    selected={props.marketplaceAdFilter.onlyImmediatelyAvailable}
                    name="onlyImmediatelyAvailable"
                    onChange={handleSwitchChange}
                />
            </div>
            <div className="col-12 col-md-3 mb-3 d-flex align-items-end justify-content-end">
                <button className="btn btn-primary d-flex align-items-center mt-3" onClick={onSubmit}>
                    Filter anwenden
                </button>
            </div>
        </div>
    );
};

export default Filter;
