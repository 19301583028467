import User from '../Entity/User/User';
import Country from '../Entity/Location/Country';
import SelectOption from '../Entity/Form/SelectOption';
import FormData from '../Entity/Form/FormData';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import InputField from '../Component/Form/Field/InputField';
import SelectField from '../Component/Form/Field/SelectField';
import TextAreaField from '../Component/Form/Field/TextAreaField';
import React from 'react';

interface UserFormProps {
    readonly formData: FormData<User>;
    readonly setFormData: Function;
    readonly countrySelectOptions: SelectOption<Country>[];
    readonly formValidationHandler?: FormValidationHandler<User>;
}

const UserBaseDataForm = (props: UserFormProps): React.JSX.Element => {
    const user: User = props.formData.data;

    const fetchCountrySelectedOption = (): SelectOption<Country> | undefined => {
        if (user.country === undefined) {
            return undefined;
        }

        return props.countrySelectOptions.find((selectOption: SelectOption<Country>): boolean => {
            return user.country.id === selectOption.value.id;
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        (user as any)[event.target.name] = event.target.value;

        updateFormData();
        validateField(event.target.name);
    };

    const handleCountrySelectChange = (selectedValue: SelectOption<Country> | null): void => {
        if (selectedValue === null) {
            return;
        }

        user.country = selectedValue.value;

        updateFormData();
        validateField('country');
    };

    const handleImprintChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        if ( event.target.value.length > 0) {
            user.imprint = event.target.value;
        } else {
            user.imprint =null;
        }

        updateFormData();
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: user});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <>
            {user.naturalPerson === false &&
                <div className="row">
                    <div className="col-lg-6 mb-3">
                        <InputField
                            name="companyName"
                            label="Unternehmen"
                            type="text"
                            required={true}
                            disabled={true}
                            value={user.companyName ?? undefined}
                        />
                    </div>
                </div>
            }
            <div className="row">
                <div className="col-md-6">
                    <InputField
                        name="firstName"
                        label="Vorname"
                        type="text"
                        required={true}
                        value={user.firstName}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'firstName')}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <InputField
                        name="lastName"
                        label="Nachname"
                        type="text"
                        required={true}
                        value={user.lastName}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'lastName')}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="streetName"
                        label="Straße"
                        type="text"
                        required={true}
                        value={user.streetName}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'streetName')}
                    />
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="houseNumber"
                        label="Hausnummer"
                        type="text"
                        required={true}
                        value={user.houseNumber}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'houseNumber')}
                    />
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="postalCode"
                        label="PLZ"
                        type="text"
                        required={true}
                        value={user.postalCode}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'postalCode')}
                    />
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="placeName"
                        label="Ort"
                        type="text"
                        required={true}
                        value={user.placeName}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'placeName')}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6 mb-3">
                    <SelectField
                        name="country"
                        label="Land"
                        required={true}
                        options={props.countrySelectOptions}
                        value={fetchCountrySelectedOption()}
                        placeholder="Bitte wählen"
                        onChange={handleCountrySelectChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'country')}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <InputField
                        name="phoneNumber"
                        label="Telefon"
                        type="text"
                        required={true}
                        value={user.phoneNumber ?? undefined}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'phoneNumber')}
                    />
                </div>
            </div>
            {process.env.NODE_ENV === 'development' &&
                <div className="row mb-3">
                    <div className="col">
                        <TextAreaField
                            name="imprint"
                            label="Anbieter-Impressum"
                            required={false}
                            value={user.imprint ?? undefined}
                            rows={5}
                            onChange={handleImprintChange}
                            description="Das Anbieter-Impressum müssen Sie ausfüllen, sofern Sie Objekte auf dem Marktplatz veröffentlichen wollen, da es sich dabei um eine Immobilienanzeige handelt."
                        />
                    </div>
                </div>
            }
        </>
    );
};

export default UserBaseDataForm;
