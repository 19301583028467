import SelectOption from './Entity/Form/SelectOption';
import LocationType, {getLocationTypeLabel} from './Entity/LocationType';
import FormError from './Entity/Form/FormError';
import SelectField from './Component/Form/Field/SelectField';
import React from 'react';
import {OnChangeValue, SingleValue} from 'react-select';

interface LocationTypeSelectFieldProps {
    readonly label: string;
    readonly title: string;
    readonly name: string;
    readonly isClearable: boolean;
    readonly required: boolean;
    readonly classNames?: string;
    readonly value?: LocationType | null;
    readonly defaultValue?: LocationType | null;
    readonly onChange?: (newValue: LocationType | null) => void;
    readonly formErrors?: FormError[];
}

const selectOptions: SelectOption<LocationType>[] = [
    {label: getLocationTypeLabel(LocationType.DowntownLocation), value: LocationType.DowntownLocation},
    {label: getLocationTypeLabel(LocationType.CityDistrictLocation), value: LocationType.CityDistrictLocation},
    {label: getLocationTypeLabel(LocationType.PeripheralLocation), value: LocationType.PeripheralLocation},
    {label: getLocationTypeLabel(LocationType.HighFrequencySpecialLocation), value: LocationType.HighFrequencySpecialLocation},
    {label: getLocationTypeLabel(LocationType.ScatteredAndSolitaryLayers), value: LocationType.ScatteredAndSolitaryLayers},
];

const LocationTypeSelectField = (props: LocationTypeSelectFieldProps): React.JSX.Element => {
    const mapValueToSelectOption = (locationTypeValue: LocationType | null | undefined): SingleValue<SelectOption<LocationType>> | null | undefined => {
        if (locationTypeValue === undefined) {
            return undefined;
        }

        if (locationTypeValue === null) {
            return null;
        }

        return selectOptions.find((selectOption: SelectOption<LocationType>): boolean => {
            return selectOption.value === locationTypeValue
        });
    };

    return (
        <SelectField
            name={props.name}
            label={props.label}
            required={props.required}
            description={props.title}
            options={selectOptions}
            value={mapValueToSelectOption(props.value)}
            defaultValue={mapValueToSelectOption(props.defaultValue)}
            isClearable={props.isClearable}
            placeholder="Bitte wählen"
            onChange={(newValue: OnChangeValue<SelectOption<LocationType>, boolean>): void => {
                if (props.onChange !== undefined) {
                    props.onChange((newValue as SelectOption<LocationType>).value);
                }
            }}
            formErrors={props.formErrors}
        />
    );
};

export default LocationTypeSelectField;
