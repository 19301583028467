import SelectOption from './Entity/Form/SelectOption';
import LocationCategory, {getLocationCategoryLabel} from './Entity/LocationCategory';
import FormError from './Entity/Form/FormError';
import SelectField from './Component/Form/Field/SelectField';
import React, {useEffect, useState} from 'react';
import {OnChangeValue, SingleValue} from 'react-select';

interface LocationCategorySelectFieldProps {
    readonly label: string;
    readonly title: string;
    readonly name: string;
    readonly isClearable: boolean
    readonly required: boolean;
    readonly selectOptions?: SelectOption<LocationCategory>[];
    readonly classNames?: string;
    readonly value?: LocationCategory | null;
    readonly defaultValue?: LocationCategory | null;
    readonly onChange?: (newValue: LocationCategory | null) => void;
    readonly formErrors?: FormError[];
}

const defaultSelectOptions: SelectOption<LocationCategory>[] = [
    {label: getLocationCategoryLabel(LocationCategory.OneALocation), value: LocationCategory.OneALocation},
    {label: getLocationCategoryLabel(LocationCategory.OneBLocation), value: LocationCategory.OneBLocation},
    {label: getLocationCategoryLabel(LocationCategory.OneCLocation), value: LocationCategory.OneCLocation},
    {label: getLocationCategoryLabel(LocationCategory.TwoALocation), value: LocationCategory.TwoALocation},
    {label: getLocationCategoryLabel(LocationCategory.TwoBLocation), value: LocationCategory.TwoBLocation},
    {label: getLocationCategoryLabel(LocationCategory.TwoCLocation), value: LocationCategory.TwoCLocation},
    {label: getLocationCategoryLabel(LocationCategory.Other), value: LocationCategory.Other},
];

const LocationCategorySelectField = (props: LocationCategorySelectFieldProps): React.JSX.Element => {
    const [selectOptions, setSelectOptions] = useState<SelectOption<LocationCategory>[]>(defaultSelectOptions);

    useEffect((): void => {
        if (props.selectOptions === undefined) {
            return;
        }

        setSelectOptions(props.selectOptions);
    }, [props.selectOptions]);

    const mapValueToSelectOption = (locationCategoryValue: LocationCategory | null | undefined): SingleValue<SelectOption<LocationCategory>> | null | undefined => {
        if (locationCategoryValue === undefined) {
            return undefined;
        }

        if (locationCategoryValue === null) {
            return null;
        }

        return selectOptions.find((selectOption: SelectOption<LocationCategory>): boolean => {
            return selectOption.value === locationCategoryValue;
        });
    };

    return (
        <SelectField
            name={props.name}
            label={props.label}
            required={props.required}
            description={props.title}
            options={selectOptions}
            value={mapValueToSelectOption(props.value)}
            defaultValue={mapValueToSelectOption(props.defaultValue)}
            isClearable={props.isClearable}
            placeholder="Bitte wählen"
            onChange={(newValue: OnChangeValue<SelectOption<LocationCategory>, boolean>): void => {
                if (props.onChange !== undefined) {
                    props.onChange((newValue as SelectOption<LocationCategory>).value);
                }
            }}
            formErrors={props.formErrors}
        />
    );
};

export default LocationCategorySelectField;
