enum MonumentProtectionType {
    singleMonument = 0,
    ensembleMonument = 1
}

const getMonumentProtectionTypeLabel = (monumentProtectionType: MonumentProtectionType): string => {
    switch (monumentProtectionType) {
        case MonumentProtectionType.singleMonument:
            return 'Einzeldenkmal';
        case MonumentProtectionType.ensembleMonument:
            return 'Ensembledenkmal';
        default:
            throw new Error();
    }
};

export default MonumentProtectionType;
export {getMonumentProtectionTypeLabel};
