import CountryResponse from '../../../../api/Llasm/Location/CountryResponse';
import Place from './Place';

class Country {
    private _id?: number;

    private _countryName: string;

    private _countryPlace: Place | null = null;

    private _isoAlphaTwoCode: string;

    private _isoAlphaThreeCode: string;

    private _europeanUnionMember: boolean;

    public static createFromCountryResponse(countryResponse: CountryResponse): Country {
        const country: Country = new this();

        country._id = countryResponse.id;
        country._countryName = countryResponse.countryName;

        if (countryResponse.countryPlace === null) {
            country._countryPlace = null;
        } else {
            country._countryPlace = Place.createFromPlaceResponse(countryResponse.countryPlace);
        }

        country._isoAlphaTwoCode = countryResponse.isoAlphaTwoCode;
        country._isoAlphaThreeCode = countryResponse.isoAlphaThreeCode;
        country._europeanUnionMember = countryResponse.europeanUnionMember;

        return country;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get countryName(): string {
        return this._countryName;
    }

    set countryName(value: string) {
        this._countryName = value;
    }

    get countryPlace(): Place | null {
        return this._countryPlace;
    }

    set countryPlace(value: Place | null) {
        this._countryPlace = value;
    }

    get isoAlphaTwoCode(): string {
        return this._isoAlphaTwoCode;
    }

    set isoAlphaTwoCode(value: string) {
        this._isoAlphaTwoCode = value;
    }

    get isoAlphaThreeCode(): string {
        return this._isoAlphaThreeCode;
    }

    set isoAlphaThreeCode(value: string) {
        this._isoAlphaThreeCode = value;
    }

    get europeanUnionMember(): boolean {
        return this._europeanUnionMember;
    }

    set europeanUnionMember(value: boolean) {
        this._europeanUnionMember = value;
    }
}

export default Country;
