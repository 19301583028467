import UserRegistrationService from './UserRegistrationService';
import AuthenticationState from '../../shared/Entity/Authentication/AuthenticationState';
import FormData from '../../shared/Entity/Form/FormData';
import UserActivation from '../../shared/Entity/User/UserActivation';
import Alert from '../../shared/Entity/Alert/Alert';
import AlertType from '../../shared/Entity/Alert/AlertType';
import UserActivationType from '../../shared/Entity/User/UserActivationType';
import RequiredValidationDefinition from '../../shared/FormValidationHandler/RequiredValidationDefinition';
import IsTrueValidationDefinition from '../../shared/FormValidationHandler/IsTrueValidationDefinition';
import PasswordRepetitionValidationDefinition from '../../shared/FormValidationHandler/PasswordRepetitionValidationDefinition';
import FieldValidationDefinition from '../../shared/FormValidationHandler/FieldValidationDefinition';
import PasswordValidationDefinition from '../../shared/FormValidationHandler/PasswordValidationDefinition';
import FormValidationHandler from '../../shared/FormValidationHandler/FormValidationHandler';
import UserActivationForm from './UserActivationForm';
import AlertBox from '../../../components/AlertBox';
import Spinner from '../../../components/Spinner';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import React, { useEffect, useState } from 'react';
import {Link, NavigateFunction, useNavigate, useParams} from 'react-router-dom';

const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.');

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Bitte fülle alle rot markierten Pflichtfelder aus.');

const fieldValidationDefinitions: FieldValidationDefinition<UserActivation>[] = [
    new PasswordValidationDefinition<UserActivation>('password', 'Das Passwort muss mindestens 8 Zeichen lang sein, mindestens je einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und auch ein Sonderzeichen enthalten.'),
    new PasswordRepetitionValidationDefinition<UserActivation>('passwordRepeat', 'password', 'Der Wert stimmt nicht mit dem Passwort überein.'),
];

const formValidationHandler: FormValidationHandler<UserActivation> = new FormValidationHandler<UserActivation>(fieldValidationDefinitions);

const userRegistrationService: UserRegistrationService = new UserRegistrationService(process.env.REACT_APP_LLASM_API_URL!);

const UserActivationPage = (): React.JSX.Element => {
    const {userActivationUuid} = useParams<string>();

    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const [userActivation, setUserActivation] = useState<UserActivation>();

    const [formData, setFormData] = useState<FormData<UserActivation>>({data: userActivation!});

    const [userActivationSuccessful, setUserActivationSuccessful] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [alert, setAlert] = useState<Alert>();

    const navigate: NavigateFunction = useNavigate();

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Aktivieren'},
            ]
        });
    }, [dispatch]);


    useEffect((): void => {
        if (authenticatedUser !== null) {
            navigate('/');
        }

        fetchUserActivation();
    }, []);

    useEffect((): void => {
        if (userActivationSuccessful === true) {
            setAlert(undefined);
        }

    }, [userActivationSuccessful]);

    const fetchUserActivation = async (): Promise<void> => {
        try {
            setIsLoading(true);

            const userActivation: UserActivation = await userRegistrationService.fetchUserActivationFromApiByActivationUuid(userActivationUuid!);

            setUserActivation(userActivation);

            if (userActivation.userActivationType === UserActivationType.AdminCreated) {
                fieldValidationDefinitions.push(new RequiredValidationDefinition('gtcAccepted', 'Nutzungsbedingungen'))
                fieldValidationDefinitions.push(new IsTrueValidationDefinition('gtcAccepted', 'Sie müssen den Nutzungsbedingungen zustimmen.'))
            }

            setFormData({...formData, data: userActivation});
        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    const activateUser = async (): Promise<void> => {
        formValidationHandler.validate(formData);

        if (formValidationHandler.hasErrors(formData) === true) {
            setAlert(formErrorAlert);

            return;
        }

        setIsLoading(true);

        try {
            await userRegistrationService.activateUser(formData.data);

            setUserActivationSuccessful(true);
        } catch (error) {
            setAlert(errorAlert);

            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading === true) {
        return <Spinner />;
    }

    return (
        <div className="row g-3 mt-1 mb-3">
            <div className="col-12 pt-1 px-md-3">
                <h1 className="fw-bold text-secondary fs-2 mb-4 mb-lg-5">Ladenfläche trifft Nachmieter:<br />Match mit!</h1>
                <h2 className="fs-3 fw-bold ls-1">Passwort vergeben</h2>
                <p className="text-muted mb-4">Hallo! Vergib ein Passwort und leg los.</p>
            </div>
            <div className="col-12 pt-1 text-start px-md-3">
                {alert !== undefined &&
                    <AlertBox alert={alert} autoDismiss={false} />
                }
            </div>
            <div className="col-12 pt-1 text-start px-md-3">
                {userActivationSuccessful === true ? (
                    <div className="alert alert-primary">
                        Dein Passwort wurde erfolgreich aktualisiert. <Link to="/login">Log Dich ein</Link>.
                    </div>
                ) : (
                    <div>
                        <UserActivationForm formData={formData} setFormData={setFormData} formValidationHandler={formValidationHandler} />
                        <div className="d-flex gap-4 mt-3">
                            <button className="btn btn-secondary w-100" type="submit" onClick={activateUser}>Speichern</button>
                            <Link to="/datenschutz" className="btn btn-outline-primary w-100" type="button">DATENSCHUTZ</Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserActivationPage;
