import FormError from '../../../Entity/Form/FormError';
import FieldStyle from './Field.module.scss';
import FieldLabel from './FieldLabel';
import React from 'react';

interface TextareaProps extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
    readonly name: string;
    readonly label?: string;
    readonly description?: string;
    readonly formErrors?: FormError[];
}

const TextAreaField = (props: TextareaProps): React.JSX.Element => {
    const hasErrors: boolean = props.formErrors !== undefined && props.formErrors.length > 0;

    return (
        <>
            {props.label !== undefined &&
                <FieldLabel label={props.label} htmlFor={props.name} required={props.required} description={props.description} className="mb-2 ps-2" />
            }
            <textarea
                name={props.name}
                value={props.value}
                rows={props.rows}
                placeholder={props.placeholder}
                required={props.required}
                className={[FieldStyle.container, hasErrors === true ? ' is-invalid form-control' : ''].join(' ')}
                onChange={props.onChange}
            />
            {hasErrors === true &&
                <>
                    {props.formErrors!.map((formError: FormError, index: number): React.JSX.Element => (
                        <div key={'textarea_error_' + props.name + '_' + index} className="invalid-feedback d-block">
                            {formError.message}
                        </div>
                    ))}
                </>
            }
        </>
    );
};

export default TextAreaField;
