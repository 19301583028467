import InterestFeedback from '../../Entity/InterestFeedback';
import React from 'react';

interface InterestFeedbackButtonProps {
    readonly interestFeedback: InterestFeedback | null;
    readonly onClickInterested: () => void;
    readonly onClickNotInterested: () => void;
}

const InterestFeedbackButton = (props: InterestFeedbackButtonProps): React.JSX.Element => {
    return (
        <div className="row">
            {props.interestFeedback === null &&
                <>
                    <div className="col-12 col-md-6 d-flex justify-content-md-end p-3 p-md-0">
                        <button className="btn btn-outline-primary text-nowrap flex-fill flex-md-grow-0" onClick={(): void => props.onClickInterested()}>
                            Interesse! <i className="bi bi-emoji-laughing-fill"></i>
                        </button>
                    </div>
                    <div className="col-12 col-md-6 d-flex justify-content-md-start">
                        <button className="btn btn-outline-secondary text-nowrap flex-fill flex-md-grow-0" onClick={(): void => props.onClickNotInterested()}>
                            <i className="bi bi-emoji-frown-fill"></i> Kein Interesse
                        </button>
                    </div>
                </>
            }
            {props.interestFeedback === InterestFeedback.Interested &&
                <>
                    <div className="col-12 col-md-6 d-flex justify-content-md-end p-3 p-md-0">
                        <button className="btn btn-primary text-nowrap flex-fill flex-md-grow-0">
                            Du bist interessiert <i className="bi bi-emoji-laughing-fill"></i>
                        </button>
                    </div>
                    <div className="col-12 col-md-6 d-flex justify-content-md-start">
                        <button className="btn btn-outline-secondary text-nowrap flex-fill flex-md-grow-0" onClick={(): void => props.onClickNotInterested()}>
                            <i className="bi bi-emoji-frown-fill"></i> Doch kein Interesse!
                        </button>
                    </div>
                </>
            }
            {props.interestFeedback === InterestFeedback.NotInterested &&
                <>
                    <div className="col-12 col-md-6 d-flex justify-content-md-end p-3 p-md-0">
                        <button className="btn btn-outline-primary text-nowrap flex-fill flex-md-grow-0" onClick={(): void => props.onClickInterested()}>
                            Doch interessiert <i className="bi bi-emoji-laughing-fill"></i>
                        </button>
                    </div>
                    <div className="col-12 col-md-6 d-flex justify-content-md-start">
                        <button className="btn btn-secondary text-nowrap flex-fill flex-md-grow-0">
                            <i className="bi bi-emoji-frown-fill"></i> Du bist nicht interessiert
                        </button>
                    </div>
                </>
            }
        </div>
    );
};

export default InterestFeedbackButton;
