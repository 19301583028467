import ConversationUser from './ConversationUser';
import ParticipatingUserResponse from '../../../../api/Llasm/Messaging/ParticipatingUserResponse';

class ParticipatingUser extends ConversationUser {
    public static createFromParticipatingUserResponse(participatingUserResponse: ParticipatingUserResponse): ParticipatingUser {
        const participatingUser: ParticipatingUser = new this();

        participatingUser._id = participatingUserResponse.id;
        participatingUser._name = participatingUserResponse.name;

        return participatingUser;
    }
}

export default ParticipatingUser;
