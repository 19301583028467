import PropertyAcquisitionType from '../../PropertyAcquisitionType';
import RentalDetail from './RentalDetail';
import PurchaseDetail from './PurchaseDetail';
import TimeAvailability from '../TimeAvailability';
import OfferDetailResponse from '../../../../../api/Llasm/Property/OfferDetail/OfferDetailResponse';

class OfferDetail {
    private _propertyAcquisitionTypes: PropertyAcquisitionType[] = [];

    private _otherDetails: string | null = null;

    private _fromTimeAvailability: TimeAvailability;

    private _availableFrom: Date | null = null;

    private _untilTimeAvailability: TimeAvailability | null = null;

    private _availableUntil: Date | null = null;

    private _rentalDetail: RentalDetail;

    private _purchaseDetail: PurchaseDetail;

    private _fundingAvailable: boolean;

    private _fundedBy: string | null = null;

    private _fundingCondition: string | null = null;

    public static createFromOfferDetailResponse(offerDetailResponse: OfferDetailResponse): OfferDetail {
        const offerDetail: OfferDetail = new this();

        offerDetail._propertyAcquisitionTypes = offerDetailResponse.propertyAcquisitionTypes;
        offerDetail._otherDetails = offerDetailResponse.otherDetails;
        offerDetail._fromTimeAvailability = offerDetailResponse.fromTimeAvailability;

        if (offerDetailResponse.availableFrom !== null) {
            offerDetail._availableFrom = new Date(offerDetailResponse.availableFrom);
        }

        offerDetail._untilTimeAvailability = offerDetailResponse.untilTimeAvailability;

        if (offerDetailResponse.availableUntil !== null) {
            offerDetail._availableUntil = new Date(offerDetailResponse.availableUntil);
        }

        offerDetail._rentalDetail = RentalDetail.createFromRentalDetailResponse(offerDetailResponse.rentalDetail);
        offerDetail._purchaseDetail = PurchaseDetail.createFromPurchaseDetailResponse(offerDetailResponse.purchaseDetail);
        offerDetail._fundingAvailable = offerDetailResponse.fundingAvailable;
        offerDetail._fundedBy = offerDetailResponse.fundedBy;
        offerDetail._fundingCondition = offerDetailResponse.fundingCondition;

        return offerDetail;
    }

    get propertyAcquisitionTypes(): PropertyAcquisitionType[] {
        return this._propertyAcquisitionTypes;
    }

    set propertyAcquisitionTypes(value: PropertyAcquisitionType[]) {
        this._propertyAcquisitionTypes = value;
    }

    get otherDetails(): string | null {
        return this._otherDetails;
    }

    set otherDetails(value: string | null) {
        this._otherDetails = value;
    }

    get fromTimeAvailability(): TimeAvailability {
        return this._fromTimeAvailability;
    }

    set fromTimeAvailability(value: TimeAvailability) {
        this._fromTimeAvailability = value;
    }

    get availableFrom(): Date | null {
        return this._availableFrom;
    }

    set availableFrom(value: Date | null) {
        this._availableFrom = value;
    }

    get untilTimeAvailability(): TimeAvailability | null {
        return this._untilTimeAvailability;
    }

    set untilTimeAvailability(value: TimeAvailability | null) {
        this._untilTimeAvailability = value;
    }

    get availableUntil(): Date | null {
        return this._availableUntil;
    }

    set availableUntil(value: Date | null) {
        this._availableUntil = value;
    }

    get rentalDetail(): RentalDetail {
        return this._rentalDetail;
    }

    set rentalDetail(value: RentalDetail) {
        this._rentalDetail = value;
    }

    get purchaseDetail(): PurchaseDetail {
        return this._purchaseDetail;
    }

    set purchaseDetail(value: PurchaseDetail) {
        this._purchaseDetail = value;
    }

    get fundingAvailable(): boolean {
        return this._fundingAvailable;
    }

    set fundingAvailable(value: boolean) {
        this._fundingAvailable = value;
    }

    get fundedBy(): string | null {
        return this._fundedBy;
    }

    set fundedBy(value: string | null) {
        this._fundedBy = value;
    }

    get fundingCondition(): string | null {
        return this._fundingCondition;
    }

    set fundingCondition(value: string | null) {
        this._fundingCondition = value;
    }
}

export default OfferDetail;
