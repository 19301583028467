import UserPasswordResetDemandResponse from '../../../../api/Llasm/User/UserPasswordResetDemandResponse';

class UserPasswordResetDemand {

    private _passwordResetUuid: string;

    private _expirationDate: Date;

    public static createFromUserPasswordResetDemandResponse(
        userPasswordResetDemandResponse: UserPasswordResetDemandResponse
    ): UserPasswordResetDemand {
        const userPasswordResetDemand: UserPasswordResetDemand = new this();

        userPasswordResetDemand._passwordResetUuid = userPasswordResetDemandResponse.passwordResetUuid;
        userPasswordResetDemand._expirationDate = new Date(userPasswordResetDemandResponse.expirationDate);

        return userPasswordResetDemand;
    }

    get passwordResetUuid(): string {
        return this._passwordResetUuid;
    }

    set passwordResetUuid(value: string) {
        this._passwordResetUuid = value;
    }

    get expirationDate(): Date {
        return this._expirationDate;
    }

    set expirationDate(value: Date) {
        this._expirationDate = value;
    }

}

export default UserPasswordResetDemand;
