import PropertyService from './PropertyService';
import LocationService from '../Location/LocationService';
import AuthenticationState from '../Entity/Authentication/AuthenticationState';
import Alert from '../Entity/Alert/Alert';
import AlertType from '../Entity/Alert/AlertType';
import FormData from '../Entity/Form/FormData';
import AccessibilityType from '../Entity/AccessibilityType';
import Country from '../Entity/Location/Country';
import Place from '../Entity/Location/Place';
import UserState from '../Entity/User/UserState';
import MarketingStatus from '../Entity/MarketingStatus';
import Property from '../Entity/Property/Property';
import LocationDetail from '../Entity/Property/LocationDetail';
import Detail from '../Entity/Property/Detail';
import OfferDetail from '../Entity/Property/OfferDetail/OfferDetail';
import RentalDetail from '../Entity/Property/OfferDetail/RentalDetail';
import PurchaseDetail from '../Entity/Property/OfferDetail/PurchaseDetail';
import AdditionalDetail from '../Entity/Property/AdditionalDetail';
import EnergyCertificate from '../Entity/Property/EnergyCertificate/EnergyCertificate';
import InformationScope from '../Entity/InformationScope';
import PropertyForm from './PropertyForm';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import MinMaxLengthOrNullValidationDefinition from '../FormValidationHandler/MinMaxLengthOrNullValidationDefinition';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import DocumentUploader from '../Document/DocumentUploader';
import ImageUploader from '../Image/ImageUploader';
import CollapseCard from '../Component/CollapseCard/CollapseCard';
import InfoBox from '../Component/InfoBox/InfoBox';
import Spinner from '../../../components/Spinner';
import AlertBox from '../../../components/AlertBox';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import React, {useEffect, useRef, useState} from 'react';
import {Link, NavigateFunction, useNavigate} from 'react-router-dom';

const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.');

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Du hast nicht alle Pflichtfelder gefüllt. Bitte kontrolliere die mit einem roten Ausrufezeichen markierten Felder.');

const locationService: LocationService = new LocationService(process.env.REACT_APP_LLASM_API_URL!);

const fieldValidationDefinitions: FieldValidationDefinition<Property>[] = [
    new RequiredValidationDefinition<Property>('marketingStatus', 'Ein Vermarktungsstatus muss gewählt sein.'),
    new RequiredValidationDefinition<Property>('title', 'Bezeichnung des Objekts muss ausgefüllt sein.'),
    new MinMaxLengthOrNullValidationDefinition('internalDesignation', 0, 35, 'Die interne Bezeichnung darf maximal 35 Zeichen lang sein.'),
    new RequiredValidationDefinition<Property>('contacts', 'Es muss mindestens ein Ansprechpartner angegeben werden.'),
];

const formValidationHandler: FormValidationHandler<Property> = new FormValidationHandler<Property>(fieldValidationDefinitions);

const propertyService: PropertyService = new PropertyService(process.env.REACT_APP_LLASM_API_URL!);

const createProperty = (): Property => {
    const property: Property = new Property();

    property.active = false;
    property.marketingStatus = MarketingStatus.Available;
    property.locationDetail = new LocationDetail();
    property.detail = new Detail();
    property.offerDetail = new OfferDetail();
    property.additionalDetail = new AdditionalDetail();
    property.offerDetail.rentalDetail = new RentalDetail();
    property.offerDetail.purchaseDetail = new PurchaseDetail();
    property.offerDetail.fundingAvailable = false;
    property.detail.accessibilityType = AccessibilityType.Available;
    property.detail.groundLevelSalesArea = false;
    property.detail.parkingLotAvailable = false;
    property.additionalDetail.storeSpaceInterestPopupConcept = false;
    property.additionalDetail.storeSpaceInterestMixedUseConcept = false;
    property.informationScope = InformationScope.Complete;

    return property;
};

const PropertyCreatePage = (): React.JSX.Element => {
    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const {user}: UserState = useAppSelector<UserState>(state => state.user);

    const [property, setProperty] = useState<Property>(createProperty());

    const [formData, setFormData] = useState<FormData<Property>>({data: property});

    const [locationDetailFormData, setLocationDetailFormData] = useState<FormData<LocationDetail>>();

    const [detailFormData, setDetailFormData] = useState<FormData<Detail>>();

    const [offerDetailFormData, setOfferDetailFormData] = useState<FormData<OfferDetail>>();

    const [rentalDetailFormData, setRentalDetailFormData] = useState<FormData<RentalDetail>>();

    const [purchaseDetailFormData, setPurchaseDetailFormData] = useState<FormData<PurchaseDetail>>();

    const [additionalDetailFormData, setAdditionalDetailFormData] = useState<FormData<AdditionalDetail>>();

    const [energyCertificateFormData, setEnergyCertificateFormData] = useState<FormData<EnergyCertificate> | null>();

    const [countryPlaces, setCountryPlaces] = useState<Place[]>();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [alert, setAlert] = useState<Alert>();

    const [isImageUploadComplete, setIsImageUploadComplete] = useState<boolean>(false);

    const [numberOfImageUploads, setNumberOfImageUploads] = useState<number>(0);

    const imageUploaderRef: React.Ref<any> = useRef<React.ForwardedRef<any>>();

    const [isDocumentUploadComplete, setIsDocumentUploadComplete] = useState<boolean>(false);

    const [numberOfDocumentUploads, setNumberOfDocumentUploads] = useState<number>(0);

    const documentUploaderRef: React.Ref<any> = useRef<React.ForwardedRef<any>>();

    const navigate: NavigateFunction = useNavigate();

    const dispatch = useAppDispatch();

    useEffect((): void => {
        fetchCountryPlaces();
    }, []);

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Meine Flächen', link: '/flaechen'},
                {name: 'Fläche anlegen'},
            ]
        });
    }, [dispatch]);

    useEffect((): void => {
        if (isImageUploadComplete === false) {
            return;
        }

        if (isDocumentUploadComplete === false) {
            return;
        }

        setIsLoading(false);

        localStorage.removeItem('initialEntry');

        navigate('/flaechen');
    }, [isImageUploadComplete, isDocumentUploadComplete]);

    useEffect((): void => {
        if (formData.data.active === false) {
            return;
        }

        persistProperty();
    }, [formData.data.active]);

    const resetActive = (): void => {
        property.active = false;

        setProperty(property);
        setFormData({...formData, data: property});
    };

    const activateProperty = (): void => {
        property.active = true;

        setProperty(property);
        setFormData({...formData, data: property});
    };

    const persistProperty = async (): Promise<void> => {
        if (locationDetailFormData !== undefined && locationDetailFormData.formValidationHandler !== undefined) {
            locationDetailFormData.formValidationHandler.validate(locationDetailFormData);
        }

        if (detailFormData !== undefined && detailFormData.formValidationHandler !== undefined) {
            detailFormData.formValidationHandler.validate(detailFormData);
        }

        if (offerDetailFormData !== undefined && offerDetailFormData.formValidationHandler !== undefined) {
            offerDetailFormData.formValidationHandler.validate(offerDetailFormData);
        }

        if (rentalDetailFormData !== undefined && rentalDetailFormData.formValidationHandler !== undefined) {
            rentalDetailFormData.formValidationHandler.validate(rentalDetailFormData);
        }

        if (purchaseDetailFormData !== undefined && purchaseDetailFormData.formValidationHandler !== undefined) {
            purchaseDetailFormData.formValidationHandler.validate(purchaseDetailFormData);
        }

        if (additionalDetailFormData !== undefined && additionalDetailFormData.formValidationHandler !== undefined) {
            additionalDetailFormData.formValidationHandler.validate(additionalDetailFormData);
        }

        if (energyCertificateFormData !== undefined && energyCertificateFormData !== null && energyCertificateFormData.formValidationHandler !== undefined) {
            energyCertificateFormData.formValidationHandler.validate(energyCertificateFormData);
        }

        formValidationHandler.validate(formData);

        if (
            formValidationHandler.hasErrors(formData) === true
            || (locationDetailFormData !== undefined && locationDetailFormData.formValidationHandler !== undefined && locationDetailFormData.formValidationHandler.hasErrors(locationDetailFormData) === true)
            || (detailFormData !== undefined && detailFormData.formValidationHandler !== undefined && detailFormData.formValidationHandler.hasErrors(detailFormData) === true)
            || (offerDetailFormData !== undefined && offerDetailFormData.formValidationHandler !== undefined && offerDetailFormData.formValidationHandler.hasErrors(offerDetailFormData) === true)
            || (rentalDetailFormData !== undefined && rentalDetailFormData.formValidationHandler !== undefined && rentalDetailFormData.formValidationHandler.hasErrors(rentalDetailFormData) === true)
            || (purchaseDetailFormData !== undefined && purchaseDetailFormData.formValidationHandler !== undefined && purchaseDetailFormData.formValidationHandler.hasErrors(purchaseDetailFormData) === true)
            || (additionalDetailFormData !== undefined && additionalDetailFormData.formValidationHandler !== undefined && additionalDetailFormData.formValidationHandler.hasErrors(additionalDetailFormData) === true)
            || (energyCertificateFormData !== undefined && energyCertificateFormData !== null && energyCertificateFormData.formValidationHandler !== undefined && energyCertificateFormData.formValidationHandler.hasErrors(energyCertificateFormData) === true)
        ) {
            resetActive();

            setAlert(formErrorAlert);

            window.scrollTo(0, 0);

            return;
        }

        setAlert(undefined);

        setIsLoading(true);

        const property: Property = formData.data;

        if (energyCertificateFormData === null) {
            property.energyCertificate = null;
        }

        try {
            const persistedProperty: Property = await propertyService.persistProperty(property);

            if (numberOfImageUploads > 0) {
                imageUploaderRef!.current.uploadFiles(process.env.REACT_APP_LLASM_API_URL! + '/v1/properties' + '/' + persistedProperty.id + '/images/upload');
            } else {
                onImageUploadQueueComplete();
            }

            if (numberOfDocumentUploads > 0) {
                documentUploaderRef!.current.uploadFiles(process.env.REACT_APP_LLASM_API_URL! + '/v1/properties' + '/' + persistedProperty.id + '/documents/upload');
            } else {
                onDocumentUploadQueueComplete();
            }
        } catch (error) {
            resetActive();

            setAlert(errorAlert);

            setIsLoading(false);
        }
    };

    const onImageUploadQueueComplete = (): void => {
        setIsImageUploadComplete(true);
    };

    const onDocumentUploadQueueComplete = (): void => {
        setIsDocumentUploadComplete(true);
    };

    const fetchCountryPlaces = async (): Promise<void> => {
        const countries: Country[] =  await locationService.fetchCountries(['DE', 'AT']);

        const countryPlaces: Place[] = countries.map((country: Country): Place => {
            if (country.countryPlace === null) {
                throw new Error('Country must have a country place!');
            }

            return country.countryPlace;
        });

        setCountryPlaces(countryPlaces);
    };

    if (countryPlaces === undefined || user === undefined) {
        return <Spinner />;
    }

    return (
        <>
            {isLoading === true &&
                <Spinner />
            }
            <div className="container-fluid ps-5 pe-5 pb-4">
                <div className="row align-items-center mb-4">
                    <div className="col-12 col-md-8 d-flex align-items-center">
                        <Link to="/flaechen"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                        <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Fläche/Ladenlokal anlegen</h1>
                    </div>
                </div>
                <InfoBox cardType="outline" className="p-3 mb-3">
                    <div className="fs-5">
                        Hier kannst Du Deine Fläche anlegen, für die Du einen Nachnutzer suchst. Bitte fülle dazu die untenstehenden Angaben zu Deinem Objekt aus.
                        Pflichtfelder sind dabei mit einem * gekennzeichnet.
                        Über die Info-Symbole erhältst Du weitere Informationen.
                    </div>
                    <div className="mb-0 fs-5">
                        Bitte stelle sicher, dass Du möglichst viele Angaben machst, so ist ein passgenaues Matching mit Flächengesuchen möglich.
                    </div>
                </InfoBox>
                {alert !== undefined &&
                    <div className="mb-3">
                        <AlertBox alert={alert} autoDismiss={false} />
                    </div>
                }
                <div className="mb-3">
                    <PropertyForm
                        formData={formData}
                        setFormData={setFormData}
                        countryPlaces={countryPlaces}
                        defaultCountryPlace={user?.country.countryPlace ?? undefined}
                        formValidationHandler={formValidationHandler}
                        setLocationDetailFormData={setLocationDetailFormData}
                        setDetailFormData={setDetailFormData}
                        setOfferDetailFormData={setOfferDetailFormData}
                        setRentalDetailFormData={setRentalDetailFormData}
                        setPurchaseDetailFormData={setPurchaseDetailFormData}
                        setAdditionalDetailFormData={setAdditionalDetailFormData}
                        setEnergyCertificateFormData={setEnergyCertificateFormData}
                    />
                    <CollapseCard cardType="shadow" title="Objektbilder" className="mb-3">
                        <p className="small mb-3">Hier kannst Du Deine favorisierten Bilder hochladen – ob Objektbilder, Grundrisse oder der Energieausweis. Die möglichen Bildformate sind jpg und png, die maximale Dateigröße beträgt 5 MB.</p>
                        <ImageUploader
                            ref={imageUploaderRef}
                            images={property.images}
                            isProcessingImages={false}
                            token={authenticatedUser!.token}
                            autoProcessQueue={false}
                            setNumberOfImageUploads={setNumberOfImageUploads}
                            onQueueComplete={onImageUploadQueueComplete}
                            uploadText="Zieh hier schöne Bilder Deines Objekts rein"
                        />
                    </CollapseCard>
                    <CollapseCard cardType="shadow" title="Dokumente" className="mb-3">
                        <DocumentUploader
                            ref={documentUploaderRef}
                            documents={property.documents}
                            isProcessingDocuments={false}
                            token={authenticatedUser!.token}
                            autoProcessQueue={false}
                            setNumberOfDocumentUploads={setNumberOfDocumentUploads}
                            onQueueComplete={onDocumentUploadQueueComplete}
                            uploadText="Zieh hier Dokumente zu Deinem Objekt rein"
                        />
                    </CollapseCard>
                    <div>
                        <button className="btn btn-secondary mt-3 me-3" type="button" onClick={persistProperty}>
                            Fläche anlegen
                        </button>
                        <button className="btn btn-primary mt-3" type="button" onClick={activateProperty}>
                            Fläche anlegen und aktivieren
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PropertyCreatePage;
