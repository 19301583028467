enum CompanyValue {
    Sustainability = 0,
    Specialized = 1,
    Innovativ = 2,
    Networking = 3,
    RelaxationRecreation = 4,
    Creativity = 5,
    ExperienceEntertainment = 6,
    LocalityRegionality = 7,
    ServiceOriented = 8,
    VitalityVibrancy = 9,
    ShopWindowDressing = 10,
    ShopFitting = 11,
    Digitalisation = 12
}

const getCompanyValueLabel = (companyValue: CompanyValue): string => {
    switch (companyValue) {
        case CompanyValue.Sustainability:
            return 'Nachhaltigkeit';
        case CompanyValue.Specialized:
            return 'spezialisiert';
        case CompanyValue.Innovativ:
            return 'innovativ';
        case CompanyValue.Networking:
            return 'Vernetzung';
        case CompanyValue.RelaxationRecreation:
            return 'Entspannung & Erholung';
        case CompanyValue.Creativity:
            return 'Kreativität';
        case CompanyValue.ExperienceEntertainment:
            return 'Erlebnis & Unterhaltung';
        case CompanyValue.LocalityRegionality:
            return 'Lokalität & Regionalität';
        case CompanyValue.ServiceOriented:
            return 'serviceorientiert';
        case CompanyValue.VitalityVibrancy:
            return 'Vitalität & Lebendigkeit';
        case CompanyValue.ShopWindowDressing:
            return 'Schaufenstergestalitung';
        case CompanyValue.ShopFitting:
            return 'Ladenbau';
        case CompanyValue.Digitalisation:
            return 'Digitalisierung';
        default:
            throw new Error();
    }
};

export default CompanyValue;
export {getCompanyValueLabel};
