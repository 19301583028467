enum SpecialObjectCategory {
    PopUpArea = 0,
    MixedUseArea = 1,
    CoWorkingSpace = 2,
    DepartmentStore = 3
}

const getSpecialObjectCategoryLabel = (specialObjectCategory: SpecialObjectCategory): string => {
    switch (specialObjectCategory) {
        case SpecialObjectCategory.PopUpArea:
            return 'Pop-Up Fläche';
        case SpecialObjectCategory.MixedUseArea:
            return 'Mixed-Use Fläche';
        case SpecialObjectCategory.CoWorkingSpace:
            return 'Co-Working-Space';
        case SpecialObjectCategory.DepartmentStore:
            return 'Kaufhaus';
        default:
            throw new Error();
    }
};

export default SpecialObjectCategory;
export {getSpecialObjectCategoryLabel};
