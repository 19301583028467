enum BuildingCondition {
    NewBuilding = 0,
    AsNew = 1,
    Renovated = 2,
    Modernized = 3,
    WellLookedAfter = 4,
    Shell = 5
}

const getBuildingConditionLabel = (buildingCondition: BuildingCondition): string => {
    switch (buildingCondition) {
        case BuildingCondition.NewBuilding:
            return 'Neubau';
        case BuildingCondition.AsNew:
            return 'Neuwertig';
        case BuildingCondition.Renovated:
            return 'Saniert';
        case BuildingCondition.Modernized:
            return 'Modernisiert';
        case BuildingCondition.WellLookedAfter:
            return 'Gepflegt';
        case BuildingCondition.Shell:
            return 'Rohbau';
        default:
            throw new Error();
    }
};

export default BuildingCondition;
export {getBuildingConditionLabel};
