import FormData from '../Entity/Form/FormData';
import UserPasswordReset from '../Entity/User/UserPasswordReset';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import InputField from '../Component/Form/Field/InputField';
import React, {ChangeEvent} from 'react';

interface UserPasswordResetFormProps {
    readonly formData: FormData<UserPasswordReset>;
    readonly setFormData: Function;
    readonly formValidationHandler?: FormValidationHandler<UserPasswordReset>;
}

const UserPasswordResetForm = (props: UserPasswordResetFormProps): React.JSX.Element => {
    const userPasswordReset: UserPasswordReset = props.formData.data;

    const handleChange = (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {

        (userPasswordReset as any)[event.target.name] = event.target.value;

        updateFormData();
        validateField(event.target.name);
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: userPasswordReset});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <>
            <div className="mb-3">
                <InputField
                    name="password"
                    label="Passwort"
                    type="password"
                    required={true}
                    value={userPasswordReset.password}
                    onChange={handleChange}
                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'password')}
                />
            </div>
            <div className="mb-3">
                <InputField
                    name="passwordRepeat"
                    label="Passwort bestätigen"
                    type="password"
                    required={true}
                    value={userPasswordReset.passwordRepeat}
                    onChange={handleChange}
                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'passwordRepeat')}
                />
            </div>
        </>
    );
};

export default UserPasswordResetForm;
