import ImageResponse from '../../../../api/Llasm/Image/ImageResponse';

class Image {
    private _id?: number;

    private _title: string | null = null;

    private _mimeType: string;

    private _fileContentAsBase64: string | null = null;

    public static createFromImageResponse(imageResponse: ImageResponse): Image {
        const image: Image = new this();

        image._id = imageResponse.id;
        image._title = imageResponse.title;
        image._mimeType = imageResponse.mimeType;
        image._fileContentAsBase64 = imageResponse.fileContentAsBase64;

        return image;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get title(): string | null {
        return this._title;
    }

    set title(value: string | null) {
        this._title = value;
    }

    get mimeType(): string {
        return this._mimeType;
    }

    set mimeType(value: string) {
        this._mimeType = value;
    }

    get fileContentAsBase64(): string | null {
        return this._fileContentAsBase64;
    }

    set fileContentAsBase64(value: string | null) {
        this._fileContentAsBase64 = value;
    }
}

export default Image;
