import PropertyService from './PropertyService';
import LocationService from '../Location/LocationService';
import AuthenticationState from '../Entity/Authentication/AuthenticationState';
import Alert from '../Entity/Alert/Alert';
import AlertType from '../Entity/Alert/AlertType';
import Property from '../Entity/Property/Property';
import LocationDetail from '../Entity/Property/LocationDetail';
import Detail from '../Entity/Property/Detail';
import OfferDetail from '../Entity/Property/OfferDetail/OfferDetail';
import AdditionalDetail from '../Entity/Property/AdditionalDetail';
import FormData from '../Entity/Form/FormData';
import Image from '../Entity/Image/Image';
import Place from '../Entity/Location/Place';
import Country from '../Entity/Location/Country';
import Document from '../Entity/Document/Document';
import EnergyCertificate from '../Entity/Property/EnergyCertificate/EnergyCertificate';
import RentalDetail from '../Entity/Property/OfferDetail/RentalDetail';
import PurchaseDetail from '../Entity/Property/OfferDetail/PurchaseDetail';
import PropertyForm from './PropertyForm';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import MinMaxLengthOrNullValidationDefinition from '../FormValidationHandler/MinMaxLengthOrNullValidationDefinition';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import DocumentUploader from '../Document/DocumentUploader';
import ImageUploader from '../Image/ImageUploader';
import CollapseCard from '../Component/CollapseCard/CollapseCard';
import InfoButtonOverlay from '../Component/InfoButtonOverlay/InfoButtonOverlay';
import AlertBox from '../../../components/AlertBox';
import Spinner from '../../../components/Spinner';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams} from 'react-router-dom';

const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.');

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Du hast nicht alle Pflichtfelder gefüllt. Bitte kontrolliere die mit einem roten Ausrufezeichen markierten Felder.');

const successAlert: Alert = new Alert(AlertType.Success, 'Ladenlokal wurde erfolgreich aktualisiert.');

const locationService: LocationService = new LocationService(process.env.REACT_APP_LLASM_API_URL!);

const fieldValidationDefinitions: FieldValidationDefinition<Property>[] = [
    new RequiredValidationDefinition<Property>('marketingStatus', 'Ein Vermarktungsstatus muss gewählt sein.'),
    new RequiredValidationDefinition<Property>('title', 'Bezeichnung des Objekts muss ausgefüllt sein.'),
    new MinMaxLengthOrNullValidationDefinition('internalDesignation', 0, 35, 'Die interne Bezeichnung darf maximal 35 Zeichen lang sein.'),
    new RequiredValidationDefinition<Property>('contacts', 'Es muss mindestens ein Ansprechpartner angegeben werden.'),
];

const formValidationHandler: FormValidationHandler<Property> = new FormValidationHandler<Property>(fieldValidationDefinitions);

const propertyService: PropertyService = new PropertyService(process.env.REACT_APP_LLASM_API_URL!);

const PropertyPage = (): React.JSX.Element => {
    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const {propertyId} = useParams<string>();

    const [property, setProperty] = useState<Property>();

    const [formData, setFormData] = useState<FormData<Property>>({data: property!});

    const [locationDetailFormData, setLocationDetailFormData] = useState<FormData<LocationDetail>>();

    const [detailFormData, setDetailFormData] = useState<FormData<Detail>>();

    const [offerDetailFormData, setOfferDetailFormData] = useState<FormData<OfferDetail>>();

    const [rentalDetailFormData, setRentalDetailFormData] = useState<FormData<RentalDetail>>();

    const [purchaseDetailFormData, setPurchaseDetailFormData] = useState<FormData<PurchaseDetail>>();

    const [additionalDetailFormData, setAdditionalDetailFormData] = useState<FormData<AdditionalDetail>>();

    const [energyCertificateFormData, setEnergyCertificateFormData] = useState<FormData<EnergyCertificate> | null>();

    const [countryPlaces, setCountryPlaces] = useState<Place[]>();

    const [images, setImages] = useState<Image[]>([]);

    const [documents, setDocuments] = useState<Document[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [isProcessingImages, setIsProcessingImages] = useState<boolean>(false);

    const [isProcessingDocuments, setIsProcessingDocuments] = useState<boolean>(false);

    const [alert, setAlert] = useState<Alert>();

    const [numberOfImageUploads, setNumberOfImageUploads] = useState<number>(0);

    const imageUploaderRef: React.Ref<any> = useRef<React.ForwardedRef<any>>();

    const [numberOfDocumentUploads, setNumberOfDocumentUploads] = useState<number>(0);

    const documentUploaderRef: React.Ref<any> = useRef<React.ForwardedRef<any>>();

    const dispatch = useAppDispatch();

    useEffect((): void => {
        fetchCountryPlaces();
    }, []);

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Meine Flächen', link: '/flaechen'},
                {name: 'Fläche bearbeiten'},
            ]
        });
    }, [dispatch]);

    useEffect((): void => {
        fetchData();
    }, []);

    useEffect((): void => {
        if (property === undefined) {
            return;
        }

        // TODO - only Owner can show this page
    }, [property]);

    const fetchData = async (): Promise<void> => {
        setIsLoading(true);

        await fetchProperty();

        setIsLoading(false);
    };

    const fetchProperty = async (): Promise<void> => {
        const property: Property = await propertyService.fetchPropertyFromApiById(Number(propertyId));

        setFormData({...formData, data: property});

        setProperty(property);

        setImages(property.images);

        setDocuments(property.documents);
    };

    const fetchImages = async (): Promise<void> => {
        if (property === undefined) {
            return ;
        }

        setIsProcessingImages(true);

        property.images = await propertyService.fetchImagesFromProperty(property);

        setIsProcessingImages(false);

        setImages(property.images);
    };

    const fetchDocuments = async (): Promise<void> => {
        if (property === undefined) {
            return ;
        }
        setIsProcessingDocuments(true);

        property.documents = await propertyService.fetchDocumentsFromProperty(property);

        setIsProcessingDocuments(false);
        setDocuments(property.documents);
    };

    const deleteImage = async (image: Image): Promise<void> => {
        if (property === undefined) {
            return ;
        }

        setIsProcessingImages(true);

        await propertyService.deleteImageFromProperty(property, image);

        await fetchImages();

        setIsProcessingImages(false);
    };

    const updateProperty = async (): Promise<void> => {
        if (locationDetailFormData !== undefined && locationDetailFormData.formValidationHandler !== undefined) {
            locationDetailFormData.formValidationHandler.validate(locationDetailFormData);
        }

        if (detailFormData !== undefined && detailFormData.formValidationHandler !== undefined) {
            detailFormData.formValidationHandler.validate(detailFormData);
        }

        if (offerDetailFormData !== undefined && offerDetailFormData.formValidationHandler !== undefined) {
            offerDetailFormData.formValidationHandler.validate(offerDetailFormData);
        }

        if (rentalDetailFormData !== undefined && rentalDetailFormData.formValidationHandler !== undefined) {
            rentalDetailFormData.formValidationHandler.validate(rentalDetailFormData);
        }

        if (purchaseDetailFormData !== undefined && purchaseDetailFormData.formValidationHandler !== undefined) {
            purchaseDetailFormData.formValidationHandler.validate(purchaseDetailFormData);
        }

        if (additionalDetailFormData !== undefined && additionalDetailFormData.formValidationHandler !== undefined) {
            additionalDetailFormData.formValidationHandler.validate(additionalDetailFormData);
        }

        if (energyCertificateFormData !== undefined && energyCertificateFormData !== null && energyCertificateFormData.formValidationHandler !== undefined) {
            energyCertificateFormData.formValidationHandler.validate(energyCertificateFormData);
        }

        formValidationHandler.validate(formData);

        if (
            formValidationHandler.hasErrors(formData) === true
            || (locationDetailFormData !== undefined && locationDetailFormData.formValidationHandler !== undefined && locationDetailFormData.formValidationHandler.hasErrors(locationDetailFormData) === true)
            || (detailFormData !== undefined && detailFormData.formValidationHandler !== undefined && detailFormData.formValidationHandler.hasErrors(detailFormData) === true)
            || (offerDetailFormData !== undefined && offerDetailFormData.formValidationHandler !== undefined && offerDetailFormData.formValidationHandler.hasErrors(offerDetailFormData) === true)
            || (rentalDetailFormData !== undefined && rentalDetailFormData.formValidationHandler !== undefined && rentalDetailFormData.formValidationHandler.hasErrors(rentalDetailFormData) === true)
            || (purchaseDetailFormData !== undefined && purchaseDetailFormData.formValidationHandler !== undefined && purchaseDetailFormData.formValidationHandler.hasErrors(purchaseDetailFormData) === true)
            || (additionalDetailFormData !== undefined && additionalDetailFormData.formValidationHandler !== undefined && additionalDetailFormData.formValidationHandler.hasErrors(additionalDetailFormData) === true)
            || (energyCertificateFormData !== undefined && energyCertificateFormData !== null && energyCertificateFormData.formValidationHandler !== undefined && energyCertificateFormData.formValidationHandler.hasErrors(energyCertificateFormData) === true)
        ) {
            setAlert(formErrorAlert);

            window.scrollTo(0, 0);

            return;
        }

        setAlert(undefined);

        setIsLoading(true);

        const property: Property = formData.data;

        if (energyCertificateFormData === null) {
            property.energyCertificate = null;
        }

        try {
            await propertyService.updateProperty(property);

            setAlert(successAlert);

            await fetchProperty();
        } catch (error) {
            setAlert(errorAlert);

            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCountryPlaces = async (): Promise<void> => {
        const countries: Country[] =  await locationService.fetchCountries(['DE', 'AT']);

        const countryPlaces: Place[] = countries.map((country: Country): Place => {
            if (country.countryPlace === null) {
                throw new Error('Country must have a country place!');
            }

            return country.countryPlace;
        });

        setCountryPlaces(countryPlaces);
    };

    const buildImageGetPath = (image: Image): string => {
        if (property === undefined) {
            throw new Error();
        }

        return PropertyService.buildImageApiPath(property, image);
    };

    const buildImageDeletePath = (image: Image): string => {
        if (property === undefined) {
            throw new Error();
        }

        return PropertyService.buildImageApiPath(property, image);
    };

    const buildDocumentGetPath = (document: Document): string => {
        if (property === undefined) {
            throw new Error();
        }

        return PropertyService.buildDocumentApiPath(property, document);
    };

    const buildDocumentPatchPath = (document: Document): string => {
        if (property === undefined) {
            throw new Error();
        }

        return PropertyService.buildDocumentApiPath(property, document);
    };

    const buildDocumentDeletePath = (document: Document): string => {
        if (property === undefined) {
            throw new Error();
        }

        return PropertyService.buildDocumentApiPath(property, document);
    };

    if (isLoading === true || countryPlaces === undefined) {
        return <Spinner />;
    }

    return (
        <div className="container-fluid ps-5 pe-5 pb-4">
            <div className="d-flex align-items-center">
                <Link to="/flaechen"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Fläche/Ladenlokal bearbeiten</h1>
                <InfoButtonOverlay title="Fläche/Ladenlokal bearbeiten" className="fs-5 me-3 ms-auto">
                    <div className="mb-3">
                        Hier kannst Du Dein Ladenlokal/Deine Immobilie bearbeiten. Bitte fülle dazu die
                        untenstehenden Angaben zu Deinem Objekt aus. Pflichtfelder sind dabei mit einem
                        * gekennzeichnet. Über die Info-Symbole erhältst Du weitere Informationen.
                    </div>
                    <div className="mb-3">
                        Bitte stelle sicher, dass Du möglichst viele Angaben machst, so ist ein
                        passgenaues Matching mit unserer Datenbank an Suchprofilen möglich.
                    </div>
                </InfoButtonOverlay>
            </div>
            {alert !== undefined &&
                <div className="mb-3">
                    <AlertBox alert={alert} autoDismiss={false} />
                </div>
            }
            <div className="mb-3">
                <PropertyForm
                    formData={formData}
                    setFormData={setFormData}
                    countryPlaces={countryPlaces}
                    formValidationHandler={formValidationHandler}
                    setLocationDetailFormData={setLocationDetailFormData}
                    setDetailFormData={setDetailFormData}
                    setOfferDetailFormData={setOfferDetailFormData}
                    setRentalDetailFormData={setRentalDetailFormData}
                    setPurchaseDetailFormData={setPurchaseDetailFormData}
                    setAdditionalDetailFormData={setAdditionalDetailFormData}
                    setEnergyCertificateFormData={setEnergyCertificateFormData}
                />
                <CollapseCard cardType="shadow" title="Objektbilder" className="mb-3">
                    <p className="small mb-3">Hier kannst Du Deine favorisierten Bilder hochladen – ob Objektbilder, Grundrisse oder der Energieausweis. Die möglichen Bildformate sind jpg und png, die maximale Dateigröße beträgt 5 MB.</p>
                    <ImageUploader
                        ref={imageUploaderRef}
                        images={images}
                        isProcessingImages={isProcessingImages}
                        token={authenticatedUser!.token}
                        autoProcessQueue={true}
                        setNumberOfImageUploads={setNumberOfImageUploads}
                        buildImageGetPath={buildImageGetPath}
                        buildImageDeletePath={buildImageDeletePath}
                        setIsProcessingImages={setIsProcessingImages}
                        uploadUrl={process.env.REACT_APP_LLASM_API_URL! + '/v1/properties' + '/' + property!.id!+ '/images/upload'}
                        deleteImage={deleteImage}
                        onQueueComplete={fetchImages}
                        uploadText="Zieh hier schöne Bilder Deines Objekts rein"
                        onDeleted={fetchImages}
                    />
                </CollapseCard>
                <CollapseCard cardType="shadow" title="Dokumente" className="mb-3">
                    <DocumentUploader
                        ref={documentUploaderRef}
                        documents={documents}
                        isProcessingDocuments={isProcessingDocuments}
                        token={authenticatedUser!.token}
                        autoProcessQueue={true}
                        setNumberOfDocumentUploads={setNumberOfDocumentUploads}
                        buildDocumentGetPath={buildDocumentGetPath}
                        buildDocumentPatchPath={buildDocumentPatchPath}
                        buildDocumentDeletePath={buildDocumentDeletePath}
                        setIsProcessingDocuments={setIsProcessingDocuments}
                        uploadUrl={process.env.REACT_APP_LLASM_API_URL! + '/v1/properties' + '/' + property!.id + '/documents/upload'}
                        onQueueComplete={fetchDocuments}
                        uploadText="Zieh hier Dokumente zu Deinem Objekt rein"
                        onDeleted={fetchDocuments}
                    />
                </CollapseCard>
                <button className="btn btn-primary d-flex align-items-center mt-3" type="button" onClick={updateProperty}>Speichern</button>
            </div>
        </div>
    );
};

export default PropertyPage;
