import SettlementConcept from '../../Entity/SettlementConcept/SettlementConcept';
import Property from '../../Entity/Property/Property';
import dropdownMenuStyle from './DropdownMenu.module.scss';
import React from 'react';

interface DropdownMenuProps {
    readonly settlementConcepts: SettlementConcept[];
    readonly properties: Property[];
    readonly selectedItem: SettlementConcept | Property | undefined;
    readonly setSelectedItem: (selectedItem: SettlementConcept | Property) => void;
}

const DropdownMenu = (props: DropdownMenuProps): React.JSX.Element => {
    return (
        <div className={dropdownMenuStyle.dropdownSelect}>
            <div className="dropdown-center d-flex justify-content-center">
                <button className="btn dropdown-toggle text-start px-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {props.selectedItem === undefined &&
                        <span className={dropdownMenuStyle.buttonText}>Bitte wählen</span>
                    }

                    {props.selectedItem instanceof SettlementConcept &&
                        <>
                            <span className={dropdownMenuStyle.buttonText}><span className="fw-bold">{props.selectedItem.reference}: </span>{props.selectedItem.title}</span><br />
                            <span className={['text-primary', dropdownMenuStyle.smallButtonText].join(' ')}>Cool! Das passt zusammen!</span>
                        </>
                    }

                    {props.selectedItem instanceof Property &&
                        <>
                            <span className={dropdownMenuStyle.buttonText}><span className="fw-bold">#{props.selectedItem.id}: </span>{props.selectedItem.title}</span><br />
                            <span className={['text-primary', dropdownMenuStyle.smallButtonText].join(' ')}>Cool! Das passt zusammen!</span>
                        </>
                    }
                </button>
                <div className={['dropdown-menu', 'p-3', dropdownMenuStyle.dropdownMenu, dropdownMenuStyle.multiColumn, (props.properties.length > 0 && props.settlementConcepts.length > 0) ? dropdownMenuStyle.columns2 : dropdownMenuStyle.columns1].join(' ')}>
                    <div className="row">
                        {props.properties.length > 0 &&
                            <div className={((props.properties.length > 0 && props.settlementConcepts.length > 0) ? 'col-sm-6' : 'col-sm-12')}>
                                <p className="fw-bold text-center">Meine Flächen/Ladenlokale</p>
                                <ul className={dropdownMenuStyle.multiColumnDropdown}>
                                    {props.properties.map((property: Property, index: number): React.JSX.Element => (
                                        <li key={'property-' + index} className="mb-2" onClick={(): void => props.setSelectedItem(property)}><span className="fw-bold">#{property.id}:</span> {property.title}</li>
                                    ))}
                                </ul>
                            </div>
                        }
                        {props.settlementConcepts.length > 0 &&
                            <div className="col-sm-6">
                                <p className="fw-bold text-center">Meine Suchprofile</p>
                                <ul className={dropdownMenuStyle.multiColumnDropdown}>
                                    {props.settlementConcepts.map((settlementConcept: SettlementConcept, index: number): React.JSX.Element => (
                                        <li key={'settlementConcept-' + index} className="mb-2" onClick={(): void => props.setSelectedItem(settlementConcept)}><span className="fw-bold">{settlementConcept.reference}:</span> {settlementConcept.title}</li>
                                    ))}
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DropdownMenu;
