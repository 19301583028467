import Property from '../Property/Property';
import MarketplaceAdResponse from '../../../../api/Llasm/Marketplace/MarketplaceAdResponse';

class MarketplaceAd {
    private _id?: number;

    private _property: Property;

    private _imprint: string | null;

    private _endDate: Date;

    public static createFromMarketplaceAdResponse(marketplaceAdResponse: MarketplaceAdResponse): MarketplaceAd {
        const marketplaceAd: MarketplaceAd = new this();

        marketplaceAd._id = marketplaceAdResponse.id;
        marketplaceAd._property = Property.createFromPropertyResponse(marketplaceAdResponse.property);
        marketplaceAd._imprint = marketplaceAdResponse.imprint;
        marketplaceAd._endDate = new Date(marketplaceAdResponse.endDate);

        return marketplaceAd;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get property(): Property {
        return this._property;
    }

    set property(value: Property) {
        this._property = value;
    }

    get imprint(): string | null {
        return this._imprint;
    }

    set imprint(value: string | null) {
        this._imprint = value;
    }

    get endDate(): Date {
        return this._endDate;
    }

    set endDate(value: Date) {
        this._endDate = value;
    }
}

export default MarketplaceAd;
