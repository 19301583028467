import PropertyInterestFeedback from '../../Entity/PropertyInterest/PropertyInterestFeedback';
import PropertyInterest from '../../Entity/PropertyInterest/PropertyInterest';
import ParticipatingUser from '../../Entity/Messaging/ParticipatingUser';
import BootstrapIcon from '../../Component/Icon/BootstrapIcon';
import Tooltip from '../../Component/Tooltip/Tooltip';
import React from 'react';

interface PropertyInterestFeedbackStatusProps {
    readonly propertyInterest: PropertyInterest | null;
    readonly participatingUser: ParticipatingUser;
}

const PropertyInterestFeedbackStatus = (props: PropertyInterestFeedbackStatusProps): React.JSX.Element => {
    return (
        <>
            {props.propertyInterest === null &&
                <>
                    <BootstrapIcon iconName="emoji-neutral-fill" color="lightgrey" />
                    <span  className="text-primary ms-1">
                        - Gefällt dir diese Fläche? Gib {props.participatingUser.name} dein Feedback!
                    </span>
                    <Tooltip
                        title="Mit einem Klick auf einen der obenstehenden Buttons kannst Du dem Anbieter der Fläche direkt mitteilen, ob Du Interesse hast oder nicht. Der Flächenanbieter sieht diese Information in seinem Chat. Details kannst Du in einer Nachricht schreiben."
                        icon={<i className="bi bi-info-circle"></i>}
                        className="text-info ms-1"
                    />
                </>
            }
            {props.propertyInterest !== null &&
                <>
                    {props.propertyInterest.propertyInterestFeedback === PropertyInterestFeedback.Interested &&
                        <BootstrapIcon iconName="emoji-laughing-fill" color="primary" />
                    }
                    {props.propertyInterest.propertyInterestFeedback === PropertyInterestFeedback.NotInterested &&
                        <BootstrapIcon iconName="emoji-frown-fill" color="grey" />
                    }
                    <span className="text-primary ms-1">- Du hast {props.participatingUser.name} dein Feedback gegeben!</span>
                    <Tooltip
                        title="Du hast dem Anbieter der Fläche bereits Dein Feedback gegeben. Du kannst Deine Meinung mit Klick auf den obenstehenden Button noch jederzeit ändern."
                        icon={<i className="bi bi-info-circle"></i>}
                        className="text-info ms-1"
                    />
                </>
            }
        </>
    );
};

export default PropertyInterestFeedbackStatus;
