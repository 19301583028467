import CompanyValue from './Entity/CompanyValue';
import Tooltip from './Component/Tooltip/Tooltip';
import React from 'react';

interface CompanyValueIconListProps {
    readonly companyValues: CompanyValue[];
}

const CompanyValueIconList = (props: CompanyValueIconListProps): React.JSX.Element => {
    return (
        <>
            {props.companyValues.map((companyValue: CompanyValue, index: number): React.JSX.Element => {
                if (companyValue === CompanyValue.Sustainability) {
                    return (
                        <Tooltip
                            key={'companyValue-' + index}
                            title="NACHHALTIGKEIT – das Konzept steht für Nachhaltigkeit (Produkte, Lieferketten, Materialien, Abfallreduktion etc)."
                            icon={<i className="bi bi-tree-fill fs-4 me-2"></i>}
                            className="text-secondary ms-1"
                        />
                    );
                }

                if (companyValue === CompanyValue.Specialized) {
                    return (
                        <Tooltip
                            key={'companyValue-' + index}
                            title="SPEZIALISIERT – das Unternehmen ist auf den Verkauf einer bestimmten Sache spezialisiert."
                            icon={<i className="bi bi-lightning-charge-fill fs-4 me-2"></i>}
                            className="text-secondary ms-1"
                        />
                    );
                }

                if (companyValue === CompanyValue.Innovativ) {
                    return (
                        <Tooltip
                            key={'companyValue-' + index}
                            title="INNOVATIV – das Konzept ist besonders interessant, neuartig oder ideenreich."
                            icon={<i className="bi bi-lightbulb-fill fs-4 me-2"></i>}
                            className="text-secondary ms-1"
                        />
                    );
                }

                if (companyValue === CompanyValue.Networking) {
                    return (
                        <Tooltip
                            key={'companyValue-' + index}
                            title="VERNETZUNG – das Konzept bietet Möglichkeiten, sich zu treffen, kennenlernen oder zu vernetzen."
                            icon={<i className="bi bi-share-fill fs-4 me-2"></i>}
                            className="text-secondary ms-1"
                        />
                    );
                }

                if (companyValue === CompanyValue.RelaxationRecreation) {
                    return (
                        <Tooltip
                            key={'companyValue-' + index}
                            title="ENTSPANNUNG & ERHOLUNG – bei diesem Konzept kann man sich entspannen oder erholen."
                            icon={<i className="bi bi-emoji-sunglasses fs-4 me-2"></i>}
                            className="text-secondary ms-1"
                        />
                    );
                }

                if (companyValue === CompanyValue.Creativity) {
                    return (
                        <Tooltip
                            key={'companyValue-' + index}
                            title="KREATIVITÄT – bei diesem Konzept steht die Förderung der Kreativität im Vordergrund."
                            icon={<i className="bi bi-palette-fill fs-4 me-2"></i>}
                            className="text-secondary ms-1"
                        />
                    );
                }

                if (companyValue === CompanyValue.ExperienceEntertainment) {
                    return (
                        <Tooltip
                            key={'companyValue-' + index}
                            title="ERLEBNIS & UNTERHALTUNG – bei diesem Konzept stehen Unterhaltung und Erlebnisfaktor im Vordergrund."
                            icon={<i className="bi bi-binoculars fs-4 me-2"></i>}
                            className="text-secondary ms-1"
                        />
                    );
                }

                if (companyValue === CompanyValue.LocalityRegionality) {
                    return (
                        <Tooltip
                            key={'companyValue-' + index}
                            title="LOKALITÄT & REGIONALITÄT – das Konzept steht für den Verkauf lokale/regionaler Produkte oder leistet auf einem anderen Weg einen Beitrag für die Region."
                            icon={<i className="bi bi-pin-map-fill fs-4 me-2"></i>}
                            className="text-secondary ms-1"
                        />
                    );
                }

                if (companyValue === CompanyValue.ServiceOriented) {
                    return (
                        <Tooltip
                            key={'companyValue-' + index}
                            title="SERVICEORIENTIERT – bei diesem Konzept steht der Kunde im Mittelpunkt."
                            icon={<i className="bi bi-question-square-fill fs-4 me-2"></i>}
                            className="text-secondary ms-1"
                        />
                    );
                }

                if (companyValue === CompanyValue.VitalityVibrancy) {
                    return (
                        <Tooltip
                            key={'companyValue-' + index}
                            title="VITALITÄT & LEBENDIGKEIT – es wird ein besonderer Fokus auf die Gesundheit gelegt."
                            icon={<i className="bi bi-heart-pulse fs-4 me-2"></i>}
                            className="text-secondary ms-1"
                        />
                    );
                }

                if (companyValue === CompanyValue.ShopWindowDressing) {
                    return (
                        <Tooltip
                            key={'companyValue-' + index}
                            title="SCHAUFENSTERGESTALTUNG – hier werden besonders inspirierende Schaufenstergestaltungen gezeigt."
                            icon={<i className="bi bi-shop-window fs-4 me-2"></i>}
                            className="text-secondary ms-1"
                        />
                    );
                }

                if (companyValue === CompanyValue.ShopFitting) {
                    return (
                        <Tooltip
                            key={'companyValue-' + index}
                            title="LADENBAU – hier wird auf besondere Ladenbaudesigns eingegangen."
                            icon={<i className="bi bi-tools fs-4 me-2"></i>}
                            className="text-secondary ms-1"
                        />
                    );
                }

                if (companyValue === CompanyValue.Digitalisation) {
                    return (
                        <Tooltip
                            key={'companyValue-' + index}
                            title="DIGITALISIERUNG – Hier geht es um Digitalisierungsmaßnahmen in und um eine Ladenfläche."
                            icon={<i className="bi bi-robot fs-4 me-2"></i>}
                            className="text-secondary ms-1"
                        />
                    );
                }

                return <></>;
            })}
        </>
    );
};

export default CompanyValueIconList;
