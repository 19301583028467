import roundedContainerStyle from './RoundedContainer.module.scss';
import React, {CSSProperties} from 'react';

interface RoundedContainerProps extends React.PropsWithChildren {
    readonly className?: string;
    readonly style?: CSSProperties;
}

const RoundedContainer = React.forwardRef<HTMLDivElement, RoundedContainerProps>((props: RoundedContainerProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const buildClassName = (): string => {
        const className: string = roundedContainerStyle.roundedContainer;

        if (props.className !== undefined) {
            return className + ' ' + props.className;
        }

        return className;
    };

    return (
        <div ref={ref} className={buildClassName()} style={props.style}>
            {props.children}
        </div>
    );
});

export default RoundedContainer;
