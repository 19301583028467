import MarketplaceService from '../MarketplaceService';
import LocationService from '../../Location/LocationService';
import UserState from '../../Entity/User/UserState';
import Place from '../../Entity/Location/Place';
import Country from '../../Entity/Location/Country';
import MarketplaceAd from '../../Entity/Marketplace/MarketplaceAd';
import MarketplaceAdFilter from '../../Entity/Marketplace/MarketplaceAdFilter';
import SortingDirection from '../../Entity/SortingOption/SortingDirection';
import SortingOption from '../../Entity/SortingOption/SortingOption';
import PaginationParameter from '../../Entity/Pagination/PaginationParameter';
import MarketplaceAdPaginated from '../../Entity/Marketplace/MarketplaceAdPaginated';
import MarketplaceAdFetchParameter from '../../Entity/Marketplace/MarketplaceAdFetchParameter';
import SelectOption from '../../Entity/Form/SelectOption';
import Pagination from '../../Pagination/Pagination';
import SpecialFilter from './SpecialFilter';
import Sorting from './Sorting';
import Filter from './Filter';
import MarketplaceAdCard from './MarketplaceAdCard';
import InfoButtonOverlay from '../../Component/InfoButtonOverlay/InfoButtonOverlay';
import BootstrapIcon from '../../Component/Icon/BootstrapIcon';
import Card from '../../Component/Card/Card';
import Spinner from '../../../../components/Spinner';
import {useAppDispatch, useAppSelector} from '../../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

const marketplaceService = new MarketplaceService(process.env.REACT_APP_LLASM_API_URL!);
const locationService: LocationService = new LocationService(process.env.REACT_APP_LLASM_API_URL!);

const sortingOptions: SortingOption[] = [
    new SortingOption('createdAt', SortingDirection.Descending, 'Neueste Angebote zuerst', 'sort-down-alt'),
    new SortingOption('createdAt', SortingDirection.Ascending, 'Älteste Angebote zuerst', 'sort-down'),
    new SortingOption('totalAreaSize', SortingDirection.Ascending, 'Gesamtfläche aufsteigend', 'sort-down-alt'),
    new SortingOption('totalAreaSize', SortingDirection.Descending, 'Gesamtfläche absteigend', 'sort-down'),
];

const placeSearchDistanceSortingOption: SortingOption = new SortingOption('placeSearchDistance', SortingDirection.Ascending, 'Entfernung', 'sort-down-alt');

const paginationLimitOptions: SelectOption<number>[] = [
    {label: '12', value: 12},
    {label: '48', value: 48},
    {label: '96', value: 96},
];

const MarketplaceAdOverviewPage = (): React.JSX.Element => {
    const {user}: UserState = useAppSelector<UserState>(state => state.user);

    const [countryPlaces, setCountryPlaces] = useState<Place[]>();

    const [fallbackCountryPlace, setFallbackCountryPlace] = useState<Place>();

    const [defaultCountryPlace, setDefaultCountryPlace] = useState<Place>();

    const [marketplaceAdFetchParameter, setMarketplaceAdFetchParameter] = useState<MarketplaceAdFetchParameter>();

    const [marketplaceAdPaginated, setMarketplaceAdPaginated] = useState<MarketplaceAdPaginated | undefined>();

    const [isFilterExpanded, setIsFilterExpanded] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Marktplatz'},
            ]
        });
    }, [dispatch]);

    useEffect((): void => {
        fetchCountryPlaces();
    }, []);

    useEffect((): void => {
        if (countryPlaces === undefined) {
            return;
        }

        if (fallbackCountryPlace === undefined) {
            return;
        }

        fetchDefaultCountryPlace();
    }, [countryPlaces, fallbackCountryPlace]);

    useEffect((): void => {
        if (countryPlaces === undefined) {
            return;
        }

        if (defaultCountryPlace === undefined) {
            return;
        }

        const marketplaceAdFilter: MarketplaceAdFilter = MarketplaceAdFilter.createDefault();

        marketplaceAdFilter.countryPlace = defaultCountryPlace;

        setMarketplaceAdFetchParameter({
            marketplaceAdFilter: marketplaceAdFilter,
            sortingOption: null,
            paginationParameter: new PaginationParameter(1, paginationLimitOptions[0].value)
        });
    }, [countryPlaces, defaultCountryPlace]);

    useEffect((): void => {
        if (marketplaceAdFetchParameter === undefined) {
            return;
        }

        fetchMarketplaceAdPaginated();
    }, [marketplaceAdFetchParameter]);

    useEffect((): void => {
        if (marketplaceAdFetchParameter === undefined) {
            return;
        }

        const index: number = sortingOptions.indexOf(placeSearchDistanceSortingOption);

        if (marketplaceAdFetchParameter.marketplaceAdFilter.placeSearchRadius !== null) {
            if (index === -1) {
                sortingOptions.push(placeSearchDistanceSortingOption);
            }
        } else {
            if (index !== -1) {
                sortingOptions.splice(index, 1);
            }
        }
    }, [marketplaceAdFetchParameter?.marketplaceAdFilter.placeSearchRadius]);

    const fetchCountryPlaces = async (): Promise<void> => {
        const countries: Country[] = await locationService.fetchCountries(['DE', 'AT']);

        const fallbackCountry: Country | undefined = countries.find((country: Country): boolean => {
            return country.isoAlphaTwoCode === 'DE';
        });

        if (fallbackCountry !== undefined) {
            if (fallbackCountry.countryPlace === null) {
                throw new Error('Country must have a country place!');
            }

            setFallbackCountryPlace(fallbackCountry.countryPlace);
        }

        const countryPlaces: Place[] = countries.map((country: Country): Place => {
            if (country.countryPlace === null) {
                throw new Error('Country must have a country place!');
            }

            return country.countryPlace;
        });

        setCountryPlaces(countryPlaces);
    };

    const fetchDefaultCountryPlace = (): void => {
        if (countryPlaces === undefined) {
            return;
        }

        if (fallbackCountryPlace === undefined) {
            return;
        }

        if (user === undefined) {
            setDefaultCountryPlace(fallbackCountryPlace);

            return;
        }

        if (user.country.countryPlace !== null && countryPlaces.includes(user.country.countryPlace) === true) {
            setDefaultCountryPlace(user.country.countryPlace);
        } else {
            setDefaultCountryPlace(fallbackCountryPlace);
        }
    };

    const fetchMarketplaceAdPaginated = async (): Promise<void> => {
        if (marketplaceAdFetchParameter === undefined) {
            return;
        }

        setMarketplaceAdPaginated(undefined);

        const marketplaceAdPaginated: MarketplaceAdPaginated = await marketplaceService.fetchMarketplaceAdsFromApi(
            marketplaceAdFetchParameter.marketplaceAdFilter,
            marketplaceAdFetchParameter.sortingOption ?? undefined,
            marketplaceAdFetchParameter.paginationParameter
        );

        setMarketplaceAdPaginated(marketplaceAdPaginated);
    };

    const toggleIsFilterExpanded = (): void => {
        setIsFilterExpanded(isFilterExpanded === false);
    };

    const updateFromFilter = (marketplaceAdFilter: MarketplaceAdFilter): void => {
        if (marketplaceAdFetchParameter === undefined) {
            return;
        }

        marketplaceAdFetchParameter.paginationParameter.page = 1;

        let sortingOption: SortingOption | null;

        if (marketplaceAdFilter.placeSearchRadius === null && marketplaceAdFetchParameter.sortingOption === placeSearchDistanceSortingOption) {
            sortingOption = null
        } else {
            sortingOption = marketplaceAdFetchParameter.sortingOption;
        }

        setMarketplaceAdFetchParameter({
            marketplaceAdFilter: marketplaceAdFilter,
            sortingOption:sortingOption,
            paginationParameter: marketplaceAdFetchParameter.paginationParameter
        });
    };

    const updateFromSpecialFilter = (marketplaceAdFilter: MarketplaceAdFilter): void => {
        if (marketplaceAdFetchParameter === undefined) {
            return;
        }

        marketplaceAdFetchParameter.paginationParameter.page = 1;

        setMarketplaceAdFetchParameter({
            marketplaceAdFilter: marketplaceAdFilter,
            sortingOption: marketplaceAdFetchParameter.sortingOption,
            paginationParameter: marketplaceAdFetchParameter.paginationParameter
        });
    };

    const updateFromSorting = (sortingOption: SortingOption | null): void => {
        if (marketplaceAdFetchParameter === undefined) {
            return;
        }

        marketplaceAdFetchParameter.paginationParameter.page = 1;

        setMarketplaceAdFetchParameter({
            marketplaceAdFilter: marketplaceAdFetchParameter.marketplaceAdFilter,
            sortingOption: sortingOption,
            paginationParameter: marketplaceAdFetchParameter.paginationParameter
        });
    };

    const updateFromPagination = (paginationParameter: PaginationParameter): void => {
        if (marketplaceAdFetchParameter === undefined) {
            return;
        }

        setMarketplaceAdFetchParameter({
            marketplaceAdFilter: marketplaceAdFetchParameter.marketplaceAdFilter,
            sortingOption: marketplaceAdFetchParameter.sortingOption,
            paginationParameter: paginationParameter
        });
    };

    if (countryPlaces === undefined || marketplaceAdFetchParameter === undefined) {
        return <Spinner />;
    }

    return (
        <div className="container-fluid">
            {marketplaceAdPaginated === undefined &&
                <Spinner />
            }

            <div className="row align-items-center mb-4">
                <div className="col-12 col-md-6 d-flex align-items-center">
                    <Link to="/"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                    <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Marktplatz</h1>
                </div>
                <div className="col-md-6 d-none d-md-flex align-items-center justify-content-end px-4">
                    <InfoButtonOverlay title="Marktplatz" className="fs-5 ms-1 me-3">
                        <div className="mb-3">
                            Auf dem Marktplatz findest Du Flächenangebote, die sich klassischerweise nicht einfach mit
                            Nachnutzungskonzepten matchen lassen. Dies sind beispielsweise Kaufhäuser oder Mixed-Use
                            Flächen aber auch geförderte Angebote.
                        </div>
                        <div className="mb-3">
                            Auf dem Marktplatz kannst Du jederzeit nach passenden Objekten suchen und Dir die Details zu
                            einer Fläche anschauen. Ist diese für Dich interessant, kannst Du direkt über ein Kontaktformular
                            mit dem Anbieter der Fläche kommunizieren.
                        </div>
                    </InfoButtonOverlay>
                    <Link to="/marktplatz/inserate" className="btn btn btn-secondary text-nowrap">
                        Meine Inserate
                    </Link>
                </div>
            </div>
            <div className="d-lg-flex">
                <div className="mb-3 d-lg-flex align-items-center">
                    <SpecialFilter marketplaceAdFilter={marketplaceAdFetchParameter.marketplaceAdFilter} onChange={updateFromSpecialFilter} />
                </div>
                <div className="ms-auto mb-3 d-flex align-content-stretch justify-content-end">
                    {isFilterExpanded === true &&
                        <div className="text-primary d-flex align-items-center cursor-pointer me-3" onClick={toggleIsFilterExpanded}>
                            <BootstrapIcon iconName="funnel" className="me-2" />
                            <span className="me-1">FILTER</span>
                            <BootstrapIcon iconName="caret-up-fill" />
                        </div>
                    }
                    {isFilterExpanded === false &&
                        <div className="d-flex align-items-center cursor-pointer me-3" onClick={toggleIsFilterExpanded}>
                            <BootstrapIcon iconName="funnel" className="me-2" />
                            <span className="me-1">FILTER</span>
                            <BootstrapIcon iconName="caret-down-fill" />
                        </div>
                    }
                    <div className="d-flex align-items-center">
                        <Sorting
                            sortingOptions={sortingOptions}
                            selectedSortingOption={marketplaceAdFetchParameter.sortingOption}
                            onChange={updateFromSorting}
                        />
                    </div>
                </div>
            </div>
            {isFilterExpanded === true &&
                <Card cardType="shadow" className="mb-3 p-3">
                    <h2>Filter</h2>
                    <Filter
                        countryPlaces={countryPlaces}
                        marketplaceAdFilter={marketplaceAdFetchParameter.marketplaceAdFilter}
                        onSubmit={updateFromFilter}
                    />
                </Card>
            }
            {marketplaceAdPaginated !== undefined &&
                <>
                    {marketplaceAdPaginated.results.length > 0 &&
                        <>
                            <div className="row mt-3">
                                {marketplaceAdPaginated.results.map((marketplaceAd: MarketplaceAd): React.JSX.Element => (
                                    <div key={'marketplaceAd' + marketplaceAd.id} className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
                                        <MarketplaceAdCard marketplaceAd={marketplaceAd} />
                                    </div>
                                ))}
                            </div>
                            <div className="pb-3">
                                <Pagination
                                    pagination={marketplaceAdPaginated.pagination}
                                    limitOptions={paginationLimitOptions}
                                    onChange={updateFromPagination}
                                />
                            </div>
                        </>
                    }
                    {marketplaceAdPaginated.results.length === 0 &&
                        <div className="text-center fs-5 pt-3 pb-4">
                            Derzeit sind keine Objekte im Marktplatz veröffentlicht oder die gewählten Filterkriterien ergeben keine Ergebnisse.
                        </div>
                    }
                </>
            }
        </div>
    );
};

export default MarketplaceAdOverviewPage;
