import React from 'react';

interface LabelValuePairProps extends React.PropsWithChildren {
    readonly label: string;
    readonly value: string | React.JSX.Element;
    readonly className?: string;
}

const LabelValuePair = (props: LabelValuePairProps): React.JSX.Element => {
    return (
        <div className={'label-value-pair ' + props.className}>
            <div className="mb-0 lh-sm">
                {props.value}
            </div>
            <label className="text-black-50 pb-1">{props.label}</label>
        </div>
    );
};

export default LabelValuePair;
