import SelectOption from './Entity/Form/SelectOption';
import PropertyAcquisitionType, {getPropertyAcquisitionTypeLabel} from './Entity/PropertyAcquisitionType';
import FieldProps from './Component/Form/Field/FieldProps';
import MultiSelectField from './Component/Form/Field/MultiSelectField';
import React from 'react';
import {ActionMeta, MultiValue, Options, PropsValue} from 'react-select';

interface PropertyAcquisitionTypeSelectFieldProps extends FieldProps {
    readonly placeholder: React.ReactNode;
    readonly closeMenuOnSelect: boolean;
    readonly value: PropertyAcquisitionType[];
    readonly onChange: (newValue: MultiValue<SelectOption<PropertyAcquisitionType>>, actionMeta: ActionMeta<SelectOption<PropertyAcquisitionType>>) => void;
    readonly defaultValue?: PropsValue<SelectOption<PropertyAcquisitionType>>;
    readonly isClearable?: boolean;
}

const selectOptions: Options<SelectOption<PropertyAcquisitionType>> = [
    {label: getPropertyAcquisitionTypeLabel(PropertyAcquisitionType.Buy), value: PropertyAcquisitionType.Buy},
    {label: getPropertyAcquisitionTypeLabel(PropertyAcquisitionType.Rent), value: PropertyAcquisitionType.Rent},
];

const fetchSelectedOptions = (propertyAcquisitionTypes: readonly PropertyAcquisitionType[]): SelectOption<PropertyAcquisitionType>[] => {
    if (propertyAcquisitionTypes.length === 0) {
        return [];
    }

    return selectOptions.filter((selectOption: SelectOption<PropertyAcquisitionType>): boolean => {
        return propertyAcquisitionTypes.includes(selectOption.value);
    });
};

const PropertyAcquisitionTypeSelectField = (props: PropertyAcquisitionTypeSelectFieldProps): React.JSX.Element => {
    return (
        <MultiSelectField
            label={props.label}
            name={props.name}
            options={selectOptions}
            required={props.required}
            closeMenuOnSelect={props.closeMenuOnSelect}
            value={fetchSelectedOptions(props.value)}
            placeholder={props.placeholder}
            onChange={props.onChange}
            description={props.description}
            formErrors={props.formErrors}
        />
    );
};

export default PropertyAcquisitionTypeSelectField;
