import AgeStructure, {getAgeStructureLabel} from './Entity/AgeStructure';
import FormError from './Entity/Form/FormError';
import SelectOption from './Entity/Form/SelectOption';
import FieldLabel from './Component/Form/Field/FieldLabel';
import React from 'react';
import Select, { OnChangeValue, PropsValue } from 'react-select';

interface AgeStructureSelectFieldProps {
    readonly label: string;
    readonly title: string;
    readonly name: string;
    readonly isMulti: boolean;
    readonly isClearable: boolean;
    readonly isRequired: boolean;
    readonly formErrors?: FormError[];
    readonly classNames?: string;
    readonly defaultValue?: AgeStructure | AgeStructure[];
    readonly onChange?: Function;
}

const AgeStructureSelectField = (props: AgeStructureSelectFieldProps): React.JSX.Element => {
    const hasErrors: boolean = props.formErrors !== undefined && props.formErrors.length > 0;

    const selectOptions: SelectOption<AgeStructure>[] = [
        {label: getAgeStructureLabel(AgeStructure.YOUNG_PEOPLE), value: AgeStructure.YOUNG_PEOPLE},
        {label: getAgeStructureLabel(AgeStructure.OLDER_PEOPLE), value: AgeStructure.OLDER_PEOPLE},
        {label: getAgeStructureLabel(AgeStructure.FAMILIES), value: AgeStructure.FAMILIES},
        {label: getAgeStructureLabel(AgeStructure.CHILDREN), value: AgeStructure.CHILDREN},
    ];

    const fetchDefaultValue = (): PropsValue<SelectOption<AgeStructure>> | undefined => {
        const selectedOptions: SelectOption<AgeStructure>[] = selectOptions.filter((selectOption: SelectOption<AgeStructure>): boolean => {
            if (Array.isArray(props.defaultValue) === true) {
                return (props.defaultValue as AgeStructure[]).includes(selectOption.value);
            } else {
                return selectOption.value === props.defaultValue;
            }
        });

        if (props.isMulti === true) {
            return selectedOptions;
        } else {
            if (selectedOptions.length > 0) {
                return selectedOptions[0];
            }
        }

        return undefined;
    };

    return (
        <div>
            {/* TODO - umbau auf SelectField / MultiSelectField */}
            <FieldLabel label={props.label} htmlFor={props.name} required={props.isRequired} description={props.title} className="mb-2 ps-2" />
            <div>
                <Select
                    name={props.name}
                    options={selectOptions}
                    isClearable={props.isClearable}
                    isMulti={props.isMulti}
                    closeMenuOnSelect={false}
                    className={(props.classNames !== undefined ? props.classNames : '') + (hasErrors === true ? ' is-invalid' : '')}
                    placeholder="Bitte wählen"
                    defaultValue={fetchDefaultValue()}
                    onChange={(newValue: OnChangeValue<SelectOption<AgeStructure>, boolean>): void => {
                        if (props.onChange !== undefined) {
                            if (Array.isArray(newValue) === true) {
                                props.onChange((newValue as Array<SelectOption<AgeStructure>>).map((selectOption: SelectOption<AgeStructure>): AgeStructure => selectOption.value));
                            } else {
                                props.onChange((newValue as SelectOption<AgeStructure>).value);
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default AgeStructureSelectField;
