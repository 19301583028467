import SelectOption from '../Entity/Form/SelectOption';
import InternetConnectionType, {getInternetConnectionTypeLabel} from '../Entity/Property/InternetConnectionType';
import FieldProps from '../Component/Form/Field/FieldProps';
import MultiSelectField from '../Component/Form/Field/MultiSelectField';
import React from 'react';
import {ActionMeta, MultiValue, Options, PropsValue} from 'react-select';

interface InternetConnectionTypeSelectFieldProps extends FieldProps {
    readonly placeholder: React.ReactNode;
    readonly closeMenuOnSelect: boolean;
    readonly value: InternetConnectionType[];
    readonly onChange: (newValue: MultiValue<SelectOption<InternetConnectionType>>, actionMeta: ActionMeta<SelectOption<InternetConnectionType>>) => void;
    readonly defaultValue?: PropsValue<SelectOption<InternetConnectionType>>;
    readonly isClearable?: boolean;
}

const selectOptions: Options<SelectOption<InternetConnectionType>> = [
    {label: getInternetConnectionTypeLabel(InternetConnectionType.BroadbandConnection), value: InternetConnectionType.BroadbandConnection},
    {label: getInternetConnectionTypeLabel(InternetConnectionType.DigitalSubscriberLineConnection), value: InternetConnectionType.DigitalSubscriberLineConnection},
    {label: getInternetConnectionTypeLabel(InternetConnectionType.CableConnection), value: InternetConnectionType.CableConnection},
    {label: getInternetConnectionTypeLabel(InternetConnectionType.FiberOpticConnection), value: InternetConnectionType.FiberOpticConnection},
];

const fetchSelectedOptions = (internetConnectionTypes: readonly InternetConnectionType[]): SelectOption<InternetConnectionType>[] => {
    if (internetConnectionTypes.length === 0) {
        return [];
    }

    return selectOptions.filter((selectOption: SelectOption<InternetConnectionType>): boolean => {
        return internetConnectionTypes.includes(selectOption.value);
    });
};

const InternetConnectionTypeSelectField = (props: InternetConnectionTypeSelectFieldProps): React.JSX.Element => {
    return (
        <MultiSelectField
            label={props.label}
            name={props.name}
            options={selectOptions}
            required={props.required}
            closeMenuOnSelect={props.closeMenuOnSelect}
            value={fetchSelectedOptions(props.value)}
            placeholder={props.placeholder}
            onChange={props.onChange}
            description={props.description}
            formErrors={props.formErrors}
        />
    );
};

export default InternetConnectionTypeSelectField;
