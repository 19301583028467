enum EnergyCertificateHeatingType {
    Oil = 0,
    Gas = 1,
    Electric = 2,
    AlternativeEnergySources = 3,
    SolarSystem = 4,
    HeatPumpBrineWater = 5,
    HeatPumpAirWater = 6,
    DistrictHeating = 7,
    CombinedHeatAndPowerPlant = 8,
    SupplementaryDecentralizedHotWater = 9,
    PelletHeating = 10
}

const getEnergyCertificateHeatingTypeLabel = (energyCertificateHeatingType: EnergyCertificateHeatingType): string => {
    switch (energyCertificateHeatingType) {
        case EnergyCertificateHeatingType.Oil:
            return  'Öl';
        case EnergyCertificateHeatingType.Gas:
            return 'Gas';
        case EnergyCertificateHeatingType.Electric:
            return  'Elektro';
        case EnergyCertificateHeatingType.AlternativeEnergySources:
            return  'Alternative Energieträger';
        case EnergyCertificateHeatingType.SolarSystem:
            return  'Solaranlage';
        case EnergyCertificateHeatingType.HeatPumpBrineWater:
            return  'Wärmepumpe (Sole-Wasser)';
        case EnergyCertificateHeatingType.HeatPumpAirWater:
            return  'Wärmepumpe (Luft-Wasser)';
        case EnergyCertificateHeatingType.DistrictHeating:
            return  'Fernwärme';
        case EnergyCertificateHeatingType.CombinedHeatAndPowerPlant:
            return  'Blockheizkraftwerk';
        case EnergyCertificateHeatingType.SupplementaryDecentralizedHotWater:
            return  'Ergänzendes dezentrales Warmwasser';
        case EnergyCertificateHeatingType.PelletHeating:
            return  'Pelletheizung';
        default:
            throw new Error();
    }
};

export default EnergyCertificateHeatingType;
export {getEnergyCertificateHeatingTypeLabel};
