import FieldProps from './FieldProps';
import FieldLabel from './FieldLabel';
import React from 'react';

interface CheckboxFieldProps extends FieldProps {
    readonly checked: boolean;
    readonly onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const CheckboxField = (props: CheckboxFieldProps): React.JSX.Element => {
    const hasErrors: boolean = props.formErrors !== undefined && props.formErrors.length > 0;

    return (
        <div className="d-flex align-items-center">
            <input
                type="checkbox"
                value=""
                name={props.name}
                id={props.name}
                disabled={props.disabled}
                className={['form-check-input', 'pe-2', 'me-2', hasErrors === true ? 'is-invalid' : ''].join(' ')}
                onChange={props.onChange}
                checked={props.checked}
            />
            {props.label !== undefined &&
                <FieldLabel label={props.label} htmlFor={props.name} required={props.required} description={props.description} />
            }
        </div>
    );
};

export default CheckboxField;
