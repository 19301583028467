import disclaimerStyle from './Disclaimer.module.scss';
import React from 'react';

interface DisclaimerProps extends React.PropsWithChildren {
    readonly title: string;
    readonly className?: string;
}

const buildClassName = (classNames: (string | undefined)[]): string => {
    return classNames.filter(className => className !== undefined).join(' ');
};

const Disclaimer = (props: DisclaimerProps): React.JSX.Element => {
    return (
        <div className={buildClassName([disclaimerStyle.disclaimer, props.className])}>
            <h3>{props.title}</h3>
            <p>
                Die LLASM Technology GmbH stellt mit der Plattform „Leerstandslotsen“ lediglich die Internetplattform zur Suche und Abgabe von Immobilienangeboten zur Verfügung.
                Die Immobilienanbietenden sowie die Suchprofilanbieter sind für die dargestellten Inhalte und eventuell damit verbundene rechtliche Willenserklärungen selbst verantwortlich.
                Für die Richtigkeit, Vollständigkeit und Aktualität der Daten übernimmt die LLASM Technology GmbH keine Haftung und keine Verantwortung.
                Einzelne Angaben (bspw. Energieausweis) können nicht dargestellt werden und sind deshalb beim Immobilienanbietenden zu erfragen. Auch die Leistungsfähigkeit und Bonität der Immobilienanbieter oder der Suchprofilanbieter wird durch die LLASM Technology GmbH zu keinem Zeitpunkt überprüft.
            </p>
            <p>
                Die LLASM Technology GmbH haftet nicht für Rechtsansprüche des Suchprofilanbieters und/oder Immobilienanbietenden, die aus Entscheidungen und/oder Maßnahmen resultieren, die aufgrund von Informationen aus dem Matching getroffen wurden.
                Diese Übersichtsseite wird ausschließlich zu Informationszwecken zur Verfügung gestellt. Es stellt keine Grundlage für Verträge dar.
                Die Kontaktaufnahme zwischen dem Suchprofilanbieter und dem Immobilienanbietenden erfolgt ohne Empfehlung und/oder Zutun der LLASM Technology GmbH.
                Ansprüche aus dem Verhältnis Immobilienanbieter und Suchprofilanbieter betreffen allein diese beiden Parteien und entstehen nicht gegenüber der LLASM Technology GmbH.
                Dem Suchprofilanbietern ist insbesondere bekannt, dass durch die Kontaktaufnahme und die Beauftragung von Maklern Provisionsansprüche entstehen können, die gegebenenfalls von ihm zu erfüllen sind.
            </p>
        </div>
    );
};

export default Disclaimer;
