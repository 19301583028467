import FormData from '../Entity/Form/FormData';
import OfferDetail from '../Entity/Property/OfferDetail/OfferDetail';
import SelectOption from '../Entity/Form/SelectOption';
import PropertyAcquisitionType from '../Entity/PropertyAcquisitionType';
import TimeAvailability, {getTimeAvailabilityLabel} from '../Entity/Property/TimeAvailability';
import SpecialObjectCategory from '../Entity/Property/SpecialObjectCategory';
import RentalDetail from '../Entity/Property/OfferDetail/RentalDetail';
import PurchaseDetail from '../Entity/Property/OfferDetail/PurchaseDetail';
import RentalDetailForm from './RentalDetailForm';
import PurchaseDetailForm from './PurchaseDetailForm';
import PropertyAcquisitionTypeSelectField from '../PropertyAcquisitionTypeSelectField';
import SelectField from '../Component/Form/Field/SelectField';
import TextAreaField from '../Component/Form/Field/TextAreaField';
import InputField from '../Component/Form/Field/InputField';
import SwitchField from '../Component/Form/Field/SwitchField';
import DatePickerField from '../Component/Form/Field/DatePickerField';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import React, {useEffect} from 'react';

interface OfferDetailFormProps {
    readonly formData: FormData<OfferDetail>;
    readonly setFormData: (formData: FormData<OfferDetail> | ((prevState: FormData<OfferDetail>) => FormData<OfferDetail>)) => void;
    readonly validationDefinitions: FieldValidationDefinition<OfferDetail>[];
    readonly rentalDetailFormData: FormData<RentalDetail>;
    readonly setRentalDetailFormData: (formData: FormData<RentalDetail> | ((prevState: FormData<RentalDetail>) => FormData<RentalDetail>)) => void;
    readonly rentalDetailValidationDefinitions: FieldValidationDefinition<RentalDetail>[];
    readonly purchaseDetailFormData: FormData<PurchaseDetail>;
    readonly setPurchaseDetailFormData: (formData: FormData<PurchaseDetail> | ((prevState: FormData<PurchaseDetail>) => FormData<PurchaseDetail>)) => void;
    readonly purchaseDetailValidationDefinitions: FieldValidationDefinition<PurchaseDetail>[];
    readonly specialObjectCategories: SpecialObjectCategory[];
}

const fromTimeAvailabilitySelectOptions: SelectOption<TimeAvailability>[] = [
    {label: getTimeAvailabilityLabel(TimeAvailability.Immediately), value: TimeAvailability.Immediately},
    {label: getTimeAvailabilityLabel(TimeAvailability.ByArrangement), value: TimeAvailability.ByArrangement},
    {label: getTimeAvailabilityLabel(TimeAvailability.CertainDate), value: TimeAvailability.CertainDate},
];

const untilTimeAvailabilitySelectOptions: SelectOption<TimeAvailability | null>[] = [
    {label: 'Bitte wählen', value: null},
    {label: getTimeAvailabilityLabel(TimeAvailability.ByArrangement), value: TimeAvailability.ByArrangement},
    {label: getTimeAvailabilityLabel(TimeAvailability.CertainDate), value: TimeAvailability.CertainDate},
];

const OfferDetailForm = (props: OfferDetailFormProps): React.JSX.Element => {
    const offerDetail: OfferDetail = props.formData.data;

    useEffect((): void => {
        props.setFormData((prevState: FormData<OfferDetail>): FormData<OfferDetail> => ({...prevState, formValidationHandler: new FormValidationHandler<OfferDetail>(props.validationDefinitions)}));
    }, [props.validationDefinitions]);

    useEffect((): void => {
        if (props.formData.formValidationHandler === undefined) {
            return;
        }

        if (offerDetail.fromTimeAvailability === TimeAvailability.CertainDate) {
            props.formData.formValidationHandler.addFieldValidationDefinition(
                new RequiredValidationDefinition<OfferDetail>('availableFrom', 'Es muss ein Datum gewählt sein.')
            );
        } else {
            props.formData.formValidationHandler.removeFieldValidationDefinitionsByFieldName('availableFrom');
        }
    }, [offerDetail.fromTimeAvailability, props.formData.formValidationHandler]);

    useEffect((): void => {
        if (props.formData.formValidationHandler === undefined) {
            return;
        }

        if (offerDetail.untilTimeAvailability === TimeAvailability.CertainDate) {
            props.formData.formValidationHandler.addFieldValidationDefinition(
                new RequiredValidationDefinition<OfferDetail>('availableUntil', 'Es muss ein Datum gewählt sein.')
            );
        } else {
            props.formData.formValidationHandler.removeFieldValidationDefinitionsByFieldName('availableUntil');
        }
    }, [offerDetail.untilTimeAvailability, props.formData.formValidationHandler]);

    useEffect((): void => {
        if (props.specialObjectCategories.includes(SpecialObjectCategory.PopUpArea) === false) {
            offerDetail.untilTimeAvailability = null;
            offerDetail.availableUntil = null;
        }

        updateFormData();
    }, [props.specialObjectCategories.length]);

    useEffect((): void => {
        if (offerDetail.propertyAcquisitionTypes.includes(PropertyAcquisitionType.Rent) === false) {
            resetRentalDetail();
        }

        if (offerDetail.propertyAcquisitionTypes.includes(PropertyAcquisitionType.Buy) === false) {
            resetPurchaseDetail();
        }
    }, [props.formData.data.propertyAcquisitionTypes.length]);

    const fetchFromTimeAvailabilitySelectedOption = (): SelectOption<TimeAvailability> | undefined => {
        if (offerDetail.fromTimeAvailability === undefined) {
            return undefined;
        }

        return fromTimeAvailabilitySelectOptions.find((selectOption: SelectOption<TimeAvailability>): boolean => {
            return offerDetail.fromTimeAvailability === selectOption.value;
        });
    };

    const fetchUntilTimeAvailabilitySelectedOption = (): SelectOption<TimeAvailability | null> | undefined => {
        return untilTimeAvailabilitySelectOptions.find((selectOption: SelectOption<TimeAvailability | null>): boolean => {
            return offerDetail.untilTimeAvailability === selectOption.value;
        });
    };

    const resetRentalDetail = (): void => {
        offerDetail.rentalDetail.netRentalPrice = null;
        offerDetail.rentalDetail.runningCosts = null;
        offerDetail.rentalDetail.commissionAmount = null;
        offerDetail.rentalDetail.commissionNote = null;
        offerDetail.rentalDetail.deposit = null;
        offerDetail.rentalDetail.dailyRent = null;

        updateFormData();
    };

    const resetPurchaseDetail = (): void => {
        offerDetail.purchaseDetail.purchasePrice = null;
        offerDetail.purchaseDetail.commissionAmount = null;
        offerDetail.purchaseDetail.commissionNote = null;

        updateFormData();
    };

    const handlePropertyAcquisitionTypesChange = (selectedValues: readonly SelectOption<PropertyAcquisitionType>[]): void => {
        offerDetail.propertyAcquisitionTypes = selectedValues.map((selectOption: SelectOption<PropertyAcquisitionType>): PropertyAcquisitionType => {
            return selectOption.value;
        });

        updateFormData();
        validateField('propertyAcquisitionTypes');
    };

    const handleFromTimeAvailabilityChange = (selectedValue: SelectOption<TimeAvailability> | null): void => {
        if (selectedValue === null) {
            return;
        }

        offerDetail.fromTimeAvailability = selectedValue.value;

        if (selectedValue.value !== TimeAvailability.CertainDate) {
            offerDetail.availableFrom = null;
        }

        updateFormData();
        validateField('fromTimeAvailability');
    };

    const handleUntilTimeAvailabilityChange = (selectedValue: SelectOption<TimeAvailability | null> | null): void => {
        if (selectedValue === null) {
            return;
        }

        offerDetail.untilTimeAvailability = selectedValue.value;

        if (selectedValue.value !== TimeAvailability.CertainDate) {
            offerDetail.availableUntil = null;
        }

        updateFormData();
        validateField('untilTimeAvailability');
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === 'fundingAvailable') {
            offerDetail.fundingAvailable =  event.target.checked;

            if (event.target.checked === false) {
                offerDetail.fundedBy = null;
                offerDetail.fundingCondition = null;
            }
        }

        updateFormData();
    };

    const handleAvailableFromChange = (date: Date | null): void => {
        offerDetail.availableFrom = date;

        updateFormData();
        validateField('availableFrom');
    };

    const handleAvailableUntilChange = (date: Date | null): void => {
        offerDetail.availableUntil = date;

        updateFormData();
        validateField('availableUntil');
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
        switch (event.target.name) {
            case 'otherDetails':
                if (event.target.value === '') {
                    offerDetail.otherDetails = null;
                } else {
                    offerDetail.otherDetails = event.target.value;
                }

                break;
            case 'fundedBy':
                if (event.target.value === '') {
                    offerDetail.fundedBy = null;
                } else {
                    offerDetail.fundedBy = event.target.value;
                }

                break;
            case 'fundingCondition':
                if (event.target.value === '') {
                    offerDetail.fundingCondition = null;
                } else {
                    offerDetail.fundingCondition = event.target.value;
                }

                break;
            default:
                (offerDetail as any)[event.target.name] = event.target.value;

                break;
        }

        updateFormData();
        validateField(event.target.name);
    };

    const updateFormData = (): void => {
        props.setFormData((prevState: FormData<OfferDetail>): FormData<OfferDetail> => ({...prevState, data: offerDetail}));
    };

    const validateField = (fieldName: string): void => {
        if (props.formData.formValidationHandler === undefined) {
            return;
        }

        props.formData.formValidationHandler.validateField(fieldName, props.formData);
        props.setFormData((prevState: FormData<OfferDetail>): FormData<OfferDetail> => ({...prevState, errors: props.formData.errors}));
    };

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6 mb-3">
                    <div className="row mb-3">
                        <div className="col-12">
                            <PropertyAcquisitionTypeSelectField
                                name="propertyAcquisitionTypes"
                                label="Angebotsart"
                                description="Bietest Du Deine Fläche zum Kauf, zur Miete bzw. Pacht oder beides an?"
                                placeholder="Bitte wählen"
                                required={true}
                                isClearable={true}
                                closeMenuOnSelect={false}
                                value={offerDetail.propertyAcquisitionTypes}
                                onChange={handlePropertyAcquisitionTypesChange}
                                formErrors={FormValidationHandler.getFieldErrors(props.formData, 'propertyAcquisitionTypes')}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 col-md-6">
                            <SelectField
                                name="fromTimeAvailability"
                                label="Verfügbar ab"
                                required={true}
                                options={fromTimeAvailabilitySelectOptions}
                                value={fetchFromTimeAvailabilitySelectedOption()}
                                placeholder="Bitte wählen"
                                onChange={handleFromTimeAvailabilityChange}
                                description="Gib hier an, ob Deine Fläche sofort, nach Vereinbarung oder ab einem bestimmten Zeitpunkt zur Verfügung steht."
                                formErrors={FormValidationHandler.getFieldErrors(props.formData, 'fromTimeAvailability')}
                            />
                        </div>
                        {offerDetail.fromTimeAvailability === TimeAvailability.CertainDate &&
                            <div className="col-12 col-md-6">
                                <DatePickerField
                                    name="availableFrom"
                                    label="Datum"
                                    value={offerDetail.availableFrom}
                                    isClearable={true}
                                    onChange={handleAvailableFromChange}
                                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'availableFrom')}
                                />
                            </div>
                        }
                    </div>
                    {props.specialObjectCategories.includes(SpecialObjectCategory.PopUpArea) === true &&
                        <div className="row mb-3">
                            <div className="col-12 col-md-6">
                                <SelectField
                                    name="untilTimeAvailability"
                                    label="Verfügbar bis"
                                    required={false}
                                    options={untilTimeAvailabilitySelectOptions}
                                    value={fetchUntilTimeAvailabilitySelectedOption()}
                                    placeholder="Bitte wählen"
                                    onChange={handleUntilTimeAvailabilityChange}
                                    description="Sofern Deine Fläche nur für einen Übergangszeitraum zur Verfügung steht, kannst Du dies näher spezifizieren."
                                />
                            </div>
                            {offerDetail.untilTimeAvailability === TimeAvailability.CertainDate &&
                                <div className="col-12 col-md-6">
                                    <DatePickerField
                                        name="availableUntil"
                                        label="Datum"
                                        value={offerDetail.availableUntil}
                                        isClearable={true}
                                        onChange={handleAvailableUntilChange}
                                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'availableUntil')}
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <TextAreaField
                        style={{gridRowEnd: 'span 5', alignSelf: 'baseline'}}
                        name="otherDetails"
                        label="Sonstige Angaben zum Angebot"
                        rows={4}
                        required={false}
                        value={offerDetail.otherDetails ?? undefined}
                        description="Gibt es noch weitere Informationen, die Du einem potenziellen Nachnutzer hinsichtlich der Angebotsdaten mit auf den Weg geben möchtest?"
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row">
                {offerDetail.propertyAcquisitionTypes.includes(PropertyAcquisitionType.Rent) === true &&
                    <div className="col-12 col-xl-6">
                        <RentalDetailForm
                            formData={props.rentalDetailFormData}
                            setFormData={props.setRentalDetailFormData}
                            validationDefinitions={props.rentalDetailValidationDefinitions}
                            specialObjectCategories={props.specialObjectCategories}
                            propertyAcquisitionTypes={offerDetail.propertyAcquisitionTypes}
                        />
                    </div>
                }
                {offerDetail.propertyAcquisitionTypes.includes(PropertyAcquisitionType.Buy) === true &&
                    <div className="col-12 col-xl-6">
                        <PurchaseDetailForm
                            formData={props.purchaseDetailFormData}
                            setFormData={props.setPurchaseDetailFormData}
                            validationDefinitions={props.purchaseDetailValidationDefinitions}
                            specialObjectCategories={props.specialObjectCategories}
                            propertyAcquisitionTypes={offerDetail.propertyAcquisitionTypes}
                        />
                    </div>
                }
            </div>
            <div className="row">
                <div className="col-12 col-md-3 pt-4">
                    <SwitchField
                        label="Förderung vorhanden"
                        description="Wird bei dieser Fläche eine Förderung gewährt, z. B. durch ein kommunales Förderprogramm?"
                        selected={offerDetail.fundingAvailable}
                        name="fundingAvailable"
                        onChange={handleSwitchChange}
                    />
                </div>
                {offerDetail.fundingAvailable === true &&
                    <>
                        <div className="col-12 col-md-3">
                            <InputField
                                name="fundedBy"
                                label="gefördert durch"
                                type="text"
                                required={false}
                                value={offerDetail.fundedBy ?? undefined}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <TextAreaField
                                style={{gridRowEnd: 'span 5', alignSelf: 'baseline'}}
                                name="fundingCondition"
                                label="Förderbedingungen/Fördergegenstand"
                                rows={4}
                                required={false}
                                value={offerDetail.fundingCondition ?? undefined}
                                description="Welche Rahmenbedingungen gibt es zur Förderung, z. B. Förderhöhe, Laufzeit o. ä."
                                onChange={handleChange}
                            />
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default OfferDetailForm;
