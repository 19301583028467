import MatchPointService from './MatchPointService';
import OrderStep from '../../shared/Entity/MatchPoint/OrderStep';
import User from '../../shared/Entity/User/User';
import BillingAddress from '../../shared/Entity/User/BillingAddress';
import Purchase from '../../shared/Entity/MatchPoint/Purchase';
import PurchaseMethod from '../../shared/Entity/Payment/PurchaseMethod';
import Currency from '../../shared/Entity/Currency';
import Alert from '../../shared/Entity/Alert/Alert';
import AlertType from '../../shared/Entity/Alert/AlertType';
import PaymentWrapper from './PaymentWrapper';
import StripePayment from '../../shared/Payment/Stripe/StripePayment';
import AlertBox from '../../../components/AlertBox';
import Spinner from '../../../components/Spinner';
import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';

interface OnlinePaymentProps {
    readonly matchPointService: MatchPointService;
    readonly setOrderStep: (orderStep: OrderStep) => void;
    readonly user: User;
    readonly numberFormat: Intl.NumberFormat;
    readonly currency: Currency;
    readonly taxRate: number;
    readonly amount: number
    readonly priceNet: number;
    readonly priceGross: number;
    readonly billingAddress: BillingAddress;
}

const OnlinePayment = (props: OnlinePaymentProps): React.JSX.Element => {
    const [purchase, setPurchase] = useState<Purchase>();

    const [paymentInProgress, setPaymentInProgress] = useState<boolean>(false);

    const [alert, setAlert] = useState<Alert | undefined>();

    const [paymentMethodText, setPaymentMethodText] = useState<string>();

    const stripePaymentRef: React.Ref<any> = useRef<React.ForwardedRef<any>>();

    useEffect((): void => {
        doPurchase();
    }, []);

    const doPurchase = async ():Promise<void> => {
        setPurchase(await props.matchPointService.purchaseMatchPoint(
            props.amount,
            props.priceNet,
            PurchaseMethod.Online,
            props.billingAddress
        ));
    };

    const submit = (): void => {
        setPaymentInProgress(true);

        stripePaymentRef.current.submit();
    };

    const onPaymentFormValidationError = (errorMessage: string): void => {
        setPaymentInProgress(false);

        setAlert(new Alert(AlertType.Error, errorMessage));
    };

    const onPaymentMethodChange = (paymentMethod: string): void => {
        if (paymentMethod === 'card') {
            setPaymentMethodText('Karte');
        }

        if (paymentMethod === 'sepa_debit') {
            setPaymentMethodText('SEPA-Lastschrift');
        }
    };

    const onPaymentError = (errorMessage: string): void => {
        setPaymentInProgress(false);

        setAlert(new Alert(AlertType.Error, errorMessage));
    };

    const onPaymentSuccess = (): void => {
        setPaymentInProgress(false);

        props.setOrderStep(OrderStep.OnlinePaymentOrderConfirmation);
    };

    const onPaymentCancelled = (): void => {
        setPaymentInProgress(false);
    };

    const onPaymentRequiresPaymentMethod = (): void => {
        setPaymentInProgress(false);
    };

    if (purchase === undefined || purchase.paymentProviderClientSecret === null) {
        return <Spinner />;
    }

    return (
        <div className="container-fluid pb-4">
            <div className="d-flex align-items-center mb-3">
                <Link to="" onClick={(): void => props.setOrderStep(OrderStep.OrderOverview)}>
                    <i className="bi bi-arrow-left-short text-secondary fs-lg"></i>
                </Link>
                <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Kauf abschließen</h1>
            </div>
            <PaymentWrapper
                user={props.user}
                numberFormat={props.numberFormat}
                currency={props.currency}
                taxRate={props.taxRate}
                amount={props.amount}
                priceNet={props.priceNet}
                priceGross={props.priceGross}
                billingAddress={props.billingAddress}
                paymentSubmitHandler={submit}
                paymentInProgress={paymentInProgress}
                purchaseMethod={PurchaseMethod.Online}
                paymentMethodText={paymentMethodText}
            >
                <div className="mb-3">
                    {props.user.country.isoAlphaTwoCode === 'DE' &&
                        <>
                            {alert !== undefined &&
                                <div className="mb-3">
                                    <AlertBox alert={alert} autoDismiss={false} />
                                </div>
                            }
                            <StripePayment
                                ref={stripePaymentRef}
                                user={props.user}
                                billingAddress={props.billingAddress}
                                clientSecret={purchase.paymentProviderClientSecret}
                                onPaymentMethodChange={onPaymentMethodChange}
                                onPaymentFormValidationError={onPaymentFormValidationError}
                                onPaymentError={onPaymentError}
                                onPaymentSuccess={onPaymentSuccess}
                                onPaymentCancelled={onPaymentCancelled}
                                onPaymentRequiresPaymentMethod={onPaymentRequiresPaymentMethod}
                            />
                        </>
                    }
                </div>
            </PaymentWrapper>
        </div>
    );
};

export default OnlinePayment;
