import FormData from '../Entity/Form/FormData';

abstract class FieldValidationDefinition<T> {
    protected readonly _fieldName: string;

    protected readonly _message: string | null;

    protected constructor(fieldName: string, message: string | null = null) {
        this._fieldName = fieldName;
        this._message = message;
    }

    get fieldName(): string {
        return this._fieldName;
    }

    get message(): string | null {
        return this._message;
    }

    public abstract check(formData: FormData<T>): boolean;
}

export default FieldValidationDefinition;
