import React from 'react';

interface LabelValuePairProps extends React.PropsWithChildren {
    readonly label: string;
    readonly values: string[];
    readonly delimiter: string;
    readonly className?: string;
}

const LabelValuesPair = (props: LabelValuePairProps): React.JSX.Element => {
    return (
        <div className={'label-value-pair ' + props.className}>
            <div className="mb-0 lh-sm">{props.values.join(props.delimiter)}</div>
            <label className="text-black-50 pb-1">{props.label}</label>
        </div>
    );
};

export default LabelValuesPair;
