import PropertyService from '../../../Property/PropertyService';
import MarketplaceLot from '../../../Entity/Marketplace/MarketplaceLot';
import ImageEntity from '../../../Entity/Image/Image';
import SpecialObjectCategory from '../../../Entity/Property/SpecialObjectCategory';
import Alert from '../../../Entity/Alert/Alert';
import AlertType from '../../../Entity/Alert/AlertType';
import PropertyDetailInformationOverlay from '../../../Property/PropertyDetailInformationOverlay';
import PropertyCard from '../../PropertyCard';
import MarketplaceService from '../../MarketplaceService';
import Image from '../../../Image/Image';
import Overlay from '../../../Component/Overlay/Overlay';
import Card from '../../../Component/Card/Card';
import CardMedia from '../../../Component/Card/CardMedia';
import CardContent from '../../../Component/Card/CardContent';
import CardFooter from '../../../Component/Card/CardFooter';
import IconBadgeWrapper from '../../../Component/IconBadge/IconBadgeWrapper';
import IconBadge from '../../../Component/IconBadge/IconBadge';
import AlertBox from '../../../../../components/AlertBox';
import LoadingIndicator from '../../../../../components/LoadingIndicator';
import noImage from '../../../../../img/no-hall-of-inspiration-concept-image.png';
import marketplaceLotCardStyle from './MarketplaceLotCard.module.scss';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

interface OccupiedMarketplaceLotCardProps {
    readonly marketplaceLot: MarketplaceLot;
    readonly onAction: (alert: Alert) => void;
}

const marketplaceService: MarketplaceService = new MarketplaceService(process.env.REACT_APP_LLASM_API_URL!);

const successAlert: Alert = new Alert(AlertType.Success, 'Der Stellplatz wurde erfolgreich freigegeben.');
const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.');

const OccupiedMarketplaceLotCard = (props: OccupiedMarketplaceLotCardProps): React.JSX.Element => {
    const [showReleaseMarketplaceLotOverlay, setShowReleaseMarketplaceLotOverlay] = useState<boolean>(false);

    const [showDetailInformationOverlay, setShowDetailInformationOverlay] = useState<boolean>(false);

    const [alert, setAlert] = useState<Alert>();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const buildThumbnailImageGetPath = (image: ImageEntity): string => {
        if (props.marketplaceLot.marketplaceAd === null) {
            throw new Error('Invalid MarketplaceLot');
        }

        return PropertyService.buildThumbnailImageApiPath(props.marketplaceLot.marketplaceAd.property, image);
    };

    const releaseMarketplaceLot = async (): Promise<void> => {
        try {
            setIsLoading(true);

            await marketplaceService.releaseMarketplaceLot(props.marketplaceLot);

            setShowReleaseMarketplaceLotOverlay(false);

            props.onAction(successAlert);
        } catch (error) {
            setAlert(errorAlert);
        } finally {
            setIsLoading(false);
        }
    };

    if (props.marketplaceLot.marketplaceAd === null) {
        throw new Error('Invalid MarketplaceLot');
    }

    return (
        <Card cardType="shadow" className="position-relative h-100">
            <CardMedia>
                <div className="d-flex align-items-center">
                    {props.marketplaceLot.marketplaceAd.property.images.length === 0 &&
                        <img src={noImage} alt="Kein Bild" className="w-100" />
                    }
                    {props.marketplaceLot.marketplaceAd.property.images.length > 0 &&
                        <Image image={props.marketplaceLot.marketplaceAd.property.images[0]}
                               buildImageGetPath={buildThumbnailImageGetPath} className="w-100" />
                    }
                </div>
            </CardMedia>
            <CardContent className="px-4 align-text-top">
                <IconBadgeWrapper positionTop={-40} positionLeft={0}>
                    {props.marketplaceLot.marketplaceAd.property.detail.specialObjectCategories.includes(SpecialObjectCategory.PopUpArea) === true &&
                        <IconBadge iconPublisher="bootstrap" iconName="clock-history" backgroundColor="secondary" description="PopUp-Fläche" />
                    }
                    {props.marketplaceLot.marketplaceAd.property.detail.specialObjectCategories.includes(SpecialObjectCategory.MixedUseArea) === true &&
                        <IconBadge iconPublisher="bootstrap" iconName="columns-gap" backgroundColor="secondary" description="MixedUse-Fläche" />
                    }
                    {props.marketplaceLot.marketplaceAd.property.detail.specialObjectCategories.includes(SpecialObjectCategory.DepartmentStore) === true &&
                        <IconBadge iconPublisher="bootstrap" iconName="building" backgroundColor="secondary" description="Kaufhaus" />
                    }
                    {props.marketplaceLot.marketplaceAd.property.detail.specialObjectCategories.includes(SpecialObjectCategory.CoWorkingSpace) === true &&
                        <IconBadge iconPublisher="bootstrap" iconName="person-workspace" backgroundColor="secondary" description="Co-Working-Space" />
                    }
                    {props.marketplaceLot.marketplaceAd.property.offerDetail.fundingAvailable === true &&
                        <IconBadge iconPublisher="bootstrap" iconName="cash-coin" backgroundColor="secondary" description="Förderfläche" />
                    }
                </IconBadgeWrapper>
                <div className={['fw-bolder', 'mb-3 mt-2', marketplaceLotCardStyle.titleHeight].join(' ')}>
                    {props.marketplaceLot.marketplaceAd.property.title}
                </div>
                <div className="row">
                    <div className="col">
                        Objekt-ID:
                    </div>
                    <div className="col">
                        #{props.marketplaceLot.marketplaceAd.property.id}
                    </div>
                </div>
                {props.marketplaceLot.marketplaceAd.property.internalDesignation !== null &&
                    <div className="row">
                        <div className="col">
                            Interne Bez.:
                        </div>
                        <div className="col text-overflow-ellipsis">
                            {props.marketplaceLot.marketplaceAd.property.internalDesignation}
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col">
                        Restlaufzeit:
                    </div>
                    <div className="col fs-5 fw-bold">
                        {props.marketplaceLot.availableNumberOfDays} Tage
                    </div>
                </div>
                <div className={marketplaceLotCardStyle.h200px}></div>
            </CardContent>
            <CardFooter className="position-absolute bottom-0 w-100">
                <button
                    className="btn btn-primary mx-auto text-nowrap text-uppercase w-100 mb-3"
                    onClick={(): void => setShowDetailInformationOverlay(true)}
                >
                    Details
                </button>
                <Link
                    to={'/flaechen/' + props.marketplaceLot.marketplaceAd.property.id + ''}
                    target="_blank"
                    className="btn btn-secondary mx-auto text-nowrap text-uppercase w-100 mb-3"
                >
                    Objekt bearbeiten
                </Link>
                <button
                    className="btn btn-danger mx-auto text-nowrap text-uppercase w-100 mb-2"
                    onClick={(): void => setShowReleaseMarketplaceLotOverlay(true)}
                >
                    Stellplatz freigeben
                </button>
            </CardFooter>
            <Overlay
                show={showReleaseMarketplaceLotOverlay}
                setShow={setShowReleaseMarketplaceLotOverlay}
                title="Stellplatz freigeben"
            >
                <div className="container pb-5">
                    <h3 className="fw-bolder">
                        Stellplatz - Restlaufzeit {props.marketplaceLot.availableNumberOfDays} Tage
                    </h3>
                    <div className="fs-5 mb-4">
                        Bist Du sicher, dass Du diesen Stellplatz freigeben möchtest? Dadurch wird die zugewiesene
                        Fläche vom Stellplatz genommen. Die Laufzeit wird angehalten,
                        sobald der Stellplatz freigegeben ist. Du kannst ihn jederzeit neu belegen.
                    </div>
                    {alert !== undefined &&
                        <AlertBox alert={alert} autoDismiss={false} />
                    }
                    {isLoading === true &&
                        <LoadingIndicator />
                    }
                    {isLoading === false &&
                        <>
                            <h3 className="fw-bolder mb-3">
                                Aktuell zugewiesene Fläche:
                            </h3>
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-4 mb-4">
                                    <PropertyCard property={props.marketplaceLot.marketplaceAd.property}>
                                        <button
                                            className="btn btn-danger mx-auto text-nowrap text-uppercase w-100 mb-3"
                                            onClick={(): Promise<void> => releaseMarketplaceLot()}
                                        >
                                            Stellplatz freigeben
                                        </button>
                                    </PropertyCard>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </Overlay>
            <PropertyDetailInformationOverlay
                show={showDetailInformationOverlay}
                setShow={setShowDetailInformationOverlay}
                property={props.marketplaceLot.marketplaceAd.property}
            />
        </Card>
    );
};

export default OccupiedMarketplaceLotCard;
