import FormData from '../Entity/Form/FormData';
import FieldValidationDefinition from './FieldValidationDefinition';

class MinMaxLengthValidationDefinition<T> extends FieldValidationDefinition<T> {
    private readonly _minLength: number | null;

    private readonly _maxLength: number | null;

    constructor(fieldName: string, minLength: number | null, maxLength: number | null, message: string | null = null) {
        super(fieldName, message);

        this._minLength = minLength;
        this._maxLength = maxLength;
    }

    public check(formData: FormData<T>): boolean {
        const formDataValue: any = (formData.data as any)[this._fieldName];

        let isValid: boolean = true;

        if (formDataValue === undefined) {
            return false;
        }

        if (this._minLength !== null && this._minLength > 0 && (formDataValue === null || formDataValue.length < this._minLength)) {
            isValid = false
        }

        if (this._maxLength !== null && formDataValue !== null && formDataValue.length > this._maxLength) {
            isValid = false
        }

        return isValid;
    }
}

export default MinMaxLengthValidationDefinition;
