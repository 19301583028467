import Property from '../Entity/Property/Property';
import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import InformationScope from '../Entity/InformationScope';
import PropertyDetailInformation from './PropertyDetailInformation/PropertyDetailInformation';
import Overlay from '../Component/Overlay/Overlay';
import React from 'react';

interface PropertyDetailInformationOverlayProps extends React.PropsWithChildren {
    readonly show: boolean;
    readonly setShow: (show: boolean) => void;
    readonly property: Property;
    readonly settlementConcept?: SettlementConcept;
    readonly contactCollapseCardChildren?: React.JSX.Element;
    readonly onClose?: () => void;
    readonly imageGetPath?: string;
    readonly documentGetPath?: string;
}

const PropertyDetailInformationOverlay = (props: PropertyDetailInformationOverlayProps): React.JSX.Element => {
    const buildTitle = (): React.JSX.Element => {
        return (
            <>
                <div>
                    {'#' +  props.property.id + ' ' + props.property.title}
                </div>
                {props.property.informationScope === InformationScope.Complete && props.property.internalDesignation !== null &&
                    <div className="text-black fs-5">
                        ({props.property.internalDesignation})
                    </div>
                }
            </>
        );
    };

    return (
        <Overlay show={props.show} setShow={props.setShow} title={buildTitle()} onClose={props.onClose}>
            <div className="container">
                <PropertyDetailInformation
                    property={props.property}
                    settlementConcept={props.settlementConcept}
                    children={props.children}
                    contactCollapseCardChildren={props.contactCollapseCardChildren}
                    imageGetPath={props.imageGetPath}
                    documentGetPath={props.documentGetPath}
                />
            </div>
        </Overlay>
    );
};

export default PropertyDetailInformationOverlay;
