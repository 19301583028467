import FormData from '../Entity/Form/FormData';
import FieldValidationDefinition from './FieldValidationDefinition';

class PasswordValidationDefinition<T> extends FieldValidationDefinition<T> {
    constructor(fieldName: string, message: string | null) {
        super(fieldName, message);
    }

    public check(formData: FormData<T>): boolean {
        return new RegExp('^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$', 'g').test((formData.data as any)[this._fieldName]) === true;
    }
}

export default PasswordValidationDefinition;
