import ContactService from '../Contact/ContactService';
import AuthenticationState from '../Entity/Authentication/AuthenticationState';
import Contact from '../Entity/Contact/Contact';
import Spinner from '../../../components/Spinner';
import { useAppSelector } from '../../../app/hooks';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const contactService: ContactService = new ContactService(process.env.REACT_APP_LLASM_API_URL!);

const PropertyInitialEntryPage = (): React.JSX.Element => {
    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const [contacts, setContacts] = useState<Contact[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect((): void => {
        fetchContacts();
    }, []);

    const fetchContacts = async (): Promise<void> => {
        setIsLoading(true);

        setContacts(await contactService.fetchContactsFromApi());

        setIsLoading(false);
    };

    if (isLoading === true) {
        return <Spinner />;
    }

    localStorage.setItem('initialEntry', 'property');

    return (
        <div className="container-fluid py-3 mb-3">
            <h1 className="card-title text-secondary text-center mb-4 fs-xl fw-bold">
                Hallo {authenticatedUser?.user.firstName}!<br/>
            </h1>
            <p className="text-center">
                <span className="fs-3">Schön, dass Du eine Fläche/ein Ladenlokal anlegen möchtest, um einen Nachnutzer zu finden.</span>
            </p>
            <p className="text-center pb-5">
                Bitte erfasse zunächst einen oder mehrere Ansprechpartner, die dem Objekt zugeordnet werden.<br/>
                Danach kannst Du Dein Ladenlokal/Deine Immobilie einstellen und passende Suchprofile finden.
            </p>

            <div className="row justify-content-center mt-3">
                <div className="col-3 position-relative align-items-center"></div>
                <div className="col-3 align-items-center"></div>
                <div className="col-12 col-md-8 col-lg-6 align-items-center"></div>
            </div>

            <div className="d-flex justify-content-center gap-5 flex-wrap">
                <button
                    onClick={(): void => navigate('/ansprechpartner/anlegen')}
                    className="btn p-5 shadow highlight rounded-5 align-items-center bg-white position-relative"
                >
                    <h2 className="text-center fs-1 mb-3">Ansprechpartner<br/>anlegen</h2>
                    <i className="bi bi-person fs-xxl"></i>
                    {contacts.length > 0 &&
                        <span className="position-absolute top-10 start-0 translate-middle badge rounded-pill bg-primary">
                            <i className="bi bi-check fs-lg text-white"></i>
                        </span>
                    }
                </button>
            </div>
            <div className="d-flex justify-content-center gap-5 flex-wrap mt-5 mb-3">
                <button
                    onClick={(): void => navigate('/flaechen/anbieten')}
                    className="btn p-5 shadow highlight rounded-5 align-items-center bg-white"
                >
                    <h2 className="text-center fs-1 mb-3 text-secondary text-decoration-none">Fläche/Ladenlokal<br/>anlegen</h2>
                    <i className="bi bi-shop fs-xxl"></i>
                </button>
            </div>
        </div>
    );
};

export default PropertyInitialEntryPage;
