enum LastUsage {
    Retail = 0,
    Gastronomy = 1,
    Service = 2,
    ArtCultureLeisure = 3,
    Other = 4
}

const getLastUsageLabel = (lastUsage: LastUsage): string => {
    switch (lastUsage) {
        case LastUsage.Retail:
            return 'Einzelhandel';
        case LastUsage.Gastronomy:
            return 'Gastronomie';
        case LastUsage.Service:
            return 'Dienstleistung';
        case LastUsage.ArtCultureLeisure:
            return 'Kunst, Kultur & Freizeit';
        case LastUsage.Other:
            return 'Sontiges'
        default:
            throw new Error();
    }
};

export default LastUsage;
export {getLastUsageLabel};
