import SettlementConceptService from './SettlementConceptService';
import IndustryClassificationService from '../IndustryClassification/IndustryClassificationService';
import LocationService from '../Location/LocationService';
import AuthenticationState from '../Entity/Authentication/AuthenticationState';
import Alert from '../Entity/Alert/Alert';
import AlertType from '../Entity/Alert/AlertType';
import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import PropertyCriteria from '../Entity/SettlementConcept/PropertyCriteria';
import PlaceCriteria from '../Entity/SettlementConcept/PlaceCriteria';
import FormData from '../Entity/Form/FormData';
import IndustryClassification from '../Entity/IndustryClassification/IndustryClassification';
import Country from '../Entity/Location/Country';
import Place from '../Entity/Location/Place';
import SettlementConceptForm from './SettlementConceptForm';
import DocumentUploader from '../Document/DocumentUploader';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import MinMaxLengthValidationDefinition from '../FormValidationHandler/MinMaxLengthValidationDefinition';
import ImageUploader from '../Image/ImageUploader';
import CollapseCard from '../Component/CollapseCard/CollapseCard';
import InfoBox from '../Component/InfoBox/InfoBox';
import Spinner from '../../../components/Spinner';
import AlertBox from '../../../components/AlertBox';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import React, {useEffect, useRef, useState} from 'react';
import {Link, NavigateFunction, useNavigate} from 'react-router-dom';

const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.');

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Du hast nicht alle Pflichtfelder gefüllt. Bitte kontrolliere die mit einem roten Ausrufezeichen markierten Felder.');

const settlementConceptService: SettlementConceptService = new SettlementConceptService(process.env.REACT_APP_LLASM_API_URL!);

const industryClassificationService: IndustryClassificationService = new IndustryClassificationService(process.env.REACT_APP_LLASM_API_URL!);

const locationService: LocationService = new LocationService(process.env.REACT_APP_LLASM_API_URL!);

const fieldValidationDefinitions: FieldValidationDefinition<SettlementConcept>[] = [
    new RequiredValidationDefinition<SettlementConcept>('brand', 'Es muss eine Marke angegeben werden.'),
    new RequiredValidationDefinition<SettlementConcept>('title', 'Es muss eine Bezeichnung angegeben werden.'),
    new RequiredValidationDefinition<SettlementConcept>('description', 'Es muss eine Suchprofilbeschreibung angegeben werden.'),
    new RequiredValidationDefinition<SettlementConcept>('contacts', 'Es muss mindestens ein Ansprechpartner angegeben werden.'),
];

const formValidationHandler: FormValidationHandler<SettlementConcept> = new FormValidationHandler<SettlementConcept>(fieldValidationDefinitions);

const propertyCriteriaFieldValidationDefinitions: FieldValidationDefinition<PropertyCriteria>[] = [
    new RequiredValidationDefinition<PropertyCriteria>('locationTypes', 'Standort-Typen müssen ausgewählt sein.'),
    new RequiredValidationDefinition<PropertyCriteria>('locationCategories', 'Lagekategorien müssen ausgewählt sein.'),
    new RequiredValidationDefinition<PropertyCriteria>('areaSizeMinimum', 'Es muss die Gesamtfläche von angegeben werden.'),
    new RequiredValidationDefinition<PropertyCriteria>('areaSizeMaximum', 'Es muss die Gesamtfläche bis angegeben werden.'),
    new RequiredValidationDefinition<PropertyCriteria>('propertyAcquisitionTypes', 'Eine Angebotsart muss ausgewählt sein.'),
    new RequiredValidationDefinition<PropertyCriteria>('parkingLotRequirementType', 'Parkplätze am Objekt müssen ausgewählt sein.'),
    new RequiredValidationDefinition<PropertyCriteria>('locationFactors', 'Standort-Faktoren müssen ausgewählt sein.'),
    new MinMaxLengthValidationDefinition<PropertyCriteria>('locationFactors', 2, 5, 'Es müssen 2 bis 5 Standort-Faktoren ausgewählt sein.'),
];

const propertyCriteriaFormValidationHandler: FormValidationHandler<PropertyCriteria> = new FormValidationHandler<PropertyCriteria>(propertyCriteriaFieldValidationDefinitions);

const createSettlementConcept = (): SettlementConcept => {
    const settlementConcept: SettlementConcept = new SettlementConcept();

    settlementConcept.active = false;
    settlementConcept.propertyCriteria = new PropertyCriteria();
    settlementConcept.industryClassifications.push(new IndustryClassification());
    settlementConcept.propertyCriteria.outdoorSalesAreaRequired = false;
    settlementConcept.propertyCriteria.groundLevelSalesAreaRequired = false;
    settlementConcept.propertyCriteria.barrierFreeAccessRequired = false;
    settlementConcept.interestPopupSpace = false;
    settlementConcept.interestMixedUseSpace = false;
    settlementConcept.onlyPopupSpace = false;
    settlementConcept.onlyMixedUseSpace = false;

    return settlementConcept;
};

const SettlementConceptCreatePage = (): React.JSX.Element => {
    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const [industryClassifications, setIndustryClassifications] = useState<IndustryClassification[]>();

    const [countryPlaces, setCountryPlaces] = useState<Place[]>();

    const [settlementConcept, setSettlementConcept] = useState<SettlementConcept>(createSettlementConcept());

    const [formData, setFormData] = useState<FormData<SettlementConcept>>({data: settlementConcept});

    const [propertyCriteriaFormData, setPropertyCriteriaFormData] = useState<FormData<PropertyCriteria>>({data: settlementConcept.propertyCriteria});

    const [placeCriteriaFormDatas, setPlaceCriteriaFormDatas] = useState<FormData<PlaceCriteria>[]>([]);

    const [hasLocationCategoryErrors, setHasLocationCategoryErrors] = useState<boolean>(false);

    const [hasIndustryClassificationError, setHasIndustryClassificationError] = useState<boolean>(false);

    const [hasCountryPlacesError, setHasCountryPlacesError] = useState<boolean>(false);

    const [hasCountryPlacesBeenChanged, setHasCountryPlacesBeenChanged] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [alert, setAlert] = useState<Alert>();

    const [isImageUploadComplete, setIsImageUploadComplete] = useState<boolean>(false);

    const [numberOfImageUploads, setNumberOfImageUploads] = useState<number>(0);

    const imageUploaderRef: React.Ref<any> = useRef<React.ForwardedRef<any>>();

    const [isDocumentUploadComplete, setIsDocumentUploadComplete] = useState<boolean>(false);

    const [numberOfDocumentUploads, setNumberOfDocumentUploads] = useState<number>(0);

    const documentUploaderRef: React.Ref<any> = useRef<React.ForwardedRef<any>>();

    const navigate: NavigateFunction = useNavigate();

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Meine Suchprofile', link: '/suchprofile'},
                {name: 'Suchprofil erstellen'},
            ]
        });
    }, [dispatch]);

    useEffect((): void => {
        fetchIndustryClassifications();
        fetchCountryPlaces();
    }, []);

    useEffect((): void => {
        if (settlementConcept === undefined) {
            return;
        }

        settlementConcept.placeCriterias = [];

        placeCriteriaFormDatas.forEach((placeCriteriaFormData: FormData<PlaceCriteria>): void => {
            settlementConcept.placeCriterias.push(placeCriteriaFormData.data);
        });

        setSettlementConcept(settlementConcept);
        setFormData({...formData, data: settlementConcept});
    }, [placeCriteriaFormDatas]);

    useEffect((): void => {
        if (isImageUploadComplete === false) {
            return;
        }

        if (isDocumentUploadComplete === false) {
            return;
        }

        setIsLoading(false);

        localStorage.removeItem('initialEntry');

        navigate('/suchprofile');
    }, [isImageUploadComplete, isDocumentUploadComplete]);

    useEffect((): void => {
        if (formData.data.active === false) {
            return;
        }

        persistSettlementConcept();
    }, [formData.data.active]);

    useEffect((): void => {
        validateIndustryClassifications();
    }, [formData.data.industryClassifications]);

    useEffect((): void => {
        if (formData.data.placeCriterias.length === 0) {
            setHasCountryPlacesError(true);

            return;
        }

        setHasCountryPlacesError(false);
    }, [formData.data.placeCriterias.length]);

    const fetchIndustryClassifications = async (): Promise<void> => {
        const industryClassifications: IndustryClassification[] = await industryClassificationService.fetchIndustryClassificationsFromApi();

        setIndustryClassifications(industryClassifications);
    };

    const resetActive = (): void => {
        settlementConcept.active = false;

        setSettlementConcept(settlementConcept);
        setFormData({...formData, data: settlementConcept});
    };

    const activateSettlementConcept = (): void => {
        settlementConcept.active = true;

        setSettlementConcept(settlementConcept);
        setFormData({...formData, data: settlementConcept});
    };

    const persistSettlementConcept = async (): Promise<void> => {
        propertyCriteriaFormValidationHandler.validate(propertyCriteriaFormData);
        formValidationHandler.validate(formData);

        placeCriteriaFormDatas.forEach((placeCriteriaFormData: FormData<PlaceCriteria>): void => {
            if (placeCriteriaFormData.formValidationHandler === undefined) {
                return;
            }

            placeCriteriaFormData.formValidationHandler.validate(placeCriteriaFormData);
        });

        let hasPlaceCriteriaFormDataErrors: boolean = false;

        placeCriteriaFormDatas.forEach((placeCriteriaFormData: FormData<PlaceCriteria>): void => {
            if (placeCriteriaFormData.formValidationHandler === undefined) {
                return;
            }

            if (placeCriteriaFormData.formValidationHandler.hasErrors(placeCriteriaFormData) === true) {
                hasPlaceCriteriaFormDataErrors = true;
            }
        });

        if (
            hasPlaceCriteriaFormDataErrors as boolean === true
            || propertyCriteriaFormValidationHandler.hasErrors(propertyCriteriaFormData) === true
            || hasLocationCategoryErrors === true
            || formValidationHandler.hasErrors(formData) === true
            || hasIndustryClassificationError === true
            || hasCountryPlacesError === true
        ) {
            resetActive();

            setAlert(formErrorAlert);

            window.scrollTo(0, 0);

            return;
        }

        setAlert(undefined);

        setIsLoading(true);

        try {
            const settlementConcept: SettlementConcept = await settlementConceptService.persistSettlementConcept(formData.data);

            if (numberOfImageUploads > 0) {
                imageUploaderRef!.current.uploadFiles(process.env.REACT_APP_LLASM_API_URL! + '/v1/settlement-concepts' + '/' + settlementConcept.id + '/images/upload');
            } else {
                onImageUploadQueueComplete();
            }

            if (numberOfDocumentUploads > 0) {
                documentUploaderRef!.current.uploadFiles(process.env.REACT_APP_LLASM_API_URL! + '/v1/settlement-concepts' + '/' + settlementConcept.id + '/documents/upload');
            } else {
                onDocumentUploadQueueComplete();
            }
        } catch (error) {
            resetActive();

            setAlert(errorAlert);

            setIsLoading(false);
        }
    };

    const onImageUploadQueueComplete = (): void => {
        setIsImageUploadComplete(true);
    };

    const onDocumentUploadQueueComplete = (): void => {
        setIsDocumentUploadComplete(true);
    };

    const fetchCountryPlaces = async (): Promise<void> => {
        const countries: Country[] =  await locationService.fetchCountries(['DE', 'AT']);

        const countryPlaces: Place[] = countries.map((country: Country): Place => {
            if (country.countryPlace === null) {
                throw new Error('Country must have a country place!');
            }

            return country.countryPlace;
        });

        setCountryPlaces(countryPlaces);
    };

    const validateIndustryClassifications = (): void => {
        if (formData.data.industryClassifications === undefined || formData.data.industryClassifications.length === 0) {
            setHasIndustryClassificationError(true);

            return;
        }

        if (formData.data.industryClassifications[0].id === undefined) {
            setHasIndustryClassificationError(true);

            return;
        }

        setHasIndustryClassificationError(false);
    };

    if (industryClassifications === undefined || countryPlaces === undefined) {
        return <Spinner />;
    }

    return (
        <>
            {isLoading === true &&
                <Spinner />
            }
            <div className="container-fluid ps-5 pe-5 pb-4">
                <div className="row align-items-center mb-4">
                    <div className="col-12 col-md-8 d-flex align-items-center">
                        <Link to="/suchprofile"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                        <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Suchprofil erstellen</h1>
                    </div>
                </div>
                <InfoBox cardType="outline" className="p-3 mb-3">
                    <div className="fs-5">
                        Hier beschreibst Du Dein Suchprofil, für das Du auf der Suche nach einer passenden (Laden-)Fläche bist.
                        Bitte fülle dazu die untenstehenden Angaben zu Deinem Suchprofil aus. Pflichtfelder sind dabei mit einem * gekennzeichnet.
                        Über die Info-Symbole erhältst Du weitere Informationen.
                    </div>
                    <div className="fs-5 mb-0">
                        Bitte stelle sicher, dass Du Dein Suchprofil mit möglichst vielen Angaben füllst. So ist ein passgenaues Matching möglich.
                    </div>
                </InfoBox>
                {alert !== undefined &&
                    <div className="mb-3">
                        <AlertBox alert={alert} autoDismiss={false} />
                    </div>
                }
                <div className="mb-3">
                    <SettlementConceptForm
                        formData={formData}
                        setFormData={setFormData}
                        propertyCriteriaFormData={propertyCriteriaFormData}
                        setPropertyCriteriaFormData={setPropertyCriteriaFormData}
                        placeCriteriaFormDatas={placeCriteriaFormDatas}
                        setPlaceCriteriaFormDatas={setPlaceCriteriaFormDatas}
                        industryClassifications={industryClassifications}
                        countryPlaces={countryPlaces}
                        hasIndustryClassificationError={hasIndustryClassificationError === true && alert !== undefined}
                        hasCountryPlacesError={hasCountryPlacesError === true && (alert !== undefined || hasCountryPlacesBeenChanged === true)}
                        setHasLocationCategoryErrors={setHasLocationCategoryErrors}
                        setHasCountryPlacesBeenChanged={setHasCountryPlacesBeenChanged}
                        formValidationHandler={formValidationHandler}
                        propertyCriteriaFormValidationHandler={propertyCriteriaFormValidationHandler}
                    />
                    <CollapseCard cardType="shadow" title="Bilder" className="mb-3">
                        <ImageUploader
                            ref={imageUploaderRef}
                            images={settlementConcept.images}
                            isProcessingImages={false}
                            token={authenticatedUser!.token}
                            autoProcessQueue={false}
                            setNumberOfImageUploads={setNumberOfImageUploads}
                            onQueueComplete={onImageUploadQueueComplete}
                            uploadText="Zieh hier schöne Bilder Deines Suchprofils rein"
                        />
                    </CollapseCard>
                    <CollapseCard cardType="shadow" title="Dokumente" className="mb-3">
                        <DocumentUploader
                            ref={documentUploaderRef}
                            documents={settlementConcept.documents}
                            isProcessingDocuments={false}
                            token={authenticatedUser!.token}
                            autoProcessQueue={false}
                            setNumberOfDocumentUploads={setNumberOfDocumentUploads}
                            onQueueComplete={onDocumentUploadQueueComplete}
                            uploadText="Zieh hier Dokumente zu Deinem Suchprofil rein"
                        />
                    </CollapseCard>
                    <div>
                        <button className="btn btn-secondary mt-3 me-3" onClick={persistSettlementConcept}>
                            Suchprofil speichern
                        </button>
                        <button className="btn btn-primary mt-3" onClick={activateSettlementConcept}>
                            Suchprofil speichern und aktivieren
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SettlementConceptCreatePage;
