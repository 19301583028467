import SelectOption from '../Entity/Form/SelectOption';
import LiftType, {getLiftTypeLabel} from '../Entity/Property/LiftType';
import FieldProps from '../Component/Form/Field/FieldProps';
import MultiSelectField from '../Component/Form/Field/MultiSelectField';
import React from 'react';
import {ActionMeta, MultiValue, Options, PropsValue} from 'react-select';

interface LiftTypeSelectFieldSelectFieldProps extends FieldProps {
    readonly placeholder: React.ReactNode;
    readonly closeMenuOnSelect: boolean;
    readonly value: LiftType[];
    readonly onChange: (newValue: MultiValue<SelectOption<LiftType>>, actionMeta: ActionMeta<SelectOption<LiftType>>) => void;
    readonly defaultValue?: PropsValue<SelectOption<LiftType>>;
    readonly isClearable?: boolean;
}

const selectOptions: Options<SelectOption<LiftType>> = [
    {label: getLiftTypeLabel(LiftType.Escalator), value: LiftType.Escalator},
    {label: getLiftTypeLabel(LiftType.PassengerLift), value: LiftType.PassengerLift},
    {label: getLiftTypeLabel(LiftType.CargoLift), value: LiftType.CargoLift},
];

const fetchSelectedOptions = (liftTypes: readonly LiftType[]): SelectOption<LiftType>[] => {
    if (liftTypes.length === 0) {
        return [];
    }

    return selectOptions.filter((selectOption: SelectOption<LiftType>): boolean => {
        return liftTypes.includes(selectOption.value);
    });
};

const LiftTypeSelectField = (props: LiftTypeSelectFieldSelectFieldProps): React.JSX.Element => {
    return (
        <MultiSelectField
            label={props.label}
            name={props.name}
            options={selectOptions}
            required={props.required}
            closeMenuOnSelect={props.closeMenuOnSelect}
            value={fetchSelectedOptions(props.value)}
            placeholder={props.placeholder}
            onChange={props.onChange}
            description={props.description}
            formErrors={props.formErrors}
        />
    );
};

export default LiftTypeSelectField;
