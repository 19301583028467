enum AccessibilityType {
    Available = 0,
    CanBeGuaranteed = 1,
    CurrentlyNotAvailable = 2,
    NotPossible = 3
}

const getAccessibilityTypeLabel = (accessibilityType: AccessibilityType): string => {
    switch (accessibilityType) {
        case AccessibilityType.Available:
            return 'Ist vorhanden';
        case AccessibilityType.CanBeGuaranteed:
            return 'Kann gewährleistet werden';
        case AccessibilityType.CurrentlyNotAvailable:
            return 'Ist derzeit nicht vorhanden';
        case AccessibilityType.NotPossible:
            return 'Ist nicht möglich';
        default:
            throw new Error();
    }
};

export default AccessibilityType;
export {getAccessibilityTypeLabel};
