import AdPartner from '../../shared/Entity/AdPartner/AdPartner';
import Image from '../../shared/Entity/Image/Image';
import Message from '../../shared/Entity/AdPartner/Message';
import AdPartnerType from '../../shared/Entity/AdPartner/AdPartnerType';
import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import MessagePostRequest from '../../../api/Llasm/AdPartner/MessagePostRequest';
import AdPartnerResponse from '../../../api/Llasm/AdPartner/AdPartnerResponse';
import ImageResponse from '../../../api/Llasm/Image/ImageResponse';
import {AxiosResponse} from 'axios';

class AdPartnerService{
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    private readonly resourcePath: string;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
        this.resourcePath = '/ad-partners';
    }

    public async fetchAdPartnersFromApi(amount: number, adPartnerType: AdPartnerType): Promise<AdPartner[]> {
        const url: string = this.resourcePath + '?amount=' + amount + '&adPartnerType=' + adPartnerType;

        const apiResponse: AxiosResponse<AdPartnerResponse[]> = await this.llasmApiV1Provider.get(url);

        return apiResponse.data.map((adPartnerResponse: AdPartnerResponse): AdPartner => {
            return AdPartner.createFromAdPartnerResponse(adPartnerResponse);
        });
    }

    public async fetchImagesFromAdPartner(adPartnerId: number): Promise<Image[]> {
        const apiResponse: AxiosResponse<ImageResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + adPartnerId + '/images');

        return apiResponse.data.map((imageResponse: ImageResponse): Image => {
            return Image.createFromImageResponse(imageResponse);
        });
    }

    public async sendMessage(adPartner: AdPartner, message: Message): Promise<void> {
        const apiResponse: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, MessagePostRequest>(
            this.resourcePath + '/' + adPartner.id + '/messages',
            AdPartnerService.mapFromMessageToMessagePostRequest(message)
        );
    }

    public static buildImageApiPath(adPartner: AdPartner, image: Image): string {
        return '/ad-partners/' + adPartner.id + '/images/' + image.id;
    }

    private static mapFromMessageToMessagePostRequest(message: Message): MessagePostRequest {
        return {
            text: message.text
        };
    }
}

export default AdPartnerService;
