import ParkingLotRequirementType, {getParkingLotRequirementTypeLabel} from './Entity/ParkingLotRequirementType';
import SelectOption from './Entity/Form/SelectOption';
import FieldLabel from './Component/Form/Field/FieldLabel';
import React from 'react';
import Select, {OnChangeValue} from 'react-select';

interface ParkingLotRequirementTypeSelectFieldProps {
    readonly label: string;
    readonly name: string;
    readonly title: string;
    readonly required: boolean;
    readonly hasErrors?: boolean | undefined;  // TODO -undefined  entfernen
    readonly defaultValue?: ParkingLotRequirementType;
    readonly onChange?: Function;
}

const ParkingLotRequirementTypeSelectField = (props: ParkingLotRequirementTypeSelectFieldProps): React.JSX.Element => {
    const selectOptions: SelectOption<ParkingLotRequirementType>[] = [
        {label: getParkingLotRequirementTypeLabel(ParkingLotRequirementType.Optionally), value: ParkingLotRequirementType.Optionally},
        {label: getParkingLotRequirementTypeLabel(ParkingLotRequirementType.Mandatory), value: ParkingLotRequirementType.Mandatory},
    ];

    return (
        <>
            <FieldLabel label={props.label} htmlFor={props.name} required={props.required} description={props.title} className="pt-0 mb-2 ps-2" />
            {/* TODO - umbau auf SelectField / MultiSelectField */}
            <Select
                name="parkingLotRequirementType"
                options={selectOptions}
                placeholder="Bitte wählen"
                className={`inset-shadow ${props.hasErrors === true ? ' is-invalid' : ''}`}
                defaultValue={selectOptions.find(selectOption => selectOption.value === props.defaultValue)}
                onChange={(newValue: OnChangeValue<SelectOption<ParkingLotRequirementType>, boolean>): void => {
                    if (props.onChange !== undefined) {
                        props.onChange((newValue as SelectOption<ParkingLotRequirementType>).value);
                    }
                }}
            />
        </>
    );
};

export default ParkingLotRequirementTypeSelectField;
