import ConversationService from './ConversationService';
import Property from '../Entity/Property/Property';
import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import ProviderSeekerConversation from '../Entity/Messaging/ProviderSeekerConversation';
import LoadingIndicator from '../../../components/LoadingIndicator';
import messagingStyle from './Messaging.module.scss';
import React, {useState} from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';

interface ConversationInitializationButtonProps {
    buttonText: string;
    settlementConcept: SettlementConcept;
    property: Property;
}

const conversationService: ConversationService = new ConversationService(process.env.REACT_APP_LLASM_API_URL!);

const ConversationInitializationButton = (props: ConversationInitializationButtonProps): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>();

    const persistProviderSeekerConversation = async (): Promise<void> => {
        setIsLoading(true);

        try {
            const conversation: ProviderSeekerConversation = await conversationService.persistProviderSeekerConversation(props.property, props.settlementConcept);

            navigate(ConversationService.createLinkToConversation(conversation));
        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading === true) {
        return (
            <div className={messagingStyle.loadingIndicatorContainer}>
                <LoadingIndicator />
            </div>
        );
    }

    return (
        <button onClick={(): Promise<void> => persistProviderSeekerConversation()} className={['btn', 'btn-primary', messagingStyle.actionButton].join(' ')}>
            <i className="bi bi-chat-fill me-2"></i>
            {props.buttonText}
        </button>
    );
};

export default ConversationInitializationButton;
