import AdvancedPlaceInformation from './AdvancedPlaceInformation';
import { PlaceType } from './PlaceType';
import PlaceResponse from '../../../../api/Llasm/Location/PlaceResponse';

class Place {

    private _id?: number;

    private _uuid: string;

    private _placeName: string;

    private _placeShortName: string | null = null;

    private _placeType: PlaceType;

    private _advancedPlaceInformation: AdvancedPlaceInformation | null = null;

    public static createFromPlaceResponse(placeResponse: PlaceResponse): Place {
        const place: Place = new this();

        place._id = placeResponse.id;
        place._uuid = placeResponse.uuid;
        place._placeName = placeResponse.placeName;
        place._placeShortName = placeResponse.placeShortName;
        place._placeType = placeResponse.placeType;

        if (placeResponse.advancedPlaceInformation !== null) {
            place._advancedPlaceInformation = AdvancedPlaceInformation.createFromAdvancedPlaceInformationResponse(placeResponse.advancedPlaceInformation);
        }

        return place;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get uuid(): string {
        return this._uuid;
    }

    set uuid(value: string) {
        this._uuid = value;
    }

    get placeName(): string {
        return this._placeName;
    }

    set placeName(value: string) {
        this._placeName = value;
    }

    get placeShortName(): string | null {
        return this._placeShortName;
    }

    set placeShortName(value: string | null) {
        this._placeShortName = value;
    }

    get placeType(): PlaceType {
        return this._placeType;
    }

    set placeType(value: PlaceType) {
        this._placeType = value;
    }

    get advancedPlaceInformation(): AdvancedPlaceInformation | null {
        return this._advancedPlaceInformation;
    }

    set advancedPlaceInformation(value: AdvancedPlaceInformation | null) {
        this._advancedPlaceInformation = value;
    }

}

export default Place;
