enum CompanyLocation {
    CompanySeat = 0,
    HomeTown = 1,
    Regional = 2,
    SupraRegional = 3,
    National = 4,
    International = 5
}

const getCompanyLocationLabel = (companyLocation: CompanyLocation): string => {
    switch (companyLocation) {
        case CompanyLocation.CompanySeat:
            return 'am Firmensitz';
        case CompanyLocation.HomeTown:
            return 'in eigener Stadt';
        case CompanyLocation.Regional:
            return 'regional';
        case CompanyLocation.SupraRegional:
            return 'überregional';
        case CompanyLocation.National:
            return 'national';
        case CompanyLocation.International:
            return 'international';
        default:
            throw new Error();
    }
};

export default CompanyLocation;
export {getCompanyLocationLabel};
