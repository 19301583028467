import FormData from '../../shared/Entity/Form/FormData';
import Message from '../../shared/Entity/AdPartner/Message';
import FormValidationHandler from '../../shared/FormValidationHandler/FormValidationHandler';
import TextAreaField from '../../shared/Component/Form/Field/TextAreaField';
import React from 'react';

interface MessageFormProps {
    readonly formData: FormData<Message>;
    readonly setFormData: (formData: FormData<Message>) => void;
    readonly formValidationHandler?: FormValidationHandler<Message>;
}

const MessageForm = (props: MessageFormProps): React.JSX.Element => {
    const message: Message = props.formData.data;

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        (message as any)[event.target.name] = event.target.value;

        updateFormData();
        validateField(event.target.name);
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: message});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <TextAreaField
            name="text"
            label="Nachricht"
            rows={6}
            placeholder="Schreibe etwas..."
            value={message.text}
            onChange={handleChange}
            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'text')}
        />
    );
};

export default MessageForm;
