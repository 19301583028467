import Pagination from './Pagination';

abstract class Paginated<T> {
    private readonly _pagination: Pagination;

    private readonly _results: T[];

    protected constructor(pagination: Pagination, results: T[]) {
        this._pagination = pagination;
        this._results = results;
    }

    get pagination(): Pagination {
        return this._pagination;
    }

    get results(): T[] {
        return this._results;
    }
}

export default Paginated;
