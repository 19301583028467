import FormData from '../Entity/Form/FormData';
import NumericRangeValidationDefinition from './NumericRangeValidationDefinition';

class NumericRangeOrNullValidationDefinition<T> extends NumericRangeValidationDefinition<T> {
    constructor(fieldName: string, min: number, max: number, message: string | null = null) {
        super(fieldName, min, max, message);
    }

    public check(formData: FormData<T>): boolean {
        const formDataValue: any = (formData.data as any)[this._fieldName];

        if (formDataValue === null) {
            return true;
        }

        return super.check(formData);
    }
}

export default NumericRangeOrNullValidationDefinition;
