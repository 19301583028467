import React from 'react';

const Item = (props: React.PropsWithChildren): React.JSX.Element => {
    return (
        <li className="glide__slide">
            {props.children}
        </li>
    );
};

export default Item;
