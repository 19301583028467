enum InterestFeedback {
    Interested = 0,
    NotInterested = 1
}

const getInterestFeedbackLabel = (interestFeedback: InterestFeedback): string => {
    switch (interestFeedback) {
        case InterestFeedback.Interested:
            return 'Interessiert';
        case InterestFeedback.NotInterested:
            return 'Nicht interessiert';
    }
};

export default InterestFeedback;
export {getInterestFeedbackLabel};
