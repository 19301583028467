import SliderConfig from './SliderConfig';
import Item from './Item';
import sliderStyle from './Slider.module.scss';
import React, {Children, MutableRefObject, useEffect, useRef, useState} from 'react';
import Glide, {Options} from '@glidejs/glide';

interface SliderProps extends React.PropsWithChildren {
    readonly config: SliderConfig;
    readonly className?: string;
}

const Slider = (props: SliderProps): React.JSX.Element => {
    const sliderContainerRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

    const sliderContentContainerRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

    const arrowButtonNextRef: MutableRefObject<HTMLButtonElement | null> = useRef<HTMLButtonElement | null>(null);

    const arrowButtonPreviousRef: MutableRefObject<HTMLButtonElement | null> = useRef<HTMLButtonElement | null>(null);

    const [glide, setGlide] = useState<Glide>();

    const [sliderContentContainerWidth, setSliderContentContainerWidth] = useState<string>();

    useEffect((): void => {
        if (props.config.showArrows === false || props.config.arrowsPosition !== 'outside') {
            return;
        }

        window.addEventListener('resize', handleWindowResize);
    }, []);

    useEffect((): void => {
        if (props.config.showArrows === false || props.config.arrowsPosition !== 'outside') {
            return;
        }

        if (glide === undefined) {
            return;
        }

        handleWindowResize();
    }, [glide]);

    useEffect((): void => {
        if (glide === undefined) {
            return;
        }

        if (sliderContentContainerWidth === undefined) {
            return;
        }

        glide.update();
    }, [sliderContentContainerWidth]);

    const buildOutsideArrowButtonClassName = (): string => {
        switch (props.config.arrowButtonSize) {
            case 'small':
                return sliderStyle.arrowButtonOutsideSmall;
            case 'large':
                return sliderStyle.arrowButtonOutsideLarge;
            default:
                return sliderStyle.arrowButtonOutsideLarge;
        }
    };

    const glideGo = (pattern: '>' | '<' | '>>' | '<<' | '|>' | '|<'): void => {
        if (glide === undefined) {
            return;
        }

        glide.go(pattern);
    };

    const handleWindowResize = (): void => {
        if (sliderContainerRef.current === null || sliderContentContainerRef.current === null) {
            return;
        }

        if (
            props.config.showArrows === false
            || props.config.arrowsPosition !== 'outside'
            || arrowButtonNextRef.current === null
            || arrowButtonPreviousRef.current === null
        ) {
            return;
        }

        const width: number = sliderContainerRef.current.offsetWidth - (arrowButtonNextRef.current.offsetWidth + arrowButtonPreviousRef.current.offsetWidth);

        setSliderContentContainerWidth(width + 'px');
    };

    if (props.config.showArrows === true && props.config.arrowsPosition === 'outside') {
        return (
            <div ref={sliderContainerRef} className={props.className + ' d-flex align-items-center'}>
                <button ref={arrowButtonNextRef} onClick={(): void => glideGo('<')} className={buildOutsideArrowButtonClassName()}>
                    <i className="bi bi-chevron-compact-left"></i>
                </button>
                <div ref={sliderContentContainerRef} style={{width: sliderContentContainerWidth}}>
                    <GlideComponent setGlide={setGlide} config={props.config}>
                        {props.children}
                    </GlideComponent>
                </div>
                <button ref={arrowButtonPreviousRef} onClick={(): void => glideGo('>')} className={buildOutsideArrowButtonClassName()}>
                    <i className="bi bi-chevron-compact-right"></i>
                </button>
            </div>
        );
    }

    return (
        <GlideComponent setGlide={setGlide} config={props.config}>
            {props.children}
        </GlideComponent>
    );
};

interface GlideComponentProps extends React.PropsWithChildren {
    readonly setGlide: (glide: Glide) => void;
    readonly config: Partial<SliderConfig>;
}

const GlideComponent = (props: GlideComponentProps): React.JSX.Element => {
    const glideRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

    const [glide, setGlide] = useState<Glide | undefined>(undefined);

    useEffect((): () => void => {
        const options: Partial<Options> = props.config as Partial<Options>;

        const glide: Glide = new Glide(glideRef.current!, options);

        glide.mount();

        setGlide(glide);

        props.setGlide(glide);

        return (): void => {
            glide.destroy();
        };
    }, [glideRef]);

    useEffect((): void => {
        if (glide !== undefined) {
            glide.update();
        }
    }, [props.children]);

    const buildInsideArrowButtonClassName = (): string => {
        switch (props.config.arrowButtonSize) {
            case 'small':
                return sliderStyle.arrowButtonInsideSmall;
            case 'large':
                return sliderStyle.arrowButtonInsideLarge;
            default:
                return sliderStyle.arrowButtonInsideSmall;
        }
    };

    return (
        <div ref={glideRef} className="glide">
            <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides">
                    {Children.map(props.children, child => (
                        <>
                            {child !== false &&
                                <Item>
                                    {child}
                                </Item>
                            }
                        </>
                    ))}
                </ul>
            </div>
            {(props.config.showArrows === true && props.config.arrowsPosition === 'inside') &&
                <div className="glide__arrows" data-glide-el="controls">
                    <button
                        type="button"
                        className={['glide__arrow', 'glide__arrow--left', buildInsideArrowButtonClassName(), 'rounded-circle', 'p-1'].join(' ')}
                        data-glide-dir="<"
                    >
                        <i className="bi bi-chevron-left"></i>
                    </button>
                    <button
                        type="button"
                        className={['glide__arrow', 'glide__arrow--right', 'glide_arrow', buildInsideArrowButtonClassName(), 'rounded-circle', 'p-1'].join(' ')}
                        data-glide-dir=">"
                    >
                        <i className="bi bi-chevron-right"></i>
                    </button>
                </div>
            }
        </div>
    );
};

export default Slider;
