import LeanPropertyResponse from '../../../../api/Llasm/Messaging/LeanPropertyResponse';
import LeanPropertyExpose from './LeanPropertyExpose';

class LeanProperty {
    private _id?: number;

    private _title: string;

    private _leanPropertyExpose: LeanPropertyExpose | null = null;

    public static createFromLeanPropertyResponse(leanPropertyResponse: LeanPropertyResponse): LeanProperty {
        const leanProperty: LeanProperty = new this();

        leanProperty._id = leanPropertyResponse.id;
        leanProperty._title = leanPropertyResponse.title;

        return leanProperty;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get leanPropertyExpose(): LeanPropertyExpose | null {
        return this._leanPropertyExpose;
    }

    set leanPropertyExpose(value: LeanPropertyExpose | null) {
        this._leanPropertyExpose = value;
    }
}

export default LeanProperty;
