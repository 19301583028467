import MarketplaceLot from '../Entity/Marketplace/MarketplaceLot';
import MarketplaceAdFilter from '../Entity/Marketplace/MarketplaceAdFilter';
import SortingOption from '../Entity/SortingOption/SortingOption';
import PaginationParameter from '../Entity/Pagination/PaginationParameter';
import MarketplaceAdPaginated from '../Entity/Marketplace/MarketplaceAdPaginated';
import Message from '../Entity/Marketplace/Message';
import MarketplaceAd from '../Entity/Marketplace/MarketplaceAd';
import Property from '../Entity/Property/Property';
import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import MarketplaceAdFilterPostRequest from '../../../api/Llasm/Marketplace/MarketplaceAdFilterPostRequest';
import MessagePostRequest from '../../../api/Llasm/Marketplace/MessagePostRequest';
import MarketplaceLotOccupyPostRequest from '../../../api/Llasm/Marketplace/MarketplaceLotOccupyPostRequest';
import MarketplaceLotReleasePostRequest from '../../../api/Llasm/Marketplace/MarketplaceLotReleasePostRequest';
import MarketplaceLotResponse from '../../../api/Llasm/Marketplace/MarketplaceLotResponse';
import MarketplaceAdPaginatedResponse from '../../../api/Llasm/Marketplace/MarketplaceAdPaginatedResponse';
import PropertyResponse from '../../../api/Llasm/Property/PropertyResponse';
import {AxiosResponse} from 'axios';

class MarketplaceService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
    }

    public async fetchMarketplaceAdsFromApi(marketplaceAdFilter: MarketplaceAdFilter, sortingOption?: SortingOption, paginationParameter?: PaginationParameter): Promise<MarketplaceAdPaginated> {
        const apiResponse: AxiosResponse<MarketplaceAdPaginatedResponse> = await this.llasmApiV1Provider.post<null, AxiosResponse<MarketplaceAdPaginatedResponse>, MarketplaceAdFilterPostRequest>(
            this.buildMarketplaceAdSearchPath(sortingOption, paginationParameter),
            MarketplaceService.mapFromMarketplaceAdFilterToMarketplaceAdFilterPostRequest(marketplaceAdFilter)
        );

        return MarketplaceAdPaginated.createFromMarketplaceAdPaginatedResponse(apiResponse.data);
    }

    public async fetchMarketplaceLotsFromApi(): Promise<MarketplaceLot[]> {
        const apiResponse: AxiosResponse<MarketplaceLotResponse[]> = await this.llasmApiV1Provider.get('/marketplace-lots');

        return apiResponse.data.map((marketplaceLotResponse: MarketplaceLotResponse): MarketplaceLot => {
            return MarketplaceLot.createFromMarketplaceLotResponse(marketplaceLotResponse);
        });
    }

    public async fetchAvailablePropertiesFromApi(): Promise<Property[]> {
        const apiResponse: AxiosResponse<PropertyResponse[]> = await this.llasmApiV1Provider.get('/marketplace-lot/properties');

        return apiResponse.data.map((propertyResponse: PropertyResponse): Property => {
            return Property.createFromPropertyResponse(propertyResponse);
        });
    }

    public async sendMessage(marketplaceAd: MarketplaceAd, message: Message): Promise<void> {
        const apiResponse: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, MessagePostRequest>(
            '/marketplace-ads/' + marketplaceAd.id + '/messages',
            MarketplaceService.mapFromMessageToMessagePostRequest(message)
        );
    }

    public async occupyMarketplaceLot(marketplaceLot: MarketplaceLot, property: Property): Promise<void> {
        const apiResponse: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, MarketplaceLotOccupyPostRequest>(
            '/marketplace-lots/' + marketplaceLot.id + '/occupy',
            MarketplaceService.mapFromPropertyToMarketplaceLotOccupyPostRequest(property)
        );
    }

    public async releaseMarketplaceLot(marketplaceLot: MarketplaceLot): Promise<void> {
        const apiResponse: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, MarketplaceLotReleasePostRequest>(
            '/marketplace-lots/' + marketplaceLot.id + '/release',
            {}
        );
    }

    private buildMarketplaceAdSearchPath(sortingOption?: SortingOption, paginationParameter?: PaginationParameter): string {
        const resourcePath: string = '/marketplace-ads/search';

        const searchParams: URLSearchParams = new URLSearchParams();

        if (sortingOption !== undefined) {
            searchParams.set('sorting-by', sortingOption.sortingBy);
            searchParams.set('direction', sortingOption.sortingDirection.toString());
        }

        if (paginationParameter !== undefined) {
            searchParams.set('page', paginationParameter.page.toString());
            searchParams.set('limit', paginationParameter.limit.toString());
        }

        if (searchParams.size > 0) {
            return resourcePath + '?' + searchParams.toString();
        } else {
            return resourcePath;
        }
    }

    private static mapFromMarketplaceAdFilterToMarketplaceAdFilterPostRequest(marketplaceAdFilter: MarketplaceAdFilter): MarketplaceAdFilterPostRequest {
        return {
            specialObjectCategories: marketplaceAdFilter.specialObjectCategories,
            fundingAvailable: marketplaceAdFilter.fundingAvailable,
            countryPlaceId: marketplaceAdFilter.countryPlace !== null ? marketplaceAdFilter.countryPlace.id! : null,
            totalAreaSizeFrom: marketplaceAdFilter.totalAreaSizeFrom,
            totalAreaSizeTo: marketplaceAdFilter.totalAreaSizeTo,
            cityId: marketplaceAdFilter.city !== null ? marketplaceAdFilter.city.id! : null,
            placeSearchRadius: marketplaceAdFilter.placeSearchRadius,
            onlyImmediatelyAvailable: marketplaceAdFilter.onlyImmediatelyAvailable
        }
    }

    private static mapFromMessageToMessagePostRequest(message: Message): MessagePostRequest {
        return {
            text: message.text
        };
    }

    private static mapFromPropertyToMarketplaceLotOccupyPostRequest(property: Property): MarketplaceLotOccupyPostRequest {
        return {
            propertyId: property.id!
        };
    }
}

export default MarketplaceService;
