import ContactService from './ContactService';
import Contact from '../Entity/Contact/Contact';
import ContactList from './ContactList';
import Tooltip from '../Component/Tooltip/Tooltip';
import Spinner from '../../../components/Spinner';
import { useAppDispatch } from '../../../app/hooks';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const contactService: ContactService = new ContactService(process.env.REACT_APP_LLASM_API_URL!);

const ContactOverviewPage = (): React.JSX.Element => {
    const [contacts, setContacts] = useState<Contact[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Meine Ansprechpartner'},
            ]
        });

    }, [dispatch]);

    useEffect((): void => {
        fetchContacts();
    }, []);

    const fetchContacts = async (): Promise<void> => {
        setIsLoading(true);

        setContacts(await contactService.fetchContactsFromApi());

        setIsLoading(false);
    };

    if (isLoading === true) {
        return <Spinner />;
    }

    return (
        <div className="container-fluid">
            <div className="row align-items-center mb-4">
                <div className="col-12 col-md-8 d-flex align-items-center">
                    <Link to="/"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                    <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Meine Ansprechpartner</h1>
                    <Tooltip
                        title="Ansprechpartner werden bei Deinem Suchprofil oder Deinem Ladenlokal für Interessenten als Ansprechpersonen genannt. Ihre Kontaktdaten sind nach dem Matching einsehbar."
                        icon={<i className="bi bi-info-circle"></i>}
                        className="text-info pb-2"
                    />
                </div>
                <div className="d-none d-md-flex col-md-4 justify-content-end px-4">
                    <Link to="/ansprechpartner/anlegen" className="btn btn-secondary d-flex align-items-center text-nowrap">
                        <i className="bi bi-plus-lg fs-5 px-2"></i>
                        Ansprechpartner anlegen
                    </Link>
                </div>
            </div>
            <div className="mb-5">
                <ContactList contacts={contacts} />
            </div>
            <div className="d-md-none col-12 col-md-4">
                <Link to="/ansprechpartner/anlegen" className="btn btn-secondary align-items-center text-nowrap">
                    <i className="bi bi-plus-lg fs-5 px-2"></i>
                    Ansprechpartner anlegen
                </Link>
            </div>
        </div>
    );
};

export default ContactOverviewPage;
