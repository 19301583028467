import FormError from './Entity/Form/FormError';
import TargetGroup, {getTargetGroupLabel} from './Entity/TargetGroup';
import SelectOption from './Entity/Form/SelectOption';
import FieldLabel from './Component/Form/Field/FieldLabel';
import React from 'react';
import Select, {OnChangeValue, PropsValue} from 'react-select';

interface TargetGroupSelectFieldProps {
    readonly label: string;
    readonly title: string;
    readonly name: string;
    readonly isMulti: boolean;
    readonly isClearable: boolean;
    readonly isRequired: boolean;
    readonly classNames?: string;
    readonly formErrors?: FormError[];
    readonly defaultValue?: TargetGroup | TargetGroup[];
    readonly onChange?: Function;
}

const TargetGroupSelectField = (props: TargetGroupSelectFieldProps): React.JSX.Element => {
    const hasErrors: boolean = props.formErrors !== undefined && props.formErrors.length > 0;

    const selectOptions: SelectOption<TargetGroup>[] = [
        {label: getTargetGroupLabel(TargetGroup.FEMALE), value: TargetGroup.FEMALE},
        {label: getTargetGroupLabel(TargetGroup.MALE), value: TargetGroup.MALE},
        {label: getTargetGroupLabel(TargetGroup.DIVERSE), value: TargetGroup.DIVERSE},
    ];

    const fetchDefaultValue = (): PropsValue<SelectOption<TargetGroup>> | undefined => {
        const selectedOptions: SelectOption<TargetGroup>[] = selectOptions.filter((selectOption: SelectOption<TargetGroup>): boolean => {
            if (Array.isArray(props.defaultValue) === true) {
                return (props.defaultValue as TargetGroup[]).includes(selectOption.value);
            } else {
                return selectOption.value === props.defaultValue;
            }
        });

        if (props.isMulti === true) {
            return selectedOptions;
        } else {
            if (selectedOptions.length > 0) {
                return selectedOptions[0];
            }
        }

        return undefined;
    };

    return (
        <div>
            <FieldLabel label={props.label} htmlFor={props.name} required={props.isRequired} description={props.title} className="mb-2 ps-2" />
            <div>
                {/* TODO - umbau auf SelectField / MultiSelectField */}
                <Select
                    name={props.name}
                    options={selectOptions}
                    isClearable={props.isClearable}
                    isMulti={props.isMulti}
                    closeMenuOnSelect={false}
                    className={('inset-shadow ' + props.classNames !== undefined ? props.classNames : '')  + (hasErrors === true ? ' is-invalid' : '')}
                    placeholder="Bitte wählen"
                    defaultValue={fetchDefaultValue()}
                    onChange={(newValue: OnChangeValue<SelectOption<TargetGroup>, boolean>): void => {
                        if (props.onChange !== undefined) {
                            if (Array.isArray(newValue) === true) {
                                props.onChange((newValue as Array<SelectOption<TargetGroup>>).map((selectOption: SelectOption<TargetGroup>): TargetGroup => selectOption.value));
                            } else {
                                props.onChange((newValue as SelectOption<TargetGroup>).value);
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default TargetGroupSelectField;
