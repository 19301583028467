import Concept from '../Entity/HallOfInspiration/Concept';
import Image from '../Entity/Image/Image';
import Message from '../Entity/HallOfInspiration/Message';
import MessagePostRequest from '../../../api/Llasm/HallOfInspiration/MessagePostRequest';
import ConceptResponse from '../../../api/Llasm/HallOfInspiration/ConceptResponse';
import ImageResponse from '../../../api/Llasm/Image/ImageResponse';
import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import {AxiosResponse} from 'axios';

class ConceptService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    private readonly resourcePath: string;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
        this.resourcePath = '/hall-of-inspiration/concepts';
    }

    public async fetchConceptsFromApi(): Promise<Concept[]> {
        const apiResponse: AxiosResponse<ConceptResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath);

        return apiResponse.data.map((conceptResponse: ConceptResponse): Concept => {
            return Concept.createFromConceptResponse(conceptResponse);
        });
    }

    public async fetchConceptFromApiById(id: number): Promise<Concept> {
        const apiResponse: AxiosResponse<ConceptResponse> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + id);

        const conceptResponse: ConceptResponse = apiResponse.data;

        return Concept.createFromConceptResponse(conceptResponse);
    }

    public async fetchImagesFromConcept(conceptId: number): Promise<Image[]> {
        const apiResponse: AxiosResponse<ImageResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + conceptId + '/images');

        return apiResponse.data.map((imageResponse: ImageResponse): Image => {
            return Image.createFromImageResponse(imageResponse);
        });
    }

    public async fetchNumberOfConcepts(): Promise<number> {
        const apiResponse: AxiosResponse<ConceptCountResponse> = await this.llasmApiV1Provider.get(this.resourcePath + '/count');

        return apiResponse.data.numberOfConcepts;
    }

    public async sendMessage(concept: Concept, message: Message): Promise<void> {
        const apiResponse: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, MessagePostRequest>(
            this.resourcePath + '/' + concept.id + '/messages',
            ConceptService.mapFromMessageToMessagePostRequest(message)
        );
    }

    public static buildImageApiPath(concept: Concept, image: Image): string {
        return '/hall-of-inspiration/concepts/' + concept.id + '/images/' + image.id;
    }

    private static mapFromMessageToMessagePostRequest(message: Message): MessagePostRequest {
        return {
            text: message.text
        };
    }
}

export default ConceptService;
