enum LocationCategory {
    OneALocation = 0,
    OneBLocation = 1,
    TwoALocation = 2,
    TwoBLocation = 3,
    Other = 4,
    OneCLocation = 5,
    TwoCLocation = 6,
    TrainStation = 7,
    ShoppingCenter = 8,
    RetailPark = 9,
    Airport = 10,
    OutletCenter = 11,
    HighwayRestStop = 12
}

const getLocationCategoryLabel = (locationCategory: LocationCategory): string => {
    switch (locationCategory) {
        case LocationCategory.OneALocation:
            return '1A-Lage';
        case LocationCategory.OneBLocation:
            return '1B-Lage';
        case LocationCategory.TwoALocation:
            return '2A-Lage';
        case LocationCategory.TwoBLocation:
            return '2B-Lage';
        case LocationCategory.Other:
            return 'Sonstige';
        case LocationCategory.OneCLocation:
            return '1C-Lage';
        case LocationCategory.TwoCLocation:
            return '2C-Lage';
        case LocationCategory.TrainStation:
            return 'Bahnhof';
        case LocationCategory.ShoppingCenter:
            return 'Einkaufszentrum';
        case LocationCategory.RetailPark:
            return 'Fachmarktzentrum';
        case LocationCategory.Airport:
            return 'Flughafen';
        case LocationCategory.OutletCenter:
            return 'Outlet-Center';
        case LocationCategory.HighwayRestStop:
            return 'Raststätte';
        default:
            throw new Error();
    }
};

export default LocationCategory;
export {getLocationCategoryLabel};
