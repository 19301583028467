import Contact from '../Entity/Contact/Contact';
import ContactEdit from './ContactEdit';
import InfoButtonOverlay from '../Component/InfoButtonOverlay/InfoButtonOverlay';
import { useAppDispatch } from '../../../app/hooks';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const ContactPage = (): React.JSX.Element => {
    const {contactId} = useParams();

    const [contact, setContact] = useState<Contact>();

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Meine Ansprechpartner', link: '/ansprechpartner'},
                {name: 'Ansprechpartner bearbeiten'},
            ]
        });
    }, [dispatch]);

    return (
        <>
            <div className="container-fluid">
                <div className="row align-items-center mb-4">
                    <div className="col-12 d-flex align-items-center">
                        <Link to="/ansprechpartner"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                        {contact !== undefined &&
                            <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">{contact.buildDisplayName() + ' bearbeiten'}</h1>
                        }
                        <InfoButtonOverlay title="Ansprechpartner bearbeiten" className="fs-5 me-3 ms-auto">
                            <div className="mb-3">
                                Hier kannst Du den Ansprechpartner zu Deinem Suchprofile oder Ladenlokale
                                bearbeiten.
                                Die angegebenen Kontaktdaten des Ansprechpartners werden im Fall eines
                                erfolgreichen Matchings dem jeweils anderen Matching-Partner freigeschaltet.
                            </div>
                        </InfoButtonOverlay>
                    </div>
                </div>

                {contactId !== undefined &&
                    <div className="py-3 px-0 px-md-3 mb-3">
                        <ContactEdit contactId={Number(contactId)} onContactFetched={setContact} />
                    </div>
                }
            </div>
        </>
    );
};

export default ContactPage;
