import PropertyService from '../PropertyService';
import Property from '../../Entity/Property/Property';
import Image from '../../Entity/Image/Image';
import Document from '../../Entity/Document/Document';
import SettlementConcept from '../../Entity/SettlementConcept/SettlementConcept';
import LocationDetailCollapseCard from './LocationDetailCollapseCard';
import DetailCollapseCard from './DetailCollapseCard';
import OfferDetailCollapseCard from './OfferDetailCollapseCard';
import EnergyCertificateCollapseCard from './EnergyCertificateCollapseCard';
import AdditionalDetailCollapseCard from './AdditionalDetailCollapseCard';
import ImageCollapseCard from '../../Image/ImageCollapseCard';
import ContactCollapseCard from '../../Contact/ContactCollapseCard';
import MarketingStatusBadge from '../MarketingStatusBadge';
import PropertyInterestButton from '../../PropertyInterest/PropertyInterestButton';
import Disclaimer from '../../Disclaimer/Disclaimer';
import DocumentCollapseCard from '../../Document/DocumentCollapseCard';
import Card from '../../Component/Card/Card';
import React from 'react';

interface PropertyDetailInformationProps extends React.PropsWithChildren {
    readonly property: Property;
    readonly settlementConcept?: SettlementConcept;
    readonly contactCollapseCardChildren?: React.JSX.Element;
    readonly imageGetPath?: string;
    readonly documentGetPath?: string;
}

const PropertyDetailInformation = (props: PropertyDetailInformationProps): React.JSX.Element => {
    const buildImageGetPath = (image: Image): string => {
        if (props.imageGetPath !== undefined) {
            return props.imageGetPath + '/' + image.id;
        }

        return PropertyService.buildImageApiPath(props.property, image);
    };

    const buildDocumentGetPath = (document: Document): string => {
        if (props.documentGetPath !== undefined) {
            return props.documentGetPath + '/' + document.id;
        }

        return PropertyService.buildDocumentApiPath(props.property, document);
    };

    return (
        <>
            <div className="d-flex justify-content-end mb-3">
                <MarketingStatusBadge marketingStatus={props.property.marketingStatus}/>
            </div>
            <LocationDetailCollapseCard property={props.property} />
            {props.property.images.length > 0 &&
                <ImageCollapseCard images={props.property.images} buildImageGetPath={buildImageGetPath} />
            }
            <DetailCollapseCard property={props.property} />
            <OfferDetailCollapseCard property={props.property} />
            {props.property.energyCertificate !== null &&
                <EnergyCertificateCollapseCard property={props.property} />
            }
            {props.property.additionalDetail.hasContent() === true &&
                <AdditionalDetailCollapseCard property={props.property} />
            }
            {props.settlementConcept !== undefined && props.settlementConcept.owner === true &&
                <Card cardType="shadow" className="mb-3 p-3 p-lg-4 p-xl-5">
                    <PropertyInterestButton settlementConcept={props.settlementConcept} property={props.property} />
                    <div className="mt-3">
                        Sende hier Dein Feedback an den/die Flächenanbieter:in und zeige damit, ob Euer gemeinsames Match für Dich grundsätzlich relevant ist. Bei Interesse solltest Du im Chat mit dem/der Flächenanbieter:in schreiben, um weitere Details zu klären. Hast Du kein Interesse, kannst Du das selbstverständlich im Chat auch begründen, ggf. hat der/die Anbieter:in ja auch noch weitere Angebote oder kann sich einen Umbau der Fläche vorstellen.
                    </div>
                </Card>
            }
            {props.property.documents.length > 0 &&
                <DocumentCollapseCard documents={props.property.documents} buildDocumentGetPath={buildDocumentGetPath} />
            }
            <ContactCollapseCard contacts={props.property.contacts} children={props.contactCollapseCardChildren} />
            {props.children}
            <Disclaimer title="Rechtliche Hinweise und Haftungsausschluss" className="mt-4 pb-5" />
        </>
    );
};

export default PropertyDetailInformation;
