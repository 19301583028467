import ConceptService from './ConceptService';
import ProductService from '../Product/ProductService';
import Product from '../Entity/Product/Product';
import ProductType from '../Entity/Product/ProductType';
import PurchaseHallOfInspirationProductOverlay from './PurchaseHallOfInspirationProductOverlay';
import Card from '../Component/Card/Card';
import RoundedContainer from '../Component/RoundedContainer/RoundedContainer';
import CardContent from '../Component/Card/CardContent';
import CardFooter from '../Component/Card/CardFooter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import purchaseHallOfInspirationProductCardStyle from './PurchaseHallOfInspirationProductCard.module.scss';
import React, {useEffect, useState} from 'react';

interface PurchaseHallOfInspirationProductCardProps {
    readonly className?: string;
    readonly onPurchaseHallOfInspirationProductOverlayClose?: () => void;
}

const conceptService: ConceptService = new ConceptService(process.env.REACT_APP_LLASM_API_URL!);
const productService: ProductService = new ProductService(process.env.REACT_APP_LLASM_API_URL!);

const PurchaseHallOfInspirationProductCard = (props: PurchaseHallOfInspirationProductCardProps): React.JSX.Element => {
    const [showOverlay, setShowOverlay] = useState<boolean>(false);

    const [numberOfConcepts, setNumberOfConcepts] = useState<number>();

    const [hallOfInspirationProducts, setHallOfInspirationProducts] = useState<Product[]>();

    useEffect((): void => {
        fetchNumberOfConcepts();
        fetchHallOfInspirationProducts();
    }, []);

    const buildClassName = (): string => {
        let className: string = purchaseHallOfInspirationProductCardStyle.purchaseHallOfInspirationProductCard;

        if (props.className !== undefined) {
            className += ' ' + props.className;
        }

        return className;
    };

    const fetchNumberOfConcepts = async (): Promise<void> => {
        setNumberOfConcepts(await conceptService.fetchNumberOfConcepts());
    };

    const fetchHallOfInspirationProducts = async (): Promise<void> => {
        const hallOfInspirationProducts: Product[] = await productService.fetchProductsFromApi(ProductType.HallOfInspiration);

        if (hallOfInspirationProducts.length === 0) {
            throw new Error();
        }

        hallOfInspirationProducts.forEach((hallOfInspirationProduct: Product): void => {
            if (hallOfInspirationProduct.productType !== ProductType.HallOfInspiration) {
                throw new Error();
            }

            if (hallOfInspirationProduct.priceMatchPoints === null) {
                throw new Error();
            }

            if (hallOfInspirationProduct.duration === null) {
                throw new Error();
            }
        });

        hallOfInspirationProducts.sort((hallOfInspirationProductOne: Product, hallOfInspirationProductTwo: Product): number => (hallOfInspirationProductOne.duration! < hallOfInspirationProductTwo.duration! ? -1 : 1));

        setHallOfInspirationProducts(hallOfInspirationProducts);
    };

    const openPurchaseHallOfInspirationProductOverlay = (): void => {
        setShowOverlay(true);
    };

    return (
        <>
            <Card className={buildClassName()} cardType="shadow">
                {(numberOfConcepts === undefined || hallOfInspirationProducts === undefined) ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        <RoundedContainer className="d-flex flex-wrap align-items-center" style={{height: '300px', backgroundColor: 'grey'}}>
                            <div className="w-100 ms-2 me-2 text-center" style={{fontSize: '40px', color: 'white'}}>
                                Lust auf noch mehr Inspiration?
                            </div>
                        </RoundedContainer>
                        <CardContent>
                            <div className="text-center">
                                Schalte jetzt unsere gesamte Hall of Inspiration frei und erhalte Zugriff auf alle aktuellen und zukünftigen Konzepte.
                            </div>
                        </CardContent>
                        <CardFooter>
                            <button className="btn btn-primary py-1 w-100 text-uppercase" onClick={openPurchaseHallOfInspirationProductOverlay}>
                                Freischalten
                            </button>
                        </CardFooter>
                    </>
                )}
            </Card>
            {(numberOfConcepts !== undefined && hallOfInspirationProducts !== undefined) &&
                <PurchaseHallOfInspirationProductOverlay
                    show={showOverlay}
                    setShow={setShowOverlay}
                    hallOfInspirationProducts={hallOfInspirationProducts}
                    numberOfConcepts={numberOfConcepts}
                    onPurchaseHallOfInspirationProductOverlayClose={props.onPurchaseHallOfInspirationProductOverlayClose}
                />
            }
        </>
    );
};

export default PurchaseHallOfInspirationProductCard;
