import BrandService from '../../Brand/BrandService';
import SettlementConcept from '../../Entity/SettlementConcept/SettlementConcept';
import AgeStructure, {getAgeStructureLabel} from '../../Entity/AgeStructure';
import {getTargetGroupLabel} from '../../Entity/TargetGroup';
import {getPriceSegmentLabel} from '../../Entity/PriceSegment';
import {getBranchingDegreeLabel} from '../../Entity/BranchingDegree';
import Image from '../../Entity/Image/Image';
import CompanyValueIconList from '../../CompanyValueIconList';
import ImageComponent from '../../Image/Image';
import CollapseCard from '../../Component/CollapseCard/CollapseCard';
import LabelValuePair from '../../../../components/LabelValuePair';
import LabelValuesPair from '../../../../components/LabelValuesPair';
import React from 'react';

interface BrandCollapseCardProps {
    readonly settlementConcept: SettlementConcept;
}

const BrandCollapseCard = (props: BrandCollapseCardProps): React.JSX.Element => {
    const ageStructureValues: string[] = props.settlementConcept.brand.ageStructures.map((ageStructure: AgeStructure): string => {
        return getAgeStructureLabel(ageStructure);
    });

    const targetGroupValues: string[] = props.settlementConcept.brand.targetGroups.map(
        targetGroup => getTargetGroupLabel(targetGroup)
    );

    const priceSegmentValues: string[] = props.settlementConcept.brand.priceSegments.map(
        priceSegment => getPriceSegmentLabel(priceSegment)
    );

    const buildImageGetPath = (image: Image): string => {
        return BrandService.buildImageGetPath(props.settlementConcept.brand);
    };

    return (
        <CollapseCard cardType="shadow" title="Marke" className="mb-3">
            <div className="row">
                <div className="col-12 col-lg-6 mb-3">
                    <LabelValuePair label="Marke" value={props.settlementConcept.brand.brandName} />
                    {props.settlementConcept.brand.companyValues.length > 0 &&
                        <div className="mt-3 label-value-pair">
                            <div>
                                <CompanyValueIconList companyValues={props.settlementConcept.brand.companyValues} />
                            </div>
                            <label>Unternehmenswerte</label>
                        </div>
                    }
                </div>
                {props.settlementConcept.brand.logo !== null &&
                    <div className="col-12 col-lg-6">
                        <ImageComponent
                            image={props.settlementConcept.brand.logo}
                            buildImageGetPath={buildImageGetPath}
                            className="img-fluid maxh-200px"
                            alt="Markenlogo"
                        />
                    </div>
                }
                <div className="col-12 mb-3">
                    <LabelValuePair label="Filialisierungsgrad" value={getBranchingDegreeLabel(props.settlementConcept.brand.branchingDegree)} />
                </div>
                {ageStructureValues.length > 0 &&
                    <div className="col-12 mb-3">
                        <LabelValuesPair label="Angesprochene Altersgruppe(n)" values={ageStructureValues} delimiter=" · " />
                    </div>
                }
                {targetGroupValues.length > 0 &&
                    <div className="col-12 mb-3">
                        <LabelValuesPair label="Angesprochene Zielgruppe(n)" values={targetGroupValues} delimiter=" · " />
                    </div>
                }
                {priceSegmentValues.length > 0 &&
                    <div className="col-12 mb-3">
                        <LabelValuesPair label="Preissegmente" values={priceSegmentValues} delimiter=" · " />
                    </div>
                }
            </div>
        </CollapseCard>
    );
};

export default BrandCollapseCard;
