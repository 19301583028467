import { configureStore } from '@reduxjs/toolkit';
import authenticationReducer from '../features/authentication/authenticationSlice';
import breadcrumbReducer from '../features/breadcrumb/breadcrumbSlice';
import userReducer from '../features/user/userSlice';

export const store = configureStore({
    reducer: {
        authentication: authenticationReducer,
        breadcrumb: breadcrumbReducer,
        user: userReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
