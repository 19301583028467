import Brand from '../Entity/Brand/Brand';
import {getBranchingDegreeLabel} from '../Entity/BranchingDegree';
import PriceSegment, {getPriceSegmentLabel} from '../Entity/PriceSegment';
import Table from '../Component/Table/Table';
import React from 'react';
import {Link} from 'react-router-dom';
import {GridActionsCellItem, GridCellParams, GridColDef, GridRowParams} from '@mui/x-data-grid';

interface BrandListProps {
    readonly brands: Brand[];
}

interface BrandListRow {
    readonly id: number;
    readonly rowNumber: number;
    readonly brandName: string;
    readonly branchingDegree: React.JSX.Element;
    readonly priceSegment: React.JSX.Element[];
}

const buildBranchingDegree = (brand: Brand): React.JSX.Element => {
    return (
        <span className="me-2 py-1 px-2 bg-light">
            {getBranchingDegreeLabel(brand.branchingDegree)}
        </span>
    );
};

const buildPriceSegments = (brand: Brand): React.JSX.Element[] => {
    return brand.priceSegments.map((priceSegment: PriceSegment): React.JSX.Element => (
        <span className="me-2 py-1 px-2 bg-light">
            {getPriceSegmentLabel(priceSegment)}
        </span>
    ));
};

const columns: GridColDef[] = [
    {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 100,
        cellClassName: 'icon-cell',
        sortable: false,
        getActions: (params: GridRowParams): [React.JSX.Element] => {
            return [
                <GridActionsCellItem
                    icon={
                        <Link to={'/marken/' + params.row.id}>
                            <i className="bi bi-pencil-square"></i>
                        </Link>
                    }
                    label="Edit"
                    className="text-primary"
                />,
            ];
        }
    },
    {
        field: 'rowNumber',
        headerName: '#',
        width: 60
    },
    {
        field: 'brandName',
        headerName: 'Markenname',
        flex: 1
    },
    {
        field: 'branchingDegree',
        headerName: 'Filialisierungsgrad',
        flex: 2,
        sortable: false,
        filterable: false,
        renderCell: (params: GridCellParams) => {
            const branchingDegree: React.JSX.Element = params.value as React.JSX.Element;

            return (
                <span>
                    <React.Fragment>{branchingDegree}</React.Fragment>
                </span>
            );
        }
    },
    {
        field: 'priceSegment',
        headerName: 'Preissegment',
        flex: 2,
        sortable: false,
        filterable: false,
        renderCell: (params: GridCellParams) => {
            const priceSegments: React.JSX.Element[] = params.value as React.JSX.Element[];

            return (
                <span>
                    {priceSegments.map((priceSegment: React.JSX.Element, index: number): React.JSX.Element => (
                        <React.Fragment key={index}>{priceSegment}</React.Fragment>
                    ))}
                </span>
            );
        }
    }
];

const createRows = (brands: Brand[]): BrandListRow[] => {
    return brands.map((brand: Brand, index: number): BrandListRow => {
        return {
            id: brand.id!,
            rowNumber: index + 1,
            brandName: brand.brandName,
            branchingDegree: buildBranchingDegree(brand),
            priceSegment: buildPriceSegments(brand)
        };
    });
};

const BrandList = (props: BrandListProps): React.JSX.Element => {
    return (
        <Table rows={createRows(props.brands)} columns={columns} checkboxSelection={false} />
    );
};

export default BrandList;
