import Card from '../Card/Card';
import infoBoxStyle from './InfoBox.module.scss';
import React from 'react';

interface InfoBoxProps extends React.PropsWithChildren {
    readonly cardType: 'outline' | 'shadow';
    readonly className?: string;
}

const InfoBox = (props: InfoBoxProps): React.JSX.Element => {
    const classNames: string[] = [infoBoxStyle.infoBox];

    if (props.className !== undefined) {
        classNames.push(props.className);
    }

    return (
        <Card className={classNames.join(' ')} cardType={props.cardType}>
            {props.children}
        </Card>
    );
};

export default InfoBox;
