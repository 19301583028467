import UserRegistrationService from './UserRegistrationService';
import LocationService from '../../shared/Location/LocationService';
import AuthenticationState from '../../shared/Entity/Authentication/AuthenticationState';
import Alert from '../../shared/Entity/Alert/Alert';
import AlertType from '../../shared/Entity/Alert/AlertType';
import Country from '../../shared/Entity/Location/Country';
import SelectOption from '../../shared/Entity/Form/SelectOption';
import UserRegistration from '../../shared/Entity/User/UserRegistration';
import FormData from '../../shared/Entity/Form/FormData';
import FormValidationHandler from '../../shared/FormValidationHandler/FormValidationHandler';
import FieldValidationDefinition from '../../shared/FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../../shared/FormValidationHandler/RequiredValidationDefinition';
import EmailValidationDefinition from '../../shared/FormValidationHandler/EmailValidationDefinition';
import IsTrueValidationDefinition from '../../shared/FormValidationHandler/IsTrueValidationDefinition';
import UserRegistrationForm from './UserRegistrationForm';
import WelcomeInfoBox from '../Public/WelcomeInfoBox';
import AlertBox from '../../../components/AlertBox';
import Spinner from '../../../components/Spinner';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import React, { useEffect, useState } from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

const emailAlreadyRegisteredErrorAlert: Alert = new Alert(
    AlertType.Error,
    'Anscheinend haben wir Deine E-Mail-Adresse bereits auf unserer Plattform hinterlegt. ' +
    'Entweder hast Du Dich bereits bei uns registriert oder es liegt daran, dass die öffentlich einsehbaren Informationen zu Deinen Suchkriterien bereits übernommen und ein Unternehmensprofil für Dich vorbereitet wurde. ' +
    'Wir helfen Dir! ' +
    'Melde Dich doch kurz bei David unter help@llasm.de / +49 2171 40188-46, um Dein Passwort zurücksetzen zu lassen, danach kannst Du sofort loslegen – let’s match!'
);

const requestErrorAlert: Alert = new Alert(AlertType.Error, 'Es ist ein Problem bei Deiner Registrierung aufgetreten. Bitte versuche es noch einmal.');

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Bitte fülle alle rot markierten Pflichtfelder aus.');

const fieldValidationDefinitions: FieldValidationDefinition<UserRegistration>[] = [
    new RequiredValidationDefinition<UserRegistration>('firstName', 'Vorname muss ausgefüllt sein.'),
    new RequiredValidationDefinition<UserRegistration>('lastName', 'Nachname muss ausgefüllt sein.'),
    new RequiredValidationDefinition<UserRegistration>('streetName', 'Straße muss ausgefüllt sein.'),
    new RequiredValidationDefinition<UserRegistration>('houseNumber', 'Hausnummer muss ausgefüllt sein.'),
    new RequiredValidationDefinition<UserRegistration>('postalCode', 'PLZ muss ausgefüllt sein.'),
    new RequiredValidationDefinition<UserRegistration>('placeName', 'Ort muss ausgefüllt sein.'),
    new RequiredValidationDefinition<UserRegistration>('country', 'Ein Land muss gewählt sein.'),
    new RequiredValidationDefinition<UserRegistration>('email', 'E-Mail muss ausgefüllt sein.'),
    new EmailValidationDefinition<UserRegistration>('email', 'Das E-Mail Format ist nicht korrekt'),
    new RequiredValidationDefinition<UserRegistration>('originChannel', '"Wie bist du auf uns aufmerksam geworden" muss ausgefüllt sein.'),
    new RequiredValidationDefinition<UserRegistration>('userIs', '"Du bist" muss ausgefüllt sein.'),
    new RequiredValidationDefinition<UserRegistration>('gtcAccepted', 'Nutzungsbedingungen'),
    new IsTrueValidationDefinition<UserRegistration>('gtcAccepted', 'Du musst den Nutzungsbedingungen zustimmen.'),
];

const formValidationHandler: FormValidationHandler<UserRegistration> = new FormValidationHandler<UserRegistration>(fieldValidationDefinitions);

const userRegistrationService: UserRegistrationService = new UserRegistrationService(process.env.REACT_APP_LLASM_API_URL!);

const locationService: LocationService = new LocationService(process.env.REACT_APP_LLASM_API_URL!);

const userRegistration: UserRegistration = new UserRegistration();

userRegistration.gtcAccepted = false;
userRegistration.naturalPerson = false;

const UserRegistrationPage = (): React.JSX.Element => {
    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const [formData, setFormData] = useState<FormData<UserRegistration>>({data: userRegistration});

    const [countrySelectOptions, setCountrySelectOptions] = useState<SelectOption<Country>[]>([]);

    const [registrationSuccessful, setRegistrationSuccessful] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [alert, setAlert] = useState<Alert>();

    const navigate: NavigateFunction = useNavigate();

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Registrieren'},
            ]
        });
    }, [dispatch]);

    useEffect((): void => {
        if (authenticatedUser !== null) {
            navigate('/');
        }
    }, [navigate]);

    useEffect((): void => {
        fetchCountrySelectOptions();
    }, []);

    useEffect((): void => {
        if (formData.data.naturalPerson === false) {
            formValidationHandler.addFieldValidationDefinition(new RequiredValidationDefinition<UserRegistration>('companyName', 'Firmenname'));
        } else {
            formValidationHandler.removeFieldValidationDefinitionsByFieldName('companyName');
        }
    }, [formData]);

    const fetchCountrySelectOptions = async (): Promise<void> => {
        setIsLoading(true);

        const countrySelectOptions: SelectOption<Country>[] = (await (locationService.fetchCountries())).map((country: Country): SelectOption<Country> => {
            return {
                label: country.countryName,
                value: country
            };
        });

        setCountrySelectOptions(countrySelectOptions);

        setIsLoading(false);
    };

    const persistUserRegistration = async (): Promise<void> => {
        formValidationHandler.validate(formData);

        if (formValidationHandler.hasErrors(formData) === true) {
            setAlert(formErrorAlert);

            return;
        }

        setAlert(undefined);

        setIsLoading(true);

        try {
            await userRegistrationService.persistUserRegistration(formData.data);

            setRegistrationSuccessful(true);
        } catch (error) {
            if ((error as AxiosError).response!.status === 409) {
                setAlert(emailAlreadyRegisteredErrorAlert);
            } else {
                setAlert(requestErrorAlert);
            }
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading === true) {
        return <Spinner />;
    }

    return (
        <div className="row g-3 mt-1 mb-3">
            <div className="col-12 pt-1 px-md-3">
                <h1 className="fw-bold text-secondary fs-2 mb-4 mb-lg-5">Ladenfläche trifft Nachmieter:<br />Match mit!</h1>
                <div className="dropdown d-lg-none mb-4">
                    <button type="button" className="btn btn-outline-primary p-3 me-2 text-black-50 position-relative" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi bi-question-circle"></i> Was wir Dir bieten
                    </button>
                    <ul className="dropdown-menu dropdown-menu-start mw-200px">
                        <li className="p-3">
                            <WelcomeInfoBox />
                        </li>
                    </ul>
                </div>
                <p className="text-muted fs-5 mb-4">Registriere Dich kostenlos!</p>
            </div>
            <div className="col-12 pt-1 text-start px-md-3">
                {alert !== undefined &&
                    <AlertBox alert={alert} autoDismiss={false} />
                }
                {registrationSuccessful === true ? (
                    <div className="alert alert-primary text-center mb-4" role="alert">
                        Deine Registrierung war erfolgreich. Du erhältst in Kürze eine E-Mail mit der
                        Möglichkeit Dein Passwort zu setzen. Danach kannst Du Dich <Link to="/login">einloggen</Link>.
                    </div>
                ) : (
                    <div>
                        <UserRegistrationForm
                            formData={formData}
                            setFormData={setFormData}
                            countrySelectOptions={countrySelectOptions}
                            formValidationHandler={formValidationHandler}
                        />
                        <div className="d-flex gap-4 mt-3 mb-4">
                            <button type="submit" className="btn btn-primary w-100" onClick={persistUserRegistration}>REGISTRIEREN</button>
                            <Link to="/datenschutz" target="_blank" className="btn btn-secondary w-100" type="button">DATENSCHUTZ</Link>
                        </div>

                        <span>Du hast bereits ein Konto? <Link to="/login">Einloggen</Link></span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserRegistrationPage;
