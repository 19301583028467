import Card from '../Card/Card';
import Collapse from '../Collapse/Collapse';
import React from 'react';

interface CollapseCardProps extends React.PropsWithChildren {
    readonly cardType: 'outline' | 'shadow';
    readonly title: string;
    readonly description?: string;
    readonly tooltipText?: string;
    readonly className?: string;
    readonly iconClassName?: string;
    readonly headerElement?: React.JSX.Element;
    readonly blurred?: boolean;
    readonly defaultCollapseExpansionState?: boolean;
    readonly padding?: 'big' | 'small';
}

const CollapseCard = (props: CollapseCardProps): React.JSX.Element => {
    const buildPaddingStyle = (): string => {
        switch (props.padding) {
            case 'small':
                return 'p-3';
            case 'big':
            default:
                return 'p-3 p-lg-4 p-xl-5';
        }
    };

    return (
        <Card className={props.className} cardType={props.cardType}>
            <div className={buildPaddingStyle()}>
                <Collapse
                    title={props.title}
                    description={props.description}
                    tooltipText={props.tooltipText}
                    iconClassName={props.iconClassName}
                    headerElement={props.headerElement}
                    blurred={props.blurred}
                    defaultCollapseExpansionState={props.defaultCollapseExpansionState}
                >
                    {props.children}
                </Collapse>
            </div>
        </Card>
    );
};

export default CollapseCard;
