import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import AutocompletePlace from '../Entity/Location/AutocompletePlace';
import Country from '../Entity/Location/Country';
import Place from '../Entity/Location/Place';
import AutocompletePlaceResponse from '../../../api/Llasm/Location/AutocompletePlaceResponse';
import CountryResponse from '../../../api/Llasm/Location/CountryResponse';
import PlaceResponse from '../../../api/Llasm/Location/PlaceResponse';
import {AxiosResponse} from 'axios';

class LocationService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
    }

    public async fetchStates(): Promise<Place[]> {
        const apiResponse: AxiosResponse = await this.llasmApiV1Provider.get('/location/states');

        return apiResponse.data.map((placeResponse: PlaceResponse): Place => {
            return Place.createFromPlaceResponse(placeResponse);
        });
    }

    public async fetchStatesByCountryPlace(countryPlace: Place): Promise<Place[]> {
        const apiResponse: AxiosResponse = await this.llasmApiV1Provider.get('/location/places/' + countryPlace.uuid + '/states');

        return apiResponse.data.map((placeResponse: PlaceResponse): Place => {
            return Place.createFromPlaceResponse(placeResponse);
        });
    }

    public async fetchCountries(isoAlphaTwoCodes?: string[]): Promise<Country[]> {
        let path: string = '/location/countries';

        if (isoAlphaTwoCodes !== undefined && isoAlphaTwoCodes.length !== 0) {
            path += '?isoAlphaTwoCodes=';

            isoAlphaTwoCodes.forEach((isoAlphaTwoCode: string, index: number): void => {
                if (index > 0) {
                    path += ',';
                }

                path += isoAlphaTwoCode;
            })
        }

        const apiResponse: AxiosResponse = await this.llasmApiV1Provider.get(path);

        return apiResponse.data.map((countryResponse: CountryResponse): Country => {
            return Country.createFromCountryResponse(countryResponse);
        });
    }

    public async fetchPlacesBySearchTerm(searchTerm: string, states?: string, countries?: string): Promise<AutocompletePlace[]> {
        let url: string = '/location/places/autocomplete?searchTerm=' + searchTerm;

        if (states !== undefined) {
            url += '&states=' + states;
        }

        if (countries !== undefined) {
            url += '&countries=' + countries;
        }

        const apiResponse: AxiosResponse<AutocompletePlaceResponse[]> = await this.llasmApiV1Provider.get(url);

        return apiResponse.data.map((autocompletePlaceResponse: AutocompletePlaceResponse): AutocompletePlace => {
            return AutocompletePlace.createFromAutocompletePlaceResponse(autocompletePlaceResponse);
        });
    }
}

export default LocationService;
