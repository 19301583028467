import React from 'react';
import { ThemeOptions } from '@mui/material/styles/createTheme';
import { DataGrid, deDE, GridColDef, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import { deDE as coreDeDE } from '@mui/material/locale';

//ToDo typing of rows
interface TableProps {
    readonly rows: any[];
    readonly columns: GridColDef[];
    readonly checkboxSelection: boolean;
    readonly setSelectedRows?: Function;
    readonly  pageSize?: number;
    readonly pageSizeOption?: number[];
}

const themeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: '#71F325'
        },
    }
};

const theme: Theme = createTheme(themeOptions, deDE, coreDeDE);

const Table = (props: TableProps): React.JSX.Element => {
    const onRowSelectionModelChange = (gridRowIds: GridRowSelectionModel): void => {
        const selectedRowsData: any[] = gridRowIds.map((gridRowId: GridRowId): any => {
            return props.rows.find((row: any): boolean => row.id === gridRowId);
        });

        if (props.setSelectedRows !== undefined) {
            props.setSelectedRows(selectedRowsData);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            {/*ToDo scroll-x integration*/}
            <div style={{ width: '100%', overflowX: 'auto' }}>
                <DataGrid
                    style={{minWidth: '600px'}}
                    rows={props.rows}
                    columns={props.columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: props.pageSize ?? 10,
                            },
                        },
                    }}
                    pageSizeOptions={props.pageSizeOption ?? [10, 25, 50]}
                    checkboxSelection={props.checkboxSelection}
                    disableRowSelectionOnClick={true}
                    onRowSelectionModelChange={(gridRowIds: GridRowSelectionModel) => onRowSelectionModelChange(gridRowIds)}
                    sx={{
                        borderRadius: 4,
                        border: 0,
                        borderColor: '#fff',
                        '& .MuiTablePagination-displayedRows': {
                            margin: 'auto',
                        },
                        '& .MuiTablePagination-selectLabel': {
                            margin: 'auto',
                        },
                        '& .MuiCheckbox-colorPrimary': {
                            color: '$primary !important',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: '600 !important',
                        },
                        '& .MuiDataGrid-cell:focus, .MuiDataGrid-cellCheckbox:focus-within, .icon-cell:focus-within, .MuiDataGrid-columnHeader:focus-within': {
                            outline: 'none !important',
                        },
                        '& .icon-cell': {
                            justifyContent: 'start',
                        },
                        '& .MuiButtonBase-root:not(.Mui-disabled)': {
                            color: '#71F325',
                        },
                    }}
                />
            </div>
        </ThemeProvider>
    );
};

export default Table;
