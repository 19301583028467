import Image from '../Entity/Image/Image';
import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import ImageResponse from '../../../api/Llasm/Image/ImageResponse';
import {AxiosResponse} from 'axios';

class ImageService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
    }

    public async fetchImage(getPath: string): Promise<Image> {
        const apiResponse: AxiosResponse<ImageResponse> = await this.llasmApiV1Provider.get(getPath);

        return Image.createFromImageResponse(apiResponse.data);
    }

    public async deleteImage(deletePath: string): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.delete(deletePath);
    }
}

export default ImageService;
