import Country from '../Location/Country';
import OriginChannel from './OriginChannel';
import UserIs from './UserIs';

class UserRegistration {
    private _email: string;

    private _naturalPerson: boolean;

    private _firstName: string;

    private _lastName: string;

    private _companyName: string | null = null;

    private _streetName: string;

    private _houseNumber: string;

    private _postalCode: string;

    private _placeName: string;

    private _country: Country;

    private _phoneNumber: string | null = null;

    private _originChannel: OriginChannel;

    private _userIs: UserIs;

    private _gtcAccepted: boolean;

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get naturalPerson(): boolean {
        return this._naturalPerson;
    }

    set naturalPerson(value: boolean) {
        this._naturalPerson = value;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value;
    }

    get companyName(): string | null {
        return this._companyName;
    }

    set companyName(value: string | null) {
        this._companyName = value;
    }

    get streetName(): string {
        return this._streetName;
    }

    set streetName(value: string) {
        this._streetName = value;
    }

    get houseNumber(): string {
        return this._houseNumber;
    }

    set houseNumber(value: string) {
        this._houseNumber = value;
    }

    get postalCode(): string {
        return this._postalCode;
    }

    set postalCode(value: string) {
        this._postalCode = value;
    }

    get placeName(): string {
        return this._placeName;
    }

    set placeName(value: string) {
        this._placeName = value;
    }

    get country(): Country {
        return this._country;
    }

    set country(value: Country) {
        this._country = value;
    }

    get phoneNumber(): string | null {
        return this._phoneNumber;
    }

    set phoneNumber(value: string | null) {
        this._phoneNumber = value;
    }

    get originChannel(): OriginChannel {
        return this._originChannel;
    }

    set originChannel(value: OriginChannel) {
        this._originChannel = value;
    }

    get userIs(): UserIs {
        return this._userIs;
    }

    set userIs(value: UserIs) {
        this._userIs = value;
    }

    get gtcAccepted(): boolean {
        return this._gtcAccepted;
    }

    set gtcAccepted(value: boolean) {
        this._gtcAccepted = value;
    }
}

export default UserRegistration;
