import ConversationEntity from '../Entity/Messaging/Conversation';
import ProviderSeekerConversation from '../Entity/Messaging/ProviderSeekerConversation';
import ConversationService from './ConversationService';
import ConversationList from './ConversationList';
import ConversationHeader from './ConversationHeader';
import Conversation from './Conversation';
import MessengerStyle from './Messenger.module.scss';
import React, {useEffect, useState} from 'react';

const conversationService: ConversationService = new ConversationService(process.env.REACT_APP_LLASM_API_URL!);

interface MessengerProps {
    readonly conversationIdentifier?: string;
}

const Messenger = (props: MessengerProps): React.JSX.Element => {
    const [conversations, setConversations] = useState<ConversationEntity[]>([]);

    const [activeConversation, setActiveConversation] = useState<ConversationEntity>();

    useEffect((): void => {
        if (conversations.length === 0) {
            return;
        }

        if (props.conversationIdentifier === undefined) {
            return;
        }

        const conversation: ConversationEntity | undefined = conversations.find((conversation: ConversationEntity): boolean => {
            return conversation.buildIdentifier() === props.conversationIdentifier;
        });

        if (conversation !== undefined) {
            setActiveConversation(conversation);
        }
    }, [conversations]);

    const updateConversationList = (): void => {
        conversationService.sortConversations(conversations);

        setConversations(conversations.concat([]));
    };

    const updateConversation = (conversationToBeUpdated: ConversationEntity): void => {
        const conversation: ConversationEntity | undefined = conversations.find((conversation: ConversationEntity): boolean => {
            return conversation.buildIdentifier() === conversationToBeUpdated.buildIdentifier();
        });

        if (conversation === undefined) {
            return;
        }

        if (conversation instanceof ProviderSeekerConversation === true) {
            (conversation as ProviderSeekerConversation).updateFromProviderSeekerConversation(conversationToBeUpdated as ProviderSeekerConversation);

            if (activeConversation !== undefined && activeConversation.buildIdentifier() === conversation.buildIdentifier()) {
                (activeConversation as ProviderSeekerConversation).updateFromProviderSeekerConversation(conversationToBeUpdated as ProviderSeekerConversation);
            }
        }

        updateConversationList();
    };

    const reloadActiveConversation = async (): Promise<void> => {
        if (activeConversation === undefined) {
            return;
        }

        const conversation: ConversationEntity = await conversationService.fetchConversationFromApiById(activeConversation.conversationType, activeConversation.id!);

        updateConversation(conversation);

        setActiveConversation(conversation);
    };

    return (
        <>
            <div className="row mb-4">
                <div className="col-12 col-md-4 col-lg-3"></div>
                <div className="col-12 col-md-8 col-lg-9">
                    <div className={['p-0', MessengerStyle.conversationHeaderWrapper].join(' ')}>
                        {activeConversation !== undefined &&
                            <ConversationHeader conversation={activeConversation} reloadConversation={reloadActiveConversation} />
                        }
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-4 col-lg-3">
                    <div className={['p-0', MessengerStyle.conversationListWrapper].join(' ')}>
                        <ConversationList
                            conversations={conversations}
                            setConversations={setConversations}
                            activeConversation={activeConversation}
                            setActiveConversation={setActiveConversation}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-8 col-lg-9">
                    <div className={['p-0', MessengerStyle.conversationWrapper].join(' ')}>
                        {activeConversation !== undefined &&
                            <Conversation conversation={activeConversation} updateConversation={updateConversation} updateConversationList={updateConversationList} />
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Messenger;
