import PropertyInterest from '../Entity/PropertyInterest/PropertyInterest';
import PropertyInterestFeedback, {getPropertyInterestFeedbackLabel} from '../Entity/PropertyInterest/PropertyInterestFeedback';
import IconBadge from '../Component/IconBadge/IconBadge';
import React from 'react';

interface PropertyInterestBadgeProps {
    readonly propertyInterest: PropertyInterest | null;
}

const PropertyInterestBadge = (props: PropertyInterestBadgeProps): React.JSX.Element => {
    return (
        <>
            {props.propertyInterest === null &&
                <IconBadge iconPublisher="bootstrap" iconName="emoji-neutral-fill" title="Kein Feedback" backgroundColor="light-grey" />
            }
            {props.propertyInterest !== null && props.propertyInterest.propertyInterestFeedback === PropertyInterestFeedback.Interested &&
                <IconBadge iconPublisher="bootstrap" iconName="emoji-laughing-fill" title={getPropertyInterestFeedbackLabel(PropertyInterestFeedback.Interested)} backgroundColor="primary" />
            }
            {props.propertyInterest !== null && props.propertyInterest.propertyInterestFeedback === PropertyInterestFeedback.NotInterested &&
                <IconBadge iconPublisher="bootstrap" iconName="emoji-frown-fill" title={getPropertyInterestFeedbackLabel(PropertyInterestFeedback.NotInterested)} backgroundColor="secondary" />
            }
        </>
    );
};

export default PropertyInterestBadge;
