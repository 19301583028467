import React, {CSSProperties} from 'react';

interface IconBadgeWrapperProps extends React.PropsWithChildren {
    readonly positionTop?: number;
    readonly positionBottom?: number;
    readonly positionLeft?: number;
    readonly positionRight?: number;
    readonly className?: string;
}

const IconBadgeWrapper = (props: IconBadgeWrapperProps): React.JSX.Element => {
    const buildPositionStyle = (): CSSProperties => {
        const style: CSSProperties = {};

        if (props.positionTop !== undefined) {
            style.top = props.positionTop + 'px';
        }

        if (props.positionBottom !== undefined) {
            style.bottom = props.positionBottom + 'px';
        }

        if (props.positionLeft !== undefined) {
            style.left = props.positionLeft + 'px';
        }

        if (props.positionRight !== undefined) {
            style.right = props.positionRight + 'px';
        }

        return style;
    };

    const buildClassName = (): string => {
        let className: string = 'position-relative';

        if (props.className !== undefined) {
            className += ' ' + props.className;
        }

        return className;
    };

    return (
        <div className={buildClassName()}>
            <div className="d-flex align-items-stretch gap-2 position-absolute z-1" style={buildPositionStyle()}>
                {props.children}
            </div>
        </div>
    );
};

export default IconBadgeWrapper;
