enum LiftType {
    Escalator = 0,
    PassengerLift = 1,
    CargoLift = 2
}

const getLiftTypeLabel = (liftType: LiftType): string => {
    switch (liftType) {
        case LiftType.Escalator:
            return 'Rolltreppe';
        case LiftType.PassengerLift:
            return 'Personenaufzug';
        case LiftType.CargoLift:
            return 'Lastenaufzug';
        default:
            throw new Error();
    }
}

export default LiftType;
export {getLiftTypeLabel};
