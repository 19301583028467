import React, {useEffect, useRef, useState} from 'react';
import {Tooltip as BootstrapTooltip} from 'bootstrap';

interface TooltipProps extends React.PropsWithChildren {
    readonly title: string;
    readonly icon?: React.JSX.Element;
    readonly className?: string;
}

const Tooltip = (props: TooltipProps): React.JSX.Element => {
    const [bootstrapTooltip, setBootstrapTooltip] = useState<BootstrapTooltip>();

    const tooltipRef: React.MutableRefObject<HTMLSpanElement | null> = useRef<HTMLSpanElement | null>(null);

    useEffect((): void => {
        setBootstrapTooltip(new BootstrapTooltip(tooltipRef.current!));
    }, []);

    useEffect((): void => {
        if (bootstrapTooltip !== undefined) {
            bootstrapTooltip.setContent({'.tooltip-inner': props.title});
        }
    }, [props.title]);

    return (
        <span
            ref={tooltipRef}
            style={{cursor: 'pointer'}}
            data-toggle="tooltip"
            data-placement="top"
            title={props.title}
            tabIndex={0}
            className={props.className}
        >
            {props.children !== undefined &&
                <>{props.children}</>
            }
            {(props.children === undefined && props.icon !== undefined) &&
                <span className="text-primary">{props.icon}</span>
            }
        </span>
    );
};

export default Tooltip;
