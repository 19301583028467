import Property from '../Entity/Property/Property';
import ImageEntity from '../Entity/Image/Image';
import InformationScope from '../Entity/InformationScope';
import Image from '../Image/Image';
import PropertyDetailInformationOverlay from './PropertyDetailInformationOverlay';
import PropertyService from './PropertyService';
import MarketingStatusBadge from './MarketingStatusBadge';
import Card from '../Component/Card/Card';
import CardMedia from '../Component/Card/CardMedia';
import CardContent from '../Component/Card/CardContent';
import StatusIndicator from '../Component/StatusIndicator/StatusIndicator';
import Slider from '../Component/Slider/Slider';
import IconBadgeWrapper from '../Component/IconBadge/IconBadgeWrapper';
import noImage from '../../../img/no-brand-logo.jpg';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

interface PropertyCardProps {
    readonly property: Property;
}

const PropertyCard = (props: PropertyCardProps): React.JSX.Element => {
    const [showDetailInformationOverlay, setShowDetailInformationOverlay] = useState<boolean>(false);

    const [cardMediaContainerHeight, setCardMediaContainerHeight] = useState<number>();

    const buildThumbnailImageGetPath = (image: ImageEntity): string => {
        return PropertyService.buildThumbnailImageApiPath(props.property, image);
    };

    return (
        <Card cardType="shadow" className="position-relative">
            <StatusIndicator active={props.property.active} right={-10} top={-10} />
            {cardMediaContainerHeight !== undefined &&
                <IconBadgeWrapper positionTop={cardMediaContainerHeight - 50} positionRight={-10}>
                    <MarketingStatusBadge marketingStatus={props.property.marketingStatus} />
                </IconBadgeWrapper>
            }
            <CardMedia setContainerHeight={setCardMediaContainerHeight}>
                {(props.property.images.length === 0) ? (
                    <div className="d-flex align-items-center" style={{height: cardMediaContainerHeight + 'px', overflow: 'hidden'}}>
                        <img src={noImage} alt="Kein Bild" className="w-100" />
                    </div>
                ) : (
                    <Slider
                        config={{
                            type: 'slider',
                            showArrows: props.property.images.length > 0,
                            arrowsPosition: 'inside',
                            rewind: true,
                            perView: 1,
                            bound: true
                        }}
                    >
                        {props.property.images.map((image: ImageEntity): React.JSX.Element => (
                            <div key={'image-' + image.id} className="d-flex align-items-center" style={{height: cardMediaContainerHeight + 'px', overflow: 'hidden'}}>
                                <Image image={image} buildImageGetPath={buildThumbnailImageGetPath} className="w-100" />
                            </div>
                        ))}
                    </Slider>
                )}
            </CardMedia>
            <CardContent className="px-2 px-md-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="fw-bolder">{props.property.title}</div>
                    <div className="text-nowrap">
                        <button className="btn btn-outline-secondary btn-sm me-2" onClick={(): void => setShowDetailInformationOverlay(true)}>
                            <i className="bi bi-eye-fill"></i>
                        </button>
                        <Link to={'/flaechen/' + props.property.id + ''} className="btn btn-sm btn-secondary">
                            <i className="bi bi-pencil-square"></i>
                        </Link>
                    </div>
                </div>
                <div className="mb-4">
                    <div className="row mb-3">
                        <div className="col-4 col-xxl-5">Fläche Nr:</div>
                        <div className="col-8 col-xxl-7">#{props.property.id}</div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4 col-xxl-5">Adresse:</div>
                        <div className="col-8 col-xxl-7">
                            {(props.property.locationDetail.streetName !== null && props.property.locationDetail.streetName !== '') &&
                                <div>{props.property.locationDetail.streetName} {props.property.locationDetail.houseNumber}</div>
                            }
                            <div>{props.property.locationDetail.postalCode} {props.property.locationDetail.city!.placeName}</div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4 col-xxl-5">(Verkaufs-)<br />Fläche:</div>
                        <div className="col-8 col-xxl-7">
                            {new Intl.NumberFormat('de-DE').format(props.property.detail.areaSize)} m²
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    {props.property.active === true &&
                        <Link to={'/flaechen/' + props.property.id + '/matching'} className="btn btn-primary mx-auto text-nowrap">
                            <i className="bi bi-arrow-repeat me-2"></i> Let's match
                        </Link>
                    }
                    {props.property.active === false &&
                        <button disabled={true} className="btn btn-primary mx-auto text-nowrap">
                            <i className="bi bi-arrow-repeat me-2"></i> Let's match
                        </button>
                    }
                </div>
            </CardContent>
            <PropertyDetailInformationOverlay
                show={showDetailInformationOverlay}
                setShow={setShowDetailInformationOverlay}
                property={props.property}
            />
        </Card>
    );
};

export default PropertyCard;
