import statusIndicatorStyle from './StatusIndicator.module.scss';
import React from 'react';

interface StatusIndicatorProps {
    readonly active: boolean;
    readonly right: number;
    readonly top: number;
}

const StatusIndicator = (props: StatusIndicatorProps): React.JSX.Element => {
    return (
        <div
            className={statusIndicatorStyle.statusIndicator + (props.active === true ? ' ' + statusIndicatorStyle.active : '')}
            style={{right: props.right + 'px', top: props.top + 'px'}}
        >
        </div>
    );
};

export default StatusIndicator;
