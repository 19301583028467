import MessageType from './MessageType';
import ParticipatingUser from './ParticipatingUser';
import MessageResponse from '../../../../api/Llasm/Messaging/MessageResponse';
import React from 'react';

abstract class Message {
    protected _id?: number;

    private _identifier: string;

    protected _messageType: MessageType;

    protected _senderUser: ParticipatingUser;

    protected _text: string | React.JSX.Element | null = null;

    protected _createdAt: Date;

    protected _unread: boolean;

    protected _isSender: boolean;

    public constructor(identifier: string, messageType: MessageType) {
        this._identifier = identifier;
        this._messageType = messageType;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get identifier(): string {
        return this._identifier;
    }

    set identifier(value: string) {
        this._identifier = value;
    }

    get messageType(): MessageType {
        return this._messageType;
    }

    set messageType(value: MessageType) {
        this._messageType = value;
    }

    get senderUser(): ParticipatingUser {
        return this._senderUser;
    }

    set senderUser(value: ParticipatingUser) {
        this._senderUser = value;
    }

    get text(): string | React.JSX.Element | null {
        return this._text;
    }

    set text(value: string | React.JSX.Element | null) {
        this._text = value;
    }

    get createdAt(): Date {
        return this._createdAt;
    }

    set createdAt(value: Date) {
        this._createdAt = value;
    }

    get unread(): boolean {
        return this._unread;
    }

    set unread(value: boolean) {
        this._unread = value;
    }

    get isSender(): boolean {
        return this._isSender;
    }

    set isSender(value: boolean) {
        this._isSender = value;
    }

    protected mapFromMessageResponse(messageResponse: MessageResponse): void {
        this._id = messageResponse.id;
        this._senderUser = ParticipatingUser.createFromParticipatingUserResponse(messageResponse.senderUser);
        this._text = messageResponse.text;
        this._createdAt = new Date(messageResponse.createdAt);
        this._unread = messageResponse.unread;
        this._isSender = this._senderUser.id !== null;
    }
}

export default Message;
