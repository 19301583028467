enum PlaceSearchRadius {
    Ten = 0,
    Twenty = 1,
    Forty = 2,
}

const getPlaceSearchRadiusLabel = (placeSearchRadius: PlaceSearchRadius): string => {
    switch (placeSearchRadius) {
        case PlaceSearchRadius.Ten:
            return '10 km';
        case PlaceSearchRadius.Twenty:
            return '20 km';
        case PlaceSearchRadius.Forty:
            return '40 km';
        default:
            throw new Error();
    }
};

export default PlaceSearchRadius;
export {getPlaceSearchRadiusLabel};
