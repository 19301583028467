enum PriceSegment {
    Low = 0,
    LowMid = 1,
    Mid = 2,
    MidHigh = 3,
    High = 4
}

const getPriceSegmentLabel = (priceSegment: PriceSegment): string => {
    switch (priceSegment) {
        case PriceSegment.Low:
            return 'Niedrig';
        case PriceSegment.LowMid:
            return 'Niedrig-Mittel';
        case PriceSegment.Mid:
            return 'Mittel';
        case PriceSegment.MidHigh:
            return 'Mittel-Hoch';
        case PriceSegment.High:
            return 'Hoch';
        default:
            throw new Error();
    }
};

export default PriceSegment;
export {getPriceSegmentLabel};
