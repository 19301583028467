import Conversation from './Conversation';
import ParticipatingUser from './ParticipatingUser';
import LeanApiConfiguration from '../LeanApiConfiguration';
import SettlementConcept from './SettlementConcept';
import LeanProperty from './LeanProperty';
import ConversationType from './ConversationType';
import ReferenceType from './ReferenceType';
import LeanConversationResponse from '../../../../api/Llasm/Messaging/LeanConversationResponse';

class LeanConversation extends Conversation {
    private _leanApiConfiguration: LeanApiConfiguration;

    private _settlementConcept: SettlementConcept;

    private _leanProperty: LeanProperty;

    public static createFromLeanConversationResponse(leanConversationResponse: LeanConversationResponse): LeanConversation {
        const leanConversation: LeanConversation = new this(ConversationType.Lean);

        leanConversation._id = leanConversationResponse.id;
        leanConversation._unreadMessages = leanConversationResponse.unreadMessages;

        if (leanConversationResponse.lastMessageAt !== null) {
            leanConversation._lastMessageAt = new Date(leanConversationResponse.lastMessageAt);
        }

        leanConversation._leanApiConfiguration = LeanApiConfiguration.createFromLeanApiConfigurationResponse(leanConversationResponse.leanApiConfiguration);
        leanConversation._settlementConcept = SettlementConcept.createFromSettlementConceptResponse(leanConversationResponse.settlementConcept);
        leanConversation._leanProperty = LeanProperty.createFromLeanPropertyResponse(leanConversationResponse.leanProperty);
        leanConversation._firstReferenceFrom = new ParticipatingUser();
        leanConversation._secondReferenceFrom = leanConversation._settlementConcept.participatingUser;
        leanConversation._firstReference =  leanConversation._leanProperty.title;
        leanConversation._secondReference = leanConversation._settlementConcept.reference + ': ' + leanConversation._settlementConcept.title;
        leanConversation._firstReferenceType = ReferenceType.Property;
        leanConversation._secondReferenceType = ReferenceType.SettlementConcept;

        leanConversation._firstReferenceFrom.name = leanConversation._leanApiConfiguration.leanProvider;

        return leanConversation;
    }

    get leanApiConfiguration(): LeanApiConfiguration {
        return this._leanApiConfiguration;
    }

    set leanApiConfiguration(value: LeanApiConfiguration) {
        this._leanApiConfiguration = value;
    }

    get settlementConcept(): SettlementConcept {
        return this._settlementConcept;
    }

    set settlementConcept(value: SettlementConcept) {
        this._settlementConcept = value;
    }

    get leanProperty(): LeanProperty {
        return this._leanProperty;
    }

    set leanProperty(value: LeanProperty) {
        this._leanProperty = value;
    }

    public buildIdentifier(): string {
        return this.conversationType + '-' + this._leanApiConfiguration.id + '-' + this._id;
    }
}

export default LeanConversation;
