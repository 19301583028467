import ParticipatingUser from './ParticipatingUser';
import PropertyResponse from '../../../../api/Llasm/Messaging/PropertyResponse';

class Property {
    private _id?: number;

    private _title: string;

    private _participatingUser: ParticipatingUser;

    public static createFromPropertyResponse(propertyResponse: PropertyResponse): Property {
        const property: Property = new this();

        property._id = propertyResponse.id;
        property._title = propertyResponse.title;
        property._participatingUser = ParticipatingUser.createFromParticipatingUserResponse(propertyResponse.participatingUser);

        return property;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get participatingUser(): ParticipatingUser {
        return this._participatingUser;
    }

    set participatingUser(value: ParticipatingUser) {
        this._participatingUser = value;
    }
}

export default Property;
