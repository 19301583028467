enum UserIs {
    PropertyProvider = 0,
    PropertySeeker = 1,
    RealEstateAgent = 2,
    MunicipalityCityMarketingEconomicDevelopment = 3,
    InterestedInHallOfInspiration = 4,
    GeneralInterestOther = 5
}

const getUserIsLabel = (userIs: UserIs): string => {
    switch (userIs) {
        case UserIs.PropertyProvider:
            return 'Flächenanbieter';
        case UserIs.PropertySeeker:
            return 'Flächensuchender';
        case UserIs.RealEstateAgent:
            return 'Immobilienmakler';
        case UserIs.MunicipalityCityMarketingEconomicDevelopment:
            return 'Kommune/Stadtmarketing/Wirtschaftsförderung';
        case UserIs.InterestedInHallOfInspiration:
            return 'Interessiert an der Hall of Inspiration';
        case UserIs.GeneralInterestOther:
            return 'Allgemein interessiert / Sonstiges';
    }
}

export default UserIs;
export {getUserIsLabel};
