enum EnergyEfficiencyClass {
    APlus = 0,
    A = 1,
    B = 2,
    C = 3,
    D = 4,
    E = 5,
    F = 6,
    G = 7,
    H = 8
}

const getEnergyEfficiencyClassLabel = (energyEfficiencyClass: EnergyEfficiencyClass): string => {
    switch (energyEfficiencyClass) {
        case EnergyEfficiencyClass.APlus:
            return 'A+';
        case EnergyEfficiencyClass.A:
            return 'A';
        case EnergyEfficiencyClass.B:
            return 'B';
        case EnergyEfficiencyClass.C:
            return 'C';
        case EnergyEfficiencyClass.D:
            return 'D';
        case EnergyEfficiencyClass.E:
            return 'E';
        case EnergyEfficiencyClass.F:
            return 'F';
        case EnergyEfficiencyClass.G:
            return 'G';
        case EnergyEfficiencyClass.H:
            return 'H';
        default:
            throw new Error();
    }
};

export default EnergyEfficiencyClass;
export {getEnergyEfficiencyClassLabel};
