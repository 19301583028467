import React from 'react';

interface SpecialFilterButtonProps {
    readonly label: string | React.JSX.Element;
    readonly isActive: boolean;
    readonly onClick: () => void;
}

const SpecialFilterButton = (props: SpecialFilterButtonProps): React.JSX.Element => {
    return (
        <button
            className={['btn btn-sm p-xl-3 me-2', props.isActive === true ? 'btn-outline-secondary active' : 'btn-outline-secondary'].join(' ')}
            onClick={props.onClick}
        >
            {props.label}
        </button>
    );
};

export default SpecialFilterButton;
