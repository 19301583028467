enum SpecialObjectSeparation {
    TemporalSeparation = 0,
    SpatialSeparation = 1
}

const getSpecialObjectSeparationLabel = (specialObjectSeparation: SpecialObjectSeparation): string => {
    switch (specialObjectSeparation) {
        case SpecialObjectSeparation.TemporalSeparation:
            return 'zeitliche Trennung/Teilung der Fläche';
        case SpecialObjectSeparation.SpatialSeparation:
            return 'räumliche Trennung der Fläche';
        default:
            throw new Error();
    }
};

export default SpecialObjectSeparation;
export {getSpecialObjectSeparationLabel};
