enum TargetGroup {
    MALE = 0,
    FEMALE = 1,
    DIVERSE = 2
}

const getTargetGroupLabel = (targetGroup: TargetGroup): string => {
    switch (targetGroup) {
        case TargetGroup.MALE:
            return 'Männlich';
        case TargetGroup.FEMALE:
            return 'Weiblich';
        case TargetGroup.DIVERSE:
            return 'Divers';
        default:
            throw new Error();
    }
};

export default TargetGroup;
export {getTargetGroupLabel};
