import CompanyValue, {getCompanyValueLabel} from '../Entity/CompanyValue';
import IconBadge from '../Component/IconBadge/IconBadge';
import React from 'react';

interface CompanyValueIconBadgeProps {
    readonly companyValue: CompanyValue;
}

const CompanyValueIconBadge = (props: CompanyValueIconBadgeProps): React.JSX.Element => {
    switch (props.companyValue) {
        case CompanyValue.Sustainability:
            return <IconBadge iconPublisher="material" iconName="forest" description={getCompanyValueLabel(CompanyValue.Sustainability)} />;
        case CompanyValue.Specialized:
            return <IconBadge iconPublisher="material" iconName="bolt" description={getCompanyValueLabel(CompanyValue.Specialized)} />;
        case CompanyValue.Innovativ:
            return <IconBadge iconPublisher="material" iconName="emoji_objects" description={getCompanyValueLabel(CompanyValue.Innovativ)} />;
        case CompanyValue.Networking:
            return <IconBadge iconPublisher="material" iconName="share" description={getCompanyValueLabel(CompanyValue.Networking)} />;
        case CompanyValue.RelaxationRecreation:
            return <IconBadge iconPublisher="material" iconName="self_improvement" description={getCompanyValueLabel(CompanyValue.RelaxationRecreation)} />;
        case CompanyValue.Creativity:
            return <IconBadge iconPublisher="material" iconName="color_lens" description={getCompanyValueLabel(CompanyValue.Creativity)} />;
        case CompanyValue.ExperienceEntertainment:
            return <IconBadge iconPublisher="material" iconName="attractions" description={getCompanyValueLabel(CompanyValue.ExperienceEntertainment)} />;
        case CompanyValue.LocalityRegionality:
            return <IconBadge iconPublisher="material" iconName="anchor" description={getCompanyValueLabel(CompanyValue.LocalityRegionality)} />;
        case CompanyValue.ServiceOriented:
            return <IconBadge iconPublisher="material" iconName="room_service" description={getCompanyValueLabel(CompanyValue.ServiceOriented)} />;
        case CompanyValue.VitalityVibrancy:
            return <IconBadge iconPublisher="material" iconName="fitness_center" description={getCompanyValueLabel(CompanyValue.VitalityVibrancy)} />;
        case CompanyValue.ShopWindowDressing:
            return <IconBadge iconPublisher="material" iconName="storefront" description={getCompanyValueLabel(CompanyValue.ShopWindowDressing)} />;
        case CompanyValue.ShopFitting:
            return <IconBadge iconPublisher="material" iconName="handyman" description={getCompanyValueLabel(CompanyValue.ShopFitting)} />;
        case CompanyValue.Digitalisation:
            return <IconBadge iconPublisher="material" iconName="smart_toy" description={getCompanyValueLabel(CompanyValue.Digitalisation)} />;
        default:
            return <></>;
    }
};

export default CompanyValueIconBadge;
