import AdPartnerService from './AdPartnerService';
import AdPartnerEntity from '../../shared/Entity/AdPartner/AdPartner';
import AdPartnerType from '../../shared/Entity/AdPartner/AdPartnerType';
import AdPartner from './AdPartner';
import LoadingIndicator from '../../../components/LoadingIndicator';
import React, {useEffect, useState} from 'react';

interface AdPartnerSectionProps {
    readonly adPartnerType: AdPartnerType;
}

const adPartnerService: AdPartnerService = new AdPartnerService(process.env.REACT_APP_LLASM_API_URL!);

const minMediumDeviceWidth: number = 768;
let windowWidth: number = window.innerWidth;

const AdPartnerSection = (props: AdPartnerSectionProps): React.JSX.Element => {
    const [adPartners, setAdPartners] = useState<AdPartnerEntity[]>();

    useEffect((): void => {
        if (adPartners !== undefined) {
            return;
        }

        if (props.adPartnerType === AdPartnerType.Standard) {
            fetchAdPartnersByWindowWidth();

            window.addEventListener('resize', handleWindowResize);
        }

        if (props.adPartnerType === AdPartnerType.Premium || props.adPartnerType === AdPartnerType.Platinum) {
            fetchAdPartners(1);
        }
    }, []);

    const handleWindowResize = (): void => {
        if (
            (window.innerWidth < minMediumDeviceWidth && windowWidth >= minMediumDeviceWidth)
            || (window.innerWidth >= minMediumDeviceWidth && windowWidth < minMediumDeviceWidth)
        ) {
            fetchAdPartnersByWindowWidth();
        }

        windowWidth = window.innerWidth;
    };

    const fetchAdPartnersByWindowWidth = (): void => {
        if (window.innerWidth < minMediumDeviceWidth) {
            fetchAdPartners(1);
        } else {
            fetchAdPartners(2);
        }
    };

    const fetchAdPartners = async (amount: number): Promise<void> => {
        const adPartners: AdPartnerEntity[] = await adPartnerService.fetchAdPartnersFromApi(amount, props.adPartnerType);

        setAdPartners(adPartners);
    };

    if (adPartners === undefined) {
        return <LoadingIndicator />;
    }

    return (
        <>
            {props.adPartnerType === AdPartnerType.Standard &&
                <div className="row">
                    {adPartners.map((adPartner: AdPartnerEntity): React.JSX.Element => (
                        <div key={'ad-partner-' + adPartner.id} className="col-12 col-md-6">
                            <AdPartner adPartner={adPartner} />
                        </div>
                    ))}
                </div>
            }

            {props.adPartnerType === AdPartnerType.Premium &&
                <div className="mb-3">
                    {adPartners.map((adPartner: AdPartnerEntity): React.JSX.Element => (
                        <div key={'ad-partner-' + adPartner.id}>
                            <AdPartner adPartner={adPartner} />
                        </div>
                    ))}
                </div>
            }

            {props.adPartnerType === AdPartnerType.Platinum &&
                <div className="mb-3">
                    {adPartners.map((adPartner: AdPartnerEntity): React.JSX.Element => (
                        <div key={'ad-partner-' + adPartner.id}>
                            <AdPartner adPartner={adPartner} />
                        </div>
                    ))}
                </div>
            }
        </>
    );
};

export default AdPartnerSection;
