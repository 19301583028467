import InfoButton from '../InfoButton/InfoButton';
import Card from '../Card/Card';
import Overlay from '../Overlay/Overlay';
import React, {useState} from 'react';

interface InfoButtonOverlayProps extends React.PropsWithChildren {
    readonly title: string;
    readonly className?: string;
}

const InfoButtonOverlay = (props: InfoButtonOverlayProps): React.JSX.Element => {
    const [showInfoOverlay, setShowInfoOverlay] = useState<boolean>(false);

    const handleInfoButtonClick = (): void => {
        setShowInfoOverlay(true);
    };

    return (
        <>
            <InfoButton className={props.className} onClick={handleInfoButtonClick} />
            <Overlay
                show={showInfoOverlay}
                setShow={setShowInfoOverlay}
                title={props.title}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Card cardType="outline" className="p-3 p-md-5">
                                {props.children}
                                <button className="btn btn-dark" onClick={(): void => setShowInfoOverlay(false)}>
                                    Alles Klar!
                                </button>
                            </Card>
                        </div>
                    </div>
                </div>
            </Overlay>
        </>
    );
};

export default InfoButtonOverlay;
