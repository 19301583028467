class JsonWebTokenParser<T> {

    private readonly jsonWebToken: string

    public constructor(jsonWebToken: string) {
        this.jsonWebToken = jsonWebToken;
    }

    public parse(): T {
        const [header, payload, signature] = this.jsonWebToken.split('.');

        return JSON.parse(atob(payload));
    }
}

export default JsonWebTokenParser;
