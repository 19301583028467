import Tooltip from '../../Tooltip/Tooltip';
import fieldLabelStyle from './FieldLabel.module.scss';
import React from 'react';

interface FieldLabelProps extends React.PropsWithChildren {
    readonly label: string;
    readonly htmlFor: string;
    readonly required?: boolean;
    readonly description?: string;
    readonly className?: string;
}

const FieldLabel = (props: FieldLabelProps): React.JSX.Element => {
    return (
        <label htmlFor={props.htmlFor} className={[fieldLabelStyle.fieldLabel, props.className].join(' ')}>
            {props.label}
            {props.required === true && <sup>*</sup>}
            {props.description !== undefined &&
                <Tooltip title={props.description} icon={<i className="bi bi-info-circle"></i>} className="text-info ms-1" />
            }
            {props.children}
        </label>
    );
};

export default FieldLabel;
