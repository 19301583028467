import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import Brand from '../Entity/Brand/Brand';
import Image from '../Entity/Image/Image';
import ImageResponse from '../../../api/Llasm/Image/ImageResponse';
import CompleteBrandResponse from '../../../api/Llasm/Brand/CompleteBrandResponse';
import BrandPostRequest from '../../../api/Llasm/Brand/BrandPostRequest';
import BrandPatchRequest from '../../../api/Llasm/Brand/BrandPatchRequest';
import { AxiosResponse } from 'axios';

class BrandService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    private readonly resourcePath: string;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
        this.resourcePath = '/brands';
    }

    public async fetchBrandsFromApi(): Promise<Brand[]> {
        const apiResponse: AxiosResponse<CompleteBrandResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath);

        return apiResponse.data.map((brandResponse: CompleteBrandResponse): Brand => {
            return Brand.createFromCompleteBrandResponse(brandResponse);
        });
    }

    public async fetchBrandFromApiById(id: number): Promise<Brand> {
        const apiResponse: AxiosResponse<CompleteBrandResponse> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + id);

        return Brand.createFromCompleteBrandResponse(apiResponse.data);
    }

    public async persistBrand(brand: Brand): Promise<Brand> {
        const response: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, BrandPostRequest>(
            this.resourcePath,
            BrandService.mapFromBrandToBrandPostRequest(brand)
        );

        const apiResponse: AxiosResponse<CompleteBrandResponse> = await this.llasmApiV1Provider.getFromLocation(response.headers.location);

        return Brand.createFromCompleteBrandResponse(apiResponse.data);
    }

    // TODO umbau auf PATCH
    public async updateBrand(brand: Brand): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.put<null, AxiosResponse<null>, BrandPatchRequest>(
            this.resourcePath + '/' + brand.id,
            BrandService.mapFromBrandToBrandPatchRequest(brand)
        );
    }

    public async fetchLogoFromBrand(brand: Brand): Promise<Image> {
        const apiResponse: AxiosResponse<ImageResponse> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + brand.id + '/logo');

        return Image.createFromImageResponse(apiResponse.data!);
    }

    public static buildImageGetPath(brand: Brand): string {
        return '/brands/' + brand.id + '/logo';
    }

    public static buildImageDeletePath(brand: Brand, image: Image): string {
        return '/brands/' + brand.id + '/logo/' + image.id;
    }

    private static mapFromBrandToBrandPostRequest(brand: Brand): BrandPostRequest {
        return BrandService.mapBrandToApiRequest(brand) as BrandPostRequest;
    }

    private static mapFromBrandToBrandPatchRequest(brand: Brand): BrandPatchRequest {
        return BrandService.mapBrandToApiRequest(brand) as BrandPatchRequest;
    }

    private static mapBrandToApiRequest(brand: Brand): BrandPostRequest | BrandPatchRequest {
        return {
            brandName: brand.brandName,
            ageStructures: brand.ageStructures,
            targetGroups: brand.targetGroups,
            priceSegments: brand.priceSegments,
            /* TODO: to be added later, requires a process for approval by an administrator
            companyValues: brand.companyValues,
             */
            companyValues: [],
            branchingDegree: brand.branchingDegree
        };
    }
}

export default BrandService;
