import User from '../../shared/Entity/User/User';
import BillingAddress from '../../shared/Entity/User/BillingAddress';
import Currency from '../../shared/Entity/Currency';
import MatchPointCard from './MatchPointCard';
import OrderOverviewInformation from './OrderOverviewInformation';
import Card from '../../shared/Component/Card/Card';
import React from 'react';

interface OrderConfirmationWrapperProps extends React.PropsWithChildren {
    readonly user: User;
    readonly numberFormat: Intl.NumberFormat;
    readonly currency: Currency;
    readonly taxRate: number;
    readonly amount: number
    readonly priceNet: number;
    readonly priceGross: number;
    readonly billingAddress: BillingAddress;
    readonly paymentMethodText?: string;
}

const OrderConfirmationWrapper = (props: OrderConfirmationWrapperProps): React.JSX.Element => {
    return (
        <div className="row">
            <div className="col-12 col-md-7 col-xl-9">
                <Card cardType="shadow" className="p-3">
                    {props.children}
                </Card>
            </div>
            <div className="col-12 col-md-5 col-xl-3">
                <MatchPointCard header="Bestellübersicht">
                    <OrderOverviewInformation
                        user={props.user}
                        numberFormat={props.numberFormat}
                        currency={props.currency}
                        taxRate={props.taxRate}
                        amount={props.amount}
                        priceNet={props.priceNet}
                        priceGross={props.priceGross}
                        billingAddress={props.billingAddress}
                        paymentMethodText={props.paymentMethodText}
                    />
                </MatchPointCard>
            </div>
        </div>
    );
};

export default OrderConfirmationWrapper;
