import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import mixedUseSpaceInformationStyle from './MixedUseSpaceInformation.module.scss';
import React from 'react';

interface MixedUseSpaceInformationProps {
    readonly settlementConcept: SettlementConcept;
    readonly fontSize: 'small' | 'big';
}

const MixedUseSpaceInformation = (props: MixedUseSpaceInformationProps): React.JSX.Element => {
    const buildFontSizeStyle = (): string => {
        return (props.fontSize === 'big') ? mixedUseSpaceInformationStyle.fsBig : mixedUseSpaceInformationStyle.fsSmall;
    };

    return (
        <>
            {props.settlementConcept.interestMixedUseSpace === true &&
                <div className={buildFontSizeStyle()}>
                    <i className="bi bi-columns-gap me-3"></i>
                    {(props.settlementConcept.onlyMixedUseSpace === true) ? (
                        <span>Sucht ausschließlich Mixed-Use-Flächen</span>
                    ) : (
                        <span>Hat auch Interesse an Mixed-Use-Flächen</span>
                    )}
                </div>
            }
        </>
    );
};

export default MixedUseSpaceInformation;
