import LocationCategory from '../LocationCategory';
import LocationType from '../LocationType';
import LocationFactor from '../LocationFactor';
import PropertyAcquisitionType from '../PropertyAcquisitionType';
import ParkingLotRequirementType from '../ParkingLotRequirementType';
import PropertyCriteriaResponse from '../../../../api/Llasm/SettlementConcept/PropertyCriteriaResponse';
import DetailedPropertyCriteriaResponse from '../../../../api/Llasm/SettlementConcept/DetailedPropertyCriteriaResponse';
import CompletePropertyCriteriaResponse from '../../../../api/Llasm/SettlementConcept/CompletePropertyCriteriaResponse';
import LockedPropertyCriteriaResponse from '../../../../api/Llasm/SettlementConcept/LockedPropertyCriteriaResponse';
import UnlockedPropertyCriteriaResponse from '../../../../api/Llasm/SettlementConcept/UnlockedPropertyCriteriaResponse';

class PropertyCriteria {
    private _locationTypes: LocationType[] = [];

    private _locationCategories: LocationCategory[] = [];

    private _locationFactors: LocationFactor[] = [];

    private _propertyAcquisitionTypes: PropertyAcquisitionType[] = [];

    private _desiredRetailEnvironment: string | null = null;

    private _areaSizeMinimum: number;

    private _areaSizeMaximum: number;

    private _secondaryAreaSize: number | null = null;

    private _outdoorSalesAreaRequired: boolean;

    private _outdoorSalesAreaSize: number | null = null;

    private _storeWidth: number | null = null;

    private _shopWindowFrontLength: number | null = null;

    private _groundLevelSalesAreaRequired: boolean;

    private _barrierFreeAccessRequired: boolean;

    private _parkingLotRequirementType: ParkingLotRequirementType;

    private _otherRequirements: string | null = null;

    public static createFromCompletePropertyCriteriaResponse(propertyCriteriaResponse: CompletePropertyCriteriaResponse): PropertyCriteria {
        const propertyCriteria: PropertyCriteria = new this();

        this.mapFromPropertyCriteriaResponse(propertyCriteria, propertyCriteriaResponse);
        this.mapFromDetailedPropertyCriteriaResponse(propertyCriteria, propertyCriteriaResponse);

        return propertyCriteria;
    }

    public static createFromLockedPropertyCriteriaResponse(propertyCriteriaResponse: LockedPropertyCriteriaResponse): PropertyCriteria {
        const propertyCriteria: PropertyCriteria = new this();

        this.mapFromPropertyCriteriaResponse(propertyCriteria, propertyCriteriaResponse);

        return propertyCriteria;
    }

    public static createFromUnlockedPropertyCriteriaResponse(propertyCriteriaResponse: UnlockedPropertyCriteriaResponse): PropertyCriteria {
        const propertyCriteria: PropertyCriteria = new this();

        this.mapFromPropertyCriteriaResponse(propertyCriteria, propertyCriteriaResponse);
        this.mapFromDetailedPropertyCriteriaResponse(propertyCriteria, propertyCriteriaResponse);

        return propertyCriteria;
    }

    private static mapFromPropertyCriteriaResponse(propertyCriteria: PropertyCriteria, propertyCriteriaResponse: PropertyCriteriaResponse): void {
        propertyCriteria._areaSizeMinimum = propertyCriteriaResponse.areaSizeMinimum;
        propertyCriteria._areaSizeMaximum = propertyCriteriaResponse.areaSizeMaximum;
    }

    private static mapFromDetailedPropertyCriteriaResponse(propertyCriteria: PropertyCriteria, propertyCriteriaResponse: DetailedPropertyCriteriaResponse): void {
        propertyCriteria._locationTypes = propertyCriteriaResponse.locationTypes;
        propertyCriteria._locationCategories = propertyCriteriaResponse.locationCategories;
        propertyCriteria._locationFactors = propertyCriteriaResponse.locationFactors;
        propertyCriteria._propertyAcquisitionTypes = propertyCriteriaResponse.propertyAcquisitionTypes;
        propertyCriteria._desiredRetailEnvironment = propertyCriteriaResponse.desiredRetailEnvironment;
        propertyCriteria._secondaryAreaSize = propertyCriteriaResponse.secondaryAreaSize;
        propertyCriteria._outdoorSalesAreaRequired = propertyCriteriaResponse.outdoorSalesAreaRequired;
        propertyCriteria._outdoorSalesAreaSize = propertyCriteriaResponse.outdoorSalesAreaSize;
        propertyCriteria._storeWidth = propertyCriteriaResponse.storeWidth;
        propertyCriteria._shopWindowFrontLength = propertyCriteriaResponse.shopWindowFrontLength;
        propertyCriteria._groundLevelSalesAreaRequired = propertyCriteriaResponse.groundLevelSalesAreaRequired;
        propertyCriteria._barrierFreeAccessRequired = propertyCriteriaResponse.barrierFreeAccessRequired;
        propertyCriteria._parkingLotRequirementType = propertyCriteriaResponse.parkingLotRequirementType;
        propertyCriteria._otherRequirements = propertyCriteriaResponse.otherRequirements;
    }

    get locationTypes(): LocationType[] {
        return this._locationTypes;
    }

    set locationTypes(value: LocationType[]) {
        this._locationTypes = value;
    }

    get locationCategories(): LocationCategory[] {
        return this._locationCategories;
    }

    set locationCategories(value: LocationCategory[]) {
        this._locationCategories = value;
    }

    get locationFactors(): LocationFactor[] {
        return this._locationFactors;
    }

    set locationFactors(value: LocationFactor[]) {
        this._locationFactors = value;
    }

    get propertyAcquisitionTypes(): PropertyAcquisitionType[] {
        return this._propertyAcquisitionTypes;
    }

    set propertyAcquisitionTypes(value: PropertyAcquisitionType[]) {
        this._propertyAcquisitionTypes = value;
    }

    get desiredRetailEnvironment(): string | null {
        return this._desiredRetailEnvironment;
    }

    set desiredRetailEnvironment(value: string | null) {
        this._desiredRetailEnvironment = value;
    }

    get areaSizeMinimum(): number {
        return this._areaSizeMinimum;
    }

    set areaSizeMinimum(value: number) {
        this._areaSizeMinimum = value;
    }

    get areaSizeMaximum(): number {
        return this._areaSizeMaximum;
    }

    set areaSizeMaximum(value: number) {
        this._areaSizeMaximum = value;
    }

    get secondaryAreaSize(): number | null {
        return this._secondaryAreaSize;
    }

    set secondaryAreaSize(value: number | null) {
        this._secondaryAreaSize = value;
    }

    get outdoorSalesAreaRequired(): boolean {
        return this._outdoorSalesAreaRequired;
    }

    set outdoorSalesAreaRequired(value: boolean) {
        this._outdoorSalesAreaRequired = value;
    }

    get outdoorSalesAreaSize(): number | null {
        return this._outdoorSalesAreaSize;
    }

    set outdoorSalesAreaSize(value: number | null) {
        this._outdoorSalesAreaSize = value;
    }

    get storeWidth(): number | null {
        return this._storeWidth;
    }

    set storeWidth(value: number | null) {
        this._storeWidth = value;
    }

    get shopWindowFrontLength(): number | null {
        return this._shopWindowFrontLength;
    }

    set shopWindowFrontLength(value: number | null) {
        this._shopWindowFrontLength = value;
    }

    get groundLevelSalesAreaRequired(): boolean {
        return this._groundLevelSalesAreaRequired;
    }

    set groundLevelSalesAreaRequired(value: boolean) {
        this._groundLevelSalesAreaRequired = value;
    }

    get barrierFreeAccessRequired(): boolean {
        return this._barrierFreeAccessRequired;
    }

    set barrierFreeAccessRequired(value: boolean) {
        this._barrierFreeAccessRequired = value;
    }

    get parkingLotRequirementType(): ParkingLotRequirementType {
        return this._parkingLotRequirementType;
    }

    set parkingLotRequirementType(value: ParkingLotRequirementType) {
        this._parkingLotRequirementType = value;
    }

    get otherRequirements(): string | null {
        return this._otherRequirements;
    }

    set otherRequirements(value: string | null) {
        this._otherRequirements = value;
    }
}

export default PropertyCriteria;
