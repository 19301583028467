class PaginationParameter {
    private _page: number;

    private _limit: number;

    public constructor(page: number, limit: number) {
        this._page = page;
        this._limit = limit;
    }

    get page(): number {
        return this._page;
    }

    set page(value: number) {
        this._page = value;
    }

    get limit(): number {
        return this._limit;
    }

    set limit(value: number) {
        this._limit = value;
    }
}

export default PaginationParameter;
