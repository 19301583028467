import FormData from '../Entity/Form/FormData';
import MinMaxLengthValidationDefinition from './MinMaxLengthValidationDefinition';

class MinMaxLengthOrNullValidationDefinition<T> extends MinMaxLengthValidationDefinition<T> {
    constructor(fieldName: string, minLength: number | null, maxLength: number | null, message: string | null) {
        super(fieldName, minLength, maxLength, message);
    }

    public check(formData: FormData<T>): boolean {
        const formDataValue: any = (formData.data as any)[this._fieldName];

        if (formDataValue === null) {
            return true;
        }

        return super.check(formData);
    }
}

export default MinMaxLengthOrNullValidationDefinition;
