import MarketplaceAd from './MarketplaceAd';
import Pagination from '../Pagination/Pagination';
import Paginated from '../Pagination/Paginated';
import MarketplaceAdPaginatedResponse from '../../../../api/Llasm/Marketplace/MarketplaceAdPaginatedResponse';
import MarketplaceAdResponse from '../../../../api/Llasm/Marketplace/MarketplaceAdResponse';

class MarketplaceAdPaginated extends Paginated<MarketplaceAd> {
    public static createFromMarketplaceAdPaginatedResponse(marketplaceAdPaginatedResponse: MarketplaceAdPaginatedResponse): MarketplaceAdPaginated {
        return new this(
            Pagination.createFromPaginationResponse(marketplaceAdPaginatedResponse.pagination),
            marketplaceAdPaginatedResponse.marketplaceAds.map((marketplaceAdResponse: MarketplaceAdResponse): MarketplaceAd => {
                return MarketplaceAd.createFromMarketplaceAdResponse(marketplaceAdResponse);
            })
        );
    }
}

export default MarketplaceAdPaginated;
