import InfoBox from '../../shared/Component/InfoBox/InfoBox';
import webinarAdWide from './banner-webinar-wide.jpg';
import webinarAdSmall from './banner-webinar-small.jpg';
import React from 'react';
import {Link} from 'react-router-dom';

const webinarStartDate: Date = new Date('2024-06-04');
const webinarEndDate: Date = new Date('2024-06-18');

const WebinarBanner = (): React.JSX.Element => {
    if (webinarStartDate >= new Date() || webinarEndDate <= new Date()) {
        return (
            <></>
        );
    }

    return (
        <InfoBox cardType="shadow" className="p-3 mb-5 text-center bg-white text-secondary">
            <Link to="https://us02web.zoom.us/webinar/register/WN_a28R58P1RZijEmV5kZyAzQ" target="_blank">
                <div className="d-none d-lg-block">
                    <img src={webinarAdWide} alt="Webinar Digitales Ansiedlungsmanagement" className="img-fluid  mb-3" />
                </div>
                <div className="d-block d-lg-none">
                    <img src={webinarAdSmall} alt="Webinar Digitales Ansiedlungsmanagement" className="img-fluid mb-3" />
                </div>
            </Link>
            <div className="mb-3 fs-6">
                Leerstandsmanager Markus Metzler der Wirtschaftsagentur Neumünster und Kunde erster Stunde berichtet, wie Neumünster im Kampf um Leerstand und Trading Down im Rahmen des Ansiedlungsmanagement das Matching der Leerstandslotsen einsetzt. Ebenfalls werden neue Features beleuchtet. Also seid dabei!
            </div>
            <div className="mb-2 fs-5">
                <a className="btn btn-secondary" href="https://us02web.zoom.us/webinar/register/WN_a28R58P1RZijEmV5kZyAzQ" target="_blank">Jetzt anmelden</a>
            </div>
        </InfoBox>
    );
};

export default WebinarBanner;
