import FormData from '../Entity/Form/FormData';
import FieldValidationDefinition from './FieldValidationDefinition';

class NumericRangeValidationDefinition<T> extends FieldValidationDefinition<T> {
    private readonly _min: number;

    private readonly _max: number;

    constructor(fieldName: string, min: number, max: number, message: string | null = null) {
        super(fieldName, message);

        this._min = min;
        this._max = max;
    }

    public check(formData: FormData<T>): boolean {
        const formDataValue: any = (formData.data as any)[this._fieldName];

        let isValid: boolean = true;

        if (formDataValue === undefined) {
            isValid = false;
        }

        if (isNaN(Number(formDataValue)) === true) {
            isValid = false;
        }

        if (Number(formDataValue) < this._min) {
            isValid = false;
        }

        if (Number(formDataValue) > this._max) {
            isValid = false;
        }

        return isValid;
    }
}

export default NumericRangeValidationDefinition;
