import Place from '../Location/Place';
import PlaceCriteriaResponse from '../../../../api/Llasm/SettlementConcept/PlaceCriteriaResponse';
import PlaceResponse from '../../../../api/Llasm/Location/PlaceResponse';

class PlaceCriteria {
    private _cityPopulationMinimum: number | null = null;

    private _cityPopulationMaximum: number | null = null;

    private _targetedCitySearch: boolean;

    private _countryPlace: Place;

    private _expansionStates: Place[] = [];

    private _expansionCities: Place[] = [];

    public static createFromPlaceCriteriaResponse(placeCriteriaResponse: PlaceCriteriaResponse): PlaceCriteria {
        const placeCriteria: PlaceCriteria = new this();

        placeCriteria._cityPopulationMinimum = placeCriteriaResponse.cityPopulationMinimum;
        placeCriteria._cityPopulationMaximum = placeCriteriaResponse.cityPopulationMaximum;
        placeCriteria._targetedCitySearch = placeCriteriaResponse.targetedCitySearch;
        placeCriteria._countryPlace = Place.createFromPlaceResponse(placeCriteriaResponse.countryPlace);

        placeCriteria._expansionStates = placeCriteriaResponse.expansionStates.map((placeResponse: PlaceResponse): Place => {
            return Place.createFromPlaceResponse(placeResponse);
        });

        placeCriteria._expansionCities = placeCriteriaResponse.expansionCities.map((placeResponse: PlaceResponse): Place => {
            return Place.createFromPlaceResponse(placeResponse);
        });

        return placeCriteria;
    }

    get cityPopulationMinimum(): number | null {
        return this._cityPopulationMinimum;
    }

    set cityPopulationMinimum(value: number | null) {
        this._cityPopulationMinimum = value;
    }

    get cityPopulationMaximum(): number | null {
        return this._cityPopulationMaximum;
    }

    set cityPopulationMaximum(value: number | null) {
        this._cityPopulationMaximum = value;
    }

    get targetedCitySearch(): boolean {
        return this._targetedCitySearch;
    }

    set targetedCitySearch(value: boolean) {
        this._targetedCitySearch = value;
    }

    get countryPlace(): Place {
        return this._countryPlace;
    }

    set countryPlace(value: Place) {
        this._countryPlace = value;
    }

    get expansionStates(): Place[] {
        return this._expansionStates;
    }

    set expansionStates(value: Place[]) {
        this._expansionStates = value;
    }

    get expansionCities(): Place[] {
        return this._expansionCities;
    }

    set expansionCities(value: Place[]) {
        this._expansionCities = value;
    }
}

export default PlaceCriteria;
