import FormData from '../Entity/Form/FormData';
import FieldValidationDefinition from './FieldValidationDefinition';

class RequiredValidationDefinition<T> extends FieldValidationDefinition<T> {
    constructor(fieldName: string, message: string | null) {
        super(fieldName, message);
    }

    public check(formData: FormData<T>): boolean {
        const formDataValue: any = (formData.data as any)[this._fieldName];

        if (formDataValue === undefined) {
            return false;
        }

        if (formDataValue === null || formDataValue === '') {
            return false;
        }

        if (Array.isArray(formDataValue) === true && formDataValue.length === 0) {
            return false;
        }

        return true;
    }
}

export default RequiredValidationDefinition;
