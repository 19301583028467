import SettlementConceptService from './SettlementConceptService';
import Alert from '../Entity/Alert/Alert';
import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import SettlementConceptCard from './SettlementConceptCard';
import AlertBox from '../../../components/AlertBox';
import Spinner from '../../../components/Spinner';
import {useAppDispatch} from '../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

const settlementConceptService: SettlementConceptService = new SettlementConceptService(process.env.REACT_APP_LLASM_API_URL!);

const SettlementConceptOverviewPage = (): React.JSX.Element => {
    const [settlementConcepts, setSettlementConcepts] = useState<SettlementConcept[]>();

    const [alert, setAlert] = useState<Alert>();

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Meine Suchprofile'},
            ]
        });
    }, [dispatch]);

    useEffect((): void => {
        fetchSettlementConcepts();
    }, []);

    const fetchSettlementConcepts = async (): Promise<void> => {
        setSettlementConcepts(await settlementConceptService.fetchSettlementConceptsFromApi());
    };

    if (settlementConcepts === undefined) {
        return <Spinner />;
    }

    return (
        <div className="container-fluid">
            <div className="row align-items-center mb-4">
                <div className="col-12 col-md-8 d-flex align-items-center">
                    <Link to="/"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                    <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Meine Suchprofile</h1>
                </div>
                <div className="d-none d-md-flex col-md-4 justify-content-end px-4">
                    <Link to="/suchprofile/einstellen" className="btn btn btn-secondary text-nowrap">
                        <i className="bi bi-plus-lg fs-5 px-2"></i>
                        Suchprofil <span className="d-md-none d-lg-inline-flex"> erstellen</span>
                    </Link>
                </div>
            </div>
            {alert !== undefined &&
                <AlertBox alert={alert} autoDismiss={false} />
            }
            <div className="row px-md-2">
                {settlementConcepts.map((settlementConcept: SettlementConcept): React.JSX.Element => (
                    <div key={settlementConcept.id} className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-4">
                        <SettlementConceptCard settlementConcept={settlementConcept} />
                    </div>
                ))}
            </div>
            <div className="d-md-none col-12 col-md-4">
                <Link to="/suchprofile/einstellen" className="btn btn btn-secondary align-items-center mb-3">
                    <i className="bi bi-plus-lg fs-5 px-2"></i>
                    Suchprofil erstellen
                </Link>
            </div>
        </div>
    );
};

export default SettlementConceptOverviewPage;
