import LeanConversation from '../../Entity/Messaging/LeanConversation';
import conversationListItemStyle from './ConversationListItem.module.scss';
import React from 'react';

interface LeanConversationListItemProps {
    readonly leanConversation: LeanConversation;
}

const LeanConversationListItem = (props: LeanConversationListItemProps): React.JSX.Element => {
    return (
        <>
            <div className="d-flex mb-2">
                <span className={['me-4', conversationListItemStyle.firstReference].join(' ')}>
                    {props.leanConversation.firstReference}
                </span>
                <span className={['ms-auto', conversationListItemStyle.date].join(' ')}>{props.leanConversation.lastMessageAt?.toLocaleDateString('de-DE', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}</span>
            </div>
            <div className={['row', 'justify-content-end', 'mb-2', conversationListItemStyle.secondReferenceIcon].join(' ')}>
                <div className={['col-6', conversationListItemStyle.secondReference].join(' ')}>
                    <div>
                        Matcht mit:
                    </div>
                    <div className="text-overflow-ellipsis">
                        {props.leanConversation.secondReference}
                    </div>
                </div>
            </div>
            <div className={['mb-1', conversationListItemStyle.sender].join(' ')}>
                Von: {props.leanConversation.firstReferenceFrom.name}
            </div>
            {props.leanConversation.unreadMessages === true &&
                <div className={conversationListItemStyle.unread}>
                    <i className="bi bi-chat-fill me-2"></i>ungelesene Nachricht(en) an mich
                </div>
            }
            {props.leanConversation.unreadMessages === false &&
                <div className={conversationListItemStyle.read}>
                    <i className="bi bi-check-circle me-2"></i>alle Nachrichten von mir gelesen
                </div>
            }
        </>
    );
};

export default LeanConversationListItem;
