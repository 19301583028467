import SettlementConcept from '../../Entity/SettlementConcept/SettlementConcept';
import {getLocationTypeLabel} from '../../Entity/LocationType';
import {getLocationCategoryLabel} from '../../Entity/LocationCategory';
import {getLocationFactorLabel} from '../../Entity/LocationFactor';
import CollapseCard from '../../Component/CollapseCard/CollapseCard';
import LabelValuesPair from '../../../../components/LabelValuesPair';
import LabelValuePair from '../../../../components/LabelValuePair';
import React from 'react';

interface LocationRequirementCollapseCardProps {
    readonly settlementConcept: SettlementConcept;
}

const LocationRequirementCollapseCard = (props: LocationRequirementCollapseCardProps): React.JSX.Element => {
    const locationTypeValues: string[] = props.settlementConcept.propertyCriteria.locationTypes.map(locationType => getLocationTypeLabel(locationType));

    const locationCategoryValues: string[] = props.settlementConcept.propertyCriteria.locationCategories.map(locationCategory => getLocationCategoryLabel(locationCategory));

    const locationFactorValues: string[] = props.settlementConcept.propertyCriteria.locationFactors.map(locationFactor => getLocationFactorLabel(locationFactor));

    return (
        <CollapseCard cardType="shadow" title="Anforderungen an die Umgebung" className="mb-3">
            <div className="row">
                {locationTypeValues.length > 0 &&
                    <div className="col-12 mb-3">
                        <LabelValuesPair label="Standort-Typ" values={locationTypeValues} delimiter=" · " />
                    </div>
                }
                {locationCategoryValues.length > 0 &&
                    <div className="col-12 col-lg-6 mb-3">
                        <LabelValuesPair label="Lagekategorie" values={locationCategoryValues} delimiter=" · " />
                    </div>
                }

                {props.settlementConcept.propertyCriteria.desiredRetailEnvironment !== null &&
                    <div className="col-12 col-lg-6 mb-3">
                        <LabelValuePair label="Gewünschtes Handelsumfeld" value={props.settlementConcept.propertyCriteria.desiredRetailEnvironment} />
                    </div>
                }

                {locationFactorValues.length > 0 &&
                    <div className="col-12 mb-3">
                        <LabelValuesPair label="Standortfaktoren" values={locationFactorValues} delimiter=" · " />
                    </div>
                }
            </div>
        </CollapseCard>
    );
};

export default LocationRequirementCollapseCard;
