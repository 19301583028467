import AdPartnerService from './AdPartnerService';
import AdPartnerEntity from '../../shared/Entity/AdPartner/AdPartner';
import Message from '../../shared/Entity/AdPartner/Message';
import ImageEntity from '../../shared/Entity/Image/Image';
import Alert from '../../shared/Entity/Alert/Alert';
import AlertType from '../../shared/Entity/Alert/AlertType';
import FormData from '../../shared/Entity/Form/FormData';
import AdPartnerType, {getAdPartnerTypeLabel} from '../../shared/Entity/AdPartner/AdPartnerType';
import MessageForm from '../AdPartner/MessageForm';
import Image from '../../shared/Image/Image';
import FieldValidationDefinition from '../../shared/FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../../shared/FormValidationHandler/RequiredValidationDefinition';
import MinMaxLengthValidationDefinition from '../../shared/FormValidationHandler/MinMaxLengthValidationDefinition';
import FormValidationHandler from '../../shared/FormValidationHandler/FormValidationHandler';
import Slider from '../../shared/Component/Slider/Slider';
import Card from '../../shared/Component/Card/Card';
import Overlay from '../../shared/Component/Overlay/Overlay';
import AlertBox from '../../../components/AlertBox';
import LoadingIndicator from '../../../components/LoadingIndicator';
import React, {useEffect, useState} from 'react';

interface AdPartnerProps {
    readonly adPartner: AdPartnerEntity;
}

const adPartnerService: AdPartnerService = new AdPartnerService(process.env.REACT_APP_LLASM_API_URL!);

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Deine Nachricht konnte leider nicht gesendet werden. Bitte gib eine Nachricht mit mindestens 5 Zeichen ein.');
const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schief gelaufen. Bitte versuche es zu einem späteren Zeitpunkt noch einmal.');
const successAlert: Alert = new Alert(AlertType.Success, 'Deine Nachricht wurde erfolgreich übermittelt.');

const messageFieldValidationDefinitions: FieldValidationDefinition<Message>[] = [
    new RequiredValidationDefinition<Message>('text', 'Die Nachricht muss ausgefüllt sein.'),
    new MinMaxLengthValidationDefinition<Message>('text', 5, null, 'Die Nachricht muss mindestens 5 Zeichen lang sein.'),
];

const formValidationHandler: FormValidationHandler<Message> = new FormValidationHandler<Message>(messageFieldValidationDefinitions);

const AdPartner = (props: AdPartnerProps): React.JSX.Element => {
    const [bannerImages, setBannerImages] = useState<ImageEntity[]>();

    const [showOverlay, setShowOverlay] = useState<boolean>(false);

    const [alert, setAlert] = useState<Alert>();

    const [messageFormData, setMessageFormData] = useState<FormData<Message>>({data: new Message()});

    const [showMessageForm, setShowMessageForm] = useState<boolean>(true);

    useEffect((): void => {
        if (bannerImages !== undefined) {
            return;
        }

        fetchBannerImages();
    }, []);

    const fetchBannerImages = async (): Promise<void> => {
        if (props.adPartner.id === undefined) {
            throw new Error();
        }

        const bannerImages: ImageEntity[] = await adPartnerService.fetchImagesFromAdPartner(props.adPartner.id);

        setBannerImages(bannerImages);
    };

    const openDetailedInformationOverlay = (): void => {
        setShowOverlay(true);
    };

    const sendMessage = async (): Promise<void> => {
        formValidationHandler.validate(messageFormData);

        if (formValidationHandler.hasErrors(messageFormData) === true) {
            setAlert(formErrorAlert);

            return;
        }

        try {
            await adPartnerService.sendMessage(props.adPartner, messageFormData.data);

            setShowMessageForm(false);
            setAlert(successAlert);
        } catch (error) {
            setAlert(errorAlert);
        }
    };

    const buildImageGetPath = (image: ImageEntity): string => {
        return AdPartnerService.buildImageApiPath(props.adPartner, image);
    };

    if (bannerImages === undefined) {
        return <LoadingIndicator />;
    }

    if (bannerImages.length < 1) {
        return <></>;
    }

    return (
        <>
            {props.adPartner.adPartnerType === AdPartnerType.Standard &&
                <StandardAdPartner
                    adPartner={props.adPartner}
                    bannerImages={bannerImages}
                    buildImageGetPath={buildImageGetPath}
                    openDetailedInformationOverlay={openDetailedInformationOverlay}
                />
            }
            {props.adPartner.adPartnerType === AdPartnerType.Premium &&
                <PremiumAdPartner
                    adPartner={props.adPartner}
                    bannerImages={bannerImages}
                    buildImageGetPath={buildImageGetPath}
                    openDetailedInformationOverlay={openDetailedInformationOverlay}
                />
            }
            {props.adPartner.adPartnerType === AdPartnerType.Platinum &&
                <PlatinumAdPartner
                    adPartner={props.adPartner}
                    bannerImages={bannerImages}
                    buildImageGetPath={buildImageGetPath}
                    openDetailedInformationOverlay={openDetailedInformationOverlay}
                />
            }
            <Overlay title={props.adPartner.name} show={showOverlay} setShow={setShowOverlay}>
                <div className="container">
                    <Card className="p-5" cardType="shadow">
                        <div dangerouslySetInnerHTML={{__html: props.adPartner.descriptionHtml}} />
                    </Card>
                    <Card className="mt-5 mb-5 p-5" cardType="shadow">
                        <h2 className="fs-5 fw-bold mb-4">Kontakt aufnehmen:</h2>
                        <div className="row">
                            <div className="col-6">
                                <div>Deine Kontaktperson:</div>
                                <div>{props.adPartner.firstName} {props.adPartner.lastName}</div>
                                {props.adPartner.position !== null &&
                                    <div>{props.adPartner.position}</div>
                                }
                                <div className="pt-2">
                                    {props.adPartner.streetName} {props.adPartner.houseNumber}
                                </div>
                                <div>
                                    {props.adPartner.postalCode} {props.adPartner.placeName}
                                </div>
                                <div className="pt-2">
                                    Tel.: {props.adPartner.phoneNumber}
                                </div>
                            </div>
                            <div className="col-6">
                                {alert !== undefined &&
                                    <AlertBox alert={alert} autoDismiss={false} />
                                }
                                {showMessageForm === true &&
                                    <>
                                        <MessageForm
                                            formData={messageFormData}
                                            setFormData={setMessageFormData}
                                            formValidationHandler={formValidationHandler}
                                        />
                                        <div className="text-center mt-2">
                                            <button className="btn btn-secondary py-1 px-5" onClick={sendMessage}>Absenden</button>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </Card>
                </div>
            </Overlay>
        </>
    );
};

interface StandardAdPartnerProps {
    readonly adPartner: AdPartnerEntity;
    readonly bannerImages: ImageEntity[];
    readonly buildImageGetPath: (image: ImageEntity) => string;
    readonly openDetailedInformationOverlay: () => void;
}

const StandardAdPartner = (props: StandardAdPartnerProps): React.JSX.Element => {
    if (props.adPartner.adPartnerType !== AdPartnerType.Standard) {
        throw new Error('Invalid adPartnerType');
    }

    return (
        <Card cardType="shadow" className="text-center h-100 d-flex flex-column p-3">
            <AdPartnerImage
                bannerImages={props.bannerImages}
                buildImageGetPath={props.buildImageGetPath}
                openDetailedInformationOverlay={props.openDetailedInformationOverlay}
            />
            <div className="fs-5 text-primary cursor-pointer mt-2" onClick={props.openDetailedInformationOverlay}>
                {props.adPartner.name}
            </div>
        </Card>
    );
};

interface PremiumAdPartnerProps {
    readonly adPartner: AdPartnerEntity;
    readonly bannerImages: ImageEntity[];
    readonly buildImageGetPath: (image: ImageEntity) => string;
    readonly openDetailedInformationOverlay: () => void;
}

const PremiumAdPartner = (props: PremiumAdPartnerProps): React.JSX.Element => {
    if (props.adPartner.adPartnerType !== AdPartnerType.Premium) {
        throw new Error('Invalid adPartnerType');
    }

    return (
        <Card cardType="outline" className="p-3">
            <div className="row">
                <div className="col-12 col-xl-8 mb-3 mb-xl-0">
                    <AdPartnerImage
                        bannerImages={props.bannerImages}
                        buildImageGetPath={props.buildImageGetPath}
                        openDetailedInformationOverlay={props.openDetailedInformationOverlay}
                    />
                </div>
                <div className="col-12 col-xl-4">
                    <div className="text-primary fs-5">{getAdPartnerTypeLabel(props.adPartner.adPartnerType)}</div>
                    <div className="fs-3">{props.adPartner.name}</div>
                    {props.adPartner.shortDescription !== null &&
                        <div className="pt-2">{props.adPartner.shortDescription}</div>
                    }
                    <button className="btn btn-outline-secondary mt-3" onClick={props.openDetailedInformationOverlay}>
                        Mehr erfahren
                    </button>
                </div>
            </div>
        </Card>
    );
};

interface PlatinumAdPartnerProps {
    readonly adPartner: AdPartnerEntity;
    readonly bannerImages: ImageEntity[];
    readonly buildImageGetPath: (image: ImageEntity) => string;
    readonly openDetailedInformationOverlay: () => void;
}

const PlatinumAdPartner = (props: PlatinumAdPartnerProps): React.JSX.Element => {
    if (props.adPartner.adPartnerType !== AdPartnerType.Platinum) {
        throw new Error('Invalid adPartnerType');
    }

    return (
        <Card cardType="outline" className="p-3">
            <div className="row">
                <div className="col-12 col-xl-8 mb-3 mb-xl-0">
                    <AdPartnerImage
                        bannerImages={props.bannerImages}
                        buildImageGetPath={props.buildImageGetPath}
                        openDetailedInformationOverlay={props.openDetailedInformationOverlay}
                    />
                </div>
                <div className="col-12 col-xl-4">
                    <div className="text-primary fs-5">{getAdPartnerTypeLabel(props.adPartner.adPartnerType)}</div>
                    <div className="fs-3">{props.adPartner.name}</div>
                    {props.adPartner.shortDescription !== null &&
                        <div className="pt-2">{props.adPartner.shortDescription}</div>
                    }
                    <button className="btn btn-outline-secondary mt-3" onClick={props.openDetailedInformationOverlay}>
                        Mehr erfahren
                    </button>
                </div>
            </div>
        </Card>
    );
};

interface AdPartnerImageProps {
    readonly bannerImages: ImageEntity[];
    readonly buildImageGetPath: (image: ImageEntity) => string;
    readonly openDetailedInformationOverlay: () => void;
}

const AdPartnerImage = (props: AdPartnerImageProps): React.JSX.Element => {
    return (
        <>
            {props.bannerImages.length === 1 ? (
                <div className="cursor-pointer" onClick={props.openDetailedInformationOverlay}>
                    <Image image={props.bannerImages[0]} buildImageGetPath={props.buildImageGetPath} className="img-fluid" />
                </div>
            ) : (
                <Slider
                    config={{
                        type: 'slider',
                        showArrows: true,
                        arrowsPosition: 'outside',
                        arrowButtonSize: 'small',
                        perView: 1
                    }}
                >
                    {props.bannerImages.map((bannerImage: ImageEntity): React.JSX.Element => (
                        <div className="cursor-pointer" onClick={props.openDetailedInformationOverlay}>
                            <Image image={bannerImage} buildImageGetPath={props.buildImageGetPath} className="img-fluid" />
                        </div>
                    ))}
                </Slider>
            )}
        </>
    );
};

export default AdPartner;
