enum Duration {
    OneMonth = 0,
    SixMonth = 1,
    TwelveMonth = 2
}

const getDurationLabel = (duration: Duration): string => {
    switch (duration) {
        case Duration.OneMonth:
            return '1 Monat';
        case Duration.SixMonth:
            return '6 Monate';
        case Duration.TwelveMonth:
            return '12 Monate';
        default:
            throw new Error();
    }
};

export default Duration;
export {getDurationLabel};
