enum AgeStructure {
    YOUNG_PEOPLE = 0,
    OLDER_PEOPLE = 1,
    FAMILIES = 2,
    CHILDREN = 3
}

const getAgeStructureLabel = (ageStructure: AgeStructure): string => {
    switch (ageStructure) {
        case AgeStructure.YOUNG_PEOPLE:
            return 'Junge Menschen';
        case AgeStructure.OLDER_PEOPLE:
            return 'Ältere Menschen';
        case AgeStructure.FAMILIES:
            return 'Familien';
        case AgeStructure.CHILDREN:
            return 'Kinder';
        default:
            throw new Error();
    }
};

export default AgeStructure;
export {getAgeStructureLabel};
