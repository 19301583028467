import Concept from '../Entity/HallOfInspiration/Concept';
import ImageEntity from '../Entity/Image/Image';
import Branch from '../Entity/HallOfInspiration/Branch';
import CompanyValue from '../Entity/CompanyValue';
import FormData from '../Entity/Form/FormData';
import Message from '../Entity/HallOfInspiration/Message';
import {getCompanyLocationLabel} from '../Entity/HallOfInspiration/CompanyLocation';
import CompanyLocation from '../Entity/HallOfInspiration/CompanyLocation';
import Alert from '../Entity/Alert/Alert';
import AlertType from '../Entity/Alert/AlertType';
import CompanyValueIconBadge from './CompanyValueIconBadge';
import MessageForm from './MessageForm';
import ConceptService from './ConceptService';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import MinMaxLengthValidationDefinition from '../FormValidationHandler/MinMaxLengthValidationDefinition';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import RoundedContainer from '../Component/RoundedContainer/RoundedContainer';
import IconBadgeWrapper from '../Component/IconBadge/IconBadgeWrapper';
import CollapseCard from '../Component/CollapseCard/CollapseCard';
import Card from '../Component/Card/Card';
import Slider from '../Component/Slider/Slider';
import TextBadge from '../Component/TextBadge/TextBadge';
import Overlay from '../Component/Overlay/Overlay';
import ImageViewer from '../Component/ImageViewer/ImageViewer';
import Image from '../Image/Image';
import Spinner from '../../../components/Spinner';
import AlertBox from '../../../components/AlertBox';
import conceptPageStyle from './ConceptPage.module.scss';
import noImage from '../../../img/no-hall-of-inspiration-concept-image.png';
import {useAppDispatch} from '../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {Link, useParams, useSearchParams} from 'react-router-dom';

const conceptService: ConceptService = new ConceptService(process.env.REACT_APP_LLASM_API_URL!);

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Deine Nachricht konnte leider nicht gesendet werden. Bitte gib eine Nachricht mit mindestens 5 Zeichen ein.');
const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schief gelaufen. Bitte versuche es zu einem späteren Zeitpunkt noch einmal.');
const successAlert: Alert = new Alert(AlertType.Success, 'Deine Nachricht wurde erfolgreich übermittelt.');

const messageFieldValidationDefinitions: FieldValidationDefinition<Message>[] = [
    new RequiredValidationDefinition<Message>('text', 'Die Nachricht muss ausgefüllt sein.'),
    new MinMaxLengthValidationDefinition<Message>('text', 5, null, 'Die Nachricht muss mindestens 5 Zeichen lang sein.'),
];

const formValidationHandler: FormValidationHandler<Message> = new FormValidationHandler<Message>(messageFieldValidationDefinitions);

const ConceptPage = (): React.JSX.Element => {
    const {conceptId} = useParams<string>();

    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useAppDispatch();

    const [concept, setConcept] = useState<Concept>();

    const [images, setImages] = useState<ImageEntity[]>();

    const [imageViewerIndex, setImageViewerIndex] = useState<number>(0);

    const [showImageViewerOverlay, setShowImageViewerOverlay] = useState<boolean>(false);

    const [alert, setAlert] = useState<Alert>();

    const [messageFormData, setMessageFormData] = useState<FormData<Message>>({data: new Message()});

    const [showMessageForm, setShowMessageForm] = useState<boolean>(true);

    const scrollPosition: number | null = searchParams.get('scrollPosition') !== null ? Number(searchParams.get('scrollPosition')) : null;

    useEffect((): void => {
        if (concept !== undefined) {
            dispatch({
                type: 'breadcrumb/setBreadcrumbs', payload: [
                    {name: 'Hall of Inspiration', link: '/hall-of-inspiration/konzepte'},
                    {name: concept.brandName},
                ]
            });
        }
    }, [dispatch, concept]);

    useEffect((): void => {
        fetchConcept();
        fetchImages();
    }, []);

    const fetchConcept = async (): Promise<void> => {
        const concept: Concept = await conceptService.fetchConceptFromApiById(Number(conceptId!));

        setConcept(concept);
    };

    const fetchImages = async (): Promise<void> => {
        const images: ImageEntity[] = await conceptService.fetchImagesFromConcept(Number(conceptId!));

        setImages(images);
    };

    const sendMessage = async (): Promise<void> => {
        if (concept === undefined) {
            return;
        }

        formValidationHandler.validate(messageFormData);

        if (formValidationHandler.hasErrors(messageFormData) === true) {
            setAlert(formErrorAlert);

            return;
        }

        try {
            await conceptService.sendMessage(concept, messageFormData.data);

            setShowMessageForm(false);
            setAlert(successAlert);
        } catch (error) {
            setAlert(errorAlert);
        }
    };

    const showImageViewer = (imageIndex: number): void => {
        setImageViewerIndex(imageIndex);
        setShowImageViewerOverlay(true);
    }

    const buildImageGetPath = (image: ImageEntity): string => {
        if (concept === undefined) {
            throw new Error();
        }

        return ConceptService.buildImageApiPath(concept, image);
    };

    if (concept === undefined) {
        return <Spinner />;
    }

    return (
        <div className="container-fluid">
            <div className="d-flex align-items-center">
                {scrollPosition !== null ? (
                    <Link to={'/hall-of-inspiration/konzepte?scrollPosition=' + scrollPosition}><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                ) : (
                    <Link to="/hall-of-inspiration/konzepte"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                )}
                <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Hall of Inspiration - {concept.brandName}</h1>
            </div>
            <div className="py-3 px-0 px-md-3 mb-3">
                <div className="row mb-5">
                    <div className="col-lg-4 col-md-6 col-12 mb-5 mb-md-0">
                        <RoundedContainer>
                            {concept.mainImage !== null ? (
                                <Image image={concept.mainImage} buildImageGetPath={buildImageGetPath} style={{width: '100%', height: 'auto'}} />
                            ) : (
                                <img src={noImage} style={{width: '100%', height: 'auto'}} alt="Hall of Inspiration" />
                            )}
                        </RoundedContainer>
                        <IconBadgeWrapper positionTop={-15} positionLeft={5}>
                            {concept.companyValues.map((companyValue: CompanyValue): React.JSX.Element => (
                                <CompanyValueIconBadge key={'companyValue-' + companyValue} companyValue={companyValue} />
                            ))}
                        </IconBadgeWrapper>
                    </div>
                    <div className="col-lg-8 col-md-6 col-12">
                        <span className="fw-bold">Dürfen wir vorstellen:</span>
                        <h1 className="text-primary mt-md-5">{concept.brandName}</h1>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-12 col-md-10 mb-2">
                        <div className="d-flex flex-wrap gap-2">
                            {concept.branches.map((branch: Branch): React.JSX.Element => (
                                <TextBadge key={'branch-' + branch.id} text={branch.name} />
                            ))}
                        </div>
                    </div>
                    <div className="col-12 col-md-2 fs-5 d-flex justify-content-end mb-2">
                        {concept.instagram !== null &&
                            <Link to={concept.instagram} target="_blank" className="text-secondary"><i className="bi bi-instagram me-2"></i></Link>
                        }
                        {concept.linkedIn !== null &&
                            <Link to={concept.linkedIn} target="_blank" className="text-secondary"><i className="bi bi-linkedin me-2"></i></Link>
                        }
                        {concept.website !== null &&
                            <Link to={concept.website} target="_blank" className="text-secondary"><i className="bi bi-globe2 me-2"></i></Link>
                        }
                    </div>
                </div>
                <CollapseCard className="mb-3" cardType="shadow" title="Das ist mein Konzept" iconClassName="bi bi-file-earmark-text-fill text-primary">
                    <div className={'fs-5 ' + conceptPageStyle.textWrap}>
                        {concept.conceptDescription}
                    </div>
                </CollapseCard>
                {((images !== undefined && images.length > 0) || concept.videos.length > 0) &&
                    <CollapseCard className="mb-3" cardType="shadow" title="Licht - Kamera - Action" iconClassName="bi bi-camera-fill text-primary">
                        {images !== undefined && images.length > 0 &&
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <Slider
                                        config={{
                                            type: 'slider',
                                            showArrows: true,
                                            arrowsPosition: 'inside',
                                            rewind: true,
                                            perView: 3,
                                            breakpoints: {
                                                992: {
                                                    perView: 3
                                                },
                                                768: {
                                                    perView: 2
                                                },
                                                480: {
                                                    perView: 1
                                                },
                                            }
                                        }}
                                    >
                                        {images.map((image: ImageEntity, index: number): React.JSX.Element => (
                                            <div key={'image-' + image.id} className="cursor-pointer" onClick={(): void => {showImageViewer(index)}}>
                                                <RoundedContainer>
                                                    <Image image={image} buildImageGetPath={buildImageGetPath} className="w-100" style={{objectFit: 'cover', height: '350px'}} />
                                                </RoundedContainer>
                                            </div>
                                        ))}
                                    </Slider>
                                    <Overlay title={concept.brandName} show={showImageViewerOverlay} setShow={setShowImageViewerOverlay}>
                                        <div className="container">
                                            <ImageViewer images={images} startAt={imageViewerIndex} buildImageGetPath={buildImageGetPath} />
                                        </div>
                                    </Overlay>
                                </div>
                            </div>
                        }
                        {concept.videos.length > 0 &&
                            <div className="row fs-5">
                                <div className="col-12 d-flex align-items-center">
                                    <i className="bi bi-youtube text-primary fs-3 align-middle me-3"></i> <span className="fw-bold align-middle">It's Popcorn time:</span>
                                </div>
                                {concept.videos.map((video: string, index: number): React.JSX.Element => (
                                    <div key={'video-link-' + index} className="col-12">
                                        <Link to={video} target="_blank">{video}</Link>
                                    </div>
                                ))}
                            </div>
                        }
                    </CollapseCard>
                }
                {concept.usp !== null &&
                    <CollapseCard className="mb-3" cardType="shadow" title="Das macht es ganz besonders" iconClassName="bi bi-heart-fill text-primary">
                        <div className={'fs-5 ' + conceptPageStyle.textWrap}>
                            {concept.usp}
                        </div>
                    </CollapseCard>
                }
                {concept.experience !== null &&
                    <CollapseCard className="mb-3" cardType="shadow" title="Erfahrungen & Herausforderungen" iconClassName="bi bi-flag-fill text-primary">
                        <div className={'fs-5 ' + conceptPageStyle.textWrap}>
                            {concept.experience}
                        </div>
                    </CollapseCard>
                }
                {concept.successStory !== null &&
                    <CollapseCard className="mb-3" cardType="shadow" title="Success Story" iconClassName="bi bi-rocket-takeoff-fill text-primary">
                        <div className={'fs-5 ' + conceptPageStyle.textWrap}>
                            {concept.successStory}
                        </div>
                    </CollapseCard>
                }
                {concept.expansionPlans !== null &&
                    <CollapseCard className="mb-3" cardType="shadow" title="Expansionspläne" iconClassName="bi bi-arrows-fullscreen text-primary">
                        <div className={'fs-5 ' + conceptPageStyle.textWrap}>
                            {concept.expansionPlans}
                        </div>
                    </CollapseCard>
                }
                <Card className="mb-3" cardType="shadow">
                    <div className="row p-3 p-lg-4 p-xl-5 gap-5 gap-md-0">
                        {concept.hasContact === true &&
                            <div className="col-12 col-md-6 col-lg-5 mb-3">
                                {alert !== undefined &&
                                    <AlertBox alert={alert} autoDismiss={false} />
                                }
                                {showMessageForm === true &&
                                    <>
                                        <MessageForm
                                            formData={messageFormData}
                                            setFormData={setMessageFormData}
                                            formValidationHandler={formValidationHandler}
                                        />
                                        <div className="text-center mt-2">
                                            <button className="btn btn-secondary py-1 px-5" onClick={sendMessage}>Absenden</button>
                                        </div>
                                    </>
                                }
                            </div>
                        }
                        <div className="col-12 col-md-6 col-lg-7 d-flex d-md-block px-5 gap-4 gap-md-0">
                            <div>
                                <div className="fw-bold">Standort</div>
                                {concept.numberOfCompanyLocations === 1 ? (
                                    <div style={{fontSize: '11px'}}>1 Standort</div>
                                ) : (
                                    <div style={{fontSize: '11px'}}>{concept.numberOfCompanyLocations} Standorte</div>
                                )}
                                {concept.companyLocations.map((companyLocation: CompanyLocation, index: number): React.JSX.Element => (
                                    <div style={{fontSize: '11px'}}>
                                        {index === 0 ? (
                                            <>
                                                {getCompanyLocationLabel(companyLocation)}
                                            </>
                                        ): (
                                            <>
                                                {', ' + getCompanyLocationLabel(companyLocation)}
                                            </>
                                        )}

                                </div>
                                ))}
                            </div>
                            {concept.address !== null &&
                                <div>
                                    <div className="fw-bold">Firmensitz</div>
                                    {(concept.address.streetName !== null || concept.address.houseNumber !== null) &&
                                        <div style={{fontSize: '11px'}}>
                                            {concept.address.streetName !== null &&
                                                <>{concept.address.streetName + ' '}</>
                                            }
                                            {concept.address.houseNumber !== null &&
                                                <>{concept.address.houseNumber}</>
                                            }
                                        </div>
                                    }
                                    <div style={{fontSize: '11px'}}>
                                        {concept.address.postalCode + ' ' + concept.address.placeName}
                                    </div>
                                    {concept.address.federalState !== null &&
                                        <div style={{fontSize: '11px'}}>
                                            {concept.address.federalState}
                                        </div>
                                    }
                                    {concept.website !== null &&
                                        <Link to={concept.website} target="_blank">{concept.website}</Link>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default ConceptPage;
