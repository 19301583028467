import SelectOption from '../../../Entity/Form/SelectOption';
import CheckboxField from './CheckboxField';
import Select, {OnChangeValue} from 'react-select';
import React from 'react';

interface CheckboxWithRelatedSelectFieldProps {
    readonly checked: boolean;
    readonly checkboxName: string;
    readonly checkboxLabel: string;
    readonly onCheckboxClick: () => void;
    readonly selectFieldName: string;
    readonly selectFieldSelectedOptions: SelectOption<any>[];
    readonly selectFieldSelectOptions: SelectOption<any>[];
    readonly isSelectFieldClearable: boolean
    readonly isSelectFieldMulti: boolean;
    readonly classNames?: string;
    readonly hasErrors?: boolean;
    readonly onSelectFieldChange?: (newValue: any[] | any | null) => void;
}

const CheckboxWithRelatedSelectField = (props: CheckboxWithRelatedSelectFieldProps): React.JSX.Element => {
    return (
        <>
            <CheckboxField
                checked={props.checked}
                name={props.checkboxName}
                onChange={props.onCheckboxClick}
                label={props.checkboxLabel}
            />
            {props.checked === true &&
                <Select
                    name={props.selectFieldName}
                    options={props.selectFieldSelectOptions}
                    value={props.selectFieldSelectedOptions}
                    isClearable={props.isSelectFieldClearable}
                    isMulti={props.isSelectFieldMulti}
                    closeMenuOnSelect={!props.isSelectFieldMulti}
                    className={`mt-3 inset-shadow ${props.classNames !== undefined ? props.classNames : '' + props.hasErrors !== undefined && props.hasErrors === true ? ' is-invalid form-control' : ''}`}
                    placeholder="Bitte wählen"
                    onChange={(newValue: OnChangeValue<SelectOption<any>, boolean>): void => {
                        if (props.onSelectFieldChange !== undefined) {
                            if (Array.isArray(newValue) === true) {
                                props.onSelectFieldChange((newValue as Array<SelectOption<any>>).map((selectOption: SelectOption<any>): any => selectOption.value));
                            } else {
                                props.onSelectFieldChange((newValue as SelectOption<any>)?.value);
                            }
                        }
                    }}
                />
            }
        </>
    );
};

export default CheckboxWithRelatedSelectField;
