import Product from '../Entity/Product/Product';
import ProductType from '../Entity/Product/ProductType';
import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import ProductResponse from '../../../api/Llasm/Product/ProductResponse';
import {AxiosResponse} from 'axios';

class ProductService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    private readonly resourcePath: string;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
        this.resourcePath = '/products';
    }

    public async fetchProductsFromApi(productType?: ProductType): Promise<Product[]> {
        const queryParameter: string = (productType !== undefined) ? '?productType=' + productType : '' ;

        const apiResponse: AxiosResponse<ProductResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath + queryParameter);

        return apiResponse.data.map((productResponse: ProductResponse): Product => {
            return Product.createFromProductResponse(productResponse);
        });
    }

    public async fetchProductFromApiById(id: number): Promise<Product> {
        const apiResponse: AxiosResponse<ProductResponse> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + id);

        return Product.createFromProductResponse(apiResponse.data);
    }
}

export default ProductService;
