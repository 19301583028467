import FormData from '../Entity/Form/FormData';
import SpecialObjectCategory from '../Entity/Property/SpecialObjectCategory';
import PropertyAcquisitionType from '../Entity/PropertyAcquisitionType';
import PurchaseDetail from '../Entity/Property/OfferDetail/PurchaseDetail';
import InputField from '../Component/Form/Field/InputField';
import TextAreaField from '../Component/Form/Field/TextAreaField';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import React, {useEffect} from 'react';

interface PurchaseDetailFormProps {
    readonly formData: FormData<PurchaseDetail>;
    readonly setFormData: (formData: FormData<PurchaseDetail> | ((prevState: FormData<PurchaseDetail>) => FormData<PurchaseDetail>)) => void;
    readonly validationDefinitions: FieldValidationDefinition<PurchaseDetail>[];
    readonly specialObjectCategories: SpecialObjectCategory[];
    readonly propertyAcquisitionTypes: PropertyAcquisitionType[];
}

const PurchaseDetailForm = (props: PurchaseDetailFormProps): React.JSX.Element => {
    const purchaseDetail: PurchaseDetail = props.formData.data;

    useEffect((): void => {
        props.setFormData((prevState: FormData<PurchaseDetail>): FormData<PurchaseDetail> => ({...prevState, formValidationHandler: new FormValidationHandler<PurchaseDetail>(props.validationDefinitions)}));
    }, [props.validationDefinitions]);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
        switch (event.target.name) {
            case 'purchasePrice':
                if (event.target.value === '') {
                    purchaseDetail.purchasePrice = null;
                } else {
                    purchaseDetail.purchasePrice = Number(event.target.value);
                }

                break;
            case 'commissionAmount':
                if (event.target.value === '') {
                    purchaseDetail.commissionAmount = null;
                } else {
                    purchaseDetail.commissionAmount = event.target.value;
                }

                break;
            case 'commissionNote':
                if (event.target.value === '') {
                    purchaseDetail.commissionNote = null;
                } else {
                    purchaseDetail.commissionNote = event.target.value;
                }

                break;
            default:
                (purchaseDetail as any)[event.target.name] = event.target.value;

                break;
        }

        updateFormData();
        validateField(event.target.name);
    };

    const updateFormData = (): void => {
        props.setFormData((prevState: FormData<PurchaseDetail>): FormData<PurchaseDetail> => ({...prevState, data: purchaseDetail}));
    };

    const validateField = (fieldName: string): void => {
        if (props.formData.formValidationHandler === undefined) {
            return;
        }

        props.formData.formValidationHandler.validateField(fieldName, props.formData);
        props.setFormData((prevState: FormData<PurchaseDetail>): FormData<PurchaseDetail> => ({...prevState, errors: props.formData.errors}));
    };

    return (
        <>
            <div className="fw-bold mb-3">Kaufinformationen</div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="mb-3">
                        <InputField
                            name="purchasePrice"
                            label="Kaufpreis"
                            type="number"
                            suffix="€"
                            min={0}
                            required={false}
                            value={purchaseDetail.purchasePrice ?? undefined}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <InputField
                            name="commissionAmount"
                            label="Provision bei Kaufvertragsabschluss"
                            description="Sofern eine Provision für den Erwerber fällig ist, sollte diese hier angegeben werden. Bei provisionsfreien Angeboten kann das Feld leer bleiben."
                            type="text"
                            required={false}
                            value={purchaseDetail.commissionAmount ?? undefined}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="mb-3">
                        <TextAreaField
                            style={{gridRowEnd: 'span 5', alignSelf: 'baseline'}}
                            name="commissionNote"
                            label="Provision (Erläuterung)"
                            rows={4}
                            required={false}
                            value={purchaseDetail.commissionNote ?? undefined}
                            description="Hier kannst Du weitere Informationen (z.B. Art der Käuferprovision, Fälligkeit der Käuferrovision, Verweis auf die AGB, rechtliche Hinweise...) zur geltenden Provisionsvereinbarung geben."
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PurchaseDetailForm;
