import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import PropertyCriteria from '../Entity/SettlementConcept/PropertyCriteria';
import PlaceCriteria from '../Entity/SettlementConcept/PlaceCriteria';
import IndustryClassification from '../Entity/IndustryClassification/IndustryClassification';
import Place from '../Entity/Location/Place';
import Contact from '../Entity/Contact/Contact';
import Image from '../Entity/Image/Image';
import Document from '../Entity/Document/Document';
import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import SettlementConceptResponse from '../../../api/Llasm/SettlementConcept/SettlementConceptResponse';
import ImageResponse from '../../../api/Llasm/Image/ImageResponse';
import DocumentResponse from '../../../api/Llasm/Document/DocumentResponse';
import SettlementConceptPatchRequest from '../../../api/Llasm/SettlementConcept/SettlementConceptPatchRequest';
import SettlementConceptPostRequest from '../../../api/Llasm/SettlementConcept/SettlementConceptPostRequest';
import PropertyCriteriaPatchRequest from '../../../api/Llasm/SettlementConcept/PropertyCriteriaPatchRequest';
import PropertyCriteriaPostRequest from '../../../api/Llasm/SettlementConcept/PropertyCriteriaPostRequest';
import PlaceCriteriaRequest from '../../../api/Llasm/SettlementConcept/PlaceCriteriaRequest';
import {AxiosResponse} from 'axios';

class SettlementConceptService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    private readonly resourcePath: string;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
        this.resourcePath = '/settlement-concepts';
    }

    public async fetchSettlementConceptsFromApi(): Promise<SettlementConcept[]> {
        const apiResponse: AxiosResponse<SettlementConceptResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath);

        return apiResponse.data.map((settlementConceptResponse: SettlementConceptResponse): SettlementConcept => {
            return SettlementConcept.createFromSettlementConceptResponse(settlementConceptResponse);
        });
    }

    public async fetchSettlementConceptFromApiById(id: number): Promise<SettlementConcept> {
        const apiResponse: AxiosResponse<SettlementConceptResponse> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + id);

        const settlementConceptResponse: SettlementConceptResponse = apiResponse.data;

        return SettlementConcept.createFromSettlementConceptResponse(settlementConceptResponse);
    }

    public async fetchImagesFromSettlementConcept(settlementConcept: SettlementConcept): Promise<Image[]> {
        const apiResponse: AxiosResponse<ImageResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + settlementConcept.id + '/images');

        return apiResponse.data.map((imageResponse: ImageResponse): Image => {
            return Image.createFromImageResponse(imageResponse);
        });
    }

    public async fetchDocumentsFromSettlementConcept(settlementConcept: SettlementConcept): Promise<Document[]> {
        const apiResponse: AxiosResponse<DocumentResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + settlementConcept.id + '/documents');

        return apiResponse.data.map((documentResponse: DocumentResponse): Document => {
            return Document.createFromDocumentResponse(documentResponse);
        });
    }

    public async deleteImageFromSettlementConcept(settlementConcept: SettlementConcept, image: Image): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.delete('/settlement-concepts/' + settlementConcept.id + '/images/' + image.id);
    }

    public async persistSettlementConcept(settlementConcept: SettlementConcept): Promise<SettlementConcept> {
        const response: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, SettlementConceptPostRequest>(
            '/settlement-concepts',
            SettlementConceptService.mapFromSettlementConceptToSettlementConceptPostRequest(settlementConcept)
        );

        const apiResponse: AxiosResponse<SettlementConceptResponse> = await this.llasmApiV1Provider.getFromLocation(response.headers.location);

        return SettlementConcept.createFromSettlementConceptResponse(apiResponse.data);
    }

    public async updateSettlementConcept(settlementConcept: SettlementConcept): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.patch<null, AxiosResponse<null>, SettlementConceptPatchRequest>(
            this.resourcePath + '/' + settlementConcept.id,
            SettlementConceptService.mapFromSettlementConceptToSettlementConceptPatchRequest(settlementConcept)
        );
    }

    public static buildImageApiPath(settlementConcept: SettlementConcept, image: Image): string {
        return '/settlement-concepts/' + settlementConcept.id + '/images/' + image.id;
    }

    public static buildThumbnailImageApiPath(settlementConcept: SettlementConcept, image: Image): string {
        return '/settlement-concepts/' + settlementConcept.id + '/thumbnail-images/' + image.id;
    }

    public static buildDocumentApiPath(settlementConcept: SettlementConcept, document: Document): string {
        return '/settlement-concepts/' + settlementConcept.id + '/documents/' + document.id;
    }

    private static mapFromSettlementConceptToSettlementConceptPostRequest(settlementConcept: SettlementConcept): SettlementConceptPostRequest {
        return SettlementConceptService.mapSettlementConceptToApiRequest(settlementConcept) as SettlementConceptPostRequest;
    }

    private static mapFromSettlementConceptToSettlementConceptPatchRequest(settlementConcept: SettlementConcept): SettlementConceptPatchRequest {
        return SettlementConceptService.mapSettlementConceptToApiRequest(settlementConcept) as SettlementConceptPatchRequest;
    }

    private static mapSettlementConceptToApiRequest(settlementConcept: SettlementConcept): SettlementConceptPostRequest | SettlementConceptPatchRequest {
        return {
            active: settlementConcept.active,
            brandId: settlementConcept.brand.id,
            title: settlementConcept.title,
            description: settlementConcept.description,
            propertyCriteria: SettlementConceptService.mapPropertyCriteriaToApiRequest(settlementConcept.propertyCriteria),
            desiredMinimumLeaseTerm: settlementConcept.desiredMinimumLeaseTerm,
            interestPopupSpace: settlementConcept.interestPopupSpace,
            onlyPopupSpace: settlementConcept.onlyPopupSpace,
            interestMixedUseSpace: settlementConcept.interestMixedUseSpace,
            onlyMixedUseSpace: settlementConcept.onlyMixedUseSpace,
            placeCriterias: settlementConcept.placeCriterias.map((placeCriteria: PlaceCriteria): PlaceCriteriaRequest => SettlementConceptService.mapPlaceCriteriaToApiRequest(placeCriteria)),
            contactIds: settlementConcept.contacts.map((contact: Contact): number => contact.id!),
            industryClassificationIds: settlementConcept.industryClassifications.map((industryClassification: IndustryClassification): number => industryClassification.id!)
        };
    }

    private static mapPropertyCriteriaToApiRequest(propertyCriteria: PropertyCriteria): PropertyCriteriaPostRequest | PropertyCriteriaPatchRequest {
        return {
            locationTypes: propertyCriteria.locationTypes,
            locationCategories: propertyCriteria.locationCategories,
            locationFactors: propertyCriteria.locationFactors,
            propertyAcquisitionTypes: propertyCriteria.propertyAcquisitionTypes,
            desiredRetailEnvironment: propertyCriteria.desiredRetailEnvironment,
            areaSizeMinimum: propertyCriteria.areaSizeMinimum,
            areaSizeMaximum: propertyCriteria.areaSizeMaximum,
            secondaryAreaSize: propertyCriteria.secondaryAreaSize,
            outdoorSalesAreaRequired: propertyCriteria.outdoorSalesAreaRequired,
            outdoorSalesAreaSize: propertyCriteria.outdoorSalesAreaSize,
            storeWidth: propertyCriteria.storeWidth,
            shopWindowFrontLength: propertyCriteria.shopWindowFrontLength,
            groundLevelSalesAreaRequired: propertyCriteria.groundLevelSalesAreaRequired,
            barrierFreeAccessRequired: propertyCriteria.barrierFreeAccessRequired,
            parkingLotRequirementType: propertyCriteria.parkingLotRequirementType,
            otherRequirements: propertyCriteria.otherRequirements
        };
    }

    private static mapPlaceCriteriaToApiRequest(placeCriteria: PlaceCriteria): PlaceCriteriaRequest {
        return {
            cityPopulationMinimum: placeCriteria.cityPopulationMinimum,
            cityPopulationMaximum: placeCriteria.cityPopulationMaximum,
            targetedCitySearch: placeCriteria.targetedCitySearch,
            countryPlaceId:  placeCriteria.countryPlace.id!,
            expansionStateIds: placeCriteria.expansionStates.map((place: Place): number => place.id!),
            expansionCityIds: placeCriteria.expansionCities.map((place: Place): number => place.id!),
        };
    }
}

export default SettlementConceptService;
